import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toolbarConfig from '../App/toolbarconfig';
import { AddNoteAPI } from '../../api/IncidentAPI';

const AddNote = props => {
    const [newNoteHoursDecmial, setNewNoteHoursDecimal] = useState(0.00);
    const [newNoteHours, setNewNoteHours] = useState(0);
    const [newNoteMinutes, setNewNoteMinutes] = useState(0);
    const [newNoteType, setNewNoteType] = useState(31);
    const [newNoteDate, setNewNoteDate] = useState(new Date());
    const [newNoteText, setNewNoteText] = useState("");
    const [newNoteCloseIncident, setNewNoteCloseIncident] = useState(false);

    /*
    These functions can probably be cleaned up a bit.  There were problems with non-numeric values
    (specifically empty string when an input is cleared) that I had to work around.
    Hours and Minutes vs. Decimal hours must be tracked separately in state due to approximation
    in the deicmal value.
    */
    const newNoteHoursDecimalChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 20 ? 20 : value < 0 ? 0 : value
        setNewNoteHoursDecimal(adjustedValue);
        const hours = Math.floor(adjustedValue);
        const minutes = Math.round((adjustedValue - hours) * 60);
        setNewNoteHours(hours);
        setNewNoteMinutes(minutes);
    }
    const newNoteHoursChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 21 ? 20 : value < 0 ? 0 : value;
        setNewNoteHours(adjustedValue);
        const adjustedNoteHoursDecimal = parseFloat(adjustedValue) + parseFloat(newNoteMinutes / 60);
        setNewNoteHoursDecimal(isNaN(parseFloat(adjustedNoteHoursDecimal)) ?
            0 : adjustedNoteHoursDecimal.toFixed(2));
    }
    const newNoteMinutesChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 60 ? 59 : value < 0 ? 0 : value;
        setNewNoteMinutes(adjustedValue);
        const adjustedNoteHoursDecimal = parseInt(newNoteHours, 10) + (adjustedValue / 60)
        setNewNoteHoursDecimal(isNaN(parseFloat(adjustedNoteHoursDecimal)) ?
            0 : adjustedNoteHoursDecimal.toFixed(2));
    }
    const createNote = () => {
        props.setLoading(true);
        let payload;
        if (props.newNoteChangeStatus === 17) {
            payload = {
                incidentID: parseInt(props.incidentID, 10),
                noteDate: newNoteDate,
                noteType: parseInt(newNoteType, 10),
                hours: parseFloat(newNoteHoursDecmial),
                noteText: newNoteText,
                closeIncident: newNoteCloseIncident,
                assignTo: parseInt(props.newNoteReassign, 10),
                changeStatus: parseInt(19, 10)
            }
        } else {
            payload = {
                incidentID: parseInt(props.incidentID, 10),
                noteDate: newNoteDate,
                noteType: parseInt(newNoteType, 10),
                hours: parseFloat(newNoteHoursDecmial),
                noteText: newNoteText,
                closeIncident: newNoteCloseIncident,
                assignTo: parseInt(props.newNoteReassign, 10),
                changeStatus: parseInt(props.newNoteChangeStatus, 10)
            }
        }
        AddNoteAPI(payload).then(response => {
            if (!response.success) {
                console.log(response);
                return;
            }
            props.setAddNote(false);
            setNewNoteDate(new Date());
            setNewNoteType(31);
            setNewNoteHoursDecimal(0.08);
            setNewNoteHours(0);
            setNewNoteMinutes(5);
            setNewNoteText("");
            setNewNoteCloseIncident(false);
            return props.loadIncident();
        }).then(response => {
            props.setIncidentInfo(response.content);
            props.setNewNoteReassign(response.content.assignedToID);
            props.setNewNoteChangeStatus(response.content.statusID);
            props.setNewNoteReassign(response.content.assignedToID);
            props.setNewNoteChangeStatus(response.content.statusID);
            props.setLoading(false);
            props.loadMissingTime();
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <div className="incidentNote">
            <table className="nextTable incidentTable">
                <tbody>
                    <tr>
                        <th colSpan="2">New Note</th>
                    </tr>
                    <tr>
                        <th style={{ width: "180px" }}>Note Date / Time:</th>
                        <td>
                            <DateTimePicker value={newNoteDate}
                                onChange={date => setNewNoteDate(date)}
                                clearIcon={null} />
                        </td>
                    </tr>
                    <tr>
                        <th>Note Type:</th>
                        <td>
                            <select value={newNoteType} onChange={e => setNewNoteType(e.target.value)}>
                                <option value={31}>Tech Note</option>
                                <option value={32}>Internal Note</option>
                            </select>
                        </td>
                    </tr>
                    {parseInt(newNoteType, 10) === 31 ? (
                        <tr>
                            <th>Hours:</th>
                            <td>
                                <input type="number" step="0.01"
                                    autoFocus={true}
                                    onFocus={e => { window.scrollTo(0, document.body.scrollHeight); e.target.focus() }}
                                    value={newNoteHoursDecmial}
                                    style={{ width: "45px" }}
                                    onChange={e => newNoteHoursDecimalChanged(e.target.value)}
                                    onBlur={e => {
                                        if (isNaN(parseFloat(e.target.value))) {
                                            newNoteHoursDecimalChanged(0)
                                        }
                                    }} />
                                <span style={{ marginLeft: "14px" }}>
                                    (<input type="number" value={newNoteHours} style={{ width: "35px" }}
                                        onChange={e => newNoteHoursChanged(e.target.value)}
                                        onBlur={e => {
                                            if (isNaN(parseFloat(e.target.value))) {
                                                newNoteHoursChanged(0);
                                            }
                                        }} /> Hours
                                    <input type="number" value={newNoteMinutes}
                                        style={{ width: "35px", marginLeft: "8px" }}
                                        onChange={e => newNoteMinutesChanged(e.target.value)}
                                        onBlur={e => {
                                            if (isNaN(parseFloat(e.target.value))) {
                                                newNoteMinutesChanged(0);
                                            }
                                        }} /> Minutes)
                                </span>
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
            <ReactQuill
                value={newNoteText} onChange={e => setNewNoteText(e)}
                modules={{ toolbar: toolbarConfig }} />
            <table className="nextTable incidentTable">
                <tbody>
                    <tr>
                        <th style={{ width: "180px" }}>Close Incident:</th>
                        <td style={{ backgroundColor: "#cccccc" }}>
                            <input type="checkbox" checked={newNoteCloseIncident}
                                onChange={e => setNewNoteCloseIncident(e.target.checked)} />
                        </td>
                    </tr>
                    <tr>
                        <th>Reassign:</th>
                        <td style={{ backgroundColor: "#cccccc" }}>
                            <select value={props.newNoteReassign} onChange={e =>
                                props.setNewNoteReassign(e.target.value)}>
                                {props.assignToList.map((contact, idx) =>
                                    <option key={contact.itemID ? contact.itemID : idx}
                                        value={contact.itemID}>
                                        {contact.itemName}
                                    </option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Change Status:</th>
                        <td style={{ backgroundColor: "#cccccc" }}>
                            {props.newNoteChangeStatus === 17 ? (
                                <select value={19} onChange={e =>
                                    props.setNewNoteChangeStatus(e.target.value)}>
                                    {props.incidentStatuses.map(status =>
                                        <option key={status.itemID} value={status.itemID}>
                                            {status.itemName}
                                        </option>
                                    )}
                                </select>
                            ): (
                                <select value={props.newNoteChangeStatus} onChange={e =>
                                props.setNewNoteChangeStatus(e.target.value)}>
                                {props.incidentStatuses.map(status =>
                                    <option key={status.itemID} value={status.itemID}>
                                        {status.itemName}
                                    </option>
                                )}
                                </select>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={{ backgroundColor: "#a20107", textAlign: "center", padding: "8px" }}>
                <input type="button" className="nexusButtonCommon" value="Add Note"
                    onClick={() => createNote()} />
                <input type="button" className="nexusButtonCommon" value="Cancel"
                    onClick={() => props.setAddNote(false)} />
            </div>
        </div>
    )
}
export default AddNote;