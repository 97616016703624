import APIRequest from './APIRequest';

export const GetProject = (projectID) => {
    return APIRequest({
        url: `Project/GetProject`,
        method: 'post',
        body: { content: projectID }
    });
}

export const GetProjectIncidents = (date) => {
    return APIRequest({
        url: `Project/GetProjectIncidents`,
        method: 'post',
        body: { selectedDate: date }
    })
}