import React from 'react';
import { Link } from 'react-router-dom';


const tabview = props => {
    return (
        <div>
            <ul className="tabList">
                {props.tabs.map(tab =>
                    <li key={tab.label} className={window.location.pathname.startsWith(tab.url) ? "tabActive" : "tabInactive"}>
                        <Link to={tab.url}>{tab.label}</Link>
                    </li>
                )}
            </ul>
            <div className="tabDisplayArea">
                {props.children}
            </div>
        </div>
    )
}

export default tabview;