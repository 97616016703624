import React, { useEffect, useState } from 'react';
import { GetOrganizationsNum } from '../../api/OrganizationAPI';
import { Accordion, NavLink } from 'react-bootstrap';
import AccordionWidget from './AccordionWidget';

const TotalOrganizations = () => {
    const [numOrgs, setNumOrgs] = useState(0);

    useEffect(() => {
        GetOrganizationsNum().then((response) => {
            if(response.success) {
                setNumOrgs(response.content);
            }
        }).catch((e) => console.error(e));
    }, []);

    return (        
        <div className="col-6 pr-1">
            <AccordionWidget title={'Total Organizations'} dashboardPreference={'TotalOrganizations'}>
                <h4 className="text-center m-4">{numOrgs}</h4>
            </AccordionWidget>
        </div>
    );
}

export default TotalOrganizations;