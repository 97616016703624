import APIRequest from './APIRequest';

export const GetTenants = () => {
    return APIRequest({
        url: `Tenant/GetTenants`,
        method: 'post',
        body: {},
    });
};

export const GetCookieTenants = (tenantURI) => {
    return APIRequest({
        url: `Tenant/GetCookieTenants`,
        method: 'post',
        body: {
            content: tenantURI,
        },
    });
};

export const CheckTenantURI = (tenantURI) => {
    return APIRequest({
        url: `Tenant/CheckTenantURI`,
        method: 'post',
        body: {
            content: tenantURI,
        },
    });
};

export const GetTenant = (tenantId) => {
    return APIRequest({
        url: `Tenant/GetTenant`,
        method: 'post',
        body: {
            content: tenantId,
        },
    });
};

export const AddTenant = (payload) => {
    return APIRequest({
        url: `Tenant/AddTenant`,
        method: 'post',
        body: payload,
    });
};

export const EditTenant = (payload) => {
    return APIRequest({
        url: `Tenant/EditTenant`,
        method: 'post',
        body: payload,
    });
};

export const DisableTenant = (payload) => {
    return APIRequest({
        url: `Tenant/DisableTenant`,
        method: 'post',
        body: payload,
    });
};
