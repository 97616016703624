import React, { Component } from 'react'
import { Accordion } from 'react-bootstrap'
import NetworkAddresses from './NetworkAddresses.js'

export default props => {

  const renderBool = (bool) => {
    if (bool) {
      return "True"
    }
    else {
      return "False"
    }
  }

  const valueOrNA = (value) => {
    if (value === null || value === "") {
      return "Unavailable"
    }
    else {
      return value
    }
  }

    return (
      <div>
        <h3>Configuration:</h3>
        <div className="col">
          <div className="row">
            <p className="align-right" >MAC Address:</p>
            <p className="align-left">{valueOrNA(props.interface.macAddress)}</p>
          </div>
          <div className="row">
            <p className="align-right" >DNS Suffix:</p>
            <p className="align-left">{valueOrNA(props.interface.dnsSuffix)}</p>
          </div>
          <div className="row">
            <p className="align-right" >DNS Enabled:</p>
            <p className="align-left">{valueOrNA(renderBool(props.interface.isDNSEnabled))}</p>
          </div>
          <div className="row">
            <p className="align-right">Dynamic DNS Enabled:</p>
            <p className="align-left">{valueOrNA(renderBool(props.interface.isDynamicDnsEnabled))}</p>
          </div>
        </div>

        <div className="row" style={{ borderTop: " 1px solid gray", borderBottom: "1px solid gray" }}>
          <div className="col" style={{ borderRight: "1px solid gray" }}>
            <h4 style={{ textAlign: "center" }} > IPv4 Configuration</h4>


            <div className="row">
              <p className="align-right-wide"> Index:</p>
              <p className="align-left">{valueOrNA(props.interface.iPv4Configuration.index)}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> Auto Private Addressing Active:</p>
              <p className="align-left">{valueOrNA(renderBool(props.interface.iPv4Configuration.isAutomaticPrivateAddressingActive))}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> Auto Private Addressing Enabled:</p>
              <p className="align-left">{valueOrNA(renderBool(props.interface.iPv4Configuration.isAutomaticPrivateAddressingEnabled))}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> DHCP Enabled:</p>
              <p className="align-left">{valueOrNA(renderBool(props.interface.iPv4Configuration.isDHCPEnabled))}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> Forwarding Enabled:</p>
              <p className="align-left">{valueOrNA(renderBool(props.interface.iPv4Configuration.isForwardingEnabled))}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> Uses WINS:</p>
              <p className="align-left">{valueOrNA(renderBool(props.interface.iPv4Configuration.usesWins))}</p>
            </div>
            <div className="row">
              <p className="align-right-wide"> MTU:</p>
              <p className="align-left">{valueOrNA(props.interface.iPv4Configuration.mtu)}</p>
            </div>


          </div>
          <div className="col" style={{ textAlign: "center" }}>
            <h4 style={{ textAlign: "center" }}>IPv6 Configuration</h4>
            <div className="row">
              <p className="align-right"> MTU:</p>
              <p className="align-left">{valueOrNA(props.interface.iPv4Configuration.mtu)}</p>
            </div>
            <div className="row">
              <p className="align-right"> Index:</p>
              <p className="alignn-left">{valueOrNA(props.interface.iPv4Configuration.index)}</p>
            </div>
          </div>
        </div>
        <div className="address-container">
          <Accordion>
            <NetworkAddresses eventkey={1} addresses={props.interface.dnsAddresses} type="DNS" />
            <NetworkAddresses eventkey={2} addresses={props.interface.dhcpServerAddresses} type="DHCP" />
            <NetworkAddresses eventkey={3} addresses={props.interface.gatewayAddresses} type="Gateway" />
            <NetworkAddresses eventkey={4} addresses={props.interface.winsServerAddresses} type="WINS" />
            <NetworkAddresses eventkey={5} addresses={props.interface.unicastAddresses} type="Unicast" />
          </Accordion>
        </div>

      </div>
    )
  }