import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../App/loader';
import { GetAssets, UpdateBulkAssetRequest, GetXBertVersions } from '../../api/AssetAPI';
import { useDispatch, useSelector } from 'react-redux';
import XBertOnlineIndicator from './xBertOnlineIndicator';
import { Alert } from 'react-bootstrap';
import NexusModal from '../App/NexusModal.js';
import NexusGrid from '../App/NexusGrid.js';

const AssetTable = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [displayUpdateModal, setDisplayUpdateModal] = useState(false);
    const versionToRequest = useRef(0);
    const [xBertVersionList, setxBertVersionList] = useState([]); 
    const [updateRequestSuccess, setUpdateRequestSuccess] = useState(false);
    const [updateRequestFailed, setUpdateRequestFailed] = useState(false);


    /* const canUserCreateAsset = useSelector(
        (state) => state.UserReducer.canModifyAssets
    );
    TODO: issue #40 in Nexus-Core GitLab to create Assets
        */
    const OnlineAssets = useSelector(state => state.WebSocketReducer.AssetsOnline);
    const test = useSelector(state => state.TenantReducer.Tenant);
    const orgid = 0;

    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);

    const getAssets = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        setFilterResults(filters);
        setSortByResults(sortBy);
        firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
        firstRender.current = false;
        GetAssets({pageSize, pageIndex, filters, sortBy})
            .then((response) => {
                setLoadingFilterSort(false);
                setLoading(false);
                if (response.success) {
                    setAssets(response.content);
                    setTotalResults(response.totalCount);
                    setTotalPages(response.pageCount)
                }
            })
            .catch(() => {
                setLoadingFilterSort(false);
                setLoading(false);
            });
            GetXBertVersions().then((res) => {
                if(res?.success) {
                    setxBertVersionList(res.content);
                    versionToRequest.current = res.content[0].xBertVersionID;
                }
            })
    }, []);

    const columns = [
        { Header: 'Asset ID', accessor: 'assetID' },
        { Header: 'Name', accessor: 'assetName' },
        { Header: 'Org', accessor: 'organizationName' },
        { 
            Header: 'xBert Installed',
            accessor: 'xBertEnabled',
            Cell: (row) => { return row.row.original.xBertEnabled ? "true": "false"; }
        },
        { Header: 'Last Seen', accessor:  'xBertLastSeen' },
        {
            Header: 'xBert Online',
            accessor: ' xBertOnline',
            Cell: (row) => {
                //console.log(row.row.original);
                return <XBertOnlineIndicator assetID={row.row.original.assetID} />;
            },
        },
        { Header: 'xBert Version', accessor: 'xBertVersionName' }
        //this.PatchStatus = PatchStatus; { colLabel: 'Patch Status', colName:  'patchStatus' },
        //this.Uptime = Uptime; { colLabel: 'Uptime', colName:  'uptime' },
        //functions column: ping, gpupdate
    ];
    const hiddenColumns = ['assetID'];

    /* const addOrgToggle = canUserCreatePerson ? (
        <Link to="/organizations/add">
            <div className="nexusButtonCommon">
                <i className="fas fa-plus"></i> Add Asset
            </div>
        </Link>
    ) : null;
    See above TODO:
    */

    const markAssetsToUpdate = () => {
        UpdateBulkAssetRequest({
            AssetIDs: selectedAssets,
            VersionID: versionToRequest.current
        }).then(res => {
            if(res.success) {
                setUpdateRequestSuccess(true);
            } else {
                setUpdateRequestFailed(true);
            }
            setDisplayUpdateModal(false);
            versionToRequest.current = xBertVersionList[0].xBertVersionID;
            setSelectedAssets([]);
        });
    }

    const content = (
        <div>
            <h2>Assets</h2>
            <Link to={{ pathname: `/${tenant}/assets/add`, state: orgid }}>
                <div className="nexusButtonCommon">
                    <i className="fas fa-plus"></i> Add Asset
                </div>
            </Link>
            <Link to={{ pathname: `/${tenant}/assetTypes`}}>
                <div className="nexusButtonCommon">
                    View Asset Types
                </div>
            </Link>
            <div className="bootstrap-wrapper">
                <Alert show={updateRequestFailed} variant="danger" dismissible onClose={() => setUpdateRequestFailed(false)}>
                    Asset update request failed
                </Alert>
                <Alert show={updateRequestSuccess} variant="success" dismissible onClose={() => setUpdateRequestSuccess(false)}>
                    Asset update request succeeded
                </Alert>
            </div>

            <NexusModal stateValue={displayUpdateModal} stateFunctionClose={() => setDisplayUpdateModal(false)}
            title={`Request xBert Updates for ${selectedAssets.length} Asset${selectedAssets.length > 1 ? 's' : ''}`}
            closeText="Update" closeAction={() => markAssetsToUpdate()} width='50%' height='auto'>
                <div className="bootstrap-wrapper">
                    <select className='mb-1' onChange={(e) => versionToRequest.current = e.target.value} style={{ width: '100%' }}>
                        {xBertVersionList.map((v) => <option key={v.xBertVersionID} value={v.xBertVersionID}>{v.name}</option>)}
                    </select>
                    <p className='text-dark m-0'>
                        <b>Note: </b> If the asset is already on the version requested or higher, it will stay at that version.
                    </p>
                </div>
            </NexusModal>

            <NexusGrid
                columns={columns}
                data={assets}
                hiddenColumns={hiddenColumns}
                linkTo={`/${tenant}/assets/:key/info`}
                tableKey='assetID'
                defaultSortBy={'assetName'}
                selectRowMode={'checkbox'}
                setSelectedRows={setSelectedAssets}
                selectedRows={selectedAssets}
                bulkActionFuncs={[ {text: 'Update', func: () => { setDisplayUpdateModal(true); } }]}
                fetchData={getAssets}
                filterData={filterResults}
                sortByData={sortByResults}
                loading={loading}
                loadingFilterSort={loadingFilterSort}
                pageMode="remote"
                perPage={25}
                totalResults={totalResults}
                totalPages={totalPages}
                defaultSortOrder={"asc"}
            />
        </div>
    );

    return content;
};

export default AssetTable;
