import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationTrunk, DisableOrganizationTrunks } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';


export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const canEdit = true;
    const [trunk, setTrunk] = useState({});
    const trunkTypes=([{label: "Customer", value:1},{label: "Carrier", value:2},]);

    useEffect(() => {
        const trunkId = props.routeProps.match.params.trunkid;
        const orgID = Number(props.routeProps.match.params.id);
        GetOrganizationTrunk({ content: { trunkid: Number(trunkId), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setTrunk(res.content[0]);
                        setLoaded(true);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const disableTrunk = () => {
        DisableOrganizationTrunks({
            content: {
                trunkId: Number(props.routeProps.match.params.trunkid),
                active: !trunk.active
            }
        })
            .then(res => {
                var obj = trunk;
                obj.active = !obj.active;
                setTrunk(obj);
            })
    }

    const getTrunkTypeLabel = (typeID) => {
        var type = trunkTypes.filter((f) => f.value === typeID);
        if (type.length > 0)
            return type[0].label;
        else
            return "";
    }

        return (
            <div className="telephony-trunk-info">
                {loaded ? (
                    <div>
                        <h3>{trunk.trunkName}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Trunk Name</th>
                                    <td>{trunk.trunkName}</td>
                                </tr>
                                <tr>
                                    <th>Details</th>
                                    <td><pre>{trunk.trunkDetails}</pre></td>
                                </tr>
                                <tr>
                                    <th>Trunk Type</th>
                                    <td>{getTrunkTypeLabel(trunk.trunkType)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />
                        <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                        <button type="submit" onClick={() => disableTrunk()} className="nexusButtonCommon">{trunk.active ? "Disable" : "Enable"}</button>
                        {canEdit ? (
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/trunk/" + trunk.trunkId + "/edit"} className="nexusButtonCommon">Edit</Link>) : ("")}
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}