import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetOrganizations } from '../../../api/OrganizationAPI';
import { CreatePerson } from '../../../api/PeopleAPI';
import classes from './PeopleForm.module.css';

import Loader from '../../App/loader';
import { useDispatch, useSelector } from 'react-redux';
import { sendCartData } from '../../../Redux/cart-actions';
import { userActions } from '../../../Redux/user-slice';
import { CRUD_ENUM } from '../../../enums/crud';
import * as Validation from '../../../utils/input-validators';
import Alert from 'react-bootstrap/Alert';

const nexusLabel = 'nexusFormLabel';

const PeopleForm = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const showLoader = useSelector((state) => state.UiReducer.loading);
    const canUserCreatePerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );
    
    const dispatch = useDispatch();
    const [formInputsValidity, setFormInputsValidity] = useState({
        firstName: true,
        lastName: true,
        emailAddress1: true,
        emailAddress2: true,
        mobile1: true,
        mobile2: true,
        officePhone: true,
        extension: true,
        homePhone: true,
        title: true,
        gender:  true,
        authenticationMethod: true,
        organization: true,
    });
    
    const [showMess, setShowMess]  = useState(false);
    const [showSus, setShowSus]  = useState(false);

    const firstNameInputRef = useRef();
    const lastNameInputRef = useRef();
    const emailAddress1InputRef = useRef();
    const emailAddress2InputRef = useRef();
    const mobile1InputRef = useRef();
    const mobile2InputRef = useRef();
    const officePhoneInputRef = useRef();
    const extensionInputRef = useRef();
    const homePhoneInputRef = useRef();
    const titleInputRef = useRef();
    const genderInputRef = useRef();
    const authenticationMethodInputRef = useRef();
    const organizationInputRef = useRef();

    const [organizations, setOrganizations] = useState([]);
    const toggleHandler = () => {
        dispatch(userActions.setPeopleModifyProperty(!canUserCreatePerson));
    };

    useEffect(() => {
        GetOrganizations({ content: -1})
            .then((res) => {
                setOrganizations(res.content);
            })
            .catch(() => {
                // TODO: properly handle errors
            });
    }, []);

    const submitHandler = (event) => {
        event.preventDefault();
        const enteredFirstName = firstNameInputRef.current.value;
        const enteredLastName = lastNameInputRef.current.value;
        const enteredEmailAddress1 = emailAddress1InputRef.current.value;
        const enteredEmailAddress2 = emailAddress2InputRef.current.value;
        const enteredMobile1 = mobile1InputRef.current.value;
        const enteredMobile2 = mobile2InputRef.current.value;
        const enteredExtension = extensionInputRef.current.value;
        const enteredOfficePhone = officePhoneInputRef.current.value;
        const enteredHomePhone = homePhoneInputRef.current.value;
        const enteredGender = genderInputRef.current.value;
        const enteredTitle = titleInputRef.current.value;
        const enteredAuthenticationMethod =
            +authenticationMethodInputRef.current.value;
        const enteredOrganization = +organizationInputRef.current.value;

        const enteredFirstNameIsValid =
            Validation.isValidString(enteredFirstName);
        const enteredLastNameIsValid =
            Validation.isValidString(enteredLastName);
        const enteredEmailAddress1IsValid =
            Validation.isValidString(enteredEmailAddress1);
        const enteredEmailAddress2IsValid = true;
        const enteredMobile1IsValid = true;
        const enteredMobile2IsValid = true;
        const enteredExtensionIsValid = true;
        const enteredOfficePhoneIsValid = true;
        const enteredHomePhoneIsValid = true;
        const enteredGenderIsValid = true;
        const enteredTitleIsValid = true;
        const enteredAuthenticationMethodIsValid = !isNaN(
            enteredAuthenticationMethod
        );
        const enteredOrganizationIsValid = true;

        setFormInputsValidity({
            firstName: enteredFirstNameIsValid,
            lastName: enteredLastNameIsValid,
            emailAddress1: enteredEmailAddress1IsValid,
            emailAddress2: enteredEmailAddress2IsValid,
            mobile1: enteredMobile1IsValid,
            mobile2: enteredMobile2IsValid,
            officePhone: enteredOfficePhoneIsValid,
            extension: enteredExtensionIsValid,
            homePhone: enteredHomePhoneIsValid,
            title: enteredTitleIsValid,
            gender: enteredGenderIsValid,
            authenticationMethod: enteredAuthenticationMethodIsValid,
            organization: enteredOrganizationIsValid,
        });

        const formIsValid =
            enteredFirstNameIsValid &&
            enteredLastNameIsValid &&
            enteredEmailAddress1IsValid &&
            enteredEmailAddress2IsValid &&
            enteredMobile1IsValid &&
            enteredMobile2IsValid &&
            enteredExtensionIsValid &&
            enteredOfficePhoneIsValid &&
            enteredHomePhoneIsValid &&
            enteredGenderIsValid &&
            enteredTitleIsValid &&
            enteredAuthenticationMethodIsValid;

        if (!formIsValid) {
            setShowMess(true);
            return; // don't allow submission
        }
        
        setShowMess(false);
        setShowSus(true);
        
        
        const payload = {
            personID: 1,
            orgID: +enteredOrganization,
            personOrgID: 1,
            emailAddress1: enteredEmailAddress1,
            emailAddress2: enteredEmailAddress2,
            mobile1: enteredMobile1,
            mobile2: enteredMobile2,
            extension: enteredExtension,
            officePhone: enteredOfficePhone,
            homePhone: enteredHomePhone,
            firstName: enteredFirstName,
            lastName: enteredLastName,
            gender: enteredGender,
            title: enteredTitle,
            authenticationMethod: +enteredAuthenticationMethod,    
        };

        //dispatch(sendCartData(payload, CRUD_ENUM.CREATE));

        CreatePerson(payload).then((response) => {
            //
        })

        resetClickedHandler();
    };

    const Organizations = organizations.map((org) => (
        <option key={org.orgID} value={org.orgID}>
            {org.orgName}
        </option>
    ));

    const firstNameControlClasses = `${classes.control} ${
        formInputsValidity.firstName ? '' : classes.invalid
    }`;
    const lastNameControlClasses = `${classes.control} ${
        formInputsValidity.lastName ? '' : classes.invalid
    }`;
    const email1ControlClasses = `${classes.control} ${
        formInputsValidity.emailAddress1 ? '' : classes.invalid
    }`;
    const email2ControlClasses = `${classes.control} ${
        formInputsValidity.emailAddress2 ? '' : classes.invalid
    }`;
    const mobile1ControlClasses = `${classes.control} ${
        formInputsValidity.mobile1 ? '' : classes.invalid
    }`;
    const mobile2ControlClasses = `${classes.control} ${
        formInputsValidity.mobile2 ? '' : classes.invalid
    }`;
    const officePhoneControlClasses = `${classes.control} ${
        formInputsValidity.officePhone ? '' : classes.invalid
    }`;
    const extensionControlClasses = `${classes.control} ${
        formInputsValidity.extension ? '' : classes.invalid
    }`;
    const homePhoneControlClasses = `${classes.control} ${
        formInputsValidity.homePhone ? '' : classes.invalid
    }`;
    const titleControlClasses = `${classes.control} ${
        formInputsValidity.title ? '' : classes.invalid
    }`;
    const genderControlClasses = `${classes.control} ${
        formInputsValidity.gender ? '' : classes.invalid
    }`;
    const authenticationMethodControlClasses = `${classes.control} ${
        formInputsValidity.authenticationMethod ? '' : classes.invalid
    }`;
    const organizationControlClasses = `${classes.control} ${
        formInputsValidity.organization ? '' : classes.invalid
    }`;

    const resetClickedHandler = () => {
        firstNameInputRef.current.value = null;
        lastNameInputRef.current.value = null;
        emailAddress1InputRef.current.value = null;
        emailAddress2InputRef.current.value = null;
        mobile1InputRef.current.value = null;
        mobile2InputRef.current.value = null;
        officePhoneInputRef.current.value = null;
        extensionInputRef.current.value = null;
        homePhoneInputRef.current.value = null;
        titleInputRef.current.value = null;
        genderInputRef.current.value = null;
        authenticationMethodInputRef.current.value = null;
        organizationInputRef.current.value = null;
    };

    const formSubmitControls = (
        <>
            <hr />
            <div className="form-group">
                <button
                    className="btn btn-danger btn-block"
                    type="button"
                    onClick={resetClickedHandler}
                >
                    Reset Form
                </button>
                <button type="submit" className="btn btn-primary btn-block">
                    Submit
                </button>
            </div>
        </>
    );
    const userInputControls = (
        <>
            <div className="form-group">
                <label>Select an Organization</label>

                <div className={organizationControlClasses}>
                    <select
                        name="organization"
                        id="organization"
                        ref={organizationInputRef}
                    >
                        <option value="0">-- No Organization --</option>
                        {Organizations}
                    </select>
                </div>
            </div>

            <div className="form-group">
                <label>First Name</label>
                <div className={firstNameControlClasses}>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="form-control"
                        ref={firstNameInputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Last Name</label>
                <div className={lastNameControlClasses}>
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="form-control"
                        ref={lastNameInputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Email Address</label>
                <div className={email1ControlClasses}>
                    <input
                        type="text"
                        name="emailAddress1"
                        id="emailAddress1"
                        className="form-control"
                        ref={emailAddress1InputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Secondary Email Address</label>
                <div className={email2ControlClasses}>
                    <input
                        type="text"
                        name="emailAddress2"
                        id="emailAddress2"
                        className="form-control"
                        ref={emailAddress2InputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Mobile Phone</label>
                <div className={mobile1ControlClasses}>
                    <input
                        type="text"
                        name="mobile1"
                        id="mobile1"
                        className="form-control"
                        ref={mobile1InputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Secondary Mobile Phone</label>
                <div className={mobile2ControlClasses}>
                    <input
                        type="text"
                        name="mobile2"
                        id="mobile2"
                        className="form-control"
                        ref={mobile2InputRef}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Office Phone</label>
                <div className={officePhoneControlClasses}>
                    <input
                        type="text"
                        name="officePhone"
                        id="officePhone"
                        className="form-control"
                        ref={officePhoneInputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Extension</label>
                <div className={extensionControlClasses}>
                    <input
                        type="text"
                        name="extension"
                        id="extension"
                        className="form-control"
                        ref={extensionInputRef}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Home Phone</label>
                <div className={homePhoneControlClasses}>
                    <input
                        type="text"
                        name="homePhone"
                        id="homePhone"
                        className="form-control"
                        ref={homePhoneInputRef}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Title</label>
                <div className={titleControlClasses}>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        className="form-control"
                        ref={titleInputRef}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className={nexusLabel}>Gender</label>
                <div className={genderControlClasses}>
                    <select name="gender" id="gender" ref={genderInputRef}>
                        <option value="">-- Select a Gender --</option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                    </select>
                </div>
            </div>

            <div className="form-group">
                <label>Authentication Method</label>
                <div className={authenticationMethodControlClasses}>
                    <select
                        name="authenticationMethod"
                        id="authenticationMethod"
                        ref={authenticationMethodInputRef}
                    >
                        <option value="">-- Select a Method --</option>
                        <option value="0">Internal</option>
                        <option value="1">Active Directory</option>
                        <option value="2">API</option>
                    </select>
                </div>
            </div>
            {formSubmitControls}
        </>
    );

    const userInputForm = (
        <div>
            <Link className="whiteLink" to={`/${tenant}/people/`}>
                <i className="fas fa-chevron-left"></i> People List
            </Link>
            <h2>New Person</h2>
            <div className="bootstrap-wrapper">
                <Alert show={showMess} variant="danger" dismissible onClose={() => setShowMess(false)}>
                    You are missing input fields. Please enter all the required information.
                </Alert>
                <Alert show={showSus} variant="success" dismissible onClose={() => setShowSus(false)}>
                    Person was added to the database!
                </Alert>
            </div>
            <div className="nexusForm personForm">
                <form onSubmit={(e) => submitHandler(e)}>
                    {userInputControls}
                </form>

                {showLoader ? <Loader /> : null}
            </div>
        </div>
    );

    let content = canUserCreatePerson ? (
        userInputForm
    ) : (
        <p>You don't have permissions to access this feature</p>
    );

    return content;
};

export default PeopleForm;
