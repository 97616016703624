import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../App/loader';
import NexusGrid from '../../../App/NexusGrid';
import { GetRecordList } from '../../../../api/DNSServiceAPI';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        loadRecords();
    });

    const loadRecords = () => {
        setLoading(true);
        GetRecordList({ content: props.domainId })
            .then(res => {
                if (res != null && res.success) {
                    setRecords(res.content);
                }

            })
            .catch(() => {
                //null
            })
        setLoading(false)
    }

    const headers = [
        { Header: "Record ID", accessor: "recordId" },
        { Header: "Record Name", accessor: "recordName" },
        { Header: "Type", accessor: "type" },
        { Header: "Content", accessor: "content" },
        { Header: "TimeToLive", accessor: "timeToLive" },
        { Header: "Disabled", accessor: "disabled" },
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h2>Records</h2>
            <Link to={`/${tenant}/organizations/${props.org.orgID}/dns/${props.domainId}/records/new`}>
                <div id="addRecordButton" className="nexusButtonCommon"><i className="fas fa-plus"></i>Add Record</div>
            </Link>
            <NexusGrid
                linkTo={`/${tenant}/organizations/${props.org.orgID}/dns/${props.domainId}/records/:key`}
                tableKey={"recordId"}
                hiddenColumns={['recordId']}
                data={records}
                defaultSortBy={"recordName"}
                columns={headers}
            />
        </div>
    )
}