import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';
import { GetServiceOrgTransitionOperations, ProgressServiceOrgTransition, CancelServiceOrgTransition } from '../../api/ServiceAPI';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [transition, setTransition] = useState({});
    const [operations, setOperations] = useState([]);
    const [redirectServiceOrg, setRedirectServiceOrg] = useState(false);
    const [redirectForm, setRedirectForm] = useState(false);

    useEffect(() => {
        loadServiceOrgTransitionOperations();
    }, [props.routeProps.match.params.transId]);

    const loadServiceOrgTransitionOperations = () => {
        const transId = parseInt(props.routeProps.match.params.transId, 10);
        if (!isNaN(transId) && transId !== 0) {
            setLoading(true);
            GetServiceOrgTransitionOperations({ content: transId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setTransition(res.serviceOrgTransition);
                            setOperations(res.serviceOrgTransitionOperations);
                        }
                    }
                })
        }
        setLoading(false);
    }

    const progressServiceOrgTransition = () => {
        const transId = parseInt(props.routeProps.match.params.transId, 10);
        if (!isNaN(transId) && transId !== 0) {
            setLoading(true);
            ProgressServiceOrgTransition({ content: transId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setTransition(res.serviceOrgTransition);
                            setOperations(res.serviceOrgTransitionOperations);
                        }
                    }
                })
        }
        setLoading(false);
    }

    const cancelServiceOrgTransition = () => {
        const transId = parseInt(props.routeProps.match.params.transId, 10);
        if (!isNaN(transId) && transId !== 0) {
            setLoading(true);
            CancelServiceOrgTransition({ content: transId })
                .then(res => {
                    if (res != null) {
                        setRedirectServiceOrg(res.success);
                    }
                })
        }
        setLoading(false);
    }

    const canCancel = () => {
        return (transition.cancelable && (transition.status === "Created" || transition.status === "InProgress"));
    }

    const colLabels = [
        { Header: "Sequence", accessor: "sequence" },
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "description" },
        {
            Header: "Status", accessor: "status",
            dataFormat: (cell) => { return <div>{cell ? "Complete" : "Incomplete"}</div> }
        },
        {
            Header: "Start Date", accessor: "startDate",
            dataFormat: (cell) => { return <div>{cell ? new Date(cell).toLocaleString() : ""}</div> }
        },
        {
            Header: "End Date", accessor: "endDate",
            dataFormat: (cell) => { return <div>{cell ? new Date(cell).toLocaleString() : ""}</div> }
        },
        {
            Header: "Process", accessor: "manual",
            dataFormat: (cell) => { return <div>{cell ? "Manual" : "Automatic"}</div> }
        },
        {
            Header: "Action", accessor: "current",
            dataFormat: (cell, row) => {
                return <div>{cell && (
                    row.form === "None" ?
                        <div className="nexusButtonCommon" onClick={() => progressServiceOrgTransition()}>Complete</div> :
                        <div className="nexusButtonCommon" onClick={() => setRedirectForm(row.form)}>Complete Form</div>)
                }</div>
            }
        },
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            {redirectServiceOrg &&
                <Redirect to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}`} />
            }
            {redirectForm &&
                <Redirect to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}/transitions/${props.routeProps.match.params.transId}/${redirectForm}`} />
            }
            <BreadcrumbsItem to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}`} label='ServiceOrg' />
            {transition.status === "Created" && <div className="nexusButtonCommon" onClick={() => progressServiceOrgTransition()}>Begin Transition</div>}
            {canCancel() && <div className="nexusButtonCommon" onClick={() => cancelServiceOrgTransition()}>Cancel Transition</div>}
            <div className="nexusButtonCommon" onClick={() => setRedirectServiceOrg(true)}>Return to ServiceOrg</div>
            <h3>Operations</h3>
            {!transition.cancelable && <>This transition was created as a rollback to a previous transition that was cancelled before completion.</>}
            <div>
                <NexusGrid
                    data={operations} 
                    defaultSortBy={"sequence"} 
                    columns={colLabels}
                    filterHeaders={false}
                    pageMode={"none"}
                />
            </div>
        </div>
    );
}