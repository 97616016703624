import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../App/loader.js';
import CredentialFileTable from './CredentialFileTable.js'
import CredentialHistoryTable from './CredentialHistoryTable.js';
import CredentialFileHistoryTable from './CredentialFileHistoryTable.js';
import { GetCredential, ViewCredentialPassword } from '../../api/CredentialAPI.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("********");
    const [credential, setCredential] = useState({
        credentialID: 0,
        username: "",
        domain: "",
        credentialTypeID: "",
        credentialTypeName: "",
        assetID: "",
        assetName: "",
        organizationID: "",
        organizationName: ""
    });

    useEffect(() => {
        loadCredential(parseInt(props.routeProps.match.params.credID, 10));
    }, [props.routeProps.match.params.credID]);

    const loadCredential = (credentialID) => {
        if (!isNaN(credentialID) && credentialID !== 0) {
            setLoading(true);
            GetCredential({ content: credentialID })
                .then(res => {
                    if (res.success) {
                        setCredential(res.content);
                    }
                });
        }
        setLoading(false);
    }
    
    const viewPassword = () => {
        setLoading(true);
        ViewCredentialPassword({ content: credential.credentialID })
            .then(res => {
                if (res.success) {
                    setPassword(res.content);
                } else {
                    setMessage(res.message);
                }
            });
        setLoading(false);
    }

    const getBaseURL = () => {
        if (!props.asset) {
            return `/${tenant}/organizations/${props.org?.orgID}/credentials`;
        }
        return `/${tenant}/assets/${props.asset?.assetID}/credentials`;
    }
    
    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <BreadcrumbsItem to={getBaseURL()} label='Credentials' />
            <div className="nexusForm">
                <table className="nexusFormInner">
                    <tbody>
                        <tr>
                            <td>Username:</td>
                            <td>{credential.username}</td>
                        </tr>
                        <tr>
                            <td>Password:</td>
                            <td>{password}</td>
                        </tr>
                        <tr>
                            <td>Domain:</td>
                            <td>{credential.domain}</td>
                        </tr>
                        <tr>
                            <td>CredentialType:</td>
                            <td>{credential.credentialTypeName}</td>
                        </tr>
                        <tr>
                            <td>Organization:</td>
                            <td>{credential.organizationName}</td>
                        </tr>
                        {credential.assetName !== "" &&
                            <tr>
                                <td>Asset:</td>
                                <td>{credential.assetName}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <table className="nexusFormInner">
                    <tbody>
                        <tr>
                            <td>
                                <Link to={`${getBaseURL()}/${credential.credentialID}/form`}>
                                    <div id="credentialFormLink" className="nexusButtonCommon">Update Credential</div>
                                </Link>
                            </td>
                            {password === "********" &&
                                <td><div id="viewPasswordButton" className="nexusButtonCommon" onClick={() => viewPassword()}>View Password</div></td>
                            }
                        </tr>
                    </tbody>
                </table>
                {message}
            </div>
            {credential && <CredentialFileTable credentialID={credential.credentialID} editing={false} />}
            {credential.credentialID !== 0 && <CredentialHistoryTable credentialID={credential.credentialID} />}
            {credential.credentialID !== 0 && <CredentialFileHistoryTable credentialID={credential.credentialID} />}
        </div>
    );
}