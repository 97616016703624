import React from 'react';

const EditableTableCell = (props) => {
  const { title, type, id, isFormEditable, placeholder, defaultValue } = props;

  const fieldChange = (e) => {
    props.changed(e);
  };

  const tableCell = isFormEditable ? (
    <td>
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={props.name}
        onChange={(e) => fieldChange(e)}
      />
    </td>
  ) : (
    <td>{placeholder}</td>
  );

  const content = (
    <>
      <th>{title}:</th>
      {tableCell}
    </>
  );

  return content;
};

export default EditableTableCell;
