export enum UpdateMode {
    ID = 1,
    VERSION_NAME,
    VERSION_FILENAME,
}

export enum VersionToUpdate {
    CURRENT = 1,
    APPROVED,
}
