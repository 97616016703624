import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationPBX, EditOrganizationPBX } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [canTestConnection, setCanTestConnection] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [isChangingSQLPassword, setIsChangingSQLPassword] = useState(false);
    const [newSQLPassword, setNewSQLPassword] = useState("");
    const [isChangingSSHPassword, setIsChangingSSHPassword] = useState(false);
    const [newSSHPassword, setNewSSHPassword] = useState("");
    const [pbxs, setPbxs] = useState({});

    useEffect(() => {
        const pbxID = props.routeProps.match.params.pbxid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationPBX({ content: { pbxID: Number(pbxID), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setPbxs(res.content[0]);
                        setLoaded(true);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const pbxChanged = (e) => {
        var pbx = pbxs;
        pbx[e.target.name] = e.target.value;
        setPbxs(pbx);
    }

    const savePBX = () => {
        setLoaded(false);
        setError(false);
        const pbx = {
            pbxid: pbxs.pbxid,
            orgid: pbxs.orgid,
            name: pbxs.name,
            description: pbxs.description,
            host: pbxs.host,
            sqlhost: pbxs.sqlhost,
            sqluser: pbxs.sqluser,
            sqlport: Number(pbxs.sqlport),
            sqlpassword: newSQLPassword.length > 0 ? newSQLPassword : null,
            sshuser: pbxs.sshuser,
            sshport: Number(pbxs.sshport),
            sshpassword: newSSHPassword.length > 0 ? newSSHPassword : null
        }
        EditOrganizationPBX({
            content: pbx
        }).then(res => {
            if (res != null) {
                if (res.pbx){
                    setPbxs(res.content);
                }
                else{
                    setLoaded(false);
                    setError(true);
                }
            } else {
                setLoaded(false);
                setError(true);
            }
        })
            .catch(() => {
                setError(true);
            })
    }

    const editSQLPassword = (e) => {
       setIsChangingSQLPassword(true);
    }

    const confirmSQLPassword = (e) => {
        var pbx = pbxs;
        pbx.sqlpassword = newSQLPassword.length > 0 ? "Yes" : "No"
        setIsChangingSQLPassword(false);
        setPbxs(pbx);
    }

    const editSSHPassword = (e) => {
        setIsChangingSSHPassword(true);
    }

    const confirmSSHPassword = (e) => {
        var pbx = pbxs;
        pbx.sshpassword = this.state.newSSHPassword.length > 0 ? "Yes" : "No";
        setIsChangingSSHPassword(false);
        setPbxs(pbx);
    }

    const passwordChanged = (e) => {
        if (e.target.name === "SSH")
            setNewSSHPassword(e.target.value);
        if (e.target.name === "SQL")
            setNewSQLPassword(e.target.value);
    }

    const cancelEditPassword = () =>   {
        setIsChangingSQLPassword(false);
        setIsChangingSSHPassword(false);
        setNewSQLPassword("");
        setNewSSHPassword("");
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{pbxs.name}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Description:</th>
                                    <td><input type="text" name="description" onChange={pbxChanged} value={pbxs.description} /></td>
                                </tr>
                                <tr>
                                    <th>Host:</th>
                                    <td><input type="text" name="host" onChange={pbxChanged} value={pbxs.host} /></td>
                                </tr>
                                <tr>
                                    <th>SQL Host:</th>
                                    <td><input type="text" name="sqlhost" onChange={pbxChanged} value={pbxs.sqlhost} /></td>
                                </tr>
                                <tr>
                                    <th>SQL User:</th>
                                    <td><input type="text" name="sqluser" onChange={pbxChanged} value={pbxs.sqluser} /></td>
                                </tr>
                                <tr>
                                    <th>SQL Password:</th>
                                    <td>
                                        {isChangingSQLPassword ?
                                            <div>
                                                <input type="text" name="SQL" value={newSQLPassword} onChange={passwordChanged} />
                                                <span href="/" className="fas fa-times-circle nexusLinkCommon" onClick={cancelEditPassword}>Cancel</span>
                                                <span href="/" className="fas fa-check-circle nexusLinkCommon" onClick={confirmSQLPassword}>Confirm</span>
                                            </div>
                                            :
                                            <div>
                                                <input type="text" name="" value={pbxs.sqlpassword === "Yes" ? "hidden" : "unset"} disabled />
                                                <span href="/" className="fas fa-edit nexusLinkCommon" onClick={editSQLPassword}>Edit</span>
                                            </div>
                                        }


                                    </td>
                                </tr>
                                <tr>
                                    <th>SQL Port:</th>
                                    <td><input type="text" name="sqlport" onChange={pbxChanged} value={pbxs.sqlport} /></td>
                                </tr>
                                <tr>
                                    <th>SSH User:</th>
                                    <td><input type="text" name="sshuser" onChange={pbxChanged} value={pbxs.sshuser} /></td>
                                </tr>
                                <tr>
                                    <th>SSH Password:</th>
                                    <td>
                                        {isChangingSSHPassword ?
                                            <div>
                                                <input type="text" name="SSH" value={newSSHPassword} onChange={passwordChanged} />
                                                <span href="/" className="fas fa-times-circle nexusLinkCommon" onClick={cancelEditPassword}>Cancel</span>
                                                <span href="/" className="fas fa-check-circle nexusLinkCommon" onClick={confirmSSHPassword}>Confirm</span>
                                            </div>
                                            :
                                            <div>
                                                <input type="text" name="" value={pbxs.sshpassword === "Yes" ? "hidden" : "unset"} disabled />
                                                <span href="/" className="fas fa-edit nexusLinkCommon" onClick={editSSHPassword}>Edit</span>
                                            </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>SSH Port:</th>
                                    <td><input type="text" name="sshport" onChange={pbxChanged} value={pbxs.sshport} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/pbx/" + pbxs.pbxid} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={savePBX}> Save </button>
                        </p>

                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}