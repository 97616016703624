import React, { useState, useEffect } from 'react';
import Loader from './loader.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Authenticate } from '../../api/NexusAPI';
import { GetTenants, CheckTenantURI } from '../../api/TenantAPI';

const TenantIdentify = props => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    let history = useHistory();

    //const [tenants, setTenants] = useState([]);
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [tenantURI, setTenantURI] = useState("");
    const dispatch = useDispatch();
    //const success = false;

    useEffect(() => {
        //
    }, [history])

    const submit = () => {
        setLoading(true);
        CheckTenantURI(tenantURI).then(result => {
            if (result.success) {
                document.cookie = result.content.name + '=' + result.content.uri + ';expires=31 Dec 9999 23:59:59 GMT';
                setLoading(false);
                //props.checkAuth();
                updateTenant(tenantURI);
                //success = true;
                history.push("/");
            } else {
                var message = "Invalid tenant identifier.";
                setMessage(message);
                setLoading(false);
                //success = false;
                //console.log(message);
            }
        }).catch(info => {
            console.error(info);
        })
    }

    //useEffect(() => {
    //    console.log(tenantURI);
    //}, [tenantURI]);

    //useEffect(() => {
    //    getTenants();
    //}, []);

    //const getTenants = () => {
    //    setLoading(true);
    //    GetTenants()
    //        .then((response) => {
    //            setLoading(false);
    //            // console.log(response);
    //            if (response.success) {
    //                setTenants(response.tenants);
    //            }
    //        })
    //        .catch(() => {
    //            setLoading(false);
    //        });
    //};

    const updateTenant = (payload) => {
        dispatch({ type: 'SET_TENANT', payload: payload })
    }

    return (
        <div className="nexusForm loginForm">
            {loading ? (
                <div className="NexusLoading">
                    <Loader />
                </div>
            ) : (
                    <div>
                        <h2>Enter a tenant identifier</h2>
                        <form className="nexusFormInner" method="post" onSubmit={(e) => { e.preventDefault(); submit() }}>
                            <table className="loginTable" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" name="Username" id="Username" value={tenantURI}
                                                onChange={e => setTenantURI(e.target.value)} required />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="nexusFormSubmit">
                                <input type="submit" name="btnTenant" id="btnTenant" value="Submit" className="nexusButton" />
                            </div>
                        </form>
                        <br/>
                        <div className="Error">
                            {message}
                        </div>
                    </div>
                )}
        </div>
    )
}

export default TenantIdentify;