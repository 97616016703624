import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetCredentialTypeList } from '../../api/CredentialAPI';
import Loader from '../App/loader';
import { useSelector } from 'react-redux';
import NexusGrid from '../App/NexusGrid';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [credentialTypes, setCredentialTypes] = useState([]);

    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0)
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);

    const loadCredentialTypes = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        setFilterResults(filters);
        setSortByResults(sortBy);
        firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
        firstRender.current = false;
        GetCredentialTypeList({ pageSize, pageIndex, sortBy, filters })
            .then((response) => {
                setLoadingFilterSort(false);
                setLoading(false);
                if (response.success) {
                    setCredentialTypes(response.content);
                    setTotalResults(response.totalCount);
                    setTotalPages(response.pageCount);
                }
            })
            .catch(() => {
                setLoadingFilterSort(false);
                setLoading(false);
            });
    }, []);

    const headers = [
        { Header: "Credential Type ID", accessor: "credentialTypeID" },
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "description" },
        { Header: "Hook", accessor: "hook" }
    ];

    return (
        <div>
            <h2>Credential Types</h2>
            <Link to={`/${tenant}/CredentialTypes/add`}>
                <div id="addCredentialTypeButton" className="nexusButtonCommon"><i className="fas fa-plus"></i>Add Credential Type</div>
            </Link>
            <NexusGrid
                linkTo={`/${tenant}/CredentialTypes/:key`}
                tableKey={"credentialTypeID"}
                hiddenColumns={['credentialTypeID']}
                data={credentialTypes}
                defaultSortBy={"name"}
                columns={headers}
                fetchData={loadCredentialTypes}
                filterData={filterResults}
                sortByData={sortByResults}
                loading={loading}
                loadingFilterSort={loadingFilterSort}
                pageMode="remote"
                perPage={25}
                totalResults={totalResults}
                totalPages={totalPages}
            />
        </div>
    )
}
