import APIRequest from './APIRequest';

export const GetIncident = (incidentID) => {
    return APIRequest({
        url: `Incident/GetIncident`,
        method: 'post',
        body: { content: incidentID }
    });
}

export const GetIncidentParamLists = () => {
    return APIRequest({
        url: `Incident/GetIncidentParamLists`,
        method: 'get'
    })
}

export const GetIncidentCompanyParamLists = (companyID) => {
    return APIRequest({
        url: `Incident/GetIncidentCompanyParamLists`,
        method: 'post',
        body: { content: companyID }
    })
}
export const CreateIncidentAPI = (incidentInfo) => {
    return APIRequest({
        url: `Incident/CreateIncident`,
        method: 'post',
        body: { content: incidentInfo }
    })
}
export const AddNoteAPI = (noteInfo) => {
    return APIRequest({
        url: `Incident/AddNote`,
        method: 'post',
        body: { content: noteInfo }
    })
}
export const EditNoteAPI = (noteInfo) => {
    return APIRequest({
        url: `Incident/EditNote`,
        method: 'post',
        body: { content: noteInfo }
    })
}
export const GetSelectedDateIncidents = (date) => {
    return APIRequest({
        url: `Incident/GetSelectedDateIncidents`,
        method: 'post',
        body: { content: date }
    })
}
export const CloseIncidents = (list) => {
    return APIRequest({
        url: `Incident/CloseIncidents`,
        method: 'post',
        body: { content: list }
    })
}
export const GetUnassignedIncidents = () => {
    return APIRequest({
        url: `Incident/GetUnassignedIncidents`,
        method: 'get'
    })
}
export const GetMyIncidents = () => {
    return APIRequest({
        url: `Incident/GetMyIncidents`,
        method: 'get'
    })
}
export const EditIncident = (incidentInfo) => {
    return APIRequest({
        url: `Incident/EditIncident`,
        method: 'post',
        body: { content: incidentInfo }
    })
}
export const GetIncidentQueue = (params) => {
    return APIRequest({
        url: `Incident/GetIncidentQueue`,
        method: 'post',
        body: params
    })
}
export const GetRequestors = (companyID) => {
    return APIRequest({
        url: `Incident/GetRequestors`,
        method: 'post',
        body: { content: companyID }
    })
}
export const GetMissingTime = () => {
    return APIRequest({
        url: `Incident/GetMissingTime`,
        method: 'get'
    })
}
export const SearchIncidents = (params) => {
    return APIRequest({
        url: `Incident/SearchIncidents`,
        method: 'post',
        body: params
    })
}
export const GetReferenceNumbers = (refID) => {
    return APIRequest({
        url: `Incident/GetReferenceNumbers`,
        method: 'post',
        body: { content: refID }
    })
}
export const UpdateNoteFlags = (params) => {
    return APIRequest({
        url: `Incident/UpdateNoteFlags`,
        method: 'post',
        body: params
    })
}
export const UpdateNoteCreditHours = (params) => {
    return APIRequest({
        url: `Incident/UpdateNoteCreditHours`,
        method: 'post',
        body: params
    })
}
export const GetIncidentTechnicians = (enabled) => {
    return APIRequest({
        url: `Incident/GetIncidentTechnicians`,
        method: 'post',
        body: { content: enabled }
    })
}
export const UpdateSearchNotesInvoiced = (params) => {
    return APIRequest({
        url: `Incident/UpdateSearchNotesInvoiced`,
        method: 'post',
        body: params
    })
}
