import React, { useState, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { GetAssetNetworkInterfaces, GetIpForScan } from '../../api/AssetAPI.js';
import Loader from '../App/loader.js';
import NetworkInterfaceAccordion from './tabNetwork/NetworkInterfaceAccordion'
import { WebSocketContext } from "../../Redux/WebSocket";
import { useSelector } from 'react-redux';

export default props => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const assetID = parseInt(props.routeProps.match.params.id, 10);
    useEffect(()=>{
        GetAssetNetworkInterfaces(assetID).then(res=>{
            setData(res.content);
            setLoaded(true);
        })
        .catch(()=>{
            setError(true);
        });
    },[]);

    var ipadd=[''];
    var i = 0;
    var ip=',';
    var ipmask = [''];

    const getIP = () =>{
        GetIpForScan({AssetID:assetID}).then(res=>{
            while(i < res.length){
                ipadd += res[i].unicastIPAddress;
                ipmask += res[i].iPv4Mask;
                if(res.length>1){
                    ipadd += ip;
                    ipmask += ip;
                }
                i++;
            }
        })
        .catch(()=>{

        });
    }

    const socket = React.useContext(WebSocketContext);
    const submitHandler = () => {
        getIP();
        const commandRequestMessage = JSON.stringify({
            type: "Nexus.Data.Classes.Models.XBert.WebSocketModels.PingScannerRequest",
            body: { AssetID: parseInt(assetID),
                    Ip: ipadd,
                    IpMask: ipmask
                }, 
          });
            socket.send(commandRequestMessage);
    }

        return (
            <div className="bootstrap-wrapper">
                    <div>
                        <button type="submit" onClick={submitHandler} className="btn btn-primary btn-block">
                            Scan for pings!
                        </button>
                    </div>
                <Accordion>
                    {
                        loaded ? (
                            data.map((networkInterface, index) => {
                                return (

                                    <Card key={index}>
                                        <Card.Header>
                                            <Accordion.Toggle eventKey={index + 1}>
                                                {networkInterface.name}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index + 1}>
                                            <Card.Body>
                                                <NetworkInterfaceAccordion interface={networkInterface} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            }
                            )
                        ) : error ? (
                            <p> Error Loading Asset Network details </p>
                        ) :
                            (<Loader />)
                    }
                </Accordion>
            </div>
        )
    }