import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../App/loader.js';
import { GetOrganizationDID, GetOrganizationTrunks, GetTrunks, EditOrganizationDID } from '../../api/TelephonyAPI';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [trunks, setTrunks] = useState([]);
    const [theDid, setTheDid] = useState({});

    useEffect(()=> {
        const didID = props.routeProps.match.params.didid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationDID({ content: { didID: Number(didID), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1) {
                        setTheDid(res.content[0]);
                        setLoaded(true);
                        GetOrganizationTrunks({ content: { orgID: Number(orgID), active: true }})
                            .then(trunkRes => {
                                if (trunkRes != null) {
                                    setTrunks(trunkRes.content);
                                    setLoaded(true);
                                } else {
                                    setLoaded(false);
                                    setError(true);
                                }
                            })
                            .catch(() => {
                                setError(true);
                            })
                    }
                    else {
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
        //GetTrunks().then(response => {
        //    if (response.success) {
        //        this.setState({ trunks: response.trunks, loaded: true });
        //    }
        //}).catch(() => {
        //    this.setState({ loaded: false, error: true });
        //})
    },[]);

    const didChanged=(e)=> {
        var did = theDid;
        did[e.target.name] = e.target.value;
        setTheDid(did);
    }

    const savedid=()=> {
        setLoaded(false);
        setError(false);
        const did = {
            number: theDid.number,
            description: theDid.description,
            didid: theDid.didid,
            orgid: theDid.orgid,
            organization: theDid.organization,
            carrierid: theDid.carrierid,
            carrier: theDid.carrier,
            trunkid: theDid.trunkId === 0 ? null : theDid.trunkId
        }
        EditOrganizationDID({
            content: did
        })
            .then(res => {
                if (res != null) {
                    if (res.did) {
                        setLoaded(true);
                        setTheDid(res.content);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    const setTrunk=(trunkId)=> {
        //const trunkid = this.state.trunks.map(function (a) { return a.trunkId; });
        trunkId = Number(trunkId);
        var trunk = trunks.filter(o => o.trunkId === Number(trunkId));
        var did = theDid;
        if (trunk.length === 1) {
            did.trunkId = Number(trunkId);
            did.trunk = trunk[0].trunkName;
        } else {
            if (trunkId === 0) {
                did.trunkId = 0
                did.trunk = null;
            }
        }
        setTheDid(did);
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{theDid.description} - {theDid.number}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Number:</th>
                                    <td><input type="text" name="number" onChange={didChanged} value={theDid.number} /></td>
                                </tr>
                                <tr>
                                    <th>Description:</th>
                                    <td><input type="text" name="description" onChange={didChanged} value={theDid.description} /></td>
                                </tr>
                                <tr>
                                    <th>Trunk:</th>
                                    <td>
                                        <select value={theDid.trunkId} onChange={e => setTrunk(e.target.value)}>
                                            <option key={0} value={0}>-- No Trunk --</option>
                                            {trunks.map(trunk =>
                                                <option key={trunk.trunkId} value={trunk.trunkId}>
                                                    {trunk.trunkName}
                                                </option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/did/" + theDid.didid} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={savedid}> Save </button>
                        </p>

                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}