import React, {useEffect, useState} from 'react'
import {Line} from "react-chartjs-2"
import {GetAssetStatuses} from '../../../api/AssetAPI'
import "../../../css/LineChart.css"
import {useParams} from "react-router-dom"

const LineChart = () => {
//  this is some placeholder code to demonstrate how a linechart can be generated with data using chart.js, need to 
    // -make api call to GetAssetStatusHistory in Asset controller and pass required query parameters
    //set value of a state variable to the response data
    //use response data to  render the correct data points on the graph.
    const {id} = useParams()
    const [statuses, setStatuses] = useState([])
    useEffect(()=>{
        GetAssetStatuses(id, 20)
        .then(response=>{
            //console.log(response)
            setStatuses(response.content)
        })
    },[])

    

    const ChartData = ()=>{
        let data = {
            labels:[],
            datasets:[]    
        }
        ///add labels
        if(statuses){
            data.labels = statuses.map(status=>{
                let displayTime = new Date(status.timeStamp)
                return  displayTime.toLocaleString()
            })
        }
        ///add datasets
        const CPUData = ()=>{
            return statuses.map(status=>{
                return status.processorUtilizationPercentage
            })
        }
        const MemoryData = ()=>{
            return statuses.map(status=>{
                return status.memoryUtilizationPercentage
            })
        }
            //CPU utilization
        data.datasets.push(
            {
                label: 'CPU Utilization (%)',
                fill: false,
                lineTension: .5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: CPUData()
            }
        )
            //Memory Utilization
        data.datasets.push(
            {
                label: 'Memory Utilization (%)',
                fill: false,
                lineTension: .5,
                backgroundColor: 'rgba(242,31,31,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: MemoryData()
            }
        )    
        return data
    }  
    
  return (
    <div className="chart-wrapper">
      <div className="chart-container">
        <Line
          data={ChartData()}
          options={{
            title:{
              display:true,
              text:'CPU/Memory utilization over time',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    </div>
  )
}

export default LineChart
