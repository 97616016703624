import React, { useEffect, useState, useSelector } from 'react';
import { GetAssetsNum } from '../../api/AssetAPI';
import { WebSocketReducer } from '../../Redux/webSocketReducer';
import AccordionWidget from './AccordionWidget';

const TotalAssets = (props) => {
    const [totalAssets, setTotalAssets] = useState(0);
    const [assetsOnline, setAssetsOnline] = useState(0);
    const [assetsOffline, setAssetsOffline] = useState(0);
    
    useEffect(() => {
        GetAssetsNum()
        .then(result => {
            if(result.success)
                setTotalAssets(result.content);
        }).catch(e => console.error(e));
    }, []);

    return (
        <div className="col-6 pl-1">
            <AccordionWidget title={'Total Assets'} dashboardPreference={'TotalAssets'}>
                <div className="row no-gutters">
                    <div className="col-4 my-auto">
                        <h3 className="text-center m-3">{totalAssets}</h3>
                    </div>
                    <div className="col-8">
                        <div className="row no-gutters text-center">
                            <div className="col-6">
                                <h6 className="widgetHeader m-0">Online</h6>
                            </div>
                            <div className="col-6 my-auto">
                                <h6 className="m-1">{props.assetNumOnline}</h6>
                            </div>
                            <div className="col-6">
                                <h6 className="widgetHeader m-0">Offline</h6>
                            </div>
                            <div className="col-6 my-auto">
                                <h6 className="m-1">{totalAssets - props.assetNumOnline}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionWidget>
        </div>
    );
}

export default TotalAssets;