import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import Loader from "../App/loader";
import LazyLoad from "react-lazyload";

import NexusAPI from "../../nexusapi";
import { GetCompanyListBasic } from "../../api/CompanyAPI";
import { GetIncidentParamLists } from "../../api/IncidentAPI";
import { GetIncidentCompanyParamLists } from "../../api/IncidentAPI";
import { GetIncidentTechnicians } from "../../api/IncidentAPI";
import { SearchIncidents } from "../../api/IncidentAPI";
import { UpdateSearchNotesInvoiced } from "../../api/IncidentAPI";
import { GetReferenceNumbers } from "../../api/IncidentAPI";
import IncidentSearchAggregate from "./IncidentSearchAggregate";
import IncidentSearchItem from "./IncidentSearchItem";

const IncidentSearch = (props) => {
  const [loading, setLoading] = useState(true);

  const [references, setReferences] = useState([]);
  const [timer, setTimer] = useState(null);

  const [searchCompanyList, setCompanyList] = useState([]);
  const [searchRequestorList, setRequestorList] = useState([
    { itemID: 0, itemName: "Search all Companies" },
  ]);
  const [searchWorkTypeList, setWorkTypeList] = useState([
    { itemID: 0, itemName: "Search all WorkTypes" },
  ]);
  const [searchProjectList, setProjectList] = useState([
    { itemID: 0, itemName: "Search all Projects" },
  ]);
  const [searchLocations, setLocationList] = useState([
    { itemID: 0, itemName: "Search all Locations" },
  ]);
  const [searchTeamList, setTeamList] = useState([
    { itemID: 0, itemName: "Search all Teams" },
  ]);
  const [searchTechList, setTechList] = useState([
    { itemID: 0, itemName: "Search all Technicians" },
  ]);

  const [searchStartDate, setIncidentStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [searchEndDate, setIncidentEndDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59
    )
  );
  const [searchKeyword, setKeyword] = useState("");
  const [searchReferenceID, setReferenceID] = useState("");
  const [searchReferences, setSearchReferences] = useState([]);

  const [searchOpen, setIncidentOpen] = useState(true);
  const [searchClosed, setIncidentClosed] = useState(true);
  const [searchBillable, setBillable] = useState(true);
  const [searchNonBillable, setNonBillable] = useState(true);
  const [searchAccountingClosed, setAccountingClosed] = useState(true);
  const [searchAccountingOpen, setAccountingOpen] = useState(true);
  const [searchReviewed, setReviewed] = useState(true);
  const [searchNonReviewed, setNonReviewed] = useState(true);

  const [searchCompanyActive, setCompanyActive] = useState(true);
  const [searchRequestorActive, setRequestorActive] = useState(true);
  const [searchWorkTypeActive, setWorkTypeActive] = useState(true);
  const [searchProjectActive, setProjectActive] = useState(true);
  const [searchTechActive, setTechActive] = useState(true);

  const [searchCompany, setCompany] = useState(0);
  const [searchRequestor, setRequestor] = useState(0);
  const [searchWorkType, setWorkType] = useState(0);
  const [searchProject, setProject] = useState(0);
  const [searchLocation, setLocation] = useState(0);
  const [searchTeam, setTeam] = useState(0);
  const [searchTech, setTech] = useState(-1);

  const [searchIncidentCount, setIncidentCount] = useState(0);
  const [searchNoteCount, setNoteCount] = useState(0);
  const [searchResults, setResults] = useState([]);
  const [searchAggregate, setAggregate] = useState([]);
  const [showAggregate, setShowAggregate] = useState(false);

  const [canReview, setCanReview] = useState(false);
  const [canBill, setCanBill] = useState(false);
  const [canInvoice, setCanInvoice] = useState(false);

  const [didSearch, setDidSearch] = useState(false);
  const [error, setError] = useState("");

  const filterCheckboxChange = (e) => {
    switch (e.target.name) {
      case "searchOpen":
        setIncidentOpen(e.target.checked);
        break;
      case "searchClosed":
        setIncidentClosed(e.target.checked);
        break;
      case "searchBillable":
        setBillable(e.target.checked);
        break;
      case "searchNonBillable":
        setNonBillable(e.target.checked);
        break;
      case "searchCompanyActive":
        setCompanyActive(e.target.checked);
        break;
      case "searchRequestorActive":
        setRequestorActive(e.target.checked);
        break;
      case "searchWorkTypeActive":
        setWorkTypeActive(e.target.checked);
        break;
      case "searchProjectActive":
        setProjectActive(e.target.checked);
        break;
      case "searchTechActive":
        setTechActive(e.target.checked);
        break;
      case "searchAccountingClosed":
        setAccountingClosed(e.target.checked);
        break;
      case "searchAccountingOpen":
        setAccountingOpen(e.target.checked);
        break;
      case "searchReviewed":
        setReviewed(e.target.checked);
        break;
      case "searchNonReviewed":
        setNonReviewed(e.target.checked);
        break;
      default:
        break;
    }
  };
  const resetSearch = (e) => {
    setIncidentCount(0);
    setNoteCount(0);
    setResults([]);
    setAggregate([]);
    setShowAggregate(false);
    setCanReview(false);
    setCanBill(false);
    setCanInvoice(false);
    setReferenceID("");
    setReferences([]);
  };

  const [fileAPI] = useState(new NexusAPI("Sierra"));

  const changeRefID = (e) => {
      setReferenceID(e.target.value);
      if (searchReferenceID !== e.target.value) {
          handleTimeout(e);
      }
  }

  const handleTimeout = (e) => {
      if (timer) {
          clearTimeout(timer);
          setTimer(null);
      }
      setTimer(setTimeout(() => {
          loadReferences(e);
      }, 1000));
  }

  const loadReferences = (e) => {
      if (e.target.value.length >= 4) {
          GetReferenceNumbers(e.target.value).then((res) => {
              if (res.success === true) {
                  setReferences(res.content);
              } else {
                  setReferences([]);
              }
          }).catch((e) => {
              console.log(e);
          });
      } else {
          setReferences([]);
      }
    }

  const refKeyEnter = (e) => {
      const ref = e.target.value;
      let refs = searchReferences;
      if (!e) {
          e = window.event;
      }
      var keyCode = e.code || e.key;
      if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
          refs.push(ref);
          setReferenceID("");
          setReferences([]);
      }
  }

  useEffect(() => {
      loadInit();
  }, []);

  //FILTERS
  useEffect(() => {
    setLoading(true);
    GetIncidentCompanyParamLists(searchCompany)
      .then((response) => {
        let selectedCompany = searchCompanyList.filter(
          (company) => company.itemID === searchCompany
        );

        let APILocationList = [...response.locationList];
        APILocationList.unshift({
          itemID: 0,
          itemName: "Search all Locations",
        });
        setLocationList(APILocationList);

        let APIRequestors = [...response.requestorList];
        APIRequestors.unshift({ itemID: 0, itemName: "Search all Requestors" });
        setRequestorList(APIRequestors);

        let APIProjects = [...response.projectList];
        APIProjects.unshift({ itemID: 0, itemName: "Search all Projects" });
        setProjectList(APIProjects);

        if (selectedCompany.length > 0 && selectedCompany[0].itemID !== 0) {
          let APIWorkTypes = [...response.workTypes];
          APIWorkTypes.unshift({ itemID: 0, itemName: "Search all WorkTypes" });
          setWorkTypeList(APIWorkTypes);
        } else {
          let APIWorkTypes = [];
          APIWorkTypes.unshift({ itemID: 0, itemName: "Search all WorkTypes" });
          setWorkTypeList(APIWorkTypes);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [searchCompany]);

  useEffect(() => {
    let proj = searchProjectList.filter(
      (project) => project.itemID === searchProject
    );
    if (proj.length === 1) setWorkType(0);
  }, [searchProject]);

  useEffect(() => {
    loadTechs();
  }, [searchTechActive]);

  const loadInit = () => {
    setLoading(true);
    GetCompanyListBasic()
      .then((response) => {
        let APICompanyList = [...response.content];
        APICompanyList.unshift({ itemID: 0, itemName: "Search all Companies" });
        setCompanyList(APICompanyList);
      })
      .then(() => {
        return GetIncidentTechnicians(searchTechActive);
      })
      .then((response) => {
        let APIAssignedToList = [...response.content];
        APIAssignedToList.unshift({
            itemID: 0,
            itemName: "Search all Unassigned",
        });
        APIAssignedToList.unshift({
          itemID: -1,
          itemName: "Search all Technicians",
        });
        setTechList(APIAssignedToList);
      })
      .then(() => {
        return GetIncidentParamLists();
      })
      .then((response) => {
        let APITeamList = [...response.teamList];
        APITeamList.unshift({ itemID: 0, itemName: "Search all Teams" });
        setTeamList(APITeamList);
        setLoading(false);
      })
      .then(() => {
        //setLoading(false);
      })
      .catch((err) => {
        //setLoading(false);
        console.log(err);
      });
  };
  const loadTechs = () => {
    setLoading(true);
    GetIncidentTechnicians(searchTechActive)
      .then((response) => {
        let APIAssignedToList = [...response.content];
        APIAssignedToList.unshift({
            itemID: 0,
            itemName: "Search all Unassigned",
        });
        APIAssignedToList.unshift({
            itemID: -1,
            itemName: "Search all Technicians",
        });
        setTechList(APIAssignedToList);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSearchCriteria = () => {
    //const refID = isNaN(searchReferenceID)
    //  ? 0
    //      : parseInt(searchReferenceID, 10);
    return {
      startDate: searchStartDate,
      endDate: searchEndDate,
      keyword: searchKeyword,
      referenceNums: searchReferences,
      companyID: parseInt(searchCompany, 10),
      requestorID: parseInt(searchRequestor, 10),
      workTypeID: parseInt(searchWorkType, 10),
      projectID: parseInt(searchProject, 10),
      locationID: parseInt(searchLocation, 10),
      technicianID: parseInt(searchTech, 10),
      teamID: parseInt(searchTeam, 10),
      openIncidents: searchOpen,
      closedIncidents: searchClosed,
      billableNotes: searchBillable,
      nonBillableNotes: searchNonBillable,
      accountingClosed: searchAccountingClosed,
      accountingOpen: searchAccountingOpen,
      reviewed: searchReviewed,
      nonReviewed: searchNonReviewed,
      activeCompanies: searchCompanyActive,
      activeRequestors: searchRequestorActive,
      activeWorkTypes: searchWorkTypeActive,
      activeProjects: searchProjectActive,
      activeTechnician: searchTechActive,
    };
  };

  const searchIncidents = () => {
    resetSearch();
    setLoading(true);
    SearchIncidents(getSearchCriteria())
      .then((response) => {
        if (!response.success) {
          console.log(response);
          setError(response.message);
          return;
        }
        setDidSearch(true);
        setIncidentCount(response.incidentCount);
        setNoteCount(response.noteCount);
        setResults(response.incidents);
        setAggregate(response.companyAggregate);
        setCanReview(response.canReview);
        setCanBill(response.canBill);
        setCanInvoice(response.canInvoice);
      })
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        setDidSearch(true);
        console.log(err);
        setError("An error occurred while searching for incidents.");
        setLoading(false);
      });
  };
  const markAllBillableInvoiced = () => {
    setLoading(true);
    let noteIDs = searchResults.map((i, idx) => {
      return i.notes
        .filter((n, nidx) => {
          if (n.billable) return n;
        })
        .map((n, nidx) => {
          return n.noteID;
        });
    });
    let params = { content: noteIDs.flat() };
    UpdateSearchNotesInvoiced(params)
      .then((response) => {
        if (!response.success) {
          console.log(response);
          setError(response.message);
          return;
        }
        let updatedResults = [...searchResults];
        for (var i = 0; i < updatedResults.length; i++) {
          for (var j = 0; j < updatedResults[i].notes.length; j++) {
            if (updatedResults[i].notes[j].billable === true) {
              updatedResults[i].notes[j].accountingClosed = true;
            }
          }
        }
        setResults(updatedResults);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const exportIncidents = () => {
    setLoading(true);
    let download = require("downloadjs");
    fileAPI.getFile({
      controller: "Incident",
      action: "ExportSearch",
      method: "POST",
      body: getSearchCriteria(),
      success: (data) => {
        if (data != null) {
          data.blob().then(function (myBlob) {
            console.log(myBlob);
            download(
              myBlob,
              "Nexus_Search_" + Date.now() + ".xlsx",
              myBlob.type
            );
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      },
      error: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
  };

  return (
    <div>
      <h2>Incident Search</h2>
      <div className="tabDisplayArea">
        {loading ? (
          <div className="NexusLoading">
            <Loader />
          </div>
        ) : null}

        <div className="search-criteria-container">
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Start Date:</th>
                  <td>
                    <DateTimePicker
                      value={searchStartDate}
                      onChange={(date) => setIncidentStartDate(date)}
                      clearIcon={null}
                    />
                  </td>
                </tr>
                <tr>
                  <th>End Date:</th>
                  <td>
                    <DateTimePicker
                      value={searchEndDate}
                      onChange={(date) => setIncidentEndDate(date)}
                      clearIcon={null}
                    />
                  </td>
                </tr>
                <tr>
                    <th>Keyword:</th>
                    <td>
                        <input
                            type="text"
                            value={searchKeyword}
                            onChange={(e) => { setKeyword(e.target.value); }}
                        ></input>
                    </td>
                </tr>
                <tr>
                  <th>Reference #:</th>
                  <td>
                    <input
                      type="text"
                      value={searchReferenceID}
                      onChange={(e) => { changeRefID(e); } }
                      list="references"
                      onKeyPress={(e) => { refKeyEnter(e); }}
                    ></input>
                    <datalist id="references">
                        {references.map((ref) => (
                            <option key={ref.incidentID} value={ref.referenceID}>
                                {ref.referenceID}
                            </option>
                        ))}
                    </datalist>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Company Name:</th>
                  <td>
                    <select
                      value={searchCompany}
                      onChange={(e) => setCompany(parseInt(e.target.value, 10))}
                    >
                      {searchCompanyList.map((company) => (
                        <option key={company.itemID} value={company.itemID}>
                          {company.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Requestor:</th>
                  <td>
                    <select
                      value={searchRequestor}
                      onChange={(e) =>
                        setRequestor(parseInt(e.target.value, 10))
                      }
                    >
                      {searchRequestorList.map((requestor) => (
                        <option key={requestor.itemID} value={requestor.itemID}>
                          {requestor.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Work Type:</th>
                  <td>
                    <select
                      value={searchWorkType}
                      onChange={(e) =>
                        setWorkType(parseInt(e.target.value, 10))
                      }
                    >
                      {searchWorkTypeList.map((workType) => (
                        <option key={workType.itemID} value={workType.itemID}>
                          {workType.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Project:</th>
                  <td>
                    <select
                      value={searchProject}
                      onChange={(e) => setProject(parseInt(e.target.value, 10))}
                    >
                      {searchProjectList.map((project) => (
                        <option key={project.itemID} value={project.itemID}>
                          {project.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Location:</th>
                  <td>
                    <select
                      value={searchLocation}
                      onChange={(e) =>
                        setLocation(parseInt(e.target.value, 10))
                      }
                    >
                      {searchLocations.map((location) => (
                        <option key={location.itemID} value={location.itemID}>
                          {location.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Active Companies</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchCompanyActive}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchCompanyActive"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Active Requestors</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchRequestorActive}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchRequestorActive"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Active WorkTypes</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchWorkTypeActive}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchWorkTypeActive"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Active Projects</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchProjectActive}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchProjectActive"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Open Incidents</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchOpen}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchOpen"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Closed Incidents</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchClosed}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchClosed"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Billable Notes</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchBillable}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchBillable"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Non Billable Notes</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchNonBillable}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchNonBillable"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Accounting Open</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchAccountingOpen}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchAccountingOpen"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Accounting Closed</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchAccountingClosed}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchAccountingClosed"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Reviewed Notes</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchReviewed}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchReviewed"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Non Reviewed Notes</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchNonReviewed}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchNonReviewed"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="search-criteria">
            <table>
              <tbody>
                <tr>
                  <th>Active Technicians</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={searchTechActive}
                      onChange={(e) => filterCheckboxChange(e)}
                      name="searchTechActive"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="">Technician:</th>
                  <td>
                    <select
                      value={searchTech}
                      onChange={(e) => setTech(parseInt(e.target.value, 10))}
                    >
                      {searchTechList.map((contact) => (
                        <option key={contact.itemID} value={contact.itemID}>
                          {contact.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th className="">Team</th>
                  <td>
                    <select
                      value={searchTeam}
                      onChange={(e) => setTeam(parseInt(e.target.value, 10))}
                    >
                      {searchTeamList.map((team) => (
                        <option key={team.itemID} value={team.itemID}>
                          {team.itemName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {searchReferences.length > 0 && (
            <>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <th>Reference #s:</th>
                                <td>
                                    {searchReferences.map((ref, i) => (
                                        <label key={i}>
                                            {ref},{' '}
                                        </label>
                                    ))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
            </>
          )}
        </div>
        <div className="search-criteria-footer">
          <div className="search-criteria-footer-separator">
            <div className="search-criteria-footer-item">
              <button
                className="nexusButtonCommon search-button"
                onClick={() => searchIncidents()}
              >
                <i className="fas fa-search"></i>
                Search
              </button>
            </div>
          </div>
          <div className="search-criteria-footer-separator search-criteria-footer-right">
            {searchNoteCount > 0 ? (
              <div className="search-criteria-footer-item">
                <button
                  className="nexusButtonCommon"
                  onClick={(e) => markAllBillableInvoiced()}
                >
                  {" "}
                  <i className="fas fa-check-double"></i> Mark Billable Notes as
                  Accounting Closed
                </button>
              </div>
            ) : null}
            {searchNoteCount > 0 ? (
              <div className="search-criteria-footer-item">
                <button
                  className="nexusButtonCommon"
                  onClick={(e) => exportIncidents()}
                >
                  {" "}
                  <i className="fas fa-file-excel"></i> Create Report
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="search-criteria-status">
          {didSearch ? (
            <label>
              {searchNoteCount} notes spanning {searchIncidentCount} incidents
              matched your search criteria
            </label>
          ) : null}
        </div>
        {searchNoteCount > 0 ? (
          <div className="search-results-container">
            <div
              className="search-aggregate-header"
              onClick={() => setShowAggregate(!showAggregate)}
            >
              <i
                className={
                  "search-header-toggle fas " +
                  (showAggregate ? "fa-chevron-down" : "fa-chevron-right")
                }
              ></i>
              <h3>Company Summary</h3>
            </div>
            <div
              className="search-aggregate-container"
              style={{ display: showAggregate ? "block" : "none" }}
            >
              <IncidentSearchAggregate aggregate={searchAggregate} />
            </div>
            <div>
              {searchResults.map((result, idx) => (
                <LazyLoad key={idx} height={300}>
                  <IncidentSearchItem
                    key={idx}
                    item={result}
                    canBill={canBill}
                    canInvoice={canInvoice}
                    canReview={canReview}
                  />
                </LazyLoad>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IncidentSearch;
