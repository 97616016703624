import React from 'react'
import {useEffect, useState, useRef, useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import webSocket, {WebSocketContext} from '../../Redux/WebSocket'

const XBertOnlineIndicator = (props) =>{
    
    const OnlineAssets = useSelector(state=> state.WebSocketReducer.AssetsOnline);

    const [AssetOnline, setAssetOnline] = useState(false);

    useEffect(()=>{
      if(OnlineAssets.includes(props.assetID.toString())){
        setAssetOnline(true)
      }
    },[])
    useEffect(()=>{
      if(OnlineAssets.includes(props.assetID.toString())){
        setAssetOnline(true)
      }
      else{
        setAssetOnline(false)
      }
      console.log("use effect ran again");
      ///everything might be working test and make sure the component is re rendering
    },[OnlineAssets])

    const OnlineOrOffline = () =>{
        if(AssetOnline){
            return(<i className="fa fa-wifi" aria-hidden="true"></i>)
        }
        else{
            return (<p>-</p>)
        }
    }
  

  return (
    <div>
      {OnlineOrOffline()}
    </div>
  )
}

export default XBertOnlineIndicator
