import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import toolbarConfig from '../App/toolbarconfig';
import Loader from '../App/loader';
import { useSelector } from 'react-redux';

import { AddPTORequest, EditPTORequest, DeletePTORequest, GetPTORequests, GetPTORequest } from '../../api/PeopleAPI';

const PTORequestForm = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [personId, setPersonId] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errmessage, setErrmessage] = useState("");
    const [error, setError] = useState("");
    const [requestID, setRequestID] = useState(-1);
    const [ptoDateTime, setPtoDateTime] = useState(null);
    const [ptoHours, setPtoHours] = useState("8.00");
    const [ptoStartDate, setPtoStartDate] = useState(null);
    const [ptoEndDate, setPtoEndDate] = useState(null);
    const [description, setDescription] = useState("");
    const [multiDays, setMultiDays] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ptoRequests, setPtoRequests] = useState([]);
    const [mode, setMode] = useState("add");

    useEffect(() => {
        loadPTO();
        setLoading(false);
    }, []);

    const loadPTO = () => {
        setError("");
        let id = 0;
        let org = 0;
        if (sessionStorage.getItem('nexusID') !== null) {
            id = sessionStorage.getItem('nexusID');
            org = sessionStorage.getItem('nexusOrg');
        }
        if (localStorage.getItem('nexusID') !== null) {
            id = localStorage.getItem('nexusID');
            org = localStorage.getItem('nexusOrg');
        }
        setPersonId(id);
        setOrgId(org);
        GetPTORequests({ personID: Number(id), orgID: Number(org) }).then(res => {
            if (res.success) {
                setPtoRequests(res.content);
            } else {
                setError(res.message);
            }
        }).catch(err => {
            setError(err);
        });
    }

    const htmlFrom = (htmlString) => {
        const html = parse(htmlString);
        return html;
    }

    const clearForm = () => {
        // do something else here?
        setPtoDateTime(null);
        setPtoHours("8.00");
        setPtoStartDate(null);
        setPtoEndDate(null);
        setDescription("");
        setMultiDays(false);
        setRequestID(-1);
        setMode("add");
        setSubmitted(false);
        setErrmessage("");
    }
    
    const submitForm = () => {
        setSubmitted(true);
        if (description.trim() === "") {
            return;
        } else if (multiDays === false && (ptoDateTime === null || ptoHours === "0")) {
            return;
        } else if (multiDays === true && (ptoStartDate === null || ptoEndDate === null)) {
            return;
        }
        setLoading(true);
        setErrmessage("");
        const payload = {
            personID: Number(personId),
            requestID: Number(requestID),
            ptoDateTime: ptoDateTime,
            ptoHours: ptoHours,
            ptoStartDate: ptoStartDate,
            ptoEndDate: ptoEndDate,
            description: description,
            orgID: Number(orgId),
            tenant: tenant
        };
        if (mode === "add") {
            setMode("add");
            AddPTORequest(payload).then(res => {
                if (res.success) {
                    // do something else here?
                    clearForm();
                    loadPTO();
                    setLoading(false);
                } else {
                    setErrmessage(res.message);
                    setLoading(false);
                }
            }).catch(err => {
                setErrmessage(err);
                setLoading(false);
            });
        } else if (mode === "edit") {
            EditPTORequest(payload).then(res => {
                if (res.success) {
                    // do something else here?
                    clearForm();
                    loadPTO();
                    setMode("add");
                    setLoading(false);
                } else {
                    setErrmessage(res.message);
                    setLoading(false);
                }
            }).catch(err => {
                setErrmessage(err);
                setLoading(false);
            });
        }
    }

    const editRequest = (e) => {
        setLoading(true);
        setError("");
        clearForm();
        setMode("edit");
        GetPTORequest({ requestID: Number(e.target.id), personID: Number(personId) }).then(res => {
            if (res.success) {
                setRequestID(res.content.requestID);
                if (Number(res.content.ptoHours) === Number(0.00) || Number(res.content.ptoHours) >= Number(8.00)) {
                    setMultiDays(true);
                    const startD = new Date(res.content.ptoStartDate);
                    const endD = new Date(res.content.ptoEndDate);
                    setPtoStartDate(startD);
                    setPtoEndDate(endD);
                    setDescription(res.content.description);
                } else {
                    setMultiDays(false);
                    const ptoDT = new Date(res.content.ptoStartDate);
                    setPtoHours(res.content.ptoHours);
                    setPtoDateTime(ptoDT);
                    setDescription(res.content.description);
                }
                setLoading(false);
            } else {
                setError(res.message);
                setLoading(false);
            }
        }).catch(err => {
            setError(err);
            setLoading(false);
        });
    }

    const cancelRequest = (e) => {
        setLoading(true);
        setError("");
        DeletePTORequest({ requestID: Number(e.target.id), personID: Number(personId), orgID: Number(orgId), tenant: tenant }).then(res => {
            if (res.success) {
                loadPTO();
                setLoading(false);
            } else {
                setError(res.message);
                setLoading(false);
            }
        }).catch(err => {
            setError(err);
            setLoading(false);
        });
    }

    const changeMultiDays = (e) => {
        setMultiDays(e.target.checked);
        if (e.target.checked === true) {
            setPtoDateTime(null);
            setPtoHours("0");
        } else if (e.target.checked === false) {
            setPtoHours("8.00");
            setPtoStartDate(null);
            setPtoEndDate(null);
        }
    }

    const requestForm = () => {
        return (
            <>
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr>
                            <th className="incidentTableHeader" colSpan="2">
                                Request PTO
                            </th>
                        </tr>
                        {multiDays === false ? (
                            <>
                                <tr>
                                    <th className="createIncidentHeader">PTO Date & Start Time:</th>
                                    <td>
                                        <DateTimePicker value={ptoDateTime} onChange={date => setPtoDateTime(date)} clearIcon={null} />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="createIncidentHeader">PTO Hours:</th>
                                    <td>
                                        <input type="number" value={ptoHours} onChange={(e) => setPtoHours(e.target.value)} step="0.01" min="0.01" max="8.00" />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <th className="createIncidentHeader">PTO Start Date:</th>
                                    <td>
                                        <DateTimePicker value={ptoStartDate} onChange={date => setPtoStartDate(date)} clearIcon={null} />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="createIncidentHeader">PTO End Date:</th>
                                    <td>
                                        <DateTimePicker value={ptoEndDate} onChange={date => setPtoEndDate(date)} clearIcon={null} />
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th className="createIncidentHeader">Description:</th>
                            <td style={submitted && description.trim() === "" ? { backgroundColor: "#d20107" } : null}>
                                <ReactQuill value={description} onChange={e => setDescription(e)}
                                    modules={{ toolbar: toolbarConfig }} />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="1" style={{ backgroundColor: "#333333" }}>
                                <label htmlFor="multiDays">Multiple Days</label>
                                <input type="checkbox" name="multiDays" checked={multiDays} onChange={(e) => changeMultiDays(e)} />
                            </th>
                            <th colSpan="1" style={{ backgroundColor: "#333333" }}>
                                <input type="button" className="nexusButtonCommon" value="Sumbit Request"
                                    onClick={() => submitForm()} />
                            </th>
                        </tr>
                    </tbody>
                </table>
                {submitted ? (
                    <div>
                        {multiDays === false && (
                            ptoDateTime === null ? ( 
                                <div className="Error PaddedError">PTO Date & Start Time is required!</div>
                            ) : null
                        )}
                        {multiDays === false && (
                            ptoHours === null ? (
                                <div className="Error PaddedError">PTO Hours is required!</div>
                            ) : null
                        )}
                        {multiDays === true && (
                            ptoStartDate === null ? (
                                <div className="Error PaddedError">PTO Start Date is required!</div>
                            ) : null
                        )}
                        {multiDays === true && (
                            ptoEndDate === null ? (
                                <div className="Error PaddedError">PTO End Date is required!</div>
                            ) : null
                        )}
                        {description.trim() === "" ? (
                            <div className="Error PaddedError">Description is required!</div>
                        ) : null}
                        <div className="Error PaddedError">{errmessage}</div>
                    </div>
                ) : null}
            </>
        )
    }

    const requests = () => {
        return (
            <div style={{ width: "100%" }}>
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr>
                            <th className="incidentTableHeader" colSpan="12">PTO Requests</th>
                        </tr>
                        <tr>
                            <th>Request Description</th>
                            <th>Request Date</th>
                            <th>PTO Date</th>
                            <th>Hours</th>
                            <th colSpan="2">Approved</th>
                            <th></th>
                        </tr>
                        {ptoRequests.length > 0 ? (
                            ptoRequests.map(pto => 
                                <tr key={pto.requestID}>
                                    <td>{htmlFrom(pto.description)}</td>
                                    <td>{pto.sPtoRequestDate}</td>
                                    {pto.ptoEndDate === null ? (<td>{pto.sPtoStartDate}</td>) : (<td>{pto.sPtoStartDate} - {pto.sPtoEndDate}</td>)}
                                    <td>{pto.ptoHours}</td>
                                    {pto.ptoApproved === true ? (<td>Yes</td>) : (<td>No</td>)}
                                    {pto.ptoApproved === true ? (
                                        <td>
                                            <input type="button" id={pto.requestID} className="nexusButton" value="Cancel" disabled />
                                        </td>
                                    ) : (
                                        <td>
                                            <input type="button" id={pto.requestID} className="nexusButtonCommon" value="Cancel" onClick={(e) => cancelRequest(e)} />
                                        </td>
                                    )}
                                    {pto.ptoApproved === true ? (
                                        <td>
                                            <input type="button" id={pto.requestID} className="nexusButton" value="Edit" disabled />
                                        </td>
                                    ) : (
                                        <td>
                                            <input type="button" id={pto.requestID} className="nexusButtonCommon" value="Edit" onClick={(e) => editRequest(e)} />
                                        </td>
                                    )}
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td className="Error PaddedError" colSpan="12">{error}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/*<div>*/}
                {/*    <div className="Error PaddedError">{error}</div>*/}
                {/*</div>*/}
            </div>
        )
    }

    return (
        <div>
            <h2>PTO Request Form</h2>
            <div className="tabDisplayArea">
                {loading ? (
                    <div className="NexusLoading">
                        <Loader />
                    </div>
                ) : null}
                {requestForm()}
                <br/>
                {requests()}
            </div>
        </div>
    )
}
export default PTORequestForm;