import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

export default props => {


  const renderBool = (bool) => {
    if (bool) {
      return "True"
    }
    else {
      return "False"
    }
  }

  const valueOrNA = (value) => {
    if (value === null || value === "") {
      return "Unavailable"
    }
    else {
      return value
    }
  }

    return (

      <Table striped bordered>
        <thead>
          <tr>
            <th>
              Address Family
                </th>
            <th>
              IP Address
                </th>
            <th>
              IPv4 Mapped To IPv6
                </th>
            <th>
              IPv6 Link Local
                </th>
            <th>
              IPv6 Multicast
                </th>
            <th>
              IPv6 Site Local
                </th>
            <th>
              IPv6 Teredo
                </th>
          </tr>
        </thead>
        <tbody>
          {props.addresses.map((address,index) => {
            return (
              <tr key={index}>
                <td>{valueOrNA(address.addressFamily)}</td>
                <td>{valueOrNA(address.ipAddress)}</td>
                <td>{renderBool(address.isIPv4MappedToIPv6)}</td>
                <td>{renderBool(address.isIPv6LinkLocal)}</td>
                <td>{renderBool(address.isIPv6Multicast)}</td>
                <td>{renderBool(address.isIPv6SiteLocal)}</td>
                <td>{renderBool(address.isIPv6Teredo)}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>

    )
  }
