import { create } from 'filepond';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GetFullCredential, UpdateCredential, CreateCredential, DeleteCredential, GetCredentialTypeList } from '../../api/CredentialAPI';
import { GetOrganizationAssets } from '../../api/AssetAPI';
import Loader from '../App/loader';
import CredentialFileTable from './CredentialFileTable.js';
import { useSelector } from 'react-redux';
import asset from '../Assets/asset';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [assetId, setAssetId] = useState(0);
    const [redirectList, setRedirectList] = useState(false);
    const [redirectCredential, setRedirectCredential] = useState(0);
    const [credentialType, setCredentialType] = useState({id: 0, name: ""});
    const [credentialTypeOptions, setCredentialTypeOptions] = useState([]);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [credential, setCredential] = useState({
        credentialID: 0,
        username: "",
        password: "",
        domain: "",
        enforcePasswordQuality: false,
        credentialTypeID: 0,
        credentialTypeName: "",
        assetID: "",
        organizationID: "",
    });

    useEffect(() => {
        loadCredentialTypeOptions();
        if (!props.asset) {
            loadOrgAssets();
        }
    }, []);

    useEffect(() => {
        loadCredential(parseInt(props.routeProps.match.params.credID, 10));
    }, [props.routeProps.match.params.credID]);

    const loadCredential = (credentialID) => {
        if (!isNaN(credentialID) && credentialID !== 0) {
            setLoading(true);
            GetFullCredential({ content: credentialID })
                .then(res => {
                    if (res.success) {
                        setCredential(res.content);
                        setPassword1(res.content.password);
                        setPassword2(res.content.password);
                        setCredentialType({ id: res.content.credentialTypeID, name: res.content.credentialTypeName });
                        setAssetId(res.content.assetID);
                    }
                });
        }
        setLoading(false);
    }

    const loadOrgAssets = () => {
        setLoading(true);
        GetOrganizationAssets({ content: props.org?.orgID ? parseInt(props.org?.orgID, 10) : 0 })
            .then((res) => {
                if (res != null) {
                    setAssets(res.content);
                }
                setLoading(false);
            })
            .catch(() => {
                //setError(true);
            });
    }

    const loadCredentialTypeOptions = () => {
        setLoading(true);
        GetCredentialTypeList({ pageSize: Number(-1), pageIndex: Number(-1), sortBy: [], filters: [] })
            .then(res => {
                if (res.success) {
                    let options = [];
                    res.content.map(ct => {
                        options.push({
                            id: ct.credentialTypeID,
                            name: ct.name
                        });
                    });
                    setCredentialTypeOptions(options);
                }
            }).catch((e) => {
                console.log(e);
            });
        setLoading(false);
    }

    const createCredential = () => {
        setLoading(true);
        let body = {
            ... credential,
            credentialTypeID: credentialType.id,
            password: password1,
            organizationID: props.org?.orgID ? parseInt(props.org?.orgID, 10) : 0,
            assetID: props.asset?.assetID ? parseInt(props.asset?.assetID, 10) : assetId
        }
        CreateCredential(body)
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setMessage("Credential Saved");
                        setRedirectCredential(res.content.credentialID);
                    } else {
                        setMessage(res.message);
                    }
                }
            })
            .catch(() => {
                setMessage("Error Saving Credential");
            })
        setLoading(false)
    }

    const updateCredential = () => {
        if (!isNaN(credential.credentialID) && credential.credentialID !== 0) {
            setLoading(true);
            UpdateCredential({ 
                ... credential, 
                credentialTypeID: credentialType.credentialTypeID,
                password: password1,
                assetID: assetId,
                })
                .then(res => {
                    if (res.success) {
                        setMessage("Credential Saved");
                        setCredential(res.content);
                    } else {
                        setMessage(res.message);
                    }
                });
        }
        setLoading(false);
    }

    const deleteCredential = () => {
        if (!isNaN(credential.credentialID) && credential.credentialID !== 0) {
            setLoading(true);
            DeleteCredential({ content: credential.credentialID })
                .then(res => {
                    if (res.success) {
                        setMessage("Credential Deleted");
                        setRedirectList(true);
                    } else {
                        setMessage(res.message);
                    }
                });
        }
        setLoading(false);
    }

    const submit = (e) => {
        e.preventDefault();
        if (credential.username === "") {
            setMessage("Cannot save: username field empty.");
            return;
        }  
        if (password1 === "") {
            setMessage("Cannot save: password field empty.");
            return;
        }
        if (password1 !== password2) {
            setMessage("Passwords do not match.");
            return;
        }
        if (credentialType.id === 0) {
            setMessage("Must select a credential type.");
            return;
        }
        credential.credentialID === 0 ? createCredential() : updateCredential();
    }

    const inputChanged = (e) => {
        let newCredential = { ...credential };
        newCredential[e.target.name] = e.target.value;
        setCredential(newCredential);
    }

    const toggleEnforcement = () => {
        let newCredential = { ...credential };
        newCredential["enforcePasswordQuality"] = !newCredential.enforcePasswordQuality;
        setCredential(newCredential);
    }
    
    const setCredentialTypeOption = (id) => {
        credentialTypeOptions.map(ct => {
            if (ct.id === id) {
                setCredentialType({id: ct.id, name: ct.name});
            }
        });
    }

    const getBaseURL = () => {
        if (!props.asset) {
            return `/${tenant}/organizations/${props.org?.orgID}/credentials`;
        }
        return `/${tenant}/assets/${props.asset?.assetID}/credentials`;
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            { redirectList && <Redirect to={getBaseURL()} /> }
            { redirectCredential !== 0 && <Redirect to={`${getBaseURL()}/${redirectCredential}`} /> }
            <BreadcrumbsItem to={`${getBaseURL()}/new/form`} label='New Credential' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            { !props.asset ?
                                <tr>
                                    <td>Organization:</td>
                                    <td>
                                        {props.org?.orgName}
                                    </td>
                                    <td></td>
                                </tr>
                            :
                                <tr>
                                    <td>Asset:</td>
                                    <td>
                                        {props.asset?.name}
                                    </td>
                                    <td></td>
                                </tr>
                            }
                            {!props.asset &&
                                <tr>
                                    <td>Asset:</td>
                                    <td>
                                        <select value={assetId} onChange={(e) => { setAssetId(Number(e.target.value)) }}>
                                            <option value={0}>No Asset</option>
                                            {assets.map((a) =>
                                                <option key={a.assetID} value={a.assetID}>{a.name}</option>
                                            )}
                                        </select>
                                    </td>
                                    <td></td>
                                </tr>
                            }
                            <tr>
                                <td>Credential Type:</td>
                                { credential.credentialID === 0 ?
                                    <td>
                                            <select value={credentialType.id} onChange={(e) => setCredentialTypeOption(parseInt(e.target.value,10))}>
                                                {credentialType.id === 0 && <option key={0} value={0}>{}</option>}
                                                {credentialTypeOptions.map((ct) =>
                                                    <option key={ct.id} value={ct.id}>{ct.name}</option>
                                                )}
                                            </select>
                                    </td> 
                                    : <td>
                                        {credential.credentialTypeName}
                                    </td>
                                }
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td><input type="text" name="username" id="username" value={credential.username} onChange={(e) => inputChanged(e)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Password:</td>
                                <td><input type="text" name="password1" id="password1" value={password1} onChange={(e) => setPassword1(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Confirm Password:</td>
                                <td><input type="text" name="password2" id="password2" value={password2} onChange={(e) => setPassword2(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Enforce Password Quality:</td>
                                <td><input type="checkbox" name="quality" id="quality" checked={credential.enforcePasswordQuality} value={credential.enforcePasswordQuality} onChange={() => toggleEnforcement()} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Domain:</td>
                                <td><input type="text" name="domain" id="domain" value={credential.domain} onChange={(e) => inputChanged(e)} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="nexusFormSubmit">
                                        <input type="submit" name="SaveCredential" id="SaveCredential" value="Save Credential" className="nexusButton" />
                                    </div>
                                </td>
                                <td>
                                    
                                    <div className="nexusFormSubmit">
                                        <input type="button" name="DeleteCredential" id="DeleteCredential" value="Delete Credential" className="nexusButton" onClick={() => deleteCredential()}/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {message}
                </form>
            </div>
            {credential.credentialID !== 0 && <CredentialFileTable credentialID={credential.credentialID} editing={true}/>}
        </div>
    )
}