import APIRequest from './APIRequest';
import UpdateAssetXBertVersionRequest from './models/UpdateAssetXBertVersionRequest';
export function XBertVersions() {
    return new Promise<{ data: any }>((resolve) =>
        APIRequest({
            url: `Asset/GetXBertVersions`,
            method: 'get',
        }).then((res) => resolve({ data: res }))
    );
}

export function GetXBertVersionByAsset(assetId: number) {
    return new Promise<{ data: any }>((resolve) =>
        APIRequest({
            url: `Asset/GetXBertVersion`,
            method: 'post',
            body: {
                content: assetId,
            },
        }).then((res) => resolve({ data: res }))
    );
}

export function GetRequestedVersionByAsset(assetId: number) {
    return new Promise<{ data: any }>((resolve) =>
        APIRequest({
            url: `Asset/GetRequestedVersionByAsset`,
            method: 'post',
            body: {
                content: assetId,
            },
        }).then((res) => resolve({ data: res }))
    );
}

export function UpdateAssetXBertVersion(req: UpdateAssetXBertVersionRequest) {
    return new Promise<{ data: any }>((resolve) =>
        APIRequest({
            url: `Asset/UpdateAssetXBertVersion`,
            method: 'post',
            body: {
                AssetID: +req.assetId,
                XBertVersionID: +req.xBertVersionId,
                UpdateMode: +req.updateMode,
                VersionToUpdate: +req.versionToUpdate,
            },
        }).then((res) => resolve({ data: res }))
    );
}
