import React, {useState, useEffect} from 'react';
import { GetOrganizationTrunks } from '../../api/TelephonyAPI.js';
import LoadingShroud from '../App/LoadingShroud.js';
import NexusGrid from '../App/NexusGrid';
import TelephonyImportExport from './telephonyimportexport.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [filterAct, setFilterAct] = useState(false);
    const [trunks, setTrunks] = useState([]);
    const [trunkTypes,  setTrunkTypes]  = useState([{label: "Customer", value:1},{label: "Carrier", value:2},]);

    useEffect(() => {
        loadItems(filterAct);
    },[])  

    const loadItems = (filterActive) => {         
        const orgID = props.routeProps.match.params.id;
        GetOrganizationTrunks({ content: { orgID: Number(orgID), active: !filterActive }})
            .then(res => {
                if (res != null) {
                    for (var i = 0; i < res.content.length; i++) {
                        res.content[i].trunkTypeName = getTrunkTypeLabel(res.content[i].trunkType);
                    }
                    setTrunks(res.content);
                    setLoaded(true);
                    setFilterAct(filterActive);
                } else {
                    setLoaded(false);
                    setError(true);
                    setFilterAct(filterActive);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    const getTrunkTypeLabel = (typeID) => {
        var type = trunkTypes.filter((f) => f.value === typeID);
        if (type.length > 0)
            return type[0].label;
        else
            return "";
    }

    const filterChanged = (e) => {
        loadItems(e.target.checked);
    }

        const headers = [
            { Header: "Trunk ID", accessor: "trunkId" },
            { Header: "Name", accessor: "trunkName" },
            { Header: "Details", accessor: "trunkDetails" },
            { Header: "Type", accessor: "trunkTypeName" }
        ];

        return (
            <div>
                <LoadingShroud Active={!loaded} />
                {loaded ? (
                    <div>
                        <div className="telephony-tab-heading">Trunks</div>
                        <TelephonyImportExport
                            routeProps={props.routeProps}
                            objectType={"Trunk"}
                            importCallback={() => loadItems()} />
                        <div className="telephony-object-filters">
                            <label>Show Inactive</label>
                            <input type="checkbox" onChange={(e) => filterChanged(e)} checked={filterAct} id="" />
                        </div>
                        <NexusGrid
                            linkTo={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/trunk/:key"}
                            tableKey={"trunkId"}
                            hiddenColumns={['trunkId']}
                            data={trunks}
                            defaultSortBy={"Name"}
                            columns={headers}
                            filterHeaders={false}
                            pageMode={"none"}
                        />
                    </div>
                ) : error ? (
                    <p>An error occurred while loading Trunk data</p>
                ) : null}
            </div>
        )
}