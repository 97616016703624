import React, { useState, useEffect } from 'react';
import Loader from './loader.js';
//import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

//import { Authenticate } from '../../api/NexusAPI';
import { GetCookieTenants } from '../../api/TenantAPI';

const TenantLogin = props => {
    const [loading, setLoading] = useState(false);

    const [tenants, setTenants] = useState([]);
    //const tenant = useSelector(state => state.TenantReducer.Tenant);
    //const dispatch = useDispatch();

    useEffect(() => {
        getCookies();
        return () => {
            setLoading(false);
        };
    }, [props]);

    const getCookies = () => {
        setLoading(true);
        var _tenants = [];
        var cookies = document.cookie.split(';').reduce(
            (cookies, cookie) => {
                const [name, val] = cookie.split('=').map(c => c.trim());
                cookies[name] = val;
                _tenants.push(cookies[name]);
                return cookies;
            }, {});
        //console.log(cookies);
        //console.log(_tenants);
        GetCookieTenants(_tenants)
            .then((response) => {
                setLoading(false);
                // console.log(response);
                if (response.success) {
                    setTenants(response.content);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    //const updateTenant = (payload) => {
    //    dispatch({ type: 'SET_TENANT', payload: payload })
    //}

    return (
        <div className="nexusForm loginForm">
            {loading ? (
                <div className="NexusLoading">
                    <Loader />
                </div>
            ) : (
                <div>
                    <h2>Sign In Under</h2>
                    <div className="nexusFormSubmit">
                        <table cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {tenants.map(t =>
                                        <td key={t.uri}>
                                            <Link to={`/${t.uri}`}>
                                                {t.tenantLogo !== null ? (
                                                    // not sure if this will work
                                                    <img src={`data:image/jpeg;base64,${t.tenantLogo}`} alt={t.name} title={t.name} />
                                                ) : (
                                                    <img src="Images/logo-placeholder.png" alt={t.name} title={t.name} />
                                                )}
                                            </Link>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <Link to={`/TenantIdentify`}>
                            <img className="nexusAddTenant" src="Images/Add.png" alt="Add Tenant" title="Add Tenant" />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TenantLogin;