import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../App/loader';
import { GetOrganizations } from '../../api/OrganizationAPI';
import { useDispatch, useSelector } from 'react-redux';
import NexusGrid, { IndeterminateCheckboxColumnFilter } from '../App/NexusGrid';

const OrgTable = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [orgs, setOrgs] = useState([]);
    const [loading, setLoading] = useState(true);
    const canUserCreatePerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );

    // remote pagination implementation requires these
    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);
    const getOrgs = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        setFilterResults(filters);
        setSortByResults(sortBy);
        firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
        firstRender.current = false;
        GetOrganizations({pageSize: pageSize, pageIndex: pageIndex, sortBy, filters})
            .then((response) => {
                setLoadingFilterSort(false);
                setLoading(false);
                if (response.success) {
                    setOrgs(response.content);
                    setTotalResults(response.totalCount);
                    setTotalPages(response.pageCount);
                }
            })
            .catch(() => {
                setLoadingFilterSort(false);
                setLoading(false);
            });
    }, []);

    const headers = [
        { Header: 'Org ID', accessor: 'orgID' },
        { Header: 'Org Name', accessor: 'orgName' },
        { Header: 'Org Code', accessor: 'orgCode' },
        { Header: 'Phone', accessor: 'mainNumber' },
        { Header: 'Active', accessor: 'active' }
    ];

    const addOrgToggle = canUserCreatePerson ? (
        <Link to={`/${tenant}/organizations/add`}>
            <div className="nexusButtonCommon">
                <i className="fas fa-plus"></i> Add Organization
            </div>
        </Link>
    ) : null;

    const content = (
        <div>
            <h2>Organizations</h2>
            <>
                {addOrgToggle}
                <NexusGrid
                    data={orgs}
                    fetchData={getOrgs}
                    filterData={filterResults}
                    sortByData={sortByResults}
                    columns={headers}
                    loading={loading}
                    loadingFilterSort={loadingFilterSort}
                    linkTo={`/${tenant}/organizations/:key/info`}
                    tableKey={'orgID'}
                    defaultSortBy={'orgID'}
                    pageMode="remote"
                    perPage={25}
                    totalResults={totalResults}
                    totalPages={totalPages}
                    defaultSortOrder={"asc"}
                    hiddenColumns={["orgID", "active"]}
                />
            </>
            </div>
    );

    return content;
};

export default OrgTable;
