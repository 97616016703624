const reactModalStyle = (width, height, padding) => {
    return {
      content: {
        display: 'table',
        width: width,
        height: height,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: padding
      },
      overlay: {
        background: 'rgb(12 12 12 / 60%)'
      }
    }
  };

export default reactModalStyle;