import React, { useState, useEffect } from 'react';
import { GetServiceOperations, GetUpdatedServiceOperations, SetServiceOperations } from '../../api/ServiceAPI';
import Loader from '../App/loader';

export default props => {
    const [loading, setLoading] = useState(true);
    const [operationsUp, setOperationsUp] = useState([]);
    const [operationsDown, setOperationsDown] = useState([]);

    const [type, setType] = useState("Up");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        loadServiceOperations();
    }, [props.service.serviceId]);

    const loadServiceOperations = () => {
        let serviceId = parseInt(props.service.serviceId, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            setLoading(true);
            GetServiceOperations({ content: serviceId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setOperationsUp(res.operationsUP);
                            setOperationsDown(res.operationsDOWN);
                        }
                    }
                });
        }
        setLoading(false);
    }

    const loadUpdatedServiceOperations = () => {
        let serviceId = parseInt(props.service.serviceId, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            setLoading(true);
            GetUpdatedServiceOperations({ content: serviceId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setOperationsUp(res.operationsUP);
                            setOperationsDown(res.operationsDOWN);
                        }
                    }
                });
        }
        setLoading(false);
    }

    const setServiceOperations = (opUps, opDowns) => {
        let serviceId = parseInt(props.service.serviceId, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            setLoading(true);
            SetServiceOperations({
                serviceID: serviceId,
                operationsUP: opUps,
                operationsDOWN: opDowns
            })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setOperationsUp(res.operationsUP);
                            setOperationsDown(res.operationsDOWN);
                        }
                    }
                });
        }
        setLoading(false);
    }

    const addOperation = () => {
        let isUp = type === "Up";
        let length = isUp ? operationsUp.length : operationsDown.length;
        let operation = {
            name: name,
            description: description,
            manual: true,
            sequence: length
        };
        let opUps = [...operationsUp];
        let opDowns = [...operationsDown];
        isUp ? opUps.push(operation) : opDowns.push(operation);
        setType("Up");
        setName("");
        setDescription("");
        setServiceOperations(opUps, opDowns);
    }

    const removeOperation = (id) => {
        let opUps = [];
        let upSequence = 0;
        for (let i = 0; i < operationsUp.length; i++) {
            if (operationsUp[i].serviceTransitionOperationId !== id) {
                operationsUp[i].sequence = upSequence++;
                opUps.push(operationsUp[i]);
            }
        }
        let opDowns = [];
        let downSequence = 0;
        for (let i = 0; i < operationsDown.length; i++) {
            if (operationsDown[i].serviceTransitionOperationId !== id) {
                operationsDown[i].sequence = downSequence++;
                opDowns.push(operationsDown[i]);
            }
        }
        setServiceOperations(opUps, opDowns);
    }

    const shiftSequence = (sequence, offset, isInUp) => {
        if ((sequence < 0) || ((isInUp && sequence > operationsUp.length - 1) || (!isInUp && sequence > operationsDown.length - 1))) {
            return;
        } else {
            let newSequence = sequence + offset;
            if ((newSequence < 0) || ((isInUp && newSequence > operationsUp.length - 1) || (!isInUp && newSequence > operationsDown.length - 1))) {
                return;
            }
        }
        let newOps = isInUp ? [...operationsUp] : [...operationsDown];
        newOps[sequence] = isInUp ? operationsUp[sequence + offset] : operationsDown[sequence + offset];
        newOps[sequence + offset] = isInUp ? operationsUp[sequence] : operationsDown[sequence];
        newOps[sequence].sequence = sequence;
        newOps[sequence + offset].sequence = sequence + offset;
        setServiceOperations(isInUp ? newOps : operationsUp, isInUp ? operationsDown : newOps);
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h3>Operations</h3>

            <div id="updateOperationsButton" className="nexusButtonCommon" onClick={() => loadUpdatedServiceOperations()}>Update Operations</div>

            <div>
                Up
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr key="0">
                            <th>Sequence</th>
                            <th>Hook</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Process</th>
                            <th>Actions</th>
                        </tr>
                        {operationsUp.map(so =>
                            <tr key={so.sequence}>
                                <td>{so.sequence}</td>
                                <td>{so.hook ? so.hook : "custom"}</td>
                                <td>{so.name}</td>
                                <td>{so.description}</td>
                                <td>{so.manual ? "Manual" : "Automatic"}</td>
                                <td>
                                    {so.sequence !== 0 && <div id="upSequenceButton" className="nexusButtonCommon" onClick={() => shiftSequence(so.sequence, -1, true)}>/\</div>}
                                    {so.sequence !== (operationsUp.length - 1) && <div id="upSequenceButton" className="nexusButtonCommon" onClick={() => shiftSequence(so.sequence, 1, true)}>\/</div>}
                                    {!so.hook && <div id="removeOperationButton" className="nexusButtonCommon" onClick={() => removeOperation(so.serviceTransitionOperationId)}><img src="/Images/Remove.png" alt="[ Remove ]" />Remove</div>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                Down
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr key="0">
                            <th>Sequence</th>
                            <th>Hook</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Process</th>
                            <th>Actions</th>
                        </tr>
                        {operationsDown.map(so =>
                            <tr key={so.sequence}>
                                <td>{so.sequence}</td>
                                <td>{so.hook ? so.hook : "custom"}</td>
                                <td>{so.name}</td>
                                <td>{so.description}</td>
                                <td>{so.manual ? "Manual" : "Automatic"}</td>
                                <td>
                                    {so.sequence !== 0 && <div id="upSequenceButton" className="nexusButtonCommon" onClick={() => shiftSequence(so.sequence, -1, false)}>/\</div>}
                                    {so.sequence !== (operationsDown.length - 1) && <div id="upSequenceButton" className="nexusButtonCommon" onClick={() => shiftSequence(so.sequence, 1, false)}>\/</div>}
                                    {!so.hook && <div id="removeOperationButton" className="nexusButtonCommon" onClick={() => removeOperation(so.serviceTransitionOperationId)}><img src="/Images/Remove.png" alt="[ Remove ]" />Remove</div>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <select name="typeSelect" id="typeSelect" value={type} onChange={(e) => setType(e.target.value)}>
                                <option key="Up" value="Up">Up</option>
                                <option key="Down" value="Down">Down</option>
                            </select>
                        </td>
                        <td>
                            Name: <input type="text" name="operationName" id="operationName" value={name} onChange={(e) => setName(e.target.value)} />
                        </td>
                        <td>
                            Description: <input type="text" name="operationDescription" id="operationDescription" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </td>
                        <td>
                            <div id="addOperationButton" className="nexusButtonCommon" onClick={() => addOperation()}><img src="/Images/Add.png" alt="[ Add ]" />Add New Operation</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}