import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../App/loader';
import { GetAssetTypes, CreateAssetType } from '../../../api/AssetAPI';
import { useSelector } from 'react-redux';
import NexusModal from '../../App/NexusModal.js';
import NexusGrid from '../../App/NexusGrid.js';


const AssetTypesTable = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [assetType, setAssetType] = useState("");
    const [assetTypes, setAssetTypes] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        getTypes();
    }, [])

    const getTypes = () => {
        setLoading(true);
        GetAssetTypes().then((res) => {
            if (res.success) {
                setAssetTypes(res.content);
            }
            setLoading(false);
        });
    }

    const addAssetType = () => {
        if (assetType === "") {
            setDisplayModal(true);
            setMessage("Must enter a name to add an asset type.")
        } else {
            let payload = {
                assetTypeID: -1,
                name: assetType,
            }
            CreateAssetType(payload).then((res) => {
                if (res.success) {
                    setAssetType("");
                    setDisplayModal(false);
                    setMessage("");
                    getTypes();
                } else {
                    setDisplayModal(true);
                    setMessage(res.message);
                }
            })
        }
    }

    const headers = [
        { Header: 'Asset Type ID', accessor: 'assetTypeID', hidden: true },
        { Header: 'Asset Type Name', accessor: 'name' },
    ];

    const content = (
        <div>
            <Link className="whiteLink" to={`/${tenant}/assets/`}>
                <i className="fas fa-chevron-left"></i> Asset List
            </Link>
            <h2>Asset Types</h2>
            {loading ? (
                <>
                <div className="NexusLoading">
                    <Loader />
                </div>
                    
                    </>
            ) : (
                <>
                    <button className="nexusButtonCommon" onClick={() => { setDisplayModal(true); }}>
                        <i className="fas fa-plus"></i> Add Asset Type
                    </button>

                    <NexusModal stateValue={displayModal} stateFunctionClose={() => { setDisplayModal(false); } } title={`Enter a name for an Asset Type`}
                    closeText="Add Type" closeAction={() => addAssetType()} width='50%' height='auto'>
                            <input
                                style={{ width: '100%', marginBottom: '5px' }}
                                type="assetType"
                                name="assetType"
                                id="assetType"
                                value={assetType}
                                onChange={(e) => { setAssetType(e.target.value) }}
                                placeholder="Enter a name"
                                required
                            />
                            <p>{message}</p>
                    </NexusModal>

                    <NexusGrid
                        tableKey={'assetTypeID'}
                        data={assetTypes}
                        defaultSortBy={'name'}
                        columns={headers}
                        hiddenColumns={['assetTypeID']}
                        pageMode="local"
                        filterHeaders={true}
                    />

                </>
            )}
        </div>
    );

    return content;
};

export default AssetTypesTable;
