import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../App/loader';
import { GetPersonLogin } from '../../../api/PeopleAPI.js';
import NexusGrid from '../../App/NexusGrid.js';


const TabLogin = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const personID = props.routeProps.match.params.id;
        GetPersonLogin({ personID: Number(personID) }).then(response => {
            setLoaded(true);
            if (response.content.length > 0) {
                setData(response.content);
            }
        }).catch((e) => {
            setError(true);
        })
    }, []);

    return (
        <div className="localSortDGContainer">
            {loaded ? (
                <table
                    cellPadding="0"
                    cellSpacing="0"
                    className="loginTable"
                >
                    <thead>
                        <tr>
                            <th>Email Address</th>
                            <th>Verified</th>
                            <th>Change Password</th>
                            <th>For Organizations</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((l, index) => (
                            <tr key={index}>
                                <td>{l.emailAddress}</td>
                                <td>{l.isVerified === true ? ("Yes") : ("No")}</td>
                                <td>{l.tempPassword}</td>
                                <td>
                                    {l.orgNames.map((o, idx) => (
                                        <React.Fragment key={idx}>
                                            - {o}
                                        </React.Fragment>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : error ? (
                <p>An error occurred while loading company data.</p>
            ) : (
                <Loader />
            )}
        </div>
    )
};

export default TabLogin;
