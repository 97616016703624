import React from 'react';
import reactModalStyle from "../../utils/reactModalStyle";
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

const NexusModal = ({stateValue, stateFunctionClose, title, closeText, closeAction, width, height, children}) => {
    return (
        <Modal
            isOpen={stateValue}
            onRequestClose={stateFunctionClose}
            contentLabel="Modal"
            style={reactModalStyle(width, height, '0')}
        >
            <div className="nexus-modal">
                <div style={{ padding: '10px', borderBottom: '#333 1px solid' }}>
                    <div style={{ textAlign: 'right', paddingBottom: '5px' }}>
                        <button className="nexusButtonSmall" onClick={stateFunctionClose}>&times;</button>
                    </div>
                    <h4 style={{ margin: '0', textAlign: 'center', color: 'black', display: title ? 'block' : 'none' }}>{title}</h4>
                </div>
                <div style={{ padding: '20px' }}>{children}</div>
                {closeText ?
                    <div style={{ textAlign: 'center', borderTop: '#333 1px solid', padding: '20px' }}>
                        <button className="nexusButton" onClick={closeAction}>{closeText}</button>
                    </div>
                : null
                }
            </div>
        </Modal>
    );
}

export default NexusModal;

NexusModal.propTypes = {
    stateValue: PropTypes.bool,
    stateFunctionClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    closeText: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.any.isRequired
}

// use this method when modals are generated in a loop
export const openDynamicGeneratedModals = (bool, modalIndex, stateArray, stateSetter) => {
    let newModalStates = stateArray.slice();
    for(let i = 0; i < modalIndex; i++) {
      if(!newModalStates[i]) {
        newModalStates[i] = false;
      }
    }
    newModalStates[modalIndex] = bool;
    stateSetter(newModalStates);
}