import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidatePasswordResetLink } from '../../api/NexusAPI';

const ValidateLink = ({routeProps}) => {
  const history = useHistory();

  var queryParams = useMemo(() => {
    var paramMap = new Map();
    var paramArr = routeProps.location.search
      .replace(/^\?/, '')
      .split('&');
    paramArr.map((v) => {
      paramMap.set(
        v.substring(0, v.indexOf('=')),
        v.substring(v.indexOf('=') + 1)
      );
    });
    return paramMap;
  }, [routeProps.location.search]);

  useEffect(() => {
    console.log(routeProps)
    if (queryParams.get('user')) {
      ValidatePasswordResetLink(queryParams.get('user')).then((response) => {
        if (!response.success) {
          history.push('/');
        }
        else {
          history.push({
            pathname: routeProps.location.pathname.replace("validate", "ChangePswd"), 
            state: {
              user: queryParams.get('user'),
              expired: response.content
            }})
        }
      });
    }
  }, []);

  return null;
};

export default ValidateLink;
