import React, { useState, useEffect, useRef } from 'react';
import { GetOrganizationAssets, GetAssetsNotInOrg, UpdateBulkAssetRequest, GetXBertVersions } from '../../../api/AssetAPI';
import { GetOrganizationAssetKey } from '../../../api/OrganizationAPI.ts';
import NexusGrid from '../../App/NexusGrid.js';
import XBertOnlineIndicator from '../../Assets/xBertOnlineIndicator';
import Loader from '../../App/loader.js';
import { Link } from 'react-router-dom';
import NexusModal from '../../App/NexusModal.js';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [assets, setAssets] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [displayUpdateModal, setDisplayUpdateModal] = useState(false);
    const [displayKeyModal, setDisplayKeyModal] = useState(false);
    const versionToRequest = useRef(0);
    const [xBertVersionList, setxBertVersionList] = useState([]);
    const [updateRequestSuccess, setUpdateRequestSuccess] = useState(false);
    const [updateRequestFailed, setUpdateRequestFailed] = useState(false);
    const [organizationAssetKey, setOrganizationAssetKey] = useState({})
    const orgid = props.routeProps.match.params.id;

    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);

    const getAssets = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        setFilterResults(filters);
        setSortByResults(sortBy);
        firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
        firstRender.current = false;
        GetOrganizationAssets({content: props.org.orgID, pageSize: pageSize, pageIndex: pageIndex, sortBy, filters})
            .then((response) => {
                setLoadingFilterSort(false);
                setLoading(false);
                if (response.success) {
                    setAssets(response.content);
                    setTotalResults(response.totalCount);
                    setTotalPages(response.pageCount);
                }
            })
            .catch(() => {
                setLoadingFilterSort(false);
                setLoading(false);
            });
            GetXBertVersions().then((res) => {
                if(res?.success) {
                    setxBertVersionList(res.content);
                    versionToRequest.current = res.content[0].xBertVersionID;
                }
            })
    }, []);

    const headers = [
        { Header: 'Asset ID', accessor: 'assetID' },
        { Header: 'Name', accessor: 'name' },
        {
            Header: 'xBert Installed',
            accessor: 'xBertEnabled',
            dataFormat: (cell, row) => {
                return String(row.xBertEnabled);
            }
        },
        { Header: 'Last Seen', accessor: 'xBertLastSeen' },
        {
            Header: 'xBert Online',
            accessor: 'xBertOnline',
            dataFormat: (cell, row) => {
                return <XBertOnlineIndicator assetID={row.assetID} />;
            },
        },
        { Header: 'xBert Version', accessor: 'xBertVersionName'}
    ];

    const markAssetsToUpdate = () => {
        console.log('ejj3w');
        UpdateBulkAssetRequest({
            AssetIDs: selectedAssets,
            VersionID: versionToRequest.current
        }).then(res => {
            if (res.success) {
                setUpdateRequestSuccess(true);
            } else {
                setUpdateRequestFailed(true);
            }
            setDisplayUpdateModal(false);
            versionToRequest.current = xBertVersionList[0].xBertVersionID;
            setSelectedAssets([]);
        });
    }

    const getOrganizationAssetKey = () => {
        GetOrganizationAssetKey(Number(orgid))
            .then((response) => {
                if (response.success) {
                    setOrganizationAssetKey(response.organizationAssetKey);
                    setDisplayKeyModal(true);
                }
                else {
                    console.error(response.message);
                }
            })
            .catch((ex) => {
                console.error(ex);
            });
    }

    return (
        <div>
            <h2>Assets</h2>
            <>
                <Link to={{ pathname: `/${tenant}/assets/add`, state: orgid }} >
                    <div className="nexusButtonCommon">
                        <i className="fas fa-plus"></i> Add Asset
                    </div>
                </Link>
                <Link to={{ pathname: `/${tenant}/assetTypes` }}>
                    <div className="nexusButtonCommon">
                        View Asset Types
                    </div>
                </Link>
                <button type="button" className="nexusButtonCommon" onClick={() => getOrganizationAssetKey()}>
                    <i className="fas fa-key"></i> Generate Key
                </button>
                <div className="bootstrap-wrapper">
                    <Alert show={updateRequestFailed} variant="danger" dismissible onClose={() => setUpdateRequestFailed(false)}>
                        Asset update request failed
                    </Alert>
                    <Alert show={updateRequestSuccess} variant="success" dismissible onClose={() => setUpdateRequestSuccess(false)}>
                        Asset update request succeeded
                    </Alert>
                </div>
                <NexusModal stateValue={displayUpdateModal} stateFunctionClose={ () => setDisplayUpdateModal(false) }
                    title={`Request xBert Updates for ${selectedAssets.length} Asset${selectedAssets.length > 1 ? 's' : ''}`}
                    closeText="Update" closeAction={() => markAssetsToUpdate()} width='50%' height='auto'>
                    <div className="bootstrap-wrapper">
                        <select className='mb-1' onChange={(e) => versionToRequest.current = e.target.value} style={{ width: '100%' }}>
                            {xBertVersionList.map((v) => <option key={v.xBertVersionID} value={v.xBertVersionID}>{v.name}</option>)}
                        </select>
                        <p className='text-dark m-0'>
                            <b>Note: </b> If the asset is already on the version requested or higher, it will stay at that version.
                        </p>
                    </div>
                </NexusModal>

                <NexusModal
                    stateValue={displayKeyModal}
                    stateFunctionClose={() => setDisplayKeyModal(false)}
                    title={"Organization Key"}
                    closeText="Close"
                    closeAction={() => setDisplayKeyModal(false)}
                    width='auto' height='auto'>
                    <div className="bootstrap-wrapper">
                        <table >
                            <tbody>
                                <tr>
                                    <th>Key:</th>
                                    <td>{organizationAssetKey.key}</td>
                                </tr>
                                <tr>
                                    <th>Expires:</th>
                                    <td>{organizationAssetKey.expiration}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </NexusModal>

                <NexusGrid
                    linkTo={`/${tenant}/assets/:key/info`}
                    tableKey={'assetID'}
                    data={assets}
                    defaultSortBy={'assetID'}
                    columns={headers}
                    pageMode="remote"
                    filterHeaders={true}
                    selectRowMode={'checkbox'}
                    selectedRows={selectedAssets}
                    setSelectedRows={setSelectedAssets}
                    bulkActionFuncs={
                        [{ text: 'Update', func: () => { setDisplayUpdateModal(true); } }]
                    }
                    fetchData={getAssets}
                    filterData={filterResults}
                    sortByData={sortByResults}
                    loading={loading}
                    loadingFilterSort={loadingFilterSort}
                    perPage={25}
                    totalResults={totalResults}
                    totalPages={totalPages}
                />
            </>
        </div>
    );
}
