export default class NexusAPI {
    constructor(tenant) {
        this.tenant = tenant;
    }
    send(params) {
        const url = "/api/" + this.tenant + "/" + params.controller + "/" + params.action;
        const rememberToken = localStorage.getItem("nexusToken");
        const sessionToken = sessionStorage.getItem("nexusToken");
        var token = rememberToken ? rememberToken : sessionToken ? sessionToken : null;
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token != null) {
            headers['Authorization'] = "Bearer " + token;
        }
        return this.goFetch(params, url, headers, true);
    }
    getFile(params) {
        const url = "/api/" + this.tenant + "/" + params.controller + "/" + params.action;
        const rememberToken = localStorage.getItem("nexusToken");
        const sessionToken = sessionStorage.getItem("nexusToken");
        var token = rememberToken ? rememberToken : sessionToken ? sessionToken : null;
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token != null) {
            headers['Authorization'] = "Bearer " + token;
        }
        return this.goFetch(params, url, headers, false);
    }
    uploadFilePond(params) {
        const url = "/api/" + this.tenant + "/" + params.controller ;
        const action = "/" + params.action;
        const rememberToken = localStorage.getItem("nexusToken");
        const sessionToken = sessionStorage.getItem("nexusToken");
        var token = rememberToken ? rememberToken : sessionToken ? sessionToken : null;
        return {
            url: url,
            process: {
                url: action,
                method: params.method,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                ondata: (formData) => {
                    for (var x = 0; x < params.formData.length; x++) {
                        formData.append(params.formData[x].key, params.formData[x].value);
                    }
                    return formData;
                },
                onload: (response) => {
                    params.onload(response)
                }
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null
        }

    }
    goFetch(params, url, headers, toJSON) {
        fetch(url, {
            method: params.method,
            body: JSON.stringify(params.body),
            headers: headers,
        }).then(results => {
            if (!results.ok) { throw Error(results); }
            if (toJSON)
                return results.json()
            else
                return results;
        }).then(data => {
            params.success(data);
        }).catch(function (error) {
            console.dir(error);
            params.error(error);
        });
    }
}