import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateOnlineAssets,
  updateSelectedAssetCdriveContents,
  updateFileSystem,
  updateFilePathContent,
  updateDownloadedFile,
  updateUploadedFile,
  updateSelectedAssetStatus,
  updateUserAgentSessions,
  setICECandidate,
  setRemoteSdp,
  setAgentHungUp,
  setAgentAccepted,
  setNativeStreamDimensions,
  setClipboardContents,
  GetCommandLine,
  GetPingScanner
} from './actions';

const WebSocketContext = createContext(null);

export { WebSocketContext };

//used for sending out to agent
export const UserAgentSessionRequestType = 'Nexus.Data.Classes.Models.XBert.WebSocketModels.UserAgentSessionRequest';
//used for incoming from agent (in this file)
const UserAgentSessionRequest = 'UserAgentSessionRequest';
export const UIConnectRequestType = 'Nexus.Data.Classes.Models.XBert.WebSocketModels.UIConnectMessage';
export const OnlineAssetsRequestType = 'Nexus.Data.Classes.Models.XBert.WebSocketModels.OnlineAssetsRequest';
export const RTC_MESSAGES = {
  GET_AGENTS: 'GET_AGENTS',
  REFRESH_AGENTS: 'REFRESH_AGENTS',
  AGENT_DISCONNECTED: 'AGENT_DISCONNECTED',
  DELETE_AGENT: 'DELETE_AGENT',
  REBOOT_AGENT: 'REBOOT_AGENT',
  TRIGGER_AGENT: 'TRIGGER_AGENT',
  NEW_ICE_CANDIDATE: 'NEW_ICE_CANDIDATE',
  VIDEO_OFFER: 'VIDEO_OFFER',
  VIDEO_ANSWER: 'VIDEO_ANSWER',
  WHOAMI: 'WHOAMI',
  MOUSE_MOVE: 'MOUSE_MOVE',
  MOUSE_CLICK: 'MOUSE_CLICK',
  MOUSE_DRAG: 'MOUSE_DRAG',
  KEYBOARD_KEYPRESS: 'KEYBOARD_KEYPRESS',
  CLIPBOARD_CHANGE: 'CLIPBOARD_CHANGE',
  NEXUS_CONNECTED: 'NEXUS_CONNECTED',
  HANG_UP: 'HANG_UP',
  AGENT_ACCEPTED: "AGENT_ACCEPTED"
};
export const NexusClient = 'NEXUSCLIENT';

export default ({ children }) => {
  const dispatch = useDispatch();
  let ws;
  let socket = null;
    const socketURL = ((window.location.protocol.indexOf("https") > -1) ? 'wss' : 'ws') + `://${window.location.host}/xbertws`;

  const sendWhenConnected = (callback, interval) => {
    if (socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        sendWhenConnected(callback, interval);
      }, interval);
    }
  };
  const send = (message, callback) => {
    sendWhenConnected(() => {
      socket.send(message);
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  };
  const OnlineAssetsRequest = JSON.stringify({
    type: OnlineAssetsRequestType,
    body: {},
  });
  const OnConnect = JSON.stringify({
    type: UIConnectRequestType,
    body: {},
  });
  const whoAmI = () => {
    send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          MessageType: UserAgentSessionRequest,
          ICEMessageType: RTC_MESSAGES.WHOAMI,
          Guid_src: NexusClient,
        },
      })
    );
  };
  const OpenConnection = () => {
    if (!socket) {
      socket = new WebSocket(socketURL);

      socket.onopen = () => {
        console.log('Websocket Opened');
        send(OnConnect);
        send(
          JSON.stringify({
            type: UserAgentSessionRequestType,
            body: {
              MessageType: UserAgentSessionRequest,
              ICEMessageType: RTC_MESSAGES.WHOAMI,
              Guid_src: NexusClient,
            },
          })
        );
      };
      socket.onClose = () => {
        socket = null;
        console.log('Socket Closed');
        OpenConnection();
      };

      socket.onmessage = (e) => {
        let data = JSON.parse(e.data);
        switch (data.MessageType) {
          case 'OnConnect':
            send(OnConnect, () => {
              console.log('OnConnect Message sent');
            });
            break;
          case 'UIConnectResponse':
            send(OnlineAssetsRequest);
            break;
          case 'OnlineAssetsResponse':
            dispatch(updateOnlineAssets(data.Message));
            break;
          case 'CDriveContentResponse':
            dispatch(updateSelectedAssetCdriveContents(data.Message));
            break;
          case 'DriveListResponse':
            dispatch(updateFileSystem(data.DriveList));
            break;
          case 'FilePathContentResponse':
            dispatch(updateFilePathContent(data.Message));
            break;
          case 'FilePathPermissionError':
            data.Message.Error =
              'Access to this Filepath was denied by the host machine';
            dispatch(updateFilePathContent(data.Message));
            break;
          case 'FileDownloadResponse':
            dispatch(updateDownloadedFile(data.Message));
            break;
          case 'FileUploadResponse':
            dispatch(updateUploadedFile(data.Message));
            break;
          case 'AssetStatusResponse':
            dispatch(updateSelectedAssetStatus(data.Message));
            break;
          case 'GetCommandLine':
            dispatch(GetCommandLine(data));
            break;
          case 'GetPingScanner':
            dispatch(GetPingScanner(data));
            break;
          case 'UserAgentSessionRequest':
            switch (data.SubMessageType) {
              case RTC_MESSAGES.GET_AGENTS:
                dispatch(updateUserAgentSessions({ 
                  assetId: data.AssetID,
                  agents: data.Agents,
                  agentScreenshots: data.AgentScreenShots
                }));
                break;
              case RTC_MESSAGES.VIDEO_OFFER:
                dispatch(setRemoteSdp(data.Sdp));
                dispatch(setNativeStreamDimensions({
                  "width": data.StreamWidth,
                  "height": data.StreamHeight
                }));
                break;
              case RTC_MESSAGES.NEW_ICE_CANDIDATE:
                dispatch(setICECandidate(data.ICECandidate));
                break;
              case RTC_MESSAGES.HANG_UP:
              case RTC_MESSAGES.AGENT_DISCONNECTED:
                dispatch(setAgentHungUp(true));
                break;
              case RTC_MESSAGES.AGENT_ACCEPTED:
                dispatch(setAgentAccepted(true));
                break;
              case RTC_MESSAGES.CLIPBOARD_CHANGE:
                dispatch(setClipboardContents(data.Payload));
                break;
              default:
                break;
            }
            break;
          default:
            console.error(e);
            break;
        }
      };
    }
  };

  OpenConnection();
  ws = {
    socket: socket,
    send,
    whoAmI,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
