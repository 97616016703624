import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

export default props => {

    const renderBool = (bool) => {
        if (bool) {
            return "True"
        }
        else {
            return "False"
        }
    }

    const valueOrNA = (value) => {
        if (value === null || value === "") {
            return "Unavailable"
        }
        else {
            return value
        }
    }

        return (
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>
                            IP Address
                </th>
                        <th>
                            IPv4 Mask
                </th>
                        <th>
                            DNS Eligible
                </th>
                        <th>
                            Transient
                </th>
                        <th>
                            Prefix Origin
                </th>
                        <th>
                            Prefix Length
                </th>
                        <th>
                            Suffix Origin
                </th>
                    </tr>
                </thead>
                <tbody>
                    {props.addresses.map((address, index) => {
                        return (
                            <tr key={index}>
                                <td>{valueOrNA(address.unicastIPAddress)}</td>
                                <td>{valueOrNA(address.iPv4Mask)}</td>
                                <td>{renderBool(address.isDnsEligible)}</td>
                                <td>{renderBool(address.isTransient)}</td>
                                <td>{valueOrNA(address.prefixOrigin)}</td>
                                <td>{valueOrNA(address.prefxLength)}</td>
                                <td>{valueOrNA(address.suffixOrigin)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

        )
}

