import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendOrganizationData } from '../../../Redux/cart-actions';
import Notification from '../../UI/Notification';
import CreateOrganizationRequest from '../../../api/models/CreateOrganizationRequest';
import {
    PostCreateOrganization,
    UpdateOrganization,
} from '../../../api/OrganizationAPI';

const OrganizationForm = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const dispatch = useDispatch();
    const canUserModifyOrgs = useSelector(
        (state) => state.UserReducer.canModifyOrgs
    );
    const notification = useSelector((state) => state.UiReducer.notification);
    const [displayNotification, setDisplayNotification] = useState(false);
    const [status, setStatus] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const orgNameInputRef = useRef();
    const orgTypeIdInputRef = useRef();
    const orgAddress1InputRef = useRef();
    const orgAddress2InputRef = useRef();
    const orgCityInputRef = useRef();
    const orgStateInputRef = useRef();
    const orgPostalCodeInputRef = useRef();
    const orgMainPhoneNumberInputRef = useRef();
    const orgFaxNumberInputRef = useRef();
    const orgWebsiteInputRef = useRef();
    const ldapServerInputRef = useRef();
    const ldapDomainInputRef = useRef();
    const ldapRootInputRef = useRef();
    const ldapUserInputRef = useRef();
    const ldapPasswordInputRef = useRef();

    /**
     * form input controls
     */
    const newOrganizationFormControls = (
        <>
            <div className="form-group">
                <label htmlFor="organizationNameInput">Organization Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="organizationNameInput"
                    placeholder="The Company Inc"
                    ref={orgNameInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="authenticationMethod">Organization Type</label>
                <select
                    className="form-control"
                    name="authenticationMethod"
                    id="authenticationMethod"
                    ref={orgTypeIdInputRef}
                >
                    <option value="">-- Select an organization type --</option>
                    <option value="1">Internal</option>
                    <option value="2">Lead</option>
                    <option value="3">Prospect</option>
                    <option value="4">Customer</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="address1Input">Address 1</label>
                <input
                    type="text"
                    className="form-control"
                    id="address1Input"
                    placeholder="1 Company Way"
                    ref={orgAddress1InputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="address2Input">Address 2</label>
                <input
                    type="text"
                    className="form-control"
                    id="address2Input"
                    placeholder="Some Other Stuff"
                    ref={orgAddress2InputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="cityInput">City</label>
                <input
                    type="text"
                    className="form-control"
                    id="cityInput"
                    placeholder="Twin Peaks"
                    ref={orgCityInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="stateInput">State</label>
                <input
                    type="text"
                    className="form-control"
                    id="stateInput"
                    placeholder="PA"
                    ref={orgStateInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="postalCodeInput">Postal Code</label>
                <input
                    type="number"
                    className="form-control"
                    id="postalCodeInput"
                    placeholder="15221"
                    ref={orgPostalCodeInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="mainPhoneInput">Main Phone Number:</label>
                <input
                    type="number"
                    className="form-control"
                    id="mainPhoneInput"
                    placeholder="5551234567"
                    ref={orgMainPhoneNumberInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="faxNumberInput">Fax Number:</label>
                <input
                    type="number"
                    className="form-control"
                    id="faxNumberInput"
                    placeholder="5551234567"
                    ref={orgFaxNumberInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="websiteInput">Website:</label>
                <input
                    type="text"
                    className="form-control"
                    id="websiteInput"
                    placeholder="some-cool-site.com"
                    ref={orgWebsiteInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ldapServerInput">LDAP Server:</label>
                <input
                    type="text"
                    className="form-control"
                    id="ldapServerInput"
                    ref={ldapServerInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ldapDomainInput">LDAP Domain:</label>
                <input
                    type="text"
                    className="form-control"
                    id="ldapDomainInput"
                    ref={ldapDomainInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ldapRootInput">LDAP Root:</label>
                <input
                    type="text"
                    className="form-control"
                    id="ldapRootInput"
                    ref={ldapRootInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ldapUserInput">LDAP User:</label>
                <input
                    type="text"
                    className="form-control"
                    id="ldapUserInput"
                    ref={ldapUserInputRef}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ldapPasswordInput">LDAP Password:</label>
                <input
                    type="password"
                    className="form-control"
                    id="ldapPasswordInput"
                    ref={ldapPasswordInputRef}
                />
            </div>
        </>
    );

    const resetClickedHandler = () => {
        orgNameInputRef.current.value = null;
        orgTypeIdInputRef.current.value = null;
        orgAddress1InputRef.current.value = null;
        orgAddress2InputRef.current.value = null;
        orgCityInputRef.current.value = null;
        orgStateInputRef.current.value = null;
        orgPostalCodeInputRef.current.value = null;
        orgMainPhoneNumberInputRef.current.value = null;
        orgFaxNumberInputRef.current.value = null;
        orgWebsiteInputRef.current.value = null;
        ldapServerInputRef.current.value = null;
        ldapDomainInputRef.current.value = null;
        ldapRootInputRef.current.value = null;
        ldapUserInputRef.current.value = null;
        ldapPasswordInputRef.current.value = null;
    };

    /**
     * form submission controls
     */
    const formSubmitControls = (
        <>
            <hr />
            <div className="form-group">
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={resetClickedHandler}
                >
                    Clear Form
                </button>
                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </div>
        </>
    );

    /**
     *
     * @param {Event} e Button click event that triggered form submission
     */
    const formSubmitClickedHandler = async (e) => {
        e.preventDefault();
        const enteredOrgName = orgNameInputRef.current.value;
        const enteredOrgTypeId = +orgTypeIdInputRef.current.value;
        const enteredOrgAddress1 = orgAddress1InputRef.current.value;
        const enteredOrgAddress2 = orgAddress2InputRef.current.value;
        const enteredOrgCity = orgCityInputRef.current.value;
        const enteredOrgState = orgStateInputRef.current.value;
        const enteredOrgPostalCode = orgPostalCodeInputRef.current.value;
        const enteredOrgMainPhoneNumber =
            orgMainPhoneNumberInputRef.current.value;
        const enteredOrgFaxNumber = orgFaxNumberInputRef.current.value;
        const enteredOrgWebsite = orgWebsiteInputRef.current.value;
        const enteredLdapServer = ldapServerInputRef.current.value;
        const enteredLdapDomain = ldapDomainInputRef.current.value;
        const enteredLdapRoot = ldapRootInputRef.current.value;
        const enteredLdapUser = ldapUserInputRef.current.value;
        const enteredLdapPassword = ldapPasswordInputRef.current.value;

        const payload = {
            OrgName: enteredOrgName,
            OrgType: enteredOrgTypeId,
            Address1: enteredOrgAddress1,
            Address2: enteredOrgAddress2,
            City: enteredOrgCity,
            State: enteredOrgState,
            Zipcode: enteredOrgPostalCode,
            MainPhone: enteredOrgMainPhoneNumber,
            FaxNumber: enteredOrgFaxNumber,
            Website: enteredOrgWebsite,
            LDAPServer: enteredLdapServer,
            LDAPDomain: enteredLdapDomain,
            LDAPRoot: enteredLdapRoot,
            LDAPUser: enteredLdapUser,
            LDAPPassword: enteredLdapPassword,
        };

        try {
            const response = await PostCreateOrganization(payload);
            setSubmitted(true);
            setDisplayNotification(true);
            if (response.success) {
                setStatus('success');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setSubmitted(true);
            setStatus('error');
        }
    };

    /**
     *
     */
    const newOrganizationForm = (
        <form
            className="nexusForm personForm"
            onSubmit={formSubmitClickedHandler}
        >
            {newOrganizationFormControls}
            {formSubmitControls}
        </form>
    );

    const linkToList = (
        <Link className="whiteLink" to={`/${tenant}/organizations/`}>
            <i className="fas fa-chevron-left"></i> Organization List
        </Link>
    );

    const content = canUserModifyOrgs ? (
        <>
            {linkToList}
            {newOrganizationForm}
            {displayNotification && submitted ? (
                <Notification
                    status={status}
                    title={'Submission Result'}
                    message={status}
                    dismiss={() => setDisplayNotification(false)}
                />
            ) : null}
        </>
    ) : (
        <p>You do not have permissions to modify organizations</p>
    );

    return content;
};

export default OrganizationForm;
