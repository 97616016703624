import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../App/loader';
import {
    colIcon,
    truncateSummary,
    changeSort
} from '../../utils/incidentUtils';

import { GetCompanyListBasic } from '../../api/CompanyAPI';
import {
    GetIncidentParamLists,
    GetIncidentQueue,
    GetRequestors
} from '../../api/IncidentAPI';

const IncidentQueue = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(50);
    const [pageCount, setPageCount] = useState(1);
    const [descending, setDescending] = useState(false);
    const [sortCol, setSortCol] = useState("referenceID");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSet, setCurrentPageSet] = useState(0);
    const maxPagesPerSet = 10;
    const [filterCompany, setFilterCompany] = useState(-1);
    const [filterRequestor, setFilterRequestor] = useState(-1);
    const [filterAssignedTo, setFilterAssignedTo] = useState(-1);
    const [filterPriority, setFilterPriority] = useState(-1);
    const [filterStatus, setFilterStatus] = useState(-1);
    const [filterTeam, setFilterTeam] = useState(-1);
    const [companyList, setCompanyList] = useState([]);
    const [requestorList, setRequestorList] = useState([]);
    const [assignedToList, setAssignedToList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [incidents, setIncidents] = useState([]);

    useEffect(() => {
        setLoading(true);
        GetCompanyListBasic().then(response => {
            setCompanyList(response.content);
            return GetIncidentParamLists()
        }).then(response => {
            setAssignedToList(response.assignedToList);
            setStatusList(response.statusList);
            setPriorityList(response.priorityList);
            setTeamList(response.teamList);
            return loadIncidentQueue();
        }).then(response => {
            setIncidents(response.content);
            setPageCount(response.pageCount);
            setLoading(false);
        })
    }, [])
    useEffect(() => {
        setLoading(true);
        loadIncidentQueue().then(response => {
            setIncidents(response.content);
            setPageCount(response.pageCount);
            return GetRequestors(parseInt(filterCompany, 10))
        }).then(response => {
            setRequestorList(response.content);
            setLoading(false)
        })
    }, [filterCompany])
    useEffect(() => {
        setLoading(true);
        loadIncidentQueue().then(response => {
            setIncidents(response.content);
            setPageCount(response.pageCount);
            setLoading(false);
        })
    }, [count, currentPage, filterRequestor, filterAssignedTo, filterPriority,
        filterStatus, filterTeam, sortCol, descending])
    const loadIncidentQueue = () => {
        return GetIncidentQueue({
            count: parseInt(count, 10),
            pageNumber: parseInt(currentPage, 10),
            companyID: parseInt(filterCompany, 10),
            requestorID: parseInt(filterRequestor, 10),
            assignedToID: parseInt(filterAssignedTo, 10),
            priorityID: parseInt(filterPriority, 10),
            statusID: parseInt(filterStatus, 10),
            teamID: parseInt(filterTeam, 10),
            descending: descending,
            sortCol: sortCol
        });
    }
    const drawPages = () => {
        let pager = [];
        let numSets = Math.ceil(pageCount / maxPagesPerSet);
        let page = 1;
        let setNum = 0;

        while(page <= pageCount)
        {
            let set = {
                lSet: false,
                rSet: false,
                pages: []
            };

            if(numSets != 1)
            {
                if(setNum == 0) {
                    set.rSet = setNum + 1;
                } else if(setNum == numSets - 1) {
                    set.lSet = setNum - 1;
                } else {
                    set.lSet = setNum - 1;
                    set.rSet = setNum + 1;
                }
            }

            for(let i = 0; i < maxPagesPerSet; i++) {
                set.pages.push(page);
                page++;
                if(page > pageCount) break;
            }
            pager[setNum++] = set;
        }

        return pager;
    }
    const changeCount = newCount => {
        setCurrentPage(1);
        setCount(newCount);
    }
    const changePage = e => {
        e.preventDefault();
        const pageNum = e.target.getAttribute('pagenum')
        if (pageNum) {
            setCurrentPage(pageNum);
        }
    }
    const changePageSet = (direction) => {
        if(direction == 'l') {
            setCurrentPageSet(currentPageSet - 1);
            setCurrentPage((currentPageSet-1) * (maxPagesPerSet) + maxPagesPerSet);
        } else if(direction == 'r') {
            setCurrentPageSet(currentPageSet + 1);
            setCurrentPage((currentPageSet+1) * (maxPagesPerSet) + 1);
        }
    }

    return (
        <div>
            <h2>Incident Queue</h2>
            <div className="tabDisplayArea">
                {loading ? (
                    <div className="NexusLoading">
                        <Loader />
                    </div>
                ) : null}
                <div style={{ marginBottom: "12px" }}>
                    <strong style={{ marginRight: "14px" }}>
                        Filters:
                    </strong>
                    <select value={filterCompany}
                        onChange={e => setFilterCompany(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Companies --</option>
                        {companyList.map(company =>
                            <option key={company.itemID} value={company.itemID}>
                                {company.itemName}
                            </option>
                        )}
                    </select>
                    <select value={filterRequestor}
                        onChange={e => setFilterRequestor(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Requestors --</option>
                        {requestorList.map(requestor =>
                            <option key={requestor.itemID} value={requestor.itemID}>
                                {requestor.itemName}
                            </option>
                        )}
                    </select>
                    <select value={filterAssignedTo}
                        onChange={e => setFilterAssignedTo(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Assigned To --</option>
                        {assignedToList.map(contact =>
                            <option key={contact.itemID} value={contact.itemID}>
                                {contact.itemName}
                            </option>
                        )}
                    </select>
                    <select value={filterPriority}
                        onChange={e => setFilterPriority(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Priorities --</option>
                        {priorityList.map(priority =>
                            <option key={priority.itemID} value={priority.itemID}>
                                {priority.itemName}
                            </option>
                        )}
                    </select>
                    <select value={filterStatus}
                        onChange={e => setFilterStatus(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Statuses --</option>
                        {statusList.map(status =>
                            <option key={status.itemID} value={status.itemID}>
                                {status.itemName}
                            </option>
                        )}
                    </select>
                    <select value={filterTeam}
                        onChange={e => setFilterTeam(e.target.value)}
                        className="filterDropdown">
                        <option value={-1}>-- All Teams --</option>
                        {teamList.map(team =>
                            <option key={team.itemID} value={team.itemID}>
                                {team.itemName}
                            </option>
                        )}
                    </select>
                    <div>
                        <strong>Page Size:</strong>
                        <select value={count}
                            onChange={e => changeCount(e.target.value)}
                            style={{ marginLeft: "14px" }}>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                            <option value={200}>200</option>
                            <option value={300}>300</option>
                        </select>
                    </div>
                </div>
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "referenceID", descending, setSortCol,
                                    setDescending)}>
                                Incident ID
                                {colIcon(sortCol, "referenceID", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "companyName", descending, setSortCol,
                                    setDescending)}>
                                Company
                                {colIcon(sortCol, "companyName", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "requestedByName", descending, setSortCol,
                                    setDescending)}>
                                Requestor
                                {colIcon(sortCol, "requestedByName", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "summary", descending, setSortCol,
                                    setDescending)}>
                                Summary
                                {colIcon(sortCol, "summary", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "assignedToName", descending, setSortCol,
                                    setDescending)}>
                                Assigned To
                                {colIcon(sortCol, "assignedToName", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "priorityName", descending, setSortCol,
                                    setDescending)}>
                                Priority
                                {colIcon(sortCol, "priorityName", descending)}
                            </th>
                            <th className="incidentTableHeader"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeSort(sortCol,
                                    "statusName", descending, setSortCol,
                                    setDescending)}>
                                Status
                                {colIcon(sortCol, "statusName", descending)}
                            </th>
                        </tr>
                        {incidents.length === 0 ? (
                            <tr>
                                <td colSpan="7">No Incidents found.</td>
                            </tr>
                        ) : null}
                        {incidents.map(incident =>
                            <tr key={incident.incidentID}>
                                <td>
                                    <Link to={`/${tenant}/Incidents/${incident.incidentID}`}
                                        className="incidentRefLink">
                                        {incident.referenceID}
                                    </Link>
                                </td>
                                <td>{incident.companyName}</td>
                                <td>{incident.requestedByName}</td>
                                <td>
                                    <div className="incidentSummary">
                                        {truncateSummary(incident.summary)}
                                    </div>
                                </td>
                                <td>{incident.assignedToName}</td>
                                <td>{incident.priorityName}</td>
                                <td>{incident.statusName}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="pager">
                    {(drawPages()[currentPageSet]?.lSet !== false) ?
                        <div className="page" onClick={() => changePageSet('l')}><a style={{ cursor: 'pointer' }}>&#171;</a></div> : null}
                    {drawPages()[currentPageSet]?.pages.map(page => {
                        return (
                            <div key={page} className={page === parseInt(currentPage, 10) ?
                             "page currentPage" : "page"}>
                                 <Link to="/" pagenum={page} onClick={changePage}>
                                     {page}
                                 </Link>
                             </div>
                         );
                    })}
                    {(drawPages()[currentPageSet]?.rSet !== false) ?
                        <div className="page" onClick={() => changePageSet('r')}><a style={{ cursor: 'pointer' }}>&raquo;</a></div> : null}
                </div>
            </div>
        </div>
    )
}

export default IncidentQueue;