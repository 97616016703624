import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../App/loader.js';
import { GetOrganizationTrunks, GetTrunks, AddOrganizationDID } from '../../api/TelephonyAPI';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [trunks,  setTrunks] = useState([]);
    const [dids, setDids]  = useState({});

    useEffect(() => {
        const trunkId = trunks.map(function (a) { return a.trunkId; });
        const orgId = props.routeProps.match.params.id;
        const active = 1;
        GetOrganizationTrunks({ content: { trunkId: Number(trunkId), orgId: Number(orgId), active: Number(active) }})
            .then(res => {
                if (res != null) {
                    setTrunks(res.content);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setLoaded(false);
                setError(true);
            })

        GetTrunks().then(response => {
            if (response.success) {
                setTrunks(response.trunks);
                setLoaded(true);
            }
        }).catch(() => {
            setLoaded(false);
            setError(true);
        })
    },[])

    const saveDid = (e) => {
        var did = dids;
        did[e.target.name] = e.target.value;
        setDids(did);
    }

    const addDid = () => {
        setLoaded(false);
        setError(false);
        const did = {
            number: dids.number,
            description: dids.description,
            carrierid: 1,
            orgid: Number(props.routeProps.match.params.id),
            trunkId: Number(trunks[0].trunkId)
        }
        AddOrganizationDID({ content: did })
            .then(res => {
                if (res != null) {
                    setDids(res.content);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setLoaded(false);
                setError(true);
            })
    }

    const setTrunk = () => {
        const trunkId = trunks.map(function (a) { return a.trunkId; });
        var trunk = trunks.filter(o => o.trunkId === Number(trunkId));
        if (trunk.length === 1) {
            var did = dids;
            did.trunkId = Number(trunkId);
            did.trunk = trunk[0].trunkName;
            setDids(did);
        }
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>Add DID Number</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>DID Number:</th>
                                    <td><textarea type="text" onChange={saveDid} name="number"></textarea></td>
                                </tr>
                                <tr>
                                    <th>Description:</th>
                                    <td><input type="text" onChange={saveDid} name="description" /></td>
                                </tr>
                                <tr>
                                    <th>Trunk:</th>
                                    <td>
                                        <input type="hidden" disabled onChange={saveDid} name="trunkName" value={dids.trunkId} />
                                        <select value={dids.trunkId} onChange={e => setTrunk(parseInt(e.target.value, 10))}>
                                            <option value={0}>-- No Trunk --</option>
                                            {trunks.map(trunk =>
                                                <option key={trunk.trunkId} value={trunk.trunkId}>
                                                    {trunk.trunkName}
                                                </option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={addDid}>Add</button>
                        </p>
                    </div>
                ) : error ? (
                    <p>An error has occurred while loading company data</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}