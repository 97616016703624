import React, { useEffect, useState } from 'react';
import { WebSocketContext, UserAgentSessionRequestType, RTC_MESSAGES } from '../../Redux/WebSocket';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedAsset } from '../../Redux/actions';
import Sessions from './Sessions/Sessions';
import Modal from 'react-modal';
import reactModalStyle from '../../utils/reactModalStyle';
import NexusModal, { openDynamicGeneratedModals } from '../App/NexusModal';
import { Card, CloseButton } from 'react-bootstrap';
import moment from 'moment';

const TabSessions = (props) => {
  const dispatch = useDispatch();
  const AssetID = props.routeProps.match.params.id;

  const socket = React.useContext(WebSocketContext);
  const UserAgentSessions = useSelector((state) => state.WebSocketReducer.UserAgentSessions);
  const UserAgentScreenShots = useSelector((state) => state.WebSocketReducer.UserAgentScreenShots);
  const [connectionText, setConnectionText] = useState('Connection Closed');
  const [deleteModalsOpen, setDeleteModalsOpen] = useState([]);
  const [sessionModalsOpen, setSessionModalsOpen] = useState([]);

  useEffect(() => {
    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          AssetID: parseInt(AssetID),
          SubMessageType: RTC_MESSAGES.GET_AGENTS,
        },
      })
    );

    dispatch(updateSelectedAsset(AssetID));
  }, []);

  const deleteAgent = (agentName) => {
    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          AssetID: parseInt(AssetID),
          UserName: agentName,
          SubMessageType: RTC_MESSAGES.DELETE_AGENT,
        },
      })
    );
  }

  const rebootAgent = (agentName) => {
    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          AssetID: parseInt(AssetID),
          UserName: agentName,
          SubMessageType: RTC_MESSAGES.REBOOT_AGENT,
        },
      })
    );
  }

  return (
    <>
      <h1 style={{ marginBottom: '0' }}>Sessions</h1>
      <p style={{ fontSize: '1' }}>When accepted, connections should take a few seconds to complete.</p>
      <div className="bootstrap-wrapper">
        <div className="row mx-0">
          {(UserAgentSessions[AssetID] !== undefined && UserAgentSessions[AssetID] != null)
            ? UserAgentSessions[AssetID].map((a, i) => {
              return (
                <div className="col-md-4 col-6" key={i}>
                  <Card key={a.AgentName} className="m-2 sessionCard" style={{ opacity: a.Online ? '' : '0.6' }}>
                    <Card.Header>
                      <div className="row">
                        <div className="col-9 my-auto">
                          {a.Online ?
                            <span className="text-success">Online</span> :
                            <span className="text-danger">Offline</span>
                          }
                        </div>
                        <div className="col-3">
                          <CloseButton onClick={(e) => {
                            openDynamicGeneratedModals(true, i, deleteModalsOpen, setDeleteModalsOpen) }} />
                          <NexusModal stateValue={deleteModalsOpen?.[i]} 
                          stateFunctionClose={() => openDynamicGeneratedModals(false, i, deleteModalsOpen, setDeleteModalsOpen)}
                          title="Delete Agent" closeText="Delete"
                          closeAction={() => { 
                            deleteAgent(a.AgentName); 
                            openDynamicGeneratedModals(false, i, deleteModalsOpen, setDeleteModalsOpen)
                          }} 
                            width='50%' height='auto'>
                            <p style={{ textAlign: 'center', color: 'black' }}>
                              Are you sure you want to delete {a.AgentName}?
                            </p>
                          </NexusModal>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <Card.Title className="text-primary mb-0 p-3">{a.AgentName}</Card.Title>
                          <img src={
                            UserAgentScreenShots?.[AssetID]?.[a.AgentName] ?
                            UserAgentScreenShots[AssetID][a.AgentName] : 'https://via.placeholder.com/720x480/eee/eee/?text=none'}
                            onError={(e) => { e.target.src = 'https://via.placeholder.com/720x480/eee/eee/?text=none' }}
                            className="w-100" />
                      <div className="p-3">
                        <div onClick={ a.Online ? (e) => openDynamicGeneratedModals(true, i, sessionModalsOpen, setSessionModalsOpen) : null}
                            className={`ml-0 ${a.Online ? 'nexusButtonCommon' : 'nexusButtonCommonNoHover'}`}
                            disabled={a.Online ? false : true}>Connect</div>
                        <Modal
                          isOpen={sessionModalsOpen?.[i]}
                          onRequestClose={(e) => openDynamicGeneratedModals(false, i, sessionModalsOpen, setSessionModalsOpen)}
                          style={reactModalStyle('99%', '99%', 0)}
                        >
                          <div className="modal">
                            <div className="bootstrap-wrapper">
                              <div className="row no-gutters p-2">
                                <div className={`col-8 text-sucess ${(connectionText == 'Connected') ? `text-success` : `text-dark`}`}>
                                  {connectionText}</div>
                                <div className='col-4 justify-content-right'>
                                  <CloseButton className="close"
                                  onClick={() => openDynamicGeneratedModals(false, i, sessionModalsOpen, setSessionModalsOpen) } />
                                </div>
                              </div>
                            </div>
                            <Sessions agent={a.AgentName} sessionModalOpen={sessionModalsOpen?.[i]}
                            setConnectionText={setConnectionText} />
                          </div>
                        </Modal>
                        <div className={`ml-0 mr-0  ${a.Online ? 'nexusButtonCommon' : 'nexusButtonCommonNoHover'}`}
                        onClick={() => rebootAgent(a.AgentName)}
                        disabled={a.Online ? false : true}>Reboot</div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      Last Checked In:
                      {` ${moment(Date.parse(a.LastOnline)).format('MMMM Do YYYY, h:mma')}`}
                    </Card.Footer>
                  </Card>
                </div>
              );
            })
            : null}
        </div>
      </div>
    </>
  );
};

export default TabSessions;
