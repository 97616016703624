import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
    // set to true for development; switch to false before deployment
    canModifyPeople: true,
    canModifyOrgs: true,
    canModifyTenants: true,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setPeopleModifyProperty(state, action) {
            state.canModifyPeople = action.payload.canModifyPeople;
        },
        setOrgModifyProperty(state, action) {
            state.canModifyOrgs = action.payload;
        },
        setTenantModifyProperty(state, action) {
            state.canModifyTenants = action.payload;
        },
    },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
