import React, { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import {UpdateAzureConnectionString, GetAsset} from '../../api/AssetAPI'
import { Alert } from 'react-bootstrap';

const EditAzureConnectionString = (props) => {

    const [mode, setMode] = useState("inactive");
    const [showSavedConnectionString, setShowSavedConnectionString] = useState(false);
    const [showFailedConnectionString, setShowFailedonnectionString] = useState(false);
    const { id } = useParams()
    const [form, setForm] = useState({
        AssetID: parseInt(id),
        ConnectionString: ""
    })
    
    useEffect(()=>{
        GetAsset({content: parseInt(id)})
        .then(response=> setForm({
            ...form,
            ConnectionString: response.content.azureConnectionString
        }))
    },[])

    const toggleMode=()=>{
        if(mode=="inactive"){
            setMode("active");
        }
        else if(mode == "active"){
            setMode("inactive");
        }
    }

    const SaveConnectionString=()=>{
        UpdateAzureConnectionString(form)
        .then(response => {
            if(response.success) {
                setMode("inactive");
                setShowSavedConnectionString(true);
            } else {
                setShowFailedonnectionString(true);
            }
        });
    }

    const handleChange = (e)=>{
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const renderComponent =()=>{
        
        if(mode == "inactive"){
            return (
                <div type='text' className="nexusButtonCommon" name='ToggleButton' onClick={toggleMode}>Edit Azure ConnectionString</div>
            )
        }
        if(mode == "active"){
            return (
                <>
                    <input style={{ maxWidth: "100%", width: '1000px' }} type='text' name="ConnectionString" value={form.ConnectionString} onChange={handleChange}></input>
                    <div className="d-block pt-2">
                        <div className="nexusButtonCommon" onClick={SaveConnectionString}>Save</div>
                        <div type="text" className="nexusButtonCommon" onClick={toggleMode}>Cancel</div>
                    </div>
                </>
            )
        }
    }

  return (
    <div className="bootstrap-wrapper" style={{ marginBottom: "5px" }}>
        {showSavedConnectionString ? 
            <Alert onClose={() => setShowSavedConnectionString(false)} variant="success" dismissible>
                Successfully saved Azure connection string
            </Alert> : null
        }
        {showFailedConnectionString ? 
            <Alert onClose={() => setShowFailedonnectionString(false)} variant="danger" dismissible>
                Failed to save Azure connection string
            </Alert> : null
        }
        {renderComponent()}
    </div>
  )
}



export default EditAzureConnectionString
