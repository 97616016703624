import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import LoginForm from './loginform.js';
import LoadingShroud from './LoadingShroud.js';
import { GetTenants, GetCookieTenants } from '../../api/TenantAPI';

import Header from './Header';
import MissingTimeEntryList from '../Incidents/MissingTimeEntryList';
import Navigation from './Navigation';
import { CheckAuth, CheckVersion } from '../../api/NexusAPI';
import { GetMissingTime } from '../../api/IncidentAPI';
import { CheckUserNavigation } from '../../api/NavigationAPI';
import MainRouter from './MainRouter';
import LoginRouter from './LoginRouter';
import { WebSocketContext } from '../../Redux/WebSocket';
import { useSelector, useDispatch } from 'react-redux';
import process from 'process';
import dotenv from 'dotenv';

import '../../css/bootstrap-wrapper.scss';

import '../../css/style.css';
import '../../css/loader.css';
import '../../css/nextTable.css';
import '../../css/nexusGrid.css';
import '../../css/nexusForm.css';
import '../../css/reactModal.css';
import '../../css/tabList.css';
import '../../css/orgForm.css';
import '../../css/peopleForm.css';
import '../../css/incidentTable.css';
import '../../css/orgTelephony.css';
import '../../css/dashboard.css';
import '../../css/search.css';
import '../../css/AssetNetworTab.css';
import '../../css/admin.css';

import '@fortawesome/fontawesome-free/css/all.css';

const NextApp = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [missingDatesLoading, setMissingDatesLoading] = useState(true);
  const [missingDates, setMissingDates] = useState([]);
  const tenant = useSelector((state) => state.TenantReducer.Tenant);
  const location = useLocation();
  const history = useHistory();
  let ws = useContext(WebSocketContext);
  const [tenants, setTenants] = useState([]);
  const [version, setVersion] = useState();

  useEffect(() => {
    ws.whoAmI();
    ws = null;
    dotenv.config();
  }, []);

  useEffect(() => {
      checkAuth();
  }, [tenant]);

  useEffect(() => {
    CheckVersion().then((v) => {
        setVersion(v.content);
    })
  }, []);

  useEffect(() => {
    if (!authenticated) return;
    loadMissingTime();
  }, [authenticated]);

  useEffect(() => {
    CheckUserNavigation(location.pathname)
      .then((result) => {
        if (result != null) {
          if (!result.success) {
            history.push('/');
          }
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [location, history]);

  const loadMissingTime = () => {
    setMissingDatesLoading(true);
    GetMissingTime().then((response) => {
      setMissingDates(response.content);
      setMissingDatesLoading(false);
    });
    };
  const getCookies = () => {
    setLoading(true);
    var _tenants = [];
    var cookies = document.cookie.split(';').reduce(
        (cookies, cookie) => {
            const [name, val] = cookie.split('=').map(c => c.trim());
            cookies[name] = val;
            _tenants.push(cookies[name]);
            return cookies;
        }, {});
    var sierraCookie = false;
    for (var i = 0; i < _tenants.length; i++) {
        if (_tenants[i] === "Sierra") {
            sierraCookie = true;
        }
        if ((i === (_tenants.length - 1)) && (sierraCookie === false)) {
            document.cookie = "SierraExperts=Sierra;expires=31 Dec 9999 23:59:59 GMT";
        }
    }
    GetCookieTenants(_tenants)
        .then((response) => {
            setLoading(false);
            if (response.success) {
                setTenants(response.content);
            }
        })
        .catch(() => {
            setLoading(false);
        });
  }
  const checkAuth = () => {
    setLoading(true);
    getCookies();
    CheckAuth()
      .then((result) => {
         setAuthenticated(true);
         setLoading(false);
         if (location.pathname === "/" || location.pathname === `/${tenant}/ChangePswd`) {
             history.push(`/${tenant}`);
         }
      })
      .catch((error) => {
        localStorage.setItem('nexusToken', null);
        sessionStorage.setItem('nexusToken', null);
        localStorage.setItem("nexusID", null);
        sessionStorage.setItem("nexusID", null);
        localStorage.setItem("nexusOrg", null);
        sessionStorage.setItem("nexusOrg", null);
        setAuthenticated(false);
        setLoading(false);
      });
  };
  const logout = () => {
    if (localStorage.getItem('nexusToken') != null) {
        localStorage.removeItem('nexusToken');
        localStorage.removeItem("nexusID");
        localStorage.removeItem("nexusOrg");
    }
    if (sessionStorage.getItem('nexusToken') != null) {
        sessionStorage.removeItem('nexusToken');
        sessionStorage.removeItem("nexusID");
        sessionStorage.removeItem("nexusOrg");
    }
    setAuthenticated(false);
    if (location.pathname !== "/" || location.pathname !== `/${tenant}/`) {
        history.push(`/${tenant}`);
    }
  };

  return (
    <div id="NexusApp">
      <LoadingShroud Active={loading} />
      <Header authenticated={authenticated} logout={logout} />
      {loading ? null : authenticated ? (
        <div className="appMain">
          <div className="leftColumn">
            <Navigation />
            {missingDates.length > 0 && (
              <MissingTimeEntryList
                dates={missingDates}
                missingDatesLoading={missingDatesLoading}
              />
            )}
          </div>
          <div className="rightColumn">
            <MainRouter loadMissingTime={loadMissingTime} />
          </div>
        </div>
      ) : (
        <div className="appMain">
          <div className="rightColumn">
            <LoginRouter checkAuth={checkAuth} />
          </div>
        </div>
      )}
        <div className="footer">
              <span className="footerCopyRight">&copy; {(new Date().getFullYear())} Sierra Experts</span>
              <span className="footerVersion">v{version}</span>
        </div>
    </div>
  );
};

export default NextApp;
