import React, { useState, useEffect } from 'react';
import { UpdateService } from '../../api/ServiceAPI';
import Loader from '../App/loader';

const ServiceEdit = (props) => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [localService, setLocalService] = useState({
        serviceName: "",
        serviceDescription: "",
        msrp: 0,
        sku: "",
        active: true
    });

    useEffect(() => {
        setLocalService(props.service);
    }, [props.service]);

    const saveService = () => {
        setLoading(true);
        UpdateService({ content: { ...localService, msrp: parseInt(localService.msrp) } })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setMessage("Service Saved");
                        props.updateService(res.content);
                    } else {
                        setMessage(res.message);
                    }
                }
            })
            .catch(() => {
                setMessage("Error Saving Service");
            })
        setLoading(false);
    }

    const submit = (e) => {
        e.preventDefault();
        saveService();
    }

    const inputChanged = (e) => {
        let newService = { ...localService };
        newService[e.target.name] = e.target.value;
        setLocalService(newService);
    }

    const toggleActive = () => {
        let newService = { ...localService };
        newService.active = !newService.active;
        setLocalService(newService);
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Service Name:</td>
                                <td><input type="text" required name="serviceName"
                                id="serviceName" value={localService.serviceName} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td><input type="text" required name="serviceDescription"
                                id="serviceDescription" value={localService.serviceDescription} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>SKU:</td>
                                <td><input type="text" required name="sku" id="sku" value={localService.sku || ''} onChange={(e)=> inputChanged(e)}/></td>
                            </tr>
                            <tr>
                                <td>MSRP:</td>
                                <td><input type="number" required name="msrp" id="msrp" value={localService.msrp || 0} onChange={(e)=> inputChanged(e)}/></td>
                            </tr>
                            <tr>
                                <td>Active:</td>
                                <td><input type="checkbox" required name="active"
                                id="active" value={localService.active || false} checked={localService.active} onChange={() => toggleActive()} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="SaveService" id="SaveService" value="Save Service Info" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
        </div>
    )
}

export default ServiceEdit