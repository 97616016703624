import React, { useEffect, useState, useRef } from 'react';
import Loader from '../../App/loader';
import Alert from 'react-bootstrap/Alert';
import { Link, useLocation } from 'react-router-dom';
import { GetOrganizations, GetOrganization } from '../../../api/OrganizationAPI';
import { CreateAsset, GetAssetTypes } from '../../../api/AssetAPI';
import DateTimePicker from 'react-datetime-picker';
import XbertVersion from '../XBert/Settings/XbertVersion';
import { useSelector } from 'react-redux';

const CreateAssets = props => {

    const [organizations, setOrganizations] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [atExists, setAtExists] = useState(true);
    const [forOrg, setForOrg] = useState(true);
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [warrantyDate, setWarrantyDate] = useState(new Date());
    const [xbertSeenDate, setXbertSeenDate] = useState(new Date());
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [orgName, setOrgName] = useState();

    const location = useLocation();
    const orgid = location.state;
    const tenant = useSelector(state => state.TenantReducer.Tenant);

    useEffect(() => {
        GetOrganizations({ content: Number(0) })
            .then((res) => {
                setOrganizations(res.content);
            })
            .catch((e) => {
                // TODO: properly handle errors
                console.log(e);
            });
        GetAssetTypes().then((res) => {
            if (res.success === true) {
                setAssetTypes(res.content);
                setAtExists(true);
            } else {
                setAtExists(false);
            }
        })
        checkOrgId();
        if (orgid != 0) {
            GetOrganization({ content: orgid }).then((re) => {
                if (re.success) {
                    setOrgName(re.content[0].orgName)
                }
                else {
                }
            })
        }
    }, []);

    const organizationRef = useRef();
    const descriptionRef = useRef();
    const assetTypeRef = useRef();
    const mfgRef = useRef();
    const modelRef = useRef();
    const serialRef = useRef();
    const purchaseDateRef = useRef();
    const warrantyExpirationRef = useRef();
    const nameRef = useRef();
    const xBertLastSeenRef = useRef();
    const currentVersionIdRef = useRef();
    const approvedVersionIdRef = useRef();
    const xBertAzureConStringRef = useRef();
    const databaseConStringRef = useRef();

    const Organizations = organizations.map((org) => (
        <option key={org.orgID} value={org.orgID}>
            {org.orgName}
        </option>
    ));

    const AssetTypes = assetTypes.map((at) => (
        <option key={at.assetTypeID} value={at.assetTypeID}>
            {at.name}
        </option>
    ));

    const checkOrgId = () => {
        if (orgid == 0) {
            setForOrg(false);
        }
        else {
            setForOrg(true);
        }
    }

    const resetClickedHandler = () => {
        if (orgid == 0) {
            organizationRef.current.value = 0;
        }
        console.log(descriptionRef);
        descriptionRef.current.value = null;
        assetTypeRef.current.value = null;
        console.log(mfgRef);
        mfgRef.current.value = null;
        modelRef.current.value = null;
        serialRef.current.value = null;
        nameRef.current.value = null;
        currentVersionIdRef.current.value = null;
        approvedVersionIdRef.current.value = null;
        xBertAzureConStringRef.current.value = null;
        databaseConStringRef.current.value = null;
        setPurchaseDate(new Date());
        setWarrantyDate(new Date());
    }

    const submitHandler = (event) => {
        event.preventDefault();
        var enOrgId = 0;
        if (orgid == 0) {
            enOrgId = organizationRef.current.value;
        }
        else {
            enOrgId = orgid;
        }

        const enteredOrgId = enOrgId;
        const enteredDescription = descriptionRef.current.value;
        const enteredAssetType = Number(assetTypeRef.current.value);
        const enteredMfg = mfgRef.current.value;
        const enteredModel = modelRef.current.value;
        const enteredSerial = serialRef.current.value;
        const enteredPurchaseDate = purchaseDate.toJSON();
        const enteredWarrantyExp = warrantyDate.toJSON();
        const enteredName = nameRef.current.value;
        const enteredXBertLastSeen = xbertSeenDate.toJSON();
        const enteredCurrentVersion = currentVersionIdRef.current.value;
        const enteredApprovedVersionId = approvedVersionIdRef.current.value;
        const enteredXBertAzureConString = xBertAzureConStringRef.current.value;
        const enteredDatabaseConString = databaseConStringRef.current.value

        const payload = {
            orgID: enteredOrgId,
            description: enteredDescription,
            Name: enteredName,
            mfg: enteredMfg,
            serial: enteredSerial,
            model: enteredModel,
            currentVersionID: enteredCurrentVersion,
            XBertAzureConString: enteredXBertAzureConString,
            databaseConString: enteredDatabaseConString,
            purchaseDate: enteredPurchaseDate,
            warrantyExDate: enteredWarrantyExp,
            XBertLastSeen: enteredXBertLastSeen,
            approvdID: enteredApprovedVersionId,
            assetTypeID: enteredAssetType,
        };

        CreateAsset(payload).then((res) => {
            if (res.success) {
                setShowSuccess(true);
            }
            else {
                setShowFailed(true);
            }
        }).catch(() => {
            setShowFailed(true);
        })
    }

    const formButtons = (
        <>
            <hr />
            <div className="form-group">
                <button
                    className="btn btn-danger btn-block"
                    type="button"
                    onClick={resetClickedHandler}
                >
                    Reset Form
                </button>
                <button type="submit" className="btn btn-primary btn-block">
                    Submit
                </button>
            </div>
        </>
    )

    const userInputs = (
        <>
            {forOrg ? (
                <div>
                    <h2>{orgName}</h2>
                </div>
            ) : (
                <div className="form-group">
                    <label>Select an Organization</label>
                    <div>
                        <select
                            name="organization"
                            id="organization"
                            ref={organizationRef}

                        >
                            <option value="0">-- No Organization --</option>
                            {Organizations}
                        </select>
                    </div>
                </div>
            )}
            <div className="form-group">
                <label>Description</label>
                <div>
                    <input
                        type="text"
                        name="description"
                        id="description"
                        className="form-control"
                        ref={descriptionRef}
                        placeholder="Description"
                    />
                </div>
            </div>
            {atExists === false ? (
                <div>
                    <label>Asset Types Do Not Exist</label>
                </div>
            ) : (
                <div className="form-group">
                    <label>Select an Asset Type</label>
                    <div>
                        <select
                            name="assetTypeID"
                            id="assetTypeID"
                            ref={assetTypeRef}

                        >
                            <option value={-1}>-- No Asset Type --</option>
                            {AssetTypes}
                        </select>
                    </div>
                </div>
            )}
            <div className="form-group">
                <label>MFG</label>
                <div>
                    <input
                        type="text"
                        name="mfg"
                        id="mfg"
                        className="form-control"
                        ref={mfgRef}
                        placeholder="MFG"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Model</label>
                <div>
                    <input
                        type="text"
                        name="model"
                        id="model"
                        className="form-control"
                        ref={modelRef}
                        placeholder="Model"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Serial</label>
                <div>
                    <input
                        type="text"
                        name="serial"
                        id="serial"
                        className="form-control"
                        ref={serialRef}
                        placeholder="Serial"
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="row" style={{ marginBottom: '40px' }}>
                    <label>Purchase Date</label>
                    <div>
                        <DateTimePicker value={purchaseDate} onChange={date => setPurchaseDate(date)} clearIcon={null} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row" style={{ marginBottom: '40px' }}>
                    <label>Warranty Expiration</label>
                    <div>
                        <DateTimePicker value={warrantyDate} onChange={date => setWarrantyDate(date)} clearIcon={null} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row" style={{ marginBottom: '40px' }}>
                    <label>Xbert Last Seen</label>
                    <div>
                        <DateTimePicker value={xbertSeenDate} onChange={date => setXbertSeenDate(date)} clearIcon={null} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label>Name</label>
                <div>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        ref={nameRef}
                        placeholder="Name"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Current Version ID</label>
                <div>
                    <input
                        type="text"
                        name="currentVersionId"
                        id="currentVersionId"
                        className="form-control"
                        ref={currentVersionIdRef}
                        placeholder="Ex.1"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Approved Version ID</label>
                <div>
                    <input
                        type="text"
                        name="approvedVersionId"
                        id="approvedVersionId"
                        className="form-control"
                        ref={approvedVersionIdRef}
                        placeholder="Ex.1"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>xBert Azure Connection String</label>
                <div>
                    <input
                        type="text"
                        name="xBertAzureConString"
                        id="xBertAzureConString"
                        className="form-control"
                        ref={xBertAzureConStringRef}
                        placeholder="XBert Azure Connection String"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Database Connection String</label>
                <div>
                    <input
                        type="text"
                        name="databaseConString"
                        id="databaseConString"
                        className="form-control"
                        ref={databaseConStringRef}
                        placeholder="Database Connection String"
                    />
                </div>
            </div>
            {formButtons}
        </>
    );

    return (
        <>
            <Link className="whiteLink" to={`/${tenant}/Assets/`}>
                <i className="fas fa-chevron-left"></i> Asset List
            </Link>
            <h2>New Asset</h2>
            <div className="bootstrap-wrapper">
                <Alert show={showFailed} variant="danger" dismissible onClose={() => setShowFailed(false)}>
                    You are missing input fields. Please make sure there is an org assigned, a 'Current Version ID' and 'Approved Version ID'
                </Alert>
                <Alert show={showSuccess} variant="success" dismissible onClose={() => setShowSuccess(false)}>
                    Asset was added!
                </Alert>
            </div>
            <div className='nexusForm'>
                <form onSubmit={(e) => submitHandler(e)}>
                    {userInputs}
                </form>
            </div>
        </>
    );
}

export default CreateAssets;