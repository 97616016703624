import APIRequest from './APIRequest.js';
import APIDownload from './APIDownload.js';

export const GetCredentialTypeList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialTypeList`,
        method: "POST",
        body
    });
}

export const GetCredentialType = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialType`,
        method: "POST",
        body
    });
}

export const CreateCredentialType = (body) => {
    return APIRequest({
        url: `Credential/CreateCredentialType`,
        method: "POST",
        body: {content: body}
    });
}

export const UpdateCredentialType = (body) => {
    return APIRequest({
        url: `Credential/UpdateCredentialType`,
        method: "POST",
        body: {content: body}
    });
}

export const DeleteCredentialType = (body) => {
    return APIRequest({
        url: `Credential/DeleteCredentialType`,
        method: "POST",
        body
    });
}

export const GetCredentialList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialList`,
        method: "POST",
        body
    });
}

export const GetCredential = (body) => {
    return APIRequest({
        url: `Credential/GetCredential`,
        method: "POST",
        body
    });
}

export const GetFullCredential = (body) => {
    return APIRequest({
        url: `Credential/GetFullCredential`,
        method: "POST",
        body
    });
}

export const CreateCredential = (body) => {
    return APIRequest({
        url: `Credential/CreateCredential`,
        method: "POST",
        body: body
    });
}

export const UpdateCredential = (body) => {
    return APIRequest({
        url: `Credential/UpdateCredential`,
        method: "POST",
        body: body
    });
}

export const DeleteCredential = (body) => {
    return APIRequest({
        url: `Credential/DeleteCredential`,
        method: "POST",
        body
    });
}

export const ViewCredentialPassword = (body) => {
    return APIRequest({
        url: `Credential/ViewCredentialPassword`,
        method: "POST",
        body
    });
}

export const GetCredentialFileList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialFileList`,
        method: "POST",
        body
    });
}

export const GetCredentialFile = (id, filename) => {
    return APIDownload({
        url: `Credential/GetCredentialFile?CredentialFileID=${id}`,
        method: "GET",
        body: {id: id},
        filename: filename
    });
}

export const UploadCredentialFile = (body) => {
    return APIRequest({
        url: `Credential/UploadCredentialFile`,
        method: "POST",
        body
    });
}

export const DeleteCredentialFile = (body) => {
    return APIRequest({
        url: `Credential/DeleteCredentialFile`,
        method: "POST",
        body
    });
}

export const GetCredentialOptions = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialOptions`,
        method: "GET"
    });
}

export const GetCredentialTypeHistoryList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialTypeHistoryList`,
        method: "POST",
        body
    });
}

export const GetCredentialHistoryList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialHistoryList`,
        method: "POST",
        body
    });
}

export const GetCredentialFileHistoryList = (body) => {
    return APIRequest({
        url: `Credential/GetCredentialFileHistoryList`,
        method: "POST",
        body
    });
}