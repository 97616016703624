import React, { useState, useEffect } from 'react';
import { GetCredentialTypeHistoryList } from '../../api/CredentialAPI';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';

export default props => {
    const [loading, setLoading] = useState(true);
    const [credentialTypeHistories, setCredentialTypeHistories] = useState([]);

    useEffect(() => {
        loadCredentialTypeHistories();
    }, []);

    const loadCredentialTypeHistories = () => {
        setLoading(true);
        GetCredentialTypeHistoryList({content: props.credentialTypeID})
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setCredentialTypeHistories(res.content);
                    }
                }
            });
        setLoading(false);
    }

    const headers = [
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "description" },
        { Header: "Hook", accessor: "hook" },
        { Header: "Active", accessor: "active" },
        { Header: "Change", accessor: "changeType" },
        { Header: "By", accessor: "changeBy" },
        { Header: "Date", accessor: "changeDate", dataFormat: (cell, row) => { return <div>{row.ChangeDate ? new Date(row.ChangeDate).toLocaleString() : ""}</div> } }
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h2>History</h2>
            <NexusGrid
                data={credentialTypeHistories}
                defaultSortBy={"Change"}
                defaultSortOrder={"DESC"}
                columns={headers}
            />
        </div>
    )
}