import APIRequest from './APIRequest';

export const Authenticate = (username, password, fromChangePswd, id, orgID, currentOrgID, fromHeader) => {
    return APIRequest({
        url: `Authentication/Authenticate`,
        method: "post",
        body: { content: {
            username: username,
            password: password,
            fromChangePswd: fromChangePswd,
            id: id,
            orgID: orgID,
            currentOrgID: currentOrgID,
            fromHeader: fromHeader,
        }}
    });
}

export const CheckAuth = () => {
    return APIRequest({
        url: `Authentication/CheckAuth`,
        method: "get",
    });
}

export const CheckVersion = () => {
    return APIRequest({
        url: `Authentication/GetVersion`,
        method: "get",
    });
}

export const DuoAuthenticate = () => {
    return APIRequest({
        url: `Authentication/DuoAuthenticate`,
        method: "post",
        cors: false,
    });
}

export const DuoCallback = (state, code) => {
    return APIRequest({
        url: `Authentication/DuoCallback`,
        method: "get",
        body: {
            state: state,
            code: code,
        }
    });
}

export const RequestResetPassword = (email) => {
    return APIRequest({
        url: 'Authentication/RequestResetPassword',
        method: 'post',
        body: { content: email }
    })
}

export const ValidatePasswordResetLink = (user) => {
    return APIRequest({
        url: 'Authentication/ValidatePasswordResetLink',
        method: 'post',
        body: { content: user }
    })
}

export const ResetPassword = (user, password) => {
    return APIRequest({
        url: 'Authentication/ResetPassword',
        method: 'post',
        body: {
            User: user,
            Password: password
        }
    })
}
