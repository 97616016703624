import React, { useState, useEffect } from 'react';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';
import NexusModal from '../App/NexusModal';
import { GetOrganizationsByService, 
    SetOrganizationToService } from '../../api/OrganizationAPI';
import { useSelector, useDispatch } from 'react-redux';


export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [orgs, setOrgs] = useState([]);
    const [orgsToAdd, setOrgsToAdd] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [currentOrgAdd, setCurrentOrgAdd] = useState(0);
    const [orgAdded, setOrgAdded] = useState(0);

    useEffect(() => {
        if(props.service.serviceId)
        {
            setLoading(true);
            GetOrganizationsByService(props.service.serviceId, true).then(response => {
                setLoading(false);
                if (response.success) {
                    setOrgs(response.content);
                }
            }).catch(() => {
                setLoading(false);
            });
        }
    }, [props.service.serviceId, orgAdded]);

    const openAddModal = () => {
        setDisplayModal(true);
        GetOrganizationsByService(props.service.serviceId, false).then(response => {
            setLoading(false);
            if (response.success) {
                setOrgsToAdd(response.content);
                setCurrentOrgAdd(response.content[0].orgID);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    const handleOrgChange = (e) => {
        setCurrentOrgAdd(e.target.value);
    }

    const addOrgToService = () => {
        SetOrganizationToService(props.service.serviceId, currentOrgAdd).then(response => {
            setLoading(false);
            if (response.success) {
                setDisplayModal(false);
                setOrgAdded(currentOrgAdd);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    const headers = [
        { Header: "OrgID", accessor: "orgID" },
        { Header: "Org Name", accessor: "orgName" },
        { Header: "Org Code", accessor: "orgCode" },
        { Header: "Phone", accessor: "mainNumber" }
    ];

    return (
        <div>
            <h2>Organizations</h2>
            <button onClick={openAddModal} className="nexusButtonCommon"><i className="fas fa-plus"></i>Add</button>
            {loading ? (
                <div className="NexusLoading">
                    <Loader />
                </div>
            ) : (
                <>
                    <NexusModal stateValue={displayModal} stateFunctionClose={() => setDisplayModal(false)} title="Select an Organization" closeText="Add Organization"
                          closeAction={() => {addOrgToService(); setDisplayModal(false)}} width='50%' height='auto'>
                        <select onChange={handleOrgChange} style={{ width: '100%', marginBottom: '5px' }}>
                            {orgsToAdd.map((org) => <option key={org.orgID} value={org.orgID}>{org.orgName}</option>)}
                        </select>
                    </NexusModal>
                    <NexusGrid
                        linkTo={`/services/${props.service.serviceId}/organizations/:key`}
                        tableKey={"orgID"}
                        hiddenColumns={['orgID']}
                        data={orgs}
                        defaultSortBy={"orgID"}
                        columns={headers}
                        filterHeaders={true}
                    />
                </>
            )}
        </div>
    )
}