import APIRequest from './APIRequest';

export const GetPeople = (payload) => {
    return APIRequest({
        url: `People/GetPeople`,
        method: 'post',
        body: payload,
    });
};

export const GetPerson = (payload) => {
    return APIRequest({
        url: `People/GetPerson`,
        method: 'post',
        body: payload,
    });
};

export const CreatePerson = (payload) => {
    return APIRequest({
        url: `People/CreatePerson`,
        method: 'post',
        body: {content: payload},
    });
};

export const UpdatePerson = (payload) => {
    return APIRequest({
        url: `People/UpdatePerson`,
        method: 'post',
        body: {content: payload},
    });
};

export const DisablePerson = (payload) => {
    return APIRequest({
        url: `People/DisablePerson`,
        method: 'post',
        body: payload,
    });
};

export const GetPersonLogin = (payload) => {
    return APIRequest({
        url: `People/GetPersonLogin`,
        method: 'post',
        body: payload,
    });
};

export const CheckPersonEmail = (payload) => {
    return APIRequest({
        url: `People/CheckPersonEmail`,
        method: 'post',
        body: payload,
    });
};

export const GetPersonOrganizations = (payload) => {
    return APIRequest({
        url: `People/GetOrganizations`,
        method: 'post',
        body: { content: payload },
    });
};

export const AddOrganizationToPerson = (body) => {
    return APIRequest({
        url: `People/AddOrganizationToPerson`,
        method: 'post',
        body
    });
};

export const GetPeopleWithoutOrg = (body) => {
    return APIRequest({
        url: `People/GetPeopleWithoutOrg`,
        method: 'post',
        body
    });
};

export const AddPersonToOrganization = (body) => {
    return APIRequest({
        url: `People/AddPersonToOrganization`,
        method: 'post',
        body
    });
};

export const GetOrgPeople = (payload) => {
    return APIRequest({
        url: `People/GetOrgPeople`,
        method: 'post',
        body: payload,
    });
};

export const GetPeopleNotInRole = (roleID, orgID) => {
    return APIRequest({
        url: `People/GetPeopleNotInRole`,
        method: 'post',
        body: {
            RoleID: roleID,
            OrganizationID: orgID
        },
    });
};

export const GetPersonPTO = (payload) => {
    return APIRequest({
        url: `People/GetPersonPTO`,
        method: 'post',
        body: payload,
    });
};

export const AddEditOrgPTO = (payload) => {
    return APIRequest({
        url: `People/AddEditOrgPTO`,
        method: 'post',
        body: payload,
    });
};

export const GetPTORequests = (payload) => {
    return APIRequest({
        url: `People/GetPTORequests`,
        method: 'post',
        body: payload,
    });
};

export const GetPTORequest = (payload) => {
    return APIRequest({
        url: `People/GetPTORequest`,
        method: 'post',
        body: payload,
    });
};

export const AddPTORequest = (payload) => {
    return APIRequest({
        url: `People/AddPTORequest`,
        method: 'post',
        body: payload,
    });
};

export const EditPTORequest = (payload) => {
    return APIRequest({
        url: `People/EditPTORequest`,
        method: 'post',
        body: payload,
    });
};

export const DeletePTORequest = (payload) => {
    return APIRequest({
        url: `People/DeletePTORequest`,
        method: 'post',
        body: payload,
    });
};
