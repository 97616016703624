import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RequestResetPassword } from '../../api/NexusAPI';

const ForgotPassword = (props) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const email = useRef();

  const sendPasswordReset = (e) => {
    e.preventDefault();
    if (email.current.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      RequestResetPassword(email.current.value);
      setSent(true);
    } else {
      setError('Please enter a valid email address.');
    }
  };

  return (
    <>
      <h2>Forgot Password</h2>
      {sent ? (
        <div className='nexusForm'>
          <h3>Password reset request successfully sent!</h3>
          <p>
            If your email address exists in our system, you will soon receive an email to complete your password reset.
          </p>
        </div>
      ) : (
        <div className="nexusForm bootstrap-wrapper">
          <Container>
            <Row>
              <Col>
                <label htmlFor="email" className="form-label">
                  Email Address:
                </label>
                <input
                  type="text"
                  id="email"
                  ref={email}
                  className="form-control"
                />
              </Col>
            </Row>
            {error ? (
              <Row className="justify-content-center">
                <Col className="col-auto Error">
                  <p>{error}</p>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="justify-content-center">
              <Col className="col-auto">
                <button
                  className="btn btn-primary"
                  style={{ marginTop: 10 + 'px' }}
                  onClick={sendPasswordReset}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
