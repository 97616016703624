import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationSoftSwitch, EditOrganizationSoftSwitch } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [canTestConnection, setCanTestConnection] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [isChangingSQLPassword, setIsChangingSQLPassword] = useState(false);
    const [newSQLPassword, setNewSQLPassword] = useState("");
    const [isChangingSSHPassword, setIsChangingSSHPassword] = useState(false);
    const [newSSHPassword, setNewSSHPassword] = useState("");
    const [isChangingAPIPassword, setIsChangingAPIPassword] = useState(false);
    const [newAPIPassword, setNewAPIPassword] = useState("");
    const [hasAPI, setHasAPI] = useState(false);
    const [softswitches, setSoftswitches] = useState({});

    useEffect(() => {
        const softswitchID = props.routeProps.match.params.softswitchid;
        GetOrganizationSoftSwitch({ content: { softswitchID: Number(softswitchID) }})
            .then(res => {
                if (res != null) {
                    if (res.softswitches.length === 1){
                        setLoaded(true);
                        setSoftswitches(res.content[0]);
                        setHasAPI(res.content[0].api.softswitchapiid  >  0);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const addAPI = () => {
        setHasAPI(true);
    }

    const softswitchChanged = (e) => {
        var softswitch = softswitches;
        if (e.target.name === "active")
            softswitch[e.target.name] = !softswitch[e.target.name];
        else
            softswitch[e.target.name] = e.target.value;
        setSoftswitches(softswitch);
    }

    const apiChanged = (e) => {
        var softswitch = softswitches;
        var api = softswitch.api;
        if (e.target.name === "active")
            api[e.target.name] = !api[e.target.name];
        else
            api[e.target.name] = e.target.value;
        softswitch.api = api;
        setSoftswitches(softswitch);
    }

    const saveSoftSwitch = () => {
        setLoaded(false);
        setError(false);
        const softswitch = {
            softswitchid: softswitches.softswitchid,
            orgid: softswitches.orgid,
            name: softswitches.name,
            description: softswitches.description,
            host: softswitches.host,
            sqlhost: softswitches.sqlhost,
            sqluser: softswitches.sqluser,
            sqlport: Number(softswitches.sqlport),
            sqlpassword: newSQLPassword.length > 0 ? newSQLPassword : null,
            sshuser: softswitches.sshuser,
            sshport: Number(softswitches.sshport),
            sshpassword: newSSHPassword.length > 0 ? newSSHPassword : null,
            active: softswitches.active,
            api: {
                softswitchapiid: softswitches.api.softswitchapiid,
                softswitchid: softswitches.softswitchid,
                host: softswitches.api.host,
                port: Number(softswitches.api.port),
                user: softswitches.api.user,
                password: newAPIPassword.length > 0 ? newAPIPassword : null,
                active: softswitches.api.active
            }
        }
        EditOrganizationSoftSwitch({ content: {
            softswitch: softswitch
        }})
            .then(res => {
                if (res != null) {
                    if (res.content){
                        setLoaded(true);
                        setSoftswitches(res.content);
                    }
                    else{
                        setError(true);
                        setLoaded(false);
                    }
                } else {
                    setError(true);
                    setLoaded(false);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    const editSQLPassword = (e) => {
        setIsChangingSQLPassword(true);
    }

    const confirmSQLPassword = (e) => {
        var softswitch = softswitches;
        softswitch.sqlpassword = newSQLPassword.length > 0 ? "Yes" : "No"
        setSoftswitches(softswitch);
        setIsChangingSQLPassword(false);
    }

    const editSSHPassword = (e) => {
        setIsChangingSSHPassword(true);
    }
    const confirmSSHPassword = (e) => {
        var softswitch = softswitches;
        softswitch.sshpassword = newSSHPassword.length > 0 ? "Yes" : "No";
        setSoftswitches(softswitch);
        setIsChangingSSHPassword(false);
    }

    const editAPIPassword = (e) => {
        setIsChangingAPIPassword(true);
    }
    const confirmAPIPassword = (e) => {
        var softswitch = softswitches;
        var api = softswitch.api;
        api.password = newAPIPassword.length > 0 ? "Yes" : "No";
        setSoftswitches(softswitch);
        setIsChangingAPIPassword(false);
    }

    const passwordChanged = (e) => {
        if (e.target.name === "SSH")
            setNewSSHPassword(e.target.value);
        if (e.target.name === "SQL")
            setNewSQLPassword(e.target.value);
        if (e.target.name === "API")
            setNewAPIPassword(e.target.value);
    }

    const cancelEditPassword = () => {
        setIsChangingAPIPassword(false);
        setIsChangingSQLPassword(false);
        setIsChangingSSHPassword(false);
        setNewAPIPassword("");
        setNewSQLPassword("");
        setNewSSHPassword("");
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{softswitches.name}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Description:</th>
                                    <td><input type="text" name="description" onChange={softswitchChanged} value={softswitches.description} /></td>
                                </tr>
                                <tr>
                                    <th>Host:</th>
                                    <td><input type="text" name="host" onChange={softswitchChanged} value={softswitches.host} /></td>
                                </tr>
                                <tr>
                                    <th>SQL Host:</th>
                                    <td><input type="text" name="sqlhost" onChange={softswitchChanged} value={softswitches.sqlhost} /></td>
                                </tr>
                                <tr>
                                    <th>SQL User:</th>
                                    <td><input type="text" name="sqluser" onChange={softswitchChanged} value={softswitches.sqluser} /></td>
                                </tr>
                                <tr>
                                    <th>SQL Password:</th>
                                    <td>
                                        {isChangingSQLPassword ?
                                            <div>
                                                <input type="text" name="SQL" value={newSQLPassword} onChange={passwordChanged} />
                                                <span className="fas fa-times-circle nexusLinkCommon" onClick={cancelEditPassword}>Cancel</span>
                                                <span className="fas fa-check-circle nexusLinkCommon" onClick={confirmSQLPassword}>Confirm</span>
                                            </div>
                                            :
                                            <div>
                                                <input type="text" name="" value={softswitches.sqlpassword === "Yes" ? "hidden" : "unset"} disabled />
                                                <span href="/" className="fas fa-edit nexusLinkCommon" onClick={editSQLPassword}>Edit</span>
                                            </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>SQL Port:</th>
                                    <td><input type="text" name="sqlport" onChange={softswitchChanged} value={softswitches.sqlport} /></td>
                                </tr>
                                <tr>
                                    <th>SSH User:</th>
                                    <td><input type="text" name="sshuser" onChange={softswitchChanged} value={softswitches.sshuser} /></td>
                                </tr>
                                <tr>
                                    <th>SSH Password:</th>
                                    <td>
                                        {isChangingSSHPassword ?
                                            <div>
                                                <input type="text" name="SSH" value={newSSHPassword} onChange={passwordChanged} />
                                                <span href="/" className="fas fa-times-circle nexusLinkCommon" onClick={cancelEditPassword}>Cancel</span>
                                                <span href="/" className="fas fa-check-circle nexusLinkCommon" onClick={confirmSSHPassword}>Confirm</span>
                                            </div>
                                            :
                                            <div>
                                                <input type="text" name="" value={softswitches.sshpassword === "Yes" ? "hidden" : "unset"} disabled />
                                                <span href="/" className="fas fa-edit nexusLinkCommon" onClick={editSSHPassword}>Edit</span>
                                            </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>SSH Port:</th>
                                    <td><input type="text" name="sshport" onChange={softswitchChanged} value={softswitches.sshport} /></td>
                                </tr>
                                <tr>
                                    <th>Active:</th>
                                    <td><input type="checkbox" name="active" onChange={softswitchChanged} checked={softswitches.active}/></td>
                                </tr>
                            </tbody>
                        </table>

                        {hasAPI || (softswitches.hasAPI || softswitches.api.softswitchapiid) ?
                            <div>
                                <h3>API</h3>
                                <table cellPadding="0" cellSpacing="0" className="infoTable">
                                    <tbody>
                                        <tr>
                                            <th>Host:</th>
                                            <td><input type="text" name="host" onChange={apiChanged} value={softswitches.api.host} /></td>
                                        </tr>
                                        <tr>
                                            <th>Port:</th>
                                            <td><input type="text" name="port" onChange={apiChanged} value={softswitches.api.port} /></td>
                                        </tr>
                                        <tr>
                                            <th>User:</th>
                                            <td><input type="text" name="user" onChange={apiChanged} value={softswitches.api.user} /></td>
                                        </tr>
                                        <tr>
                                            <th>Password Configured:</th>
                                            <td>
                                                {isChangingAPIPassword ?
                                                    <div>
                                                        <input type="text" name="API" value={newAPIPassword} onChange={passwordChanged} />
                                                        <span href="/" className="fas fa-times-circle nexusLinkCommon" onClick={cancelEditPassword}>Cancel</span>
                                                        <span href="/" className="fas fa-check-circle nexusLinkCommon" onClick={confirmAPIPassword}>Confirm</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <input type="text" name="" value={softswitches.api.password === "Yes" ? "hidden" : "unset"} disabled />
                                                        <span href="/" className="fas fa-edit nexusLinkCommon" onClick={editAPIPassword}>Edit</span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Active:</th>
                                            <td><input type="checkbox" name="active" onChange={apiChanged} checked={softswitches.api.active} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : <div>
                                <h4>No API has been configured</h4>
                                <button className="nexusButtonCommon" onClick={ () => addAPI }> Add API </button>
                              </div>
                        }

                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/softswitch/" + softswitches.softswitchid} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={saveSoftSwitch}> Save </button>
                        </p>

                    </div>
                ) : error ? (
                    <p>An error occurred while loading Soft Switch data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}