import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbsLink = (props) => {
    return (
        <Link className="whiteLink" to={props.to}>
            {props.label ? props.label : ''}
        </Link>
    );
};

export default BreadcrumbsLink;
