import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { CreateCredentialType } from '../../api/CredentialAPI';
import Loader from '../App/loader';
import { useSelector, useDispatch } from 'react-redux';

import { GetHookList } from '../../api/HookAPI';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(0);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [hook, setHook] = useState("");
    const [hooks, setHooks] = useState([]);

    useEffect(() => {
        loadHooks();
    }, []);

    const loadHooks = () => {
        setLoading(true);
        GetHookList()
            .then(res => {
                if (res.success) {
                    let hookOptions = [];
                    hookOptions.push("");
                    res.content.map(h => {
                        hookOptions.push(h.name);
                    });
                    setHooks(hookOptions);
                }
            });
        setLoading(false);
    }

    const createCredentialType = () => {
        setLoading(true);
        CreateCredentialType({
            name: name,
            description: description,
            hook: hook
        })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setMessage("Credential Type Saved");
                        setRedirect(res.credentialType.credentialTypeID);
                    } else {
                        setMessage(res.message);
                    }
                }
            })
            .catch(() => {
                setMessage("Error Saving Service");
            });
        setLoading(false);
    }

    const submit = (e) => {
        e.preventDefault();
        if (name === "") {
            setMessage("Cannot save: name field empty.");
        } else if (description === "") {
            setMessage("Cannot save: description field empty.");
        } else {
            createCredentialType();
        }
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            {redirect !== 0 && <Redirect to={`/${tenant}/CredentialTypes/${redirect}`} />}
            <BreadcrumbsItem to={`/${tenant}/CredentialTypes/`} label='Credential Type List' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td><input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td><input type="text" name="description" id="description" value={description} onChange={(e) => setDescription(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Hook:</td>
                                <td>
                                    <select name="hookSelect" id="hookSelect" value={hook} onChange={(e) => setHook(e.target.value)}>
                                        {hooks.map((hook) =>
                                            <option key={hook} value={hook}>{hook}</option>
                                        )}
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="SaveCredentialType" id="SaveCredentialType" value="Create Credential Type" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
        </div>
    )
}