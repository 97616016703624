import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GetDomainTypeOptions, GetDomain, UpdateDomain } from '../../../../api/DNSServiceAPI';
import Loader from '../../../App/loader';
import { useSelector } from 'react-redux';

import RecordList from './dnsRecordList.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [domain, setDomain] = useState({
        domainId: 0,
        domainName: "",
        master: "",
        type: "",
    });
    /*
    const [domainTypes, setDomainTypes] = useState([]);
    const [conditionalDomainType, setConditionalDomainType] = useState("");

    useEffect(() => {
        loadDomainTypeOptions();
    }, []);
    */

    useEffect(() => {
        loadDomain(parseInt(props.routeProps.match.params.domainId, 10));
    }, [props.routeProps.match.params.domainId]);

    /*
    const loadDomainTypeOptions = () => {
        setLoading(true);
        GetDomainTypeOptions()
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setDomainTypes(res.options);
                        setConditionalDomainType(res.conditionalDomainType);
                    }
                }
            })
            .catch(() => {

            })
        setLoading(false)
    }
    */

    const loadDomain = (id) => {
        if (id !== 0 && !isNaN(id)) {
            setLoading(true);
            GetDomain({ content: id })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setDomain(res.content);
                        }
                    }
                })
                .catch(res => {

                })
            setLoading(false)
        }
    }

    const saveDomain = () => {
        setLoading(true);
        UpdateDomain({
            domain: { ...domain },
            orgId: parseInt(props.org.orgID, 10)
        })
            .then(res => {
                if (res !== null) {
                    if (res.success) {
                        setMessage("Domain Saved");
                        setDomain(res.domain);
                    } else {
                        setMessage(res.message);
                    }
                }
            })
            .catch(() => {

            })
        setLoading(false)
    }

    const submit = (e) => {
        e.preventDefault();
        saveDomain();
    }

    const inputChanged = (e) => {
        let newDomain = { ...domain };
        newDomain[e.target.name] = e.target.value;
        setDomain(newDomain);
    }

    /*
    const setType = (type) => {
        let newDomain = { ...domain };
        newDomain.type = type;
        setDomain(newDomain);
    }
    */

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <BreadcrumbsItem to={`/${tenant}/organizations/${props.routeProps.match.params.orgId}/dns`} label='Domains' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Domain Name:</td>
                                <td><input type="text" name="domainName" id="domainName" value={domain.domainName} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                        {/* 
                            <tr>
                                <td>Type:</td>
                                <td><select name="type" id="type" value={domain.type} onChange={(e) => setType(e.target.value)}>
                                    {<option value="" />}
                                    {domainTypes && domainTypes.map((type) =>
                                        <option key={type} value={type}>{type}</option>
                                    )}
                                </select></td>
                            </tr>
                            {domain.type === conditionalDomainType &&
                                <tr>
                                    <td>Master:</td>
                                    <td><input type="text" name="master" id="master" value={domain.master} onChange={(e) => inputChanged(e)} /></td>
                                </tr>
                            }
                        */}
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="SaveDomain" id="SaveDomain" value="Save Domain Info" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
            {(!isNaN(domain.domainId) && domain.domainId !== 0) && <RecordList domainId={domain.domainId} org={props.org} />}
        </div>
    );
}