import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Table, ProgressBar, Row, Col } from "react-bootstrap";
import { WebSocketContext } from "../../../Redux/WebSocket";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import base32 from "hi-base32";
import "../../../css/FilePathContent.css";
import NexusModal from "../../App/NexusModal";
import { BlobServiceClient } from "@azure/storage-blob";
import { GetFileUploadCredential } from '../../../api/FilesystemAPI';

const FilePathContent = (props) => {
  const socket = React.useContext(WebSocketContext);
  const { FilePath } = useParams();
  const DisplayFilePath = base32.decode(FilePath);
  const FilePathContent = useSelector(
    (state) => state.WebSocketReducer.FilePathContent
  );
  const LastDownloadedFileRaw = useSelector(
    (state) => state.WebSocketReducer.LastDownloadedFileRaw
  );
  const LastUploadedFileName = useSelector(
    (state) => state.WebSocketReducer.LastUploadedFileName
  );
  const uploadFile = useRef(null);
  const [downloadModal, setDownloadModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadFileStatus, setUploadFileStatus] = useState('');

  let { path, url } = useRouteMatch();


  const getFilePathContent = () => {
    socket.send(
      JSON.stringify({
        type:
          "Nexus.Data.Classes.Models.XBert.WebSocketModels.FilePathContentRequest",
        body: {
          MessageType: "FilePathContentRequest",
          AssetID: parseInt(props.assetID),
          Message: DisplayFilePath,
        },
      })
    );
  }

  useEffect(() => {
    getFilePathContent();
  }, []);

  useEffect(() => {
    getFilePathContent();

    if(props.mode.mode == "Setting Backup Exclusions") {
      if(!DisplayFilePath.includes(props.mode.path)){
        props.setMode({});
      }
    }
  }, [url]);

  useEffect(() => {
    if(LastDownloadedFileRaw
      && Object.keys(LastDownloadedFileRaw).length !== 0
      && Object.getPrototypeOf(LastDownloadedFileRaw) === Object.prototype) {
        setDownloadModal(false);
        let link = document.createElement('a');
        link.href = LastDownloadedFileRaw.Url;
        link.download = LastDownloadedFileRaw.FileName;
        link.click();
      }
  }, [LastDownloadedFileRaw]);

  useEffect(() => {
    if(LastUploadedFileName) {
      setUploadModal(false);
    }
    getFilePathContent();
  }, [LastUploadedFileName]);

  const FileDisplay = () => {
    if (FilePathContent.Files) {
      return FilePathContent.Files.map((file, index) => {
          return (
           <tr key={index}>
            <td className="px-3 py-4">
              <a style={{ cursor: 'pointer' }} className="text-underline text-dark py-2 d-inline-block file" onClick={(e) => handleFileDownload(e)}>
                <i className="fa fa-file pr-2" aria-hidden="true"></i><i className="pr-2 fas fa-file-download"></i>{file}
              </a>
              {showTargetEditControls(file, false)}
            </td>
          </tr>
        );
      });
    }
  };

  const FolderDisplay = () => {
    if (FilePathContent.Folders) {
      return FilePathContent.Folders.map((folder, index) => {
        let encodedFolder = base32.encode(folder);
        let newPath = path.replace(":FilePath", encodedFolder);
        let fixedPath = newPath.replace(":id", props.assetID);
        return (
          <tr key={index}>
            <td className="px-3 py-4">
              <Link to={fixedPath} className="py-2 d-inline-block">
                <i className="fas fa-folder pr-2" aria-hidden="true"></i>{folder}
              </Link>
              {showTargetEditControls(folder)}
            </td>
          </tr>
        );
      });
    }
  };

  const ErrorDisplay = () => {
    if (FilePathContent.Error && FilePathContent.Error.length > 0) {
      return (
        <tr>
          <td className="px-3 py-4">
            <strong>Error: </strong> {FilePathContent.Error} <br/>
            <a onClick={NavigateBack} className="d-inline-block pt-3" style={{ cursor: 'pointer' }}>
              <i className="fas fa-angle-left pr-2" aria-hidden="true"></i>  Go Back</a>
          </td>
        </tr>
      );
    }
  };

  const showTargetEditControls = (folder, showAddExclusionsButton=true)=>{

    let addTarget = (e)=>{
      props.setBackupTargets("add",folder)
    }

    let removeTarget = (e)=>{
      props.setBackupTargets("remove", folder)
    }

    const editExclusions = (e)=>{
      props.setMode({
        mode: "Setting Backup Exclusions",
        path: folder
      })
    }

    const addExclusion = (e)=>{
      props.AddExclusion(props.mode.path, folder)
      console.log(`exclusion being added to ${props.mode.path}` )
    }
    const removeExclusion = (e)=>{
      props.RemoveExclusion(props.mode.path, folder)
      console.log(`removing exclusion for ${folder} in ${props.mode.path}`)
    }

    let encodedFolder = base32.encode(folder);
    let newPath = path.replace(":FilePath", encodedFolder);
    let fixedPath = newPath.replace(":id", props.assetID);
    
    if(props.backupTargets  && !props.backupTargets.filter(x=> x.target == folder).length > 0){
      if(props.mode.mode != "Setting Backup Exclusions"){
        return(<button style={{float:"right"}} type="button" className="nexusButtonCommon rounded mx-4" data={folder} onClick={addTarget}>Add Target</button>)
      }
      else{
        if(props.backupTargets.filter(x=> x.exclusions.includes(folder)).length == 0 ){
          return (<button style={{float: "right"}} type="button" className="nexusButtonCommon rounded mx-4" data={folder} onClick={addExclusion}>Exclude</button>)
        }
        else{
          return (<button style={{float: "right"}} type="button" className="nexusButtonCommon rounded mmx-4" data={folder} onClick={removeExclusion}>Remove Exclusion</button>)
        }
      }
    }
    else if(props.backupTargets && props.backupTargets.filter(x=> x.target == folder).length > 0){

        if(showAddExclusionsButton){
         return (
          <>
            <Link to={fixedPath}>
              <button style={{float:"right"}} type="button" className="nexusButtonCommon rounded mx-4" data={folder} onClick={editExclusions}> Edit Target Exclusions</button>
            </Link>
              <button style={{float:"right"}} type="button" className="nexusButtonCommon rounded mx-4" data={folder} onClick={removeTarget}>Remove Target</button>
          </>
        )}
        else{
          return(<button style={{float:"right"}} type="button" className="nexusButtonCommon rounded mx-4" data={folder} onClick={removeTarget}>Remove Target</button>)
        }
      
    }
  }

  const NavigateBack = () =>{
    window.history.go(-1)
  }

  const displayModeToggle = ()=>{
    if(Object.keys(props.mode).length > 0){
      return(
        <p>{props.mode.mode} for: {props.mode.path}</p>
      )
    }
  }

  const NavigationControls = ()=>{
    const NavigateBack = () =>{
      window.history.back();
    }

    return(
      <button type="button" className="nexusButtonCommon rounded" onClick={NavigateBack}>Back</button>
    )

  }

  const uploadFileToBlob = async (file) => {
    if (!file) return [];

    setUploadModal(true);
    setUploadFileStatus('uploading');

    let randomString = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for(let i = 0; i < 8; i++ ) {
      randomString += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    let newFileName = file.name.replace(/\.[^/.]+$/, "") + "-" + randomString + '.' + file.name.split('.').pop();
    
    GetFileUploadCredential().then(response => {
      let storageAccountName = response.azureAtreyuAccount;
      let sasToken = response.azureTempFileConnectionString;
      let blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
      );
      const containerClient = blobService.getContainerClient('temp-file-sharing');
      const blobClient = containerClient.getBlockBlobClient(newFileName);
      const options = { blobHTTPHeaders: { blobContentType: file.type } };
      blobClient.uploadData(file, options).then(() => {
        socket.send(
          JSON.stringify({
            type:
              "Nexus.Data.Classes.Models.XBert.WebSocketModels.FileUploadRequest",
            body: {
              MessageType: "FileUploadRequest",
              AssetID: parseInt(props.assetID),
              FileNameUploaded: newFileName,
              DownloadPath: DisplayFilePath
            },
          })
        );
        setUploadFileStatus('downloading');
      });
    }).catch(err => console.error(err));
  };

  const handleFileDownload = (e) => {
    socket.send(
      JSON.stringify({
        type:
          "Nexus.Data.Classes.Models.XBert.WebSocketModels.FileDownloadRequest",
        body: {
          MessageType: "FileDownloadRequest",
          AssetID: parseInt(props.assetID),
          Message: e.target.innerText
        },
      })
    );
    setDownloadModal(true);
  }

  return (
    <div className="bootstrap-wrapper">
      <Table striped bordered>
        <thead className="bg-secondary">
          <tr className="text-white container-fluid">
            <th className="p-3 row no-gutters">
              <div className="col-8" style={{ verticalAlign: 'middle' }}>
                <h4 className="font-weight-bold">Path: {DisplayFilePath}</h4>
                <input type="file" ref={uploadFile} name="filename" onChange={(e) => uploadFileToBlob(e.target.files[0])} className="d-none" />
                <a style={{ cursor: 'pointer' }} className="text-underline text-white" onClick={() => uploadFile.current.click()}>
                  Upload File
                </a>
              </div>
              <div className="col-4 text-right align-self-center">
                {NavigationControls()}
              </div>
              <div className="col-12 pt-2">
                {displayModeToggle()}
                {props.ToggleExclusionMode()}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {ErrorDisplay()}
          {FolderDisplay()}
          {FileDisplay()}
        </tbody>
      </Table>
      <NexusModal stateValue={downloadModal} stateFunctionClose={() => setDownloadModal(false)} title={"File is uploading from asset"}
        closeAction={() => {setDownloadModal(false)}} width='50%' height='auto'>
        <div className="bootstrap-wrapper">
          <ProgressBar striped animated now={100} />
          <p className="text-primary text-center pt-3">Don't close the browser window until finished</p>
        </div>
      </NexusModal>
      <NexusModal stateValue={uploadModal} stateFunctionClose={() => setUploadModal(false)} title={`File is ${uploadFileStatus} to asset`}
        closeAction={() => {setUploadModal(false)}} width='50%' height='auto'>
        <div className="bootstrap-wrapper">
          <ProgressBar striped animated now={100} />
          <p className="text-primary text-center pt-3">
            {uploadFileStatus == 'uploading' ? 'Don\'t close the browser window until finished' : 'You may close the browser window'}
          </p>
        </div>
      </NexusModal>
    </div>
  );
};

export default FilePathContent;
