import APIRequest from './APIRequest';
import CreateOrganizationRequest from './models/CreateOrganizationRequest';

export const GetOrganizations = (body: any) => {
    return APIRequest({
        url: `Org/GetOrganizations`,
        method: 'post',
        body
    });
};

export const GetOrganizationsNum = () => {
    return APIRequest({
        url: `Org/GetOrganizationsNum`,
        method: 'post',
        body: {},
    });
}

export const GetOrganizationByTenant = (tenantURI: string) => {
    return APIRequest({
        url: `Org/GetOrganizationByTenant`,
        method: 'post',
        body: {
            TenantURI: tenantURI,
        },
    });
}

export const GetOrganizationsByService = (serviceID: Number, enabled: boolean) => {
    return APIRequest({
        url: `Org/GetOrganizationsByService`,
        method: 'post',
        body: {
            serviceID: serviceID,
            enabled: enabled
        }
    });
};

export const SetOrganizationToService = (serviceID: Number, orgId: Number) => {
    return APIRequest({
        url: `Org/SetOrganizationToService`,
        method: 'post',
        body: {
            serviceID: serviceID,
            orgId: orgId
        }
    });
};

export const GetPagedOrganizations = (
    pagenum: any,
    perpage: any,
    sortdirection: any,
    sortfield: any
) => {
    return APIRequest({
        url: `Org/GetOrganizations`,
        method: 'post',
        body: {},
    });
};

export const GetOrganization = (body: any) => {
    return APIRequest({
        url: `Org/GetOrganization`,
        method: 'post',
        body,
    });
};

export const GetOrganizationCanReceiveService = (orgId: Number) => {
    return APIRequest({
        url: `Org/GetOrganizationCanReceiveService`,
        method: 'post',
        body: {
            content: orgId
        }
    });
};

export const PostCreateOrganization = (body: CreateOrganizationRequest) => {
    return APIRequest({
        url: `Org/CreateOrganization`,
        method: 'post',
        body: {content: body},
    });
};

export const UpdateOrganization = (body: any) => {
    return APIRequest({
        url: `Org/UpdateOrganization`,
        method: 'post',
        body: {content: body},
    });
};

export const DeleteOrganization = (body: any) => {
    return APIRequest({
        url: `Org/DeleteOrganization`,
        method: 'post',
        body: {content: body},
    });
};

export const GetOrganizationsForPerson = (body: any) => {
    return APIRequest({
        url: `Org/GetOrganizationsForPerson`,
        method: 'post',
        body,
    });
};

export const GetOrganizationAssetKey = (orgID : Number) => {
    return APIRequest({
        url: `Org/GetOrganizationAssetKey`,
        method: 'post',
        body: {
           content: orgID
        },
    });
};
