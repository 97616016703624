import React, { useState, useEffect } from 'react';
import { GetHookList } from '../../api/HookAPI';
import { GetServiceHooks, ActivateServiceHook, DisableServiceHook } from '../../api/ServiceAPI';

import Loader from '../App/loader';

export default props => {
    const [loading, setLoading] = useState(true);
    const [newHook, setNewHook] = useState("");
    const [hooks, setHooks] = useState([]);
    const [serviceHooks, setServiceHooks] = useState([]);

    useEffect(() => {
        loadHooks();
    }, []);

    useEffect(() => {
        loadServiceHooks();
    }, [props.service.serviceId]);

    const loadHooks = () => {
        setLoading(true);
        GetHookList()
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setHooks(res.content);
                    }
                }
            })
        setLoading(false)
    }

    const loadServiceHooks = () => {
        let serviceId = parseInt(props.service.serviceId, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            setLoading(true);
            GetServiceHooks({ content: serviceId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setServiceHooks(res.content);
                            setNewHook("");
                        }
                    }
                })
        }
        setLoading(false);
    }

    const addHook = () => {
        let serviceId = parseInt(props.service.serviceId, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            ActivateServiceHook({
                hook: newHook,
                serviceId: serviceId
            })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            loadServiceHooks();
                        }
                    }
                })
        }
    }

    const removeHook = (serviceHookId) => {
        DisableServiceHook({ content: serviceHookId })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        loadServiceHooks();
                    }
                }
            })
    }

    const getActiveServiceHooks = () => {
        let activeServiceHooks = [];
        serviceHooks.forEach(sh => {
            if (sh.active) {
                activeServiceHooks.push(sh);
            }
        });
        return activeServiceHooks;
    }

    const getHookOptions = () => {
        let activeServiceHooks = [];
        serviceHooks.forEach(sh => {
            if (sh.active) {
                activeServiceHooks.push(sh.hook);
            }
        });
        let hookOptions = [];
        hooks.forEach(h => {
            if (!activeServiceHooks.includes(h.name)) {
                hookOptions.push(h.name);
            }
        });
        return hookOptions;
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h3>Hooks</h3>
            <table className="nextTable incidentTable">
                <tbody>
                    <tr key="0">
                        <th>Hook</th>
                        <th>Action</th>
                    </tr>
                    {getActiveServiceHooks().map(sh =>
                        <tr key={sh.serviceHookId}>
                            <td>{sh.hook}</td>
                            <td>
                                <div className="nexusButtonCommon" onClick={() => removeHook(sh.serviceHookId)}>
                                    <img src="/Images/Remove.png" alt="[ Remove ]" />Remove Hook
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <select name="newHookSelect" id="newHookSelect" value={newHook} onChange={(e) => setNewHook(e.target.value)}>
                                {newHook === "" && <option value="" />}
                                {getHookOptions().map((hook) =>
                                    <option key={hook} value={hook}>{hook}</option>
                                )}
                            </select>
                        </td>
                        <td>
                            <div id="addHookButton" className="nexusButtonCommon" onClick={() => addHook()}><img src="/Images/Add.png" alt="[ Add ]" />Add Selected Hook</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}