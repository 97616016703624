/* eslint-disable no-case-declarations */
import * as actions from './actions';
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    UserAgentSessions: {},
    UserAgentScreenShots: {},
    ICECandidate: null,
    remoteSdp: null,
    agentHungUp: null,
    agentAccepted: null,
    nativeStreamDimensions: {},
    clipboardContents: '',
    AssetsOnline: [],
    SelectedAsset: null,
    SelectedAssetDrives: [],
    SelectedAssetStatus: {},
    FilePathContent: { Files: [], Folders: [], Error: '' },
    LastDownloadedFileRaw: {},
    LastUploadedFileName: '',
    CommandLine: {},
    PingScanner: {},
};

const webSocketReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.updateOnlineAssets, (state, action) => {
            state.AssetsOnline = action.payload
        })
        .addCase(actions.updateSelectedAsset, (state, action) => {
            state.SelectedAsset = action.payload;
            state.SelectedAssetDrives = [];
            state.SelectedAssetStatus = {};
        })
        .addCase(actions.updateSelectedAssetCdriveContents, (state, action) => {
            state.SelectedAssetCdriveContent = action.payload;
        })
        .addCase(actions.updateFileSystem, (state, action) => {
            state.SelectedAssetDrives = action.payload.Drives;
        })
        .addCase(actions.updateFilePathContent, (state, action) => {
            if (action.payload.Files && action.payload.Folders) {
                let newFiles = {};
                Object.assign(newFiles, action.payload);
                state.FilePathContent = newFiles;
            }
        })
        .addCase(actions.updateDownloadedFile, (state, action) => {
            state.LastDownloadedFileRaw = action.payload;
        })
        .addCase(actions.updateUploadedFile, (state, action) => {
            state.LastUploadedFileName = action.payload;
        })
        .addCase(actions.updateSelectedAssetStatus, (state, action) => {
            state.SelectedAssetStatus = action.payload;
        })
        .addCase(actions.updateUserAgentSessions, (state, action) => {
            const { assetId, agents, agentScreenshots } = action.payload;
            let newSessions = {};
            let newScreenshots = {};
            Object.assign(newSessions, state.UserAgentSessions);
            Object.assign(newScreenshots, state.UserAgentScreenShots);
            newSessions[assetId] = agents;
            newScreenshots[assetId] = agentScreenshots;
            state.UserAgentSessions = newSessions;
            state.UserAgentScreenShots = newScreenshots;
        })
        .addCase(actions.setICECandidate, (state, action) => {
            state.ICECandidate = JSON.parse(action.payload);
        })
        .addCase(actions.setRemoteSdp, (state, action) => {
            state.remoteSdp = JSON.parse(action.payload);
        })
        .addCase(actions.setAgentHungUp, (state, action) => {
            state.agentHungUp = action.payload;
        })
        .addCase(actions.setAgentAccepted, (state, action) => {
            state.agentAccepted = action.payload;
        })
        .addCase(actions.setNativeStreamDimensions, (state, action) => {
            state.nativeStreamDimensions = action.payload;
        })
        .addCase(actions.setClipboardContents, (state, action) => {
            state.clipboardContents = action.payload;
        })
        .addCase(actions.GetCommandLine, (state, action) => {
            state.CommandLine = action.payload;
        })
        .addCase(actions.GetPingScanner, (state, action) => {
            state.PinigScanner = action.payload;
        })
        .addDefaultCase((state, action) => {
            return state;
        })
});

export default webSocketReducer;
