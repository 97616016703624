/* eslint-disable no-case-declarations */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Mouse from './Mouse/Mouse';
import SessionFunctions from './SessionFunctions';
import {
  RTC_MESSAGES,
  WebSocketContext,
  UserAgentSessionRequestType
} from '../../../Redux/WebSocket';
import {
  setAgentHungUp,
  setAgentAccepted,
  setRemoteSdp,
  setICECandidate
} from '../../../Redux/actions';

const Sessions = (props) => {
  const remoteVideoRef = useRef(null);
  const myPeerConnection = useRef(null);
  const dispatch = useDispatch();
  const [EarlyRemoteICE, setEarlyRemoteICE] = useState([]);
  const [savedEvent, setSavedEvent] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [specialKeysSet, setSpecialKeysSet] = useState([]);
  const socket = React.useContext(WebSocketContext);

  const [timesMouseMove, setTimesMouseMove] = useState(0);
  // increasing will make move/drag function less smooth but reduce the socket messages
    const ONE_MOUSE_PER_MOVE = 15;

    const throttlePSecond = 15;
    var throttle = false;

  let assetID = useSelector((state) => state.WebSocketReducer.SelectedAsset);
  let remoteSdp = useSelector((state) => state.WebSocketReducer.remoteSdp);
  let ICECandidate = useSelector((state) => state.WebSocketReducer.ICECandidate);
  let agentHungUp = useSelector((state) => state.WebSocketReducer.agentHungUp);
  let agentAccepted = useSelector((state) => state.WebSocketReducer.agentAccepted);
  let nativeStreamDimensions = useSelector((state) => state.WebSocketReducer.nativeStreamDimensions);
  let clipboardContents = useSelector((state) => state.WebSocketReducer.clipboardContents);

  useEffect(() => {
    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          AssetID: parseInt(assetID),
          UserName: String(props.agent),
          SubMessageType: RTC_MESSAGES.TRIGGER_AGENT,
        },
      })
    );
  }, []);

  useEffect(() => {
    if(!props.sessionModalOpen) {
      closeConnection();
    }
  }, [props.sessionModalOpen]);

  /*
   * RTC handler functions
   */

  function trackIn(event) {
    setSavedEvent(event);
  }

  function handleICECandidateEvent(event) {
    if (event.candidate) {
      socket.send(
        JSON.stringify({
          type: UserAgentSessionRequestType,
          body: {
            UserName: props.agent,
            AssetID: parseInt(assetID),
            SubMessageType: RTC_MESSAGES.NEW_ICE_CANDIDATE,
            ICECandidate: JSON.stringify(event.candidate)
          },
        }));
    }
  }

  useEffect(() => {
    async function addIceCandidate() {
      if(ICECandidate) {
        if(myPeerConnection.current) {
          await myPeerConnection.current.addIceCandidate(
            new RTCIceCandidate(ICECandidate)
          );
        } else {
          setEarlyRemoteICE(prevICE =>
            [...prevICE, JSON.stringify(ICECandidate)]);
        }
      }
    }

    addIceCandidate();
  }, [ICECandidate]);

  useEffect(() => {
    if(remoteSdp) {
      async function handleOfferMsg() {
        if (myPeerConnection.current == null) {
          myPeerConnection.current = new RTCPeerConnection({
            iceServers: [
              { 
                urls: "stun:turn.sierravoip.com:5349"
              },
              {
                urls: "turns:turn.sierravoip.com:5349",
                username: "SIERR001-xBert",
                credential: "12345dogs"
              }
            ],
          });
          myPeerConnection.current.ontrack = trackIn;
          myPeerConnection.current.onicecandidate = handleICECandidateEvent;
        }
        var desc = new RTCSessionDescription(remoteSdp);

        if (myPeerConnection.current.signalingState !== 'stable') {
          console.log('setting remote description (not stable)');
          await Promise.all([
            myPeerConnection.current.setLocalDescription({ type: 'rollback' }),
            myPeerConnection.current.setRemoteDescription(desc),
          ]);
          return;
        } else {
          console.log('setting remote description (is stable)');
          await myPeerConnection.current.setRemoteDescription(desc);
        }

        try {
          myPeerConnection.current.addTransceiver('video');
          myPeerConnection.current.getTransceivers().forEach(t => t.direction = 'recvonly');
        } catch(err) {
          console.error(err);
        }

        await myPeerConnection.current.setLocalDescription(
          await myPeerConnection.current.createAnswer()
        );

        setTimeout(() => {
          socket.send(
            JSON.stringify({
              type: UserAgentSessionRequestType,
              body: {
                UserName: props.agent,
                AssetID: parseInt(assetID),
                SubMessageType: RTC_MESSAGES.VIDEO_ANSWER,
                Sdp: JSON.stringify(myPeerConnection.current.localDescription)
              },
            }))
        }, 1000);

        
        
        for (let i = 0; i < EarlyRemoteICE.length; i++) {
          await myPeerConnection.current.addIceCandidate(
            new RTCIceCandidate(JSON.parse(EarlyRemoteICE[i]))
          );
        }
      }
      handleOfferMsg();
      setConnectionStatus(RTC_MESSAGES.VIDEO_OFFER);
    }
  }, [remoteSdp]);

  /*
   * updating connection button and functions
   */

  useEffect(() => {
    if (connectionStatus == null && !agentAccepted) {
      props.setConnectionText('Connection closed');
    } else if (connectionStatus == null && agentAccepted) {
      props.setConnectionText('Connecting');
    } else if (connectionStatus == RTC_MESSAGES.VIDEO_OFFER &&
      savedEvent !== null && savedEvent !== undefined && agentAccepted) {
      handleTrackEvent();
    } else if (connectionStatus == RTC_MESSAGES.NEXUS_CONNECTED) {
      props.setConnectionText('Connected');
    }
  }, [connectionStatus, savedEvent, agentAccepted]);

  function handleTrackEvent() {
    const remoteVideo = remoteVideoRef.current;
    remoteVideo.srcObject = savedEvent.streams[0];
    remoteVideo.onloadedmetadata = () => {
      remoteVideo.play();
    };

    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          UserName: props.agent,
          AssetID: parseInt(assetID),
          SubMessageType: RTC_MESSAGES.NEXUS_CONNECTED,
        },
      }));
    // to have hangup button, otherwise refresh TabSessions page
    setConnectionStatus(RTC_MESSAGES.NEXUS_CONNECTED);
  }

  function hangUpPeerConnection() {
    dispatch(setAgentHungUp(false));
    dispatch(setAgentAccepted(false));
    dispatch(setRemoteSdp(null));
    dispatch(setICECandidate(null));
    setSavedEvent(null);
    setEarlyRemoteICE([]);
    setConnectionStatus(null);

    if (myPeerConnection.current != null) {
      myPeerConnection.current.ontrack = null;
      myPeerConnection.current.onicecandidate = null;
      myPeerConnection.current.close();
      myPeerConnection.current = null;
    }
  }

  function closeConnection() {
    console.log('hanging up connection');
    socket.send(
      JSON.stringify({
        type: UserAgentSessionRequestType,
        body: {
          UserName: String(props.agent),
          AssetID: parseInt(assetID),
          SubMessageType: RTC_MESSAGES.HANG_UP,
        },
      })
    );
    hangUpPeerConnection();
  }

  useEffect(() => {
    if (agentHungUp !== null) {
      hangUpPeerConnection();
    }
  }, [agentHungUp]);

  /*
   * Input handlers 
   */

  const sendMouseMove = (payload) => {
      if (!throttle) {
          socket.send(JSON.stringify({
              type: UserAgentSessionRequestType,
              body: {
                  UserName: props.agent,
                  AssetID: parseInt(assetID),
                  SubMessageType: RTC_MESSAGES.MOUSE_MOVE,
                  Payload: payload,
              },
          }));

          throttle = true;
          setTimeout(function () {
              throttle = false;
          }, 1000 / throttlePSecond);
      }
  };

  const sendMouseClick = (payload) => {
    socket.send(JSON.stringify({
      type: UserAgentSessionRequestType,
      body: {
        UserName: props.agent,
        AssetID: parseInt(assetID),
        SubMessageType: RTC_MESSAGES.MOUSE_CLICK,
        Payload: payload,
      },
    }));
  };

    const sendMouseDrag = (payload) => {
        let type = JSON.parse(payload).type;
        if (!throttle || type != 'drag') {
            socket.send(JSON.stringify({
                type: UserAgentSessionRequestType,
                body: {
                    UserName: props.agent,
                    AssetID: parseInt(assetID),
                    SubMessageType: RTC_MESSAGES.MOUSE_DRAG,
                    Payload: payload,
                },
            }));
            if (!throttle) {
                throttle = true;
                setTimeout(function () {
                    throttle = false;
                }, 1000 / throttlePSecond);
            }
        }
    }

    useEffect(() => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(clipboardContents)
        .then(text => console.log(`written to clipboard: ${clipboardContents}`))
        .catch(err => console.error(err));
    }
  }, [clipboardContents]);

  const keyDownPressed = (e) => {
    e.preventDefault();
    let keyToSend = null;

    // if is canceable/prevent default will work via keyboard
    // otherwise, send with buttons
    let modKeys = [];
    const { key, altKey,
      ctrlKey, metaKey, shiftKey } = e;
    if (altKey || specialKeysSet.includes('alt')) {
      modKeys.push('alt');
    }
    if (ctrlKey || specialKeysSet.includes('control')) {
      modKeys.push('control');
    }
    if (metaKey || specialKeysSet.includes('command')) {
      modKeys.push('command');
    }
    if (shiftKey || specialKeysSet.includes('shift')) {
      modKeys.push('shift');
    }

    switch (key.toLowerCase()) {
      case 'backspace':
      case 'delete':
      case 'enter':
      case 'tab':
      case 'escape':
      case 'up':
      case 'down':
      case 'right':
      case 'left':
      case 'home':
      case 'end':
      case 'pageup':
      case 'pagedown':
      case 'f1':
      case 'f2':
      case 'f3':
      case 'f4':
      case 'f5':
      case 'f6':
      case 'f7':
      case 'f8':
      case 'f9':
      case 'f10':
      case 'f11':
      case 'f12':
      case 'command':
      case 'alt':
      case 'control':
      case 'shift': // right_shift is just shift
      case 'space':
      case 'printscreen':
      case 'insert':
      case 'capslock': // not in robotJS and does nothing
        keyToSend = key.toLowerCase();
        break;
      case 'mediatrackprevious':
        keyToSend = 'audio_prev';
        break;
      case 'mediaplaypause':
        keyToSend = 'audio_pause';
        break;
      case 'mediatracknext':
        keyToSend = 'audio_next';
        break;
      case 'mediastop':
        keyToSend = 'audio_stop';
        break;
      case 'meta':
        keyToSend = 'command';
        break;
      default:
        keyToSend = key;
    }

    socket.send(JSON.stringify({
      type: UserAgentSessionRequestType,
      body: {
        UserName: props.agent,
        AssetID: parseInt(assetID),
        SubMessageType: RTC_MESSAGES.KEYBOARD_KEYPRESS,
        Payload: JSON.stringify({ 'key': keyToSend, 'modKey': modKeys })
      },
    }));
  };

  const toggleSpecialKey = (key) => {
    if (!specialKeysSet.includes(key)) setSpecialKeysSet(keys => [...keys, key]);
    else setSpecialKeysSet(specialKeysSet.filter(keys => keys != key));
  }

  const sessionWindow = (
    <Mouse className="mouseContainer"
      mouseMove={sendMouseMove}
      mouseClick={sendMouseClick}
      mouseDrag={sendMouseDrag}
      keyPress={keyDownPressed}
      nativeStreamDimensions={nativeStreamDimensions}
      keyPressedModalEvent={props.keyPressedModalEvent}
      specialKeysSet={specialKeysSet}
      >
        <video style={{ width: '100%', maxHeight: '91vh' }} ref={remoteVideoRef} autoPlay />
    </Mouse>
  );

  return (
    <>
      <div style={{ marginBottom: '5px' }}>{sessionWindow}</div>
      {connectionStatus == RTC_MESSAGES.NEXUS_CONNECTED ?
        <SessionFunctions keys={specialKeysSet} toggleSpecialKey={toggleSpecialKey} agent={props.agent} /> : null}
    </>
  );
};

export default Sessions;
