import React, {useState,useEffect, useRef} from 'react';
import { GetPeople, GetPeopleWithoutOrg, AddOrganizationToPerson } from '../../../api/PeopleAPI.js';
import Loader from '../../App/loader.js';
import NexusGrid from '../../App/NexusGrid';
import NexusModal from '../../App/NexusModal';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const [canEditPeople,  setCanEditPeople] = useState(true);
    const [orgPeople, setOrgPeople] = useState([]);
    const [displayModal,setDisplayModal] = useState(false);
    const [allPpl, setAllPpl] = useState([]);
    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);
    const [currentPersonToAdd, setCurrentPersonToAdd] = useState(0);
    const [personAdded, setPersonAdded] = useState(0);
    const [search, setSearch] = useState("");
    const [searchedList, setSearchedList] = useState([]);
    const [searchLoaded, setSearchLoaded] = useState(true);
    const [authMethod, setAuthMethod] = useState(1);

    const orgid  =  props.routeProps.match.params.id;

    //useEffect(() => {
    //        setLoaded(false);
    //        getPeople(orgid);
    //},[personAdded]);

    //const getPeople = (orgID) => {
    //    GetPeople({ orgID: Number(orgID), pageSize: 25, pageIndex: 0, sortBy: [], filters: [] })
    //        .then((res) => {
    //            if (res.success) {
    //                setOrgPeople(res.content);
    //                setCanEditPeople(res.canEditPeople);
    //                setTotalResults(res.totalCount);
    //                setTotalPages(res.pageCount);
    //                setLoaded(true);
    //            } else {
    //                setLoaded(true);
    //            }
    //        })
    //        .catch(() => {
    //            setError(true);
    //        });
    //}

    const getPeople = React.useCallback(
        ({ pageSize, pageIndex, sortBy, filters }) => {
            setFilterResults(filters);
            setSortByResults(sortBy);
            firstRender.current ? setLoaded(false) : setLoadingFilterSort(true);
            firstRender.current = false;

            GetPeople({
                orgid: Number(orgid),
                pageSize: pageSize,
                pageIndex: pageIndex,
                filters: filters,
                sortBy: sortBy,
            })
                .then((response) => {
                    setLoadingFilterSort(false);
                    setLoaded(true);
                    if (response.success) {
                        setOrgPeople(response.content);
                        setCanEditPeople(response.canEditPeople);
                        setTotalResults(response.totalCount);
                        setTotalPages(response.pageCount);
                    }
                })
                .catch(() => {
                    setLoadingFilterSort(false);
                    setLoaded(true);
                });
        },
        []
    );

    //const getPeoplWithoutOrg = () =>{
    //    setSearchLoaded(false);
    //    const personID = 1;
    //    GetPeopleWithoutOrg({personID: Number(personID), orgID: Number(orgid)}).then((res) =>{
    //        if(res.success){
    //            setSearchLoaded(true);
    //            setAllPpl(res.content);
    //            setCurrentPersonToAdd(res.content[0].personID);
    //            setSearchedList(res.content);
    //            setLoaded(true);
    //        }else{
    //            setLoaded(true);
    //            console.log(res);
    //        }
    //    })
    //    .catch(()  =>{
    //        setError(true);
    //    });
    //}

    const getAllPpl = () =>{
        setSearchLoaded(false);
        GetPeople({ orgID: Number(0), pageSize: 9999999, active: true }).then((res) =>{
            if(res.success){
                setSearchLoaded(true);
                setAllPpl(res.content);
                setCurrentPersonToAdd(res.content[0].personID);
                setSearchedList(res.content);
                setLoaded(true);
            }else{
                setLoaded(true);
                console.log(res);
            }
        })
        .catch(()  =>{
            setError(true);
        });
    }

    const openAddModal  = () =>{
        setDisplayModal(true);
        //getPeoplWithoutOrg();
        getAllPpl();
    }

    const handleOrgChange = (e) => {
        setCurrentPersonToAdd(e.target.value);
    }
    
    const addPersonToOrg = () =>{
        AddOrganizationToPerson({ personID: Number(currentPersonToAdd), orgID: Number(orgid), authMethod: Number(authMethod) }).then(res =>{
            if(res.success){
                setDisplayModal(false);
                setPersonAdded(currentPersonToAdd);
            }
        }).catch(() =>{
            setError(true);
        })
    }

    const handlePersonChange = (e) => {
        setSearch(e.target.value);
    }

    const enterKeyPressed = (event)=>{
        if(event.key === 'Enter'){
            setSearchLoaded(false);
            searchPerson();
        }
    }

    const searchPerson = () =>{
        setSearchLoaded(false);
        if(search !== ''){
            const result = allPpl.filter((ppl)=>{
                return ppl.firstName.toLowerCase().includes(search.toLowerCase()) ||
                ppl.lastName.toLowerCase().includes(search.toLowerCase())||
                ppl.emailAddress1.toLowerCase().includes(search.toLowerCase());
            })
            if(result.length === 0){
                setSearchLoaded(true);
                setSearchedList(result);
            }else{
                setSearchLoaded(true);
                setSearchedList(result);
                setCurrentPersonToAdd(result[0].personID);
            }
        }else{
            setSearchLoaded(true);
            setSearchedList(allPpl);
        }
    }

    const headers = [
        { Header: 'Person ID', accessor: 'personID' },
        { Header: 'First Name', accessor: 'firstName' },
        { Header: 'Last Name', accessor: 'lastName' },
        { Header: 'Primary Email', accessor: 'emailAddress1' },
        { Header: 'Office Phone', accessor: 'officePhone' },
        { Header: 'Primary Mobile', accessor: 'mobile1' },
        { Header: 'Active', accessor: 'active' }
    ];

    return (
        <div className="localSortDGContainer">
            <h2>People</h2>
            {canEditPeople ? (
                <button onClick={openAddModal} className="nexusButtonCommon"><i className="fas fa-plus"></i>Add</button>
            ) : null}
            {loaded ? (
                <>
                <NexusModal stateValue={displayModal} stateFunctionClose={() => setDisplayModal(false)} title="Select a Person" closeText="Add Person"
                    closeAction={() => {addPersonToOrg(); setDisplayModal(false); }} width='50%' height='auto'>
                    {searchLoaded  ? (
                            <>
                                <div style={{ marginBottom: '20px' }}>
                                    <input onChange={handlePersonChange} onKeyDown={enterKeyPressed} placeholder='Search person' style={{ width: '80%' }}></input>
                                    <button onClick={()=>{setSearchLoaded(false); searchPerson();}} className="nexusButton" style={{ float: 'right' }}>Search Person</button>
                                </div>
                                <select onChange={handleOrgChange} style={{ width: '100%' }}>
                                    {searchedList.map((p) => <option id='options' key={p.personID} value={p.personID}>{p.firstName + " " + p.lastName + " ("+p.emailAddress1+")"}</option>)}
                                </select>
                                <select onChange={(e) => setAuthMethod(e.target.value)} style={{ width: '100%', marginBottom: '5px' }}>
                                    <option value="">-- Select an Authentication Method --</option>
                                    <option value="1">Active Directory</option>
                                    <option value="4">Local Authentication</option>
                                </select>
                                
                            </>
                    ) : (
                        <div className="NexusLoading">
                            <Loader/>
                        </div>
                    )}
                </NexusModal>
                <NexusGrid
                        linkTo={`/${tenant}/people/:key/info`}
                        tableKey={'personID'}
                        hiddenColumns={['personID', 'active']}
                        data={orgPeople}
                        defaultSortBy={'personID'}
                        columns={headers}
                        fetchData={getPeople}
                        filterData={filterResults}
                        sortByData={sortByResults}
                        loading={!loaded}
                        loadingFilterSort={loadingFilterSort}
                        pageMode="remote"
                        perPage={25}
                        totalResults={totalResults}
                        totalPages={totalPages}
                        defaultSortOrder={'asc'}
                />
                </>
            ) : error ? (
                <p>There was an error loading the data.</p>
            ) : (
                <Loader />
            )}
        </div>
    );
}