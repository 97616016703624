import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useSelector } from 'react-redux';
import { GetOrgHookList } from '../../api/HookAPI';
import Loader from '../App/loader';
import TabView from '../App/tabview';
import TabInfo from './Tabs/tabinfo';
import TabTelephony from './Tabs/tabtelephony';
import PBXInfo from '../Telephony/pbxinfo';
import PBXEdit from '../Telephony/pbxedit';
import SoftSwitchInfo from '../Telephony/softswitchinfo';
import SoftSwitchEdit from '../Telephony/softswitchedit';
import TrunkInfo from '../Telephony/trunkinfo';
import TrunkEdit from '../Telephony/trunkedit';
import DIDInfo from '../Telephony/didinfo';
import DIDEdit from '../Telephony/didedit';
import DidAdd from '../Telephony/didadd';
import CarrierInfo from '../Telephony/carrierinfo';
import CarrierEdit from '../Telephony/carrieredit';
import TabPeople from './Tabs/tabpeople';
import TabLocations from './Tabs/tablocations';
import TabCredential from './Tabs/tabCredential';
import TabService from './Tabs/tabservice';
import DnsServiceRouter from './Services/DNS/dnsServiceRouter';
import WhmServiceInfo from './Services/WHM/whmServiceInfo';
import TabAssets from './Tabs/tabassets';
import TabNotes from './Tabs/tabnotes';
import { GetOrganization } from '../../api/OrganizationAPI';


export const getBreadcrumbBack = (precedingParam) => {
    const location = window.location.href.split('/');
    let assetBreadcrumbBack = '';
    for(let i = 0; i < location.length; i++) {
        if(precedingParam == location[i] && location[i+1]) {
            assetBreadcrumbBack = location[i+1];
            return assetBreadcrumbBack;
        }
    }
}

export default (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [organization, setOrganization] = useState({});
    const [hooks, setHooks] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [orgBreadcrumbBack, setOrgBreadcrumbBack] = useState('info');

    useEffect(() => {
        const orgID = parseInt(props.routeProps.match.params.id, 10);
        loadOrganization(orgID);
        loadOrgHooks(orgID);
    }, [props.routeProps.match.params.id]);

    useEffect(() => {
        setTabList(getTabList());
    }, [hooks]);

    const loadOrganization = (orgID) => {
        setLoading(true);

        GetOrganization({ content: orgID })
            .then((res) => {
                if (res.success) {
                    if (res.content[0].orgID != null) {
                        setOrganization(res.content[0]);
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    };

    const loadOrgHooks = (orgID) => {
        GetOrgHookList({ content: orgID })
            .then((res) => {
                if (res.success) {
                    setHooks(res.content);
                }
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    };

    const hasHook = (hook) => {
        let match = false;
        hooks.forEach((h) => {
            if (h.name === hook) {
                match = true;
            }
        });
        return match;
    };

    const getTabList = () => {
        const tabListBaseURL =
            `/` + tenant + '/organizations/' + props.routeProps.match.params.id;
        const tabList = [];
        tabList.push({
            label: 'Organization Info',
            url: tabListBaseURL + '/info',
            default: true,
        });
        tabList.push({ label: 'People', url: tabListBaseURL + '/people' });
        tabList.push({
            label: 'Locations',
            url: tabListBaseURL + '/locations',
        });
        tabList.push({ label: 'Services', url: tabListBaseURL + '/services' });
        tabList.push({ label: 'Assets', url: tabListBaseURL + '/assets' });
        tabList.push({ label: 'Notes', url: tabListBaseURL + '/notes' });
        tabList.push({ label: 'Telephony', url: tabListBaseURL + '/telephony' });
        tabList.push({ label: 'Credentials', url: tabListBaseURL + '/credentials' });
        if (hasHook('DNS'))
            tabList.push({ label: 'DNS', url: tabListBaseURL + '/dns' });
        if (hasHook('WHM'))
            tabList.push({ label: 'WHM', url: tabListBaseURL + '/whm' });
        return tabList;
    };

    return loading ? (
        <Loader />
    ) : error ? (
        <p>An error occurred while loading company data.</p>
    ) : (
        <div className="orgContainer">
            <BreadcrumbsItem to={`/${tenant}/organizations/`} label="Organizations" />
            <BreadcrumbsItem
                to={`/${tenant}/organizations/${organization.orgID}/${getBreadcrumbBack(props.routeProps.match.params.id)}/`}
                label={`${organization.orgName}`}
            />
            <div className="orgContainerInner">
                <h2>{organization.orgName}</h2>
                <TabView tabs={tabList}>
                    <Switch>
                        <Route
                            path={`/${tenant}/organizations/:id/info`}
                            render={(routeProps) => (
                                <TabInfo
                                    routeProps={routeProps}
                                    org={organization}
                                />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/people`}
                            render={(routeProps) => (
                                <TabPeople
                                    routeProps={routeProps}
                                    org={organization}
                                />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/locations`}
                            render={(routeProps) => (
                                <TabLocations routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/credentials`}
                            render={(routeProps) => (
                                <TabCredential
                                    routeProps={routeProps}
                                    org={organization}
                                    setBreadcrumbBack={setOrgBreadcrumbBack}
                                />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/services`}
                            render={(routeProps) => (
                                <TabService
                                    routeProps={routeProps}
                                    org={organization}
                                />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/dns`}
                            render={
                                hasHook('DNS')
                                    ? (routeProps) => (
                                          <DnsServiceRouter
                                              routeProps={routeProps}
                                              org={organization}
                                          />
                                      )
                                    : (routeProps) => (
                                          <TabInfo
                                              routeProps={routeProps}
                                              org={organization}
                                          />
                                      )
                            }
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/whm`}
                            render={
                                hasHook('WHM')
                                    ? (routeProps) => (
                                          <WhmServiceInfo
                                              routeProps={routeProps}
                                              org={organization}
                                          />
                                      )
                                    : (routeProps) => (
                                          <TabInfo
                                              routeProps={routeProps}
                                              org={organization}
                                          />
                                      )
                            }
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/assets`}
                            render={(routeProps) => (
                                <TabAssets
                                    routeProps={routeProps}
                                    org={organization}
                                />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/notes`}
                            render={(routeProps) => (
                                <TabNotes routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/telephone`}
                            render={(routeProps) => (
                                <TabTelephony routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/pbx/:pbxid/edit`}
                            render={(routeProps) => (
                                <PBXEdit routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/pbx/:pbxid`}
                            render={(routeProps) => (
                                <PBXInfo routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/softswitch/:softswitchid/edit`}
                            render={(routeProps) => (
                                <SoftSwitchEdit routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/softswitch/:softswitchid`}
                            render={(routeProps) => (
                                <SoftSwitchInfo routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/carrier/:carrierid/edit`}
                            render={(routeProps) => (
                                <CarrierEdit routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/carrier/:carrierid`}
                            render={(routeProps) => (
                                <CarrierInfo routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/trunk/:trunkid/edit`}
                            render={(routeProps) => (
                                <TrunkEdit routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/trunk/:trunkid`}
                            render={(routeProps) => (
                                <TrunkInfo routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/did/:didid/edit`}
                            render={(routeProps) => (
                                <DIDEdit routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/did/:didid`}
                            render={(routeProps) => (
                                <DIDInfo routeProps={routeProps} />
                            )}
                        />
                        <Route
                            path={`/${tenant}/organizations/:id/did/Add`}
                            render={(routeProps) => (
                                <DidAdd routeProps={routeProps} />
                            )}
                        />
                    </Switch>
                </TabView>
            </div>
        </div>
    );
};
