import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetOrganizationDID, DisableOrganizationDID } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [theDid, setTheDid] = useState({});

    useEffect(() => {
        const didID = this.props.routeProps.match.params.didid;
        const orgID = this.props.routeProps.match.params.id;
        GetOrganizationDID({ content: { didID: Number(didID), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setLoaded(true);
                        setTheDid(res.content[0]);
                    }
                    else{
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const disableDid = () => {
        DisableOrganizationDID({
            content: {
                didid: theDid.didid,
                active: !theDid.active
            }
        }).then(res => {
            var obj = theDid;
            obj.active = !obj.active;
            setTheDid(obj);
        })
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{theDid.description} - {theDid.number}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Number</th>
                                    <td>{theDid.number}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{theDid.description}</td>
                                </tr>
                                <tr>
                                    <th>Trunk</th>
                                    <td>{theDid.trunk}</td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />
                        <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                        <button className="nexusButtonCommon" type="submit" onClick={() => disableDid()}>{theDid.active ? "Disable" : "Enable"}</button>
                        {canEdit ? (
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/did/" + theDid.didid + "/edit"} className="nexusButtonCommon">Edit</Link>
                        ) : ("")}
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}