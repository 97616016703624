import store from '../Redux/store';

const APIDownload = params => {
    const state = store.getState();
    const tenant = state.TenantReducer.Tenant;
    let download = require('downloadjs')

    return new Promise((resolve, reject) => {
        let nexusToken = localStorage.getItem("nexusToken");
        if (nexusToken === null)
            nexusToken = sessionStorage.getItem("nexusToken");

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (nexusToken !== null)
            headers['Authorization'] = `Bearer ${nexusToken}`

        fetch(
            `/api/${tenant}/${params.url}`, {
            method: params.method,
            headers: headers
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok) {
                throw Error(response)
            }
            return response.blob();
        }).then(blob => {
            download(blob, params.filename);
            resolve();
        }).catch(error => {
            reject({ reason: "Unknown", details: error });
        });
    });
}

export default APIDownload;
