import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { GetTenants, GetCookieTenants } from '../../api/TenantAPI';
//import Loader from './loader.js';
import { GetPersonOrganizations } from '../../api/PeopleAPI.js';
import { Authenticate } from '../../api/NexusAPI';

const Header = props => {
    const [loading, setLoading] = useState(true);
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [data, setData] = useState([]);
    const [personID, setPersonID] = useState(-1);
    const [orgID, setOrgID] = useState(-1);

    useEffect(() => {
        if (localStorage.getItem("nexusID") != null) {
            setPersonID(localStorage.getItem("nexusID"));
        } else if (sessionStorage.getItem("nexusID") != null) {
            setPersonID(sessionStorage.getItem("nexusID"));
        }
        if (localStorage.getItem("nexusOrg") != null) {
            setOrgID(localStorage.getItem("nexusOrg"));
        } else if (sessionStorage.getItem("nexusOrg") != null) {
            setOrgID(sessionStorage.getItem("nexusOrg"));
        }
    }, [sessionStorage.getItem("nexusID"), localStorage.getItem("nexusID"), sessionStorage.getItem("nexusOrg"), localStorage.getItem("nexusOrg")]);

    useEffect(() => {
        //console.log(personID);
        if (personID != null && Number(personID) > 0) {
            setLoading(true);
            GetPersonOrganizations({ personID: Number(personID) })
                .then(res => {
                    //console.log(res);
                    if (res != null) {
                        setData(res.content);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    //setError(true);
                    setLoading(false);
                });
        }
    }, [personID]);

    const changeOrg = (e) => {
        Authenticate("", "", false, Number(localStorage.getItem("nexusID")), Number(e.target.value), Number(orgID), true).then(result => {
            setOrgID(result.content.orgID);
            if (result.content.authenticated) {
                if (localStorage.getItem("nexusOrg") != null) {
                    //localStorage.removeItem("nexusOrg");
                    localStorage.setItem("nexusOrg", result.content.orgID);
                } else if (sessionStorage.getItem("nexusOrg") != null) {
                    //sessionStorage.removeItem("nexusOrg");
                    sessionStorage.setItem("nexusOrg", result.content.orgID);
                }
            } else {
                props.logout();
            }
        }).catch(e => {
            console.error(e);
        });
    }

    return (
        <div className="header">
            <div className="logo">
                <Link to={`/${tenant}`} ><img src="/Images/NexusLogo.png" alt="NEXUS" /></Link>
            </div>
            {props.authenticated ? (
                <div className="headerControls">
                    <strong>Organization:</strong>
                    <select className="lstOrg" value={orgID} onChange={(e) => changeOrg(e)}>
                        {/*<option value={1}>Sierra Experts</option>*/}
                        {data.map(o => (
                            <option key={o.orgID} value={o.orgID}>{o.orgName}</option>
                        ))}
                    </select>
                    <Link to='/' onClick={() => props.logout()}>Logout</Link>
                </div>
            ) : (
                    <div className="headerControls"><span></span></div>
                )}
        </div>
    )
}
export default Header;