import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';

import {
    sortIncidents,
    selectIncident,
    getTotalHours,
    truncateSummary,
    closeSelectedIncidents,
    changeSort,
    colIcon
} from '../../utils/incidentUtils';
import { GetSelectedDateIncidents } from '../../api/IncidentAPI';
import LoadingShroud from '../App/LoadingShroud';
import AccordionWidget from './AccordionWidget';

const SelectedDateIncidents = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [calDate, setCalDate] = useState(new Date());
    const [descending, setDescending] = useState(true);
    const [sortCol, setSortCol] = useState("referenceID");
    const [rawIncidentdata, setRawIncidentData] = useState([]);
    const [incidents, setIncidents] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!props.date) {
            return;
        }
        const matchdate = props.date.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})/)
        if (matchdate && matchdate.length === 2) {
            const strdate = matchdate[1] + "T00:00:00";
            setCalDate(new Date(strdate));
        }
    }, [props.date]);

    useEffect(() => {
        loadIncidents(calDate);
    }, [calDate])

    useEffect(() => {
        setIncidents(sortIncidents(rawIncidentdata, sortCol, descending))
    }, [sortCol, descending, rawIncidentdata])

    const loadIncidents = (calendarDate) => {
        setLoading(true);
        GetSelectedDateIncidents(calendarDate).then(response => {
            if (!response.success) {
                setError(response.message);
                setLoading(false);
                return;
            }
            setError("");
            const addFlag = response.content.map(incident => {
                return { ...incident, selected: false }
            });
            setRawIncidentData(addFlag)
            setLoading(false);
        }).catch(err => {
            setError("An error occurred while loading Incidents for this day.");
            setLoading(false);
        })
    }
    const changeSelectIncident = (e, incident) => {
        setIncidents(selectIncident(e, incident, incidents));
    }
    return (
        <div className="col-12 pr-1">
            <AccordionWidget title={'Selected Date\'s Incidents'} dashboardPreference={'SelectedDateIncidents'}>
                <div className="widgetBody">
                    <LoadingShroud Active={loading} />
                    <div style={{ width: "262px" }}>
                        <Calendar value={calDate} onChange={e => setCalDate(e)} />
                    </div>
                    {error !== "" ? (
                        <div className="Error PaddedError">{error}</div>
                    ) : incidents.length === 0 ? (
                        <div className="paddedBold">No Incidents Found for the Selected Date.</div>
                    ) : (
                            <div style={{ width: "100%" }}>
                                <table className="nextTable incidentTable">
                                    <tbody>
                                        <tr>
                                            <th onClick={() => changeSort(sortCol,
                                                "referenceID", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Incident ID
                                        {colIcon(sortCol, "referenceID", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "companyName", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Company
                                        {colIcon(sortCol, "companyName", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "requestedByName", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Requestor
                                        {colIcon(sortCol, "requestedByName", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "summary", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Summary
                                        {colIcon(sortCol, "summary", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "assignedToName", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Assigned To
                                        {colIcon(sortCol, "assignedToName", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "priorityName", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Priority
                                        {colIcon(sortCol, "priorityName", descending)}
                                            </th>
                                            <th onClick={() => changeSort(sortCol,
                                                "statusName", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Status
                                        {colIcon(sortCol, "statusName", descending)}
                                            </th>
                                            <th>Close</th>
                                            <th onClick={() => changeSort(sortCol,
                                                "totalHours", descending, setSortCol,
                                                setDescending)}
                                                style={{ cursor: "pointer" }}>
                                                Hours
                                        {colIcon(sortCol, "totalHours", descending)}
                                            </th>
                                        </tr>
                                        {incidents.map(incident =>
                                            <tr key={incident.incidentID}>
                                                <td>
                                                    <Link to={`/${tenant}/Incidents/${incident.incidentID}`}
                                                        className="incidentRefLink">
                                                        {incident.referenceID}
                                                    </Link>
                                                </td>
                                                <td>{incident.companyName}</td>
                                                <td>{incident.requestedByName}</td>
                                                <td>
                                                    <div className="incidentSummary"
                                                        dangerouslySetInnerHTML={{ __html: truncateSummary(incident.summary) }} />
                                                </td>
                                                <td>{incident.assignedToName}</td>
                                                <td>{incident.priorityName}</td>
                                                <td>{incident.statusName}</td>
                                                <td>
                                                    {incident.statusName !== "Completed" ? (
                                                        <input type="checkbox" checked={incident.selected}
                                                            onChange={e => changeSelectIncident(e, incident)} />
                                                    ) : null}
                                                </td>
                                                <td>{incident.totalHours}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th colSpan="8" style={{ textAlign: "left" }}>
                                                Total Hours for Selected Date
                                    </th>
                                            <th style={{ textAlign: "left" }}>{getTotalHours(incidents)}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="dashboardAlignRightContainer">
                                    <input type="button" className="nexusButtonCommon"
                                        value="Close Selected Incidents"
                                        onClick={() => closeSelectedIncidents(setLoading,
                                            incidents,
                                            setError,
                                            loadIncidents)} />
                                </div>
                            </div>
                        )}
                </div>
            </AccordionWidget>
        </div>
    )
}

export default SelectedDateIncidents;