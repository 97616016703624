import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationPBX, DisableOrganizationPBX } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';
import PBXConnection from './pbxconnection.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true)
    const [canTestConnection, setCanTestConnection] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [pbx, setPbx] = useState({});

    useEffect(()=> {
        const pbxID = props.routeProps.match.params.pbxid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationPBX({ content: { pbxID: Number(pbxID), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1)
                    {
                        setPbx(res.content[0])
                        setLoaded(true);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }                        
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const disablePBX = () => {
        const pbx = {
            pbxid: Number(props.routeProps.match.params.pbxid),
            active: !pbx.active
        }
        DisableOrganizationPBX({
            content: pbx
        })
            .then(res => {
                var obj = pbx;
                obj.active = !obj.active;
                setPbx(obj);
            })
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{pbx.name}</h3>

                        <PBXConnection routeProps={props.routeProps} type="pbx" pbxid={pbx.pbxid} />
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Description:</th>
                                    <td>{pbx.description}</td>
                                </tr>
                                <tr>
                                    <th>Host:</th>
                                    <td>{pbx.host}</td>
                                </tr>
                                <tr>
                                    <th>SQL Host:</th>
                                    <td>{pbx.sqlhost}</td>
                                </tr>
                                <tr>
                                    <th>SQL User:</th>
                                    <td>{pbx.sqluser}</td>
                                </tr>
                                <tr>
                                    <th>SQL Password Configured:</th>
                                    <td>{pbx.sqlpassword}</td>
                                </tr>
                                <tr>
                                    <th>SQL Port:</th>
                                    <td>{pbx.sqlport}</td>
                                </tr>
                                <tr>
                                    <th>SSH User:</th>
                                    <td>{pbx.sshuser}</td>
                                </tr>
                                <tr>
                                    <th>SSH Password Configured:</th>
                                    <td>{pbx.sshpassword}</td>
                                </tr>
                                <tr>
                                    <th>SSH Port:</th>
                                    <td>{pbx.sshport}</td>
                                </tr>

                            </tbody>
                        </table>

                        <hr />
                        <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                        <button type="submit" onClick={() => disablePBX} className="nexusButtonCommon">{pbx.active ? "Disable" : "Enable"}</button>
                        {canEdit ? (
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/pbx/" + pbx.pbxid + "/edit"} className="nexusButtonCommon">Edit</Link>
                        ) : ("")}
                    </div>
                ) : error ? (
                    <p>An error occurred while loading organization pbx data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}