import React, {useState,useEffect} from 'react';
import { GetOrganizationDIDs } from '../../api/TelephonyAPI.js';
import LoadingShroud from '../App/LoadingShroud.js';
import NexusGrid from '../App/NexusGrid';
import TelephonyImportExport from './telephonyimportexport.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded,setLoaded]= useState(false);
    const [error,setError] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [dids,setDids] = useState([]);

    useEffect(()=> {
        loadItems(filterActive);
    },[]);

    const loadItems = (filterActive) => {
        const orgID = props.routeProps.match.params.id
        GetOrganizationDIDs({ content: { orgID: Number(orgID), active: !filterActive }})
            .then(res => {
                if (res != null) {
                    setDids(res.content);
                    setLoaded(true);
                    setFilterActive(filterActive);
                } else {
                    setLoaded(false);
                    setError(true);
                    setFilterActive(filterActive);
                }
            })
            .catch(() => {
                setError(true);
            })
    }
    const filterChanged = (e) => {
        loadItems(e.target.checked);
    }

        const headers = [
            { Header: "DID ID", accessor: "didid" },
            { Header: "Number", accessor: "number" },
            { Header: "Number", accessor: "number" },
            { Header: "Description", accessor: "description" },
            { Header: "Trunk", accessor: "trunk" }
        ];
        return (
            <div>
                <LoadingShroud Active={!loaded} />
                {loaded ? (
                    <div>
                        <div className="telephony-tab-heading">DIDs</div>
                        <TelephonyImportExport
                            routeProps={props.routeProps}
                            objectType={"DID"}
                            importCallback={() => loadItems()} />
                        <div className="telephony-object-filters">
                            <label>Show Inactive</label>
                            <input type="checkbox" onChange={(e) => filterChanged(e)} checked={filterActive} id="" />
                        </div>
                        <NexusGrid
                            linkTo={"/${tenant}/organizations/" + props.routeProps.match.params.id + "/did/:key"}
                            tableKey={"didid"}
                            hiddenColumns={["didid"]}
                            data={dids}
                            defaultSortBy={"Number"}
                            columns={headers}
                            filterHeaders={false}
                            pageMode={"none"}
                        />
                    </div>
                ) : error ? (
                    <p>An error occurred while loading DID data.</p>
                ) : null}
            </div>
        )
}