import React, { useState, useEffect } from 'react';
import { FilePond } from 'react-filepond';
import { GetCredentialFileList, GetCredentialFile, DeleteCredentialFile } from '../../api/CredentialAPI.js';
import { CredentialFileAPIRequest } from '../../api/FileAPIRequest.js';
import Loader from '../App/loader';

export default props => {
    const [loading, setLoading] = useState(true);
    const [credentialFiles, setCredentialFiles] = useState([]);

    useEffect(() => {
        loadCredentialFiles(props.credentialID);
    }, [props.credentialID]);

    const loadCredentialFiles = (credentialID) => {
        setLoading(true);
        GetCredentialFileList({content: credentialID})
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setCredentialFiles(res.content);
                    }
                }
            });
        setLoading(false);
    }

    const reloadFiles = () => {
        loadCredentialFiles(parseInt(props.credentialID))
    }

    const downloadFile = (credentialFileID, filename) => {
        GetCredentialFile(credentialFileID, filename);
    }

    const removeFile = (credentialFileID) => {
        setLoading(true);
        DeleteCredentialFile({content: credentialFileID})
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setCredentialFiles(res.content);
                    }
                }
            });
        setLoading(false);
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h3>Credential Files</h3>
            <table className="nextTable incidentTable">
                <tbody>
                    <tr key="0">
                        <th>File</th>
                        <th>Download</th>
                        {props.editing && <th>Delete</th>}
                    </tr>
                    {credentialFiles.map(cf =>
                        <tr key={cf.credentialFileID}>
                            <td>{cf.name}</td>
                            <td>
                                <div className="nexusButtonCommon" onClick={() => downloadFile(cf.credentialFileID, cf.name)}>
                                    <img src="/Images/Download.png" alt="[ Download ]" />Download
                                </div>
                            </td>
                            {props.editing && 
                                <td>
                                    <div className="nexusButtonCommon" onClick={() => removeFile(cf.credentialFileID)}>
                                        <img src="/Images/Remove.png" alt="[ Remove ]" />Remove
                                    </div>
                                </td>
                            }
                        </tr>
                    )}
                </tbody>
            </table>


            {props.editing &&
                <FilePond
                    className="mt-5"
                    allowMultiple={false}
                    server={CredentialFileAPIRequest({credentialID: props.credentialID, reload: reloadFiles})}
                />
            }   
        </div>
    )
}