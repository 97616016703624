import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toolbarConfig from '../App/toolbarconfig';

import { EditIncident } from '../../api/IncidentAPI';

const IncidentHeader = props => {
    const [editIncidentDate, setEditIncidentDate] = useState(new Date());
    const [editIncidentStatus, setEditIncidentStatus] = useState(0);
    const [editIncidentRequestor, setEditIncidentRequestor] = useState(0);
    const [editIncidentPriority, setEditIncidentPriority] = useState(0);
    const [editIncidentAssignedTo, setEditIncidentAssignedTo] = useState(0);
    const [editIncidentRequestType, setEditIncidentRequestType] = useState(0);
    const [editIncidentProject, setEditIncidentProject] = useState(0);
    const [editIncidentWorkType, setEditIncidentWorkType] = useState(0);
    const [editIncidentAsset, setEditIncidentAsset] = useState(0);
    const [editIncidentLocation, setEditIncidentLocation] = useState(0);
    const [editIncidentTeam, setEditIncidentTeam] = useState(0);
    const [editIncidentSummary, setEditIncidentSummary] = useState("");
    const [wtSelectionEnabled, setWTSelectionEnabled] = useState(true);
    const [error, setError] = useState("");
    useEffect(() => {
        setEditIncidentDate(new Date(Date.parse(props.incidentInfo.incidentDate)));
        setEditIncidentStatus(props.incidentInfo.statusID);
        setEditIncidentRequestor(props.incidentInfo.requestorID);
        setEditIncidentPriority(props.incidentInfo.priorityID);
        setEditIncidentAssignedTo(props.incidentInfo.assignedToID);
        setEditIncidentRequestType(props.incidentInfo.requestTypeID);
        setEditIncidentProject(props.incidentInfo.projectID);
        setEditIncidentWorkType(props.incidentInfo.workTypeID);
        setEditIncidentAsset(props.incidentInfo.assetID);
        setEditIncidentLocation(props.incidentInfo.locationID);
        setEditIncidentTeam(props.incidentInfo.teamID);
        setEditIncidentSummary(props.incidentInfo.summary)
        if (props.incidentInfo.projectID !== 0)
            setWTSelectionEnabled(false);
    }, [])
    useEffect(() => {
        if (parseInt(editIncidentProject, 10) === 0) {
            setWTSelectionEnabled(true);
            return;
        }
        let proj = props.projectList.filter(project =>
            project.itemID === parseInt(editIncidentProject, 10));
        if (proj.length === 1)
            setEditIncidentWorkType(proj[0].projectWorkTypeID);
        setWTSelectionEnabled(false);
    }, [editIncidentProject])
    const getTotalHours = () => {
        const totalHours = props.incidentInfo.notes.reduce((total, note) => {
            return total + note.hours
        }, 0);
        return totalHours;
    }
    const saveIncident = () => {
        props.setLoading(true);
        EditIncident({
            incidentID: props.incidentInfo.incidentID,
            incidentDate: editIncidentDate,
            statusID: parseInt(editIncidentStatus, 10),
            requestorID: parseInt(editIncidentRequestor, 10),
            priorityID: parseInt(editIncidentPriority, 10),
            assignedToID: parseInt(editIncidentAssignedTo, 10),
            requestTypeID: parseInt(editIncidentRequestType, 10),
            projectID: parseInt(editIncidentProject, 10),
            workTypeID: parseInt(editIncidentWorkType, 10),
            assetID: parseInt(editIncidentAsset, 10),
            locationID: parseInt(editIncidentLocation, 10),
            teamID: parseInt(editIncidentTeam, 10),
            summary: editIncidentSummary
        }).then(response => {
            if (!response.success) {
                console.log(response);
                setError(response.message);
                return;
            }
            return props.loadIncident()
        }).then(response => {
            props.setIncidentInfo(response.content);
            props.setEditIncident(false);
            props.setLoading(false);
        }).catch(err => {
            console.log(err);
            setError("An error occurred while updating this incident.");
            props.setLoading(false);
        })
    }
    return (
        <table className="nextTable incidentTable">
            <tbody>
                <tr>
                    <th colSpan="4" className="incidentTableHeader">
                        <h3>{props.incidentInfo.companyName} - #{props.incidentInfo.referenceID}</h3>
                    </th>
                </tr>
                <tr>
                    <th>Incident Date:</th>
                    <td style={{ maxWidth: "200px" }}>
                        {props.editIncident ? (
                            <DateTimePicker value={editIncidentDate}
                                onChange={date => setEditIncidentDate(date)}
                                clearIcon={null} />
                        ) : props.incidentInfo.incidentDateFormatted}
                    </td>
                    <th>Status:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentStatus}
                                onChange={e => setEditIncidentStatus(e.target.value)}>
                                {props.statuses.map(status =>
                                    <option key={status.itemID} value={status.itemID}>
                                        {status.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.statusName}
                    </td>
                </tr>
                <tr>
                    <th>Requestor:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentRequestor}
                                onChange={e => setEditIncidentRequestor(e.target.value)}>
                                {props.requestors.map(requestor =>
                                    <option key={requestor.itemID} value={requestor.itemID}>
                                        {requestor.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.requestorName}
                    </td>
                    <th>Priority:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentPriority}
                                onChange={e => setEditIncidentPriority(e.target.value)}>
                                {props.priorities.map(priority =>
                                    <option key={priority.itemID} value={priority.itemID}>
                                        {priority.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.priorityName}
                    </td>
                </tr>
                <tr>
                    <th>Assigned To:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentAssignedTo}
                                onChange={e => setEditIncidentAssignedTo(e.target.value)}>
                                {props.assignToList.map(contact =>
                                    <option key={contact.itemID} value={contact.itemID}>
                                        {contact.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.assignedToName}</td>
                    <th>Request Type:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentRequestType}
                                onChange={e => setEditIncidentRequestType(e.target.value)}>
                                {props.requestTypes.map(rt =>
                                    <option key={rt.itemID} value={rt.itemID}>
                                        {rt.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.requestTypeName}
                    </td>
                </tr>
                <tr>
                    <th>Project:</th>
                    <td style={{ maxWidth: "200px" }}>
                        {props.editIncident ? (
                            <select value={editIncidentProject}
                                style={{ maxWidth: "200px" }}
                                onChange={e => setEditIncidentProject(e.target.value)}>
                                {props.projectList.map(project =>
                                    <option key={project.itemID} value={project.itemID}>
                                        {project.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.projectName}
                    </td>
                    <th>Work Type:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentWorkType}
                                onChange={e => setEditIncidentWorkType(e.target.value)}
                                disabled={!wtSelectionEnabled}>
                                {props.workTypeList.map(wt =>
                                    <option key={wt.itemID} value={wt.itemID}>
                                        {wt.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.workTypeName}
                    </td>
                </tr>
                <tr>
                    <th>Budget Hours:</th>
                    <td>{props.incidentInfo.budgetHours.toFixed(2)}</td>
                    <th>Asset:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentAsset}
                                onChange={e => setEditIncidentAsset(e.target.value)}>
                                {props.assetList.map(asset =>
                                    <option key={asset.itemID} value={asset.itemID}>
                                        {asset.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.assetName}
                    </td>
                </tr>
                <tr>
                    <th>Location:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentLocation}
                                onChange={e => setEditIncidentLocation(e.target.value)}>
                                {props.locations.map(location =>
                                    <option key={location.itemID} value={location.itemID}>
                                        {location.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.locationName}
                    </td>
                    <th>Team:</th>
                    <td>
                        {props.editIncident ? (
                            <select value={editIncidentTeam}
                                onChange={e => setEditIncidentTeam(e.target.value)}>
                                {props.teamList.map(team =>
                                    <option key={team.itemID} value={team.itemID}>
                                        {team.itemName}
                                    </option>
                                )}
                            </select>
                        ) : props.incidentInfo.teamName}
                    </td>
                </tr>
                <tr>
                    <th colSpan="4" style={{ textAlign: "left" }}>Total Hours: {getTotalHours()}</th>
                </tr>
                <tr>
                    <td colSpan="4" style={{ padding: "8px" }}>
                        {props.editIncident ? (
                            <ReactQuill value={editIncidentSummary}
                                onChange={e => setEditIncidentSummary(e)}
                                modules={{ toolbar: toolbarConfig }} />
                        ) : (
                                <div dangerouslySetInnerHTML={{
                                    __html:
                                        DOMPurify.sanitize(props.incidentInfo.summary)
                                }} />
                            )}
                        {props.editIncident ? (
                            <div style={{ textAlign: "right" }}>
                                <input type="button" className="nexusButtonCommon" value="Save"
                                    onClick={() => saveIncident()} />
                                <input type="button" className="nexusButtonCommon" value="Cancel"
                                    onClick={() => props.setEditIncident(false)} />
                            </div>
                        ) : null}
                        {props.incidentInfo.statusName !== "Completed" ? (
                            <div>
                                {!props.editIncident &&
                                    props.incidentInfo.assignedToID === props.incidentInfo.myContactID &&
                                    props.statusName !== "Completed" ? (
                                        <input type="button" className="nexusButtonCommon" value="Edit Incident"
                                            onClick={() => props.setEditIncident(true)} />
                                    ) : null}
                                {!props.addNote && !props.editIncident ? (
                                    <input type="button" className="nexusButtonCommon" value="Add Note"
                                        onClick={() => props.setAddNote(true)} />
                                ) : null}
                            </div>
                        ) : null}
                        {error !== "" ? (
                            <div className="Error PaddedError">{error}</div>
                        ) : null}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default IncidentHeader;