import React,  { useState,useEffect } from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import { GetPerson } from '../../api/PeopleAPI';
import Loader from '../App/loader';
import TabView from '../App/tabview';
import TabInfo from './Tabs/tabinfo';
import TabOrganizations from './Tabs/taborganizations';
import TabLogin from './Tabs/tablogin';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() =>{
        const personID = props.routeProps.match.params.id;
        GetPerson({personID: Number(personID)}).then((res)=>{
            if(res.content[0].personID != null){
                setData(res.content[0]);
                setLoaded(true);
            }
        }).catch(()=>{
            setError(true);
        });
    },[])

    const tabListBaseURL =
            '/' + tenant +'/people/' + props.routeProps.match.params.id;
    const tabList = [
            {
                label: 'Person Info',
                url: tabListBaseURL + '/info',
                default: true,
            },
            { label: 'Organizations', url: tabListBaseURL + '/organizations' },
        { label: 'Contact Info', url: tabListBaseURL + '/contact' },
        { label: 'User Logins', url: tabListBaseURL + '/logins' },
        ];
        return (
            <div className="orgContainer">
                <Link className="whiteLink" to={`/${tenant}/people/`}>
                    <i className="fas fa-chevron-left"></i> People List
                </Link>
                {loaded ? (
                    <div className="peopleContainerInner">
                        <h2>
                            {data.firstName +
                                ' ' +
                            data.lastName}
                        </h2>
                        <TabView tabs={tabList}>
                            <Switch>
                                <Route
                                    path={`/${tenant}/people/:id/info`}
                                    render={(routeProps) => (
                                        <TabInfo
                                            routeProps={routeProps}
                                            person={data}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/${tenant}/people/:id/organizations`}
                                    render={(routeProps) => (
                                        <TabOrganizations
                                            routeProps={routeProps}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/${tenant}/people/:id/logins`}
                                    render={(routeProps) => (
                                        <TabLogin
                                            routeProps={routeProps}
                                        />
                                    )}
                                />
                            </Switch>
                        </TabView>
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }