import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../Redux/WebSocket";
//import ProgressBar from "@ramonak/react-progress-bar";
import { ProgressBar } from "react-bootstrap";
import "../../css/AssetStatusDisplay.css";

const AssetStatusDisplay = (props) => {
  const socket = React.useContext(WebSocketContext);
  const status = useSelector(
    (state) => state.WebSocketReducer.SelectedAssetStatus
  );
  const statusRequestMessage = JSON.stringify({
    type: " Nexus.Data.Classes.Models.XBert.WebSocketModels.AssetStatusRequest",
    body: { AssetID: parseInt(props.AssetID) },
  });
  useEffect(() => {
    socket.send(statusRequestMessage);
    const interval = setInterval(() => {
      socket.send(statusRequestMessage);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log(status);
  }, [status]);

  const displayDriveStatuses = () => {
    if (status.DriveStatuses) {
      return status.DriveStatuses.map((drive, index) => {
        let value = (drive.usedDiskSpace / drive.diskCapacity).toFixed(2) * 100;
        return (
          <div className="Drive-Display" key={index}>
            <div>
              <p className="drive-Label">{drive.name}</p>
              {/* <ProgressBar
                completed={value}
                bgColor="#439D42"
                width="50%"
                labelAlignment="center"
                margin="auto"
              /> */}
              <div className="bootstrap-wrapper">
                <ProgressBar
                  now={value}
                  label={`${value}%`}
                  className="asset-status-progress-bar w-75 m-auto"
                />
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const StatusDisplay = () => {
    return (
      <div className="AssetStatusDisplay">
        <div className="progressBar-Container">
          <p className="Label">CPU Utilization:</p>
          <div>
            {/* <ProgressBar
              completed={status.CpuUsage}
              bgColor="#439D42"
              width="75%"
              labelAlignment="center"
              margin="auto"
            /> */}
            <div className="bootstrap-wrapper">
              <ProgressBar
                now={status.CpuUsage}
                label={`${status.CpuUsage}%`}
                className="asset-status-progress-bar"
              />
            </div>
          </div>
        </div>
        <div className="progressBar-Container">
          <p className="Label">Memory Utilization:</p>
          <p>Virtual Memory Utilization: {status.VirtualMemory} bytes</p>
          <div className="bootstrap-wrapper">
              <ProgressBar
                  now={status.MemoryUsage}
                  label={`${status.MemoryUsage}%`}
                  className="asset-status-progress-bar"
                />
            </div>
          <div>
            {/* <ProgressBar
              completed={status.MemoryUsage}
              bgColor="#439D42"
              width="75%"
              labelAlignment="center"
              margin="auto"
            /> */}
            
          </div>
        </div>
        <div className="Drives-Container">
          <p className="Label">Disk Utilization:</p>
          {displayDriveStatuses()}
        </div>
      </div>
    );
  };

  const LoaderOrStatusDisplay = () => {
    if (Object.keys(status).length) {
      return StatusDisplay();
    } else {
      return <p>retrieving status details from Asset {props.AssetID}</p>;
    }
  };

  

  return(
    <>
      {LoaderOrStatusDisplay()}
      
    </>
  ) 
};

export default AssetStatusDisplay;
