import React, { useState, useEffect } from 'react';

const IncidentSearchAggregate = props => {
    const [searchAggregate, setAggregate] = useState(props.aggregate);
    const [searchAggregateTotal, setAggregateTotal] = useState({
        name: "Total:",
        hours: 0,
        invoiceHours: 0,
        creditHours: 0,
        billableHours: 0
    });

    useEffect(() => {
        var tempTotal = getBlankAggregateTotal();
        for (var i = 0; i < props.aggregate.length; i++) {
            var item = props.aggregate[i];
            tempTotal.hours += item.hours;
            tempTotal.invoiceHours += item.invoiceHours;
            tempTotal.creditHours += item.creditHours;
            tempTotal.billableHours += item.billableHours;
            tempTotal.nonBillableHours += item.nonBillableHours;
        }
        setAggregateTotal(tempTotal);
        setAggregate(props.aggregate);
    }, [props])
    const getBlankAggregateTotal = () => {
        return {
            name: "Total:",
            hours: 0,
            invoiceHours: 0,
            creditHours: 0,
            billableHours: 0,
            nonBillableHours: 0
        };
    }
    return (
        <div>
            {searchAggregate.length > 0 ?
                <table className="nextTable search-aggregate">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Total Hours</th>
                            <th>Accounting Closed</th>
                            <th>Credited Hours</th>
                            <th>Billable Hours</th>
                            <th>Non-Billable Hours</th>
                            <th>Finalized</th>
                        </tr>
                        {searchAggregate.map((company, idx) =>
                            <tr key={idx}>
                                <td>{company.companyName}</td>
                                <td>{company.hours}</td>
                                <td>{company.invoiceHours}</td>
                                <td>{company.creditHours}</td>
                                <td>{company.billableHours}</td>
                                <td>{company.nonBillableHours}</td>
                                <td>{company.hours === company.invoiceHours ? "Yes" : "No"}</td>
                            </tr>
                        )}
                        <tr className="search-aggregate-footer">
                            <td>{searchAggregateTotal.name}</td>
                            <td>{searchAggregateTotal.hours.toFixed(2)}</td>
                            <td>{searchAggregateTotal.invoiceHours.toFixed(2)}</td>
                            <td>{searchAggregateTotal.creditHours.toFixed(2)}</td>
                            <td>{searchAggregateTotal.billableHours.toFixed(2)}</td>
                            <td>{searchAggregateTotal.nonBillableHours.toFixed(2)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                : null}
        </div>
    )
}

export default IncidentSearchAggregate;