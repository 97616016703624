import React from 'react';

export default props => {
        return (
            <div className="cs-loader">
                <div className="cs-loader-inner">
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                </div>
            </div>
        );
}