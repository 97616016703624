import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect } from "react-router-dom";
import TabBackup from "../Tabbackup";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  GetBackupSchedules,
  PostBackupSchedule,
  DeleteBackupSchedule,
} from "../../../api/BackupsAPI";
import DateTimePicker from "react-datetime-picker";
import FileSystemExplorer from "../FileSystemExplorer";
import "../../../css/BackupScheduleEdit.css";
import BackupTargetList from "./BackupTargetList";
import moment from "moment";
import NexusModal from "../../App/NexusModal";
import { Alert } from "react-bootstrap";
import { useSelector } from 'react-redux';

const BackupScheduleEdit = (props) => {
  const tenant = useSelector(state => state.TenantReducer.Tenant);
  const { backupID } = useParams();
  const { id } = useParams(); //Asset ID
  const { url } = useRouteMatch();
  const [storageVolumes, setStorageVolumes] = useState([]);
  const [selectedStorageVolume, setStorageVolume] = useState({});
  const [backupTargets, setBackupTargets] = useState([]);
  const [backupSchedule, setBackupSchedule] = useState({});
  const [successMessage, setSuccessMessage] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [form, setform] = useState({
    assetID: parseInt(id),
    backupID: 0,
    backupFrequency: 1,
    backupStart: new Date(),
    backupType: "Incremental",
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    blobVersionRetention: 1,
    throttle: 1,
    sqlBackupEnabled: false,
    storageVolume: selectedStorageVolume,
    backupTargets: backupTargets,
  });

  useEffect(() => {
    if(backupID != 'new') {
        GetBackupSchedules({ AssetID: id, BackupID: backupID }).then((schedule) => {
        if (schedule) {
          setBackupSchedule(schedule.backupSchedules[0]);
          setStorageVolumes(schedule.storageVolumes);
          setBackupTargets(schedule.backupTargets);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(backupSchedule).length !== 0) {
      setform({
        ...backupSchedule,
        backupStart: new Date(backupSchedule.backupStart),
        storageVolume: selectedStorageVolume,
      });
    }
  }, [backupSchedule]);

  useEffect(() => {
    setform({
      ...form,
      storageVolume: selectedStorageVolume,
    });
  }, [selectedStorageVolume]);

  const setTargets = (addOrRemoveString, newTarget) => {
    let newValue = form.backupTargets;
    if (addOrRemoveString == "add") {
      newValue.push({ target: newTarget, exclusions: [] });
    } else if (addOrRemoveString == "remove") {
      newValue = newValue.filter((target) => target.target != newTarget);
    }

    setform({
      ...form,
      backupTargets: newValue,
    });
  };

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const fieldIsChecked = (field) => {
    if (form[field] === true || form[field] === 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheck = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    PostBackupSchedule({
      ...form,
      throttle: parseInt(form.throttle),
    })
      .then((response) => {
        if(response.success) {
          setSuccessMessage(response);
          if(url.includes('/new')) {
            props.setEditType({
              type: 'created',
              backupID: backupID
            });
          } else {
            props.setEditType({
              ...props.EditType,
              type: '',
            });
          }
        }
      })
      .then((x) => {
          GetBackupSchedules({ AssetID: id }).then((schedules) =>
          props.setBackupScheduleList(schedules.backupSchedules)
        );
      });
  };

  const handleDelete = () => {
    let payload = { ID: parseInt(backupID) };
    if(isNaN(payload.ID)) {
      props.setEditType({
        type: 'deleted',
        backupID: backupID
      });
    } else {
      DeleteBackupSchedule(payload)
      .then((response) => {
        setSuccessMessage(response);
        setDeleteModal(false);
        if(response.success) {
          props.setEditType({
            type: 'deleted',
            backupID: backupID
          });
        }
      }).then((x) => {
          GetBackupSchedules({ AssetID: id }).then((schedules) =>
          props.setBackupScheduleList(schedules.backupSchedules)
        );
      });
    }
  };

  const displaySuccessMessage = (e) => {
    if(successMessage.success == true || successMessage.success == false)
    {
      return (
        <div className="bootstrap-wrapper">
          <Alert variant={successMessage.success ? 'success' : 'danger' }
          dismissible
          onClose={() => setSuccessMessage({...successMessage, success: null})}
          >
            {successMessage.message}
          </Alert>
        </div>
      );
    }
  };

  const AddExclusion = (targetPath, exclusionPath) => {
    let target = form.backupTargets.find((x) => x.target == targetPath);
    if (target) {
      target.exclusions.push(exclusionPath);
    }

    setform({
      ...form,
      backupTargets: form.backupTargets,
    });
  };

  const RemoveExclusion = (targetPath, exclusionPath) => {
    let target = form.backupTargets.find((x) => x.target == targetPath);

    if (target) {
      const index = target.exclusions.indexOf(exclusionPath);
      if (index > -1) {
        target.exclusions.splice(index, 1);
        setform({
          ...form,
          backupTargets: form.backupTargets,
        });
      }
    }
  };

  return (
    <>
    <Switch>
      {props.EditType.type && props.EditType.backupID == backupID
      ? <Redirect to={"/" + tenant + "/assets/" + id + "/backup"} /> : null}
    </Switch>
      <BreadcrumbsItem weight={10} to={`${tenant}/assets/${id}/backup/${backupID}`} label={`Backup ${moment(form.backupStart).format('LLL')}`} />
      <div className="Form-Container">
        <form onSubmit={handleSubmit}>
          <div className="flex-container" style={{justifyContent: 'left' }}>
            <div className="flex-item" style={{width: '100%'}}>
              {displaySuccessMessage()}
              <input type="submit" value="SAVE" className="SubmitButton"></input>
              <input
                type="button"
                value="Delete Backup"
                className="DeleteButton nexusButtonCommon"
                onClick={() => setDeleteModal(true)}
              ></input>
              <NexusModal stateValue={deleteModal} stateFunctionClose={() => setDeleteModal(false)} title={"Delete"} closeText="Delete"
                          closeAction={() => {handleDelete()}} width='50%' height='auto'>
                <div className="bootstrap-wrapper">
                  <p className="text-dark text-center m-0">Are you sure you want to delete this backup schedule? This cannot be undone.</p>
                </div>
              </NexusModal>
            </div>
            <div className="flex-item" style={{width: '100%'}}>
              <p className="bold">Start Time:</p>
              <DateTimePicker
                value={form.backupStart}
                onChange={(date) => setform({ ...form, backupStart: new Date(date) })}
                clearIcon={null}
              />
            </div>
            <div className="flex-item">
              <label>
                <p className="bold">Frequency:</p>
              </label>
              <input
                type="number"
                name="backupFrequency"
                defaultValue={form.backupFrequency}
                onChange={handleChange || 0}
              ></input>
              <br />


              <label>
                <p className="bold">Threshold of Copies to Keep:</p>
              </label>
              <input
                type="number"
                name="blobVersionRetention"
                min={0}
                onChange={handleChange}
                value={Number(form.blobVersionRetention)}
              ></input>
              <br />

              <label>
                <p className="bold">Throttle:</p>
              </label>
              <input
                type="number"
                name="throttle"
                min={0}
                onChange={handleChange}
                value={form.throttle || 0}
              ></input>
              <br />

              <div className="input-container">
                <p className="float-left">SQL Backup Enabled:</p>
                <input
                  className="checkBox"
                  type="checkbox"
                  name="sqlBackupEnabled"
                  onChange={handleCheck}
                  checked={fieldIsChecked("sqlBackupEnabled")}
                ></input>
              </div>
              <br />
              <div className="backupDaysContainer">
                <h3>Backup Days:</h3>
              </div>
              <div className="checkboxContainer">
                <div className="input-container">
                  <p className="float-left">Sunday</p>
                  <input
                    className="checkBox"
                    name="sunday"
                    type="checkbox"
                    checked={fieldIsChecked("sunday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Monday</p>
                  <input
                    className="checkBox"
                    name="monday"
                    type="checkbox"
                    checked={fieldIsChecked("monday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Tuesday</p>
                  <input
                    className="checkBox"
                    name="tuesday"
                    type="checkbox"
                    checked={fieldIsChecked("tuesday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Wednesday</p>
                  <input
                    className="checkBox"
                    name="wednesday"
                    type="checkbox"
                    checked={fieldIsChecked("wednesday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Thursday</p>
                  <input
                    className="checkBox"
                    name="thursday"
                    type="checkbox"
                    checked={fieldIsChecked("thursday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Friday</p>
                  <input
                    className="checkBox"
                    name="friday"
                    type="checkbox"
                    checked={fieldIsChecked("friday")}
                    onChange={handleCheck}
                  ></input>
                </div>
                <div className="input-container">
                  <p className="float-left">Saturday</p>
                  <input
                    className="checkBox"
                    name="saturday"
                    type="checkbox"
                    checked={fieldIsChecked("saturday")}
                    onChange={handleCheck}
                  ></input>
                </div>
              </div>
            </div>
            <div className="flex-item" style={{marginBottom: '2rem'}}>
              <BackupTargetList
                targets={form.backupTargets}
                setTargets={setTargets}
              />
            </div>
          </div>
          <FileSystemExplorer
            mode="backupTargetEditor"
            setStorageVolume={setStorageVolume}
            storageVolumes={storageVolumes}
            backupTargets={form.backupTargets}
            setBackupTargets={setTargets}
            AddExclusion={AddExclusion}
            RemoveExclusion={RemoveExclusion}
          />
          <br></br>
        </form>
      </div>
    </>
  );
};

export default BackupScheduleEdit;
