import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: 'ui',
    initialState: { loading: false, notification: null },
    reducers: {
        toggleLoader(state, action) {
            state.loading = action.payload.loading;
        },
        showNotification(state, action) {
            state.notification = {
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message,
            };
        },
        clearNotification(state) {
            state.notification = null;
        },
    },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
