import React,  { useState,useEffect } from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import { GetTenant } from '../../api/TenantAPI';
import Loader from '../App/loader';
import TabView from '../App/tabview';
import TabInfo from './Tabs/tabinfo';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() =>{
        const tenantID = props.routeProps.match.params.id;
        GetTenant(Number(tenantID)).then((res) => {
            if(res.success == true){
                setData(res.content);
                setLoaded(true);
                setError(false);
            }
        }).catch(()=>{
            setError(true);
        });
    },[])

    const tabListBaseURL =
            '/' + tenant +'/tenants/' + props.routeProps.match.params.id;
    const tabList = [
            {
                label: 'Tenant Info',
                url: tabListBaseURL + '/info',
                default: true,
            },
            //{ label: 'Organizations', url: tabListBaseURL + '/organizations' },
            //{ label: 'Contact Info', url: tabListBaseURL + '/contact' },
        ];
        return (
            <div className="orgContainer">
                <Link className="whiteLink" to={`/${tenant}/tenants`} >
                    <i className="fas fa-chevron-left"></i> Tenant List
                </Link>
                {loaded ? (
                    <div className="tenantContainerInner">
                        <h2>
                            {data.name}
                        </h2>
                        <TabView tabs={tabList}>
                            <Switch>
                                <Route
                                    path={`/${tenant}/tenants/:id/info`}
                                    render={(routeProps) => (
                                        <TabInfo
                                            routeProps={routeProps}
                                            tenant={data}
                                        />
                                    )}
                                />
                                {/*<Route*/}
                                {/*    path={`/${tenant}/people/:id/organizations`}*/}
                                {/*    render={(routeProps) => (*/}
                                {/*        <TabOrganizations*/}
                                {/*            routeProps={routeProps}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </Switch>
                        </TabView>
                    </div>
                ) : error ? (
                    <p>An error occurred while loading tenant data.</p>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }