import React, { useState, useEffect } from 'react';
import Loader from './loader.js';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, withRouter, Link } from 'react-router-dom';

import { Authenticate, DuoAuthenticate } from '../../api/NexusAPI';
import { CheckTenantURI } from '../../api/TenantAPI';
//import { GetOrganizationByTenant } from '../../api/OrganizationAPI';
//import { CheckPersonEmail } from '../../api/PeopleAPI.js';

const LoginForm = (props) => {
  let { tenantURI } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  //const [validUN, setValidUN] = useState(false);

  const [tenantName, setTenantName] = useState('');
  const [tenantLogo, setTenantLogo] = useState(null);
  const tenant = useSelector((state) => state.TenantReducer.Tenant);
  //const [tenantURI, setTenantURI] = useState("");
  //const [orgID, setOrgID] = useState(-1);
  const dispatch = useDispatch();
  let history = useHistory();
  //const [timer, setTimer] = useState(null);

  const login = () => {
    setLoading(true);
    Authenticate(username, password, false, null, -1, null, false, false, null)
      .then((result) => {
        if (
          result.content.authenticated &&
          result.content.token != null &&
          result.content.mustChangePassword === false
        ) {
          //if (result.content.duoAuth) {
          //    //DuoAuthenticate();
          //    //setLoading(false);
          //    DuoAuthenticate().then((res) => {
          //        console.log("duo auth");
          //        console.log(res);
          //        if (rememberMe) {
          //            localStorage.setItem("nexusToken", result.content.token);
          //            localStorage.setItem("nexusID", result.content.id);
          //            localStorage.setItem("nexusOrg", orgID);
          //        } else {
          //            localStorage.removeItem("nexusToken");
          //            localStorage.removeItem("nexusID");
          //            localStorage.removeItem("nexusOrg");
          //            sessionStorage.setItem("nexusToken", result.content.token);
          //            sessionStorage.setItem("nexusID", result.content.id);
          //            sessionStorage.setItem("nexusOrg", orgID);
          //        }
          //        setLoading(false);
          //        props.checkAuth();
          //    }).catch(info => {
          //        console.log("duo auth error");
          //        console.error(info);
          //        setMessage("Something occurred while trying to connect to Duo.")
          //        setLoading(false);
          //    });
          //}
          if (rememberMe) {
            localStorage.setItem('nexusToken', result.content.token);
            localStorage.setItem('nexusID', result.content.id);
            localStorage.setItem('nexusOrg', result.content.orgID);
          } else {
            localStorage.removeItem('nexusToken');
            localStorage.removeItem('nexusID');
            localStorage.removeItem('nexusOrg');
            sessionStorage.setItem('nexusToken', result.content.token);
            sessionStorage.setItem('nexusID', result.content.id);
            sessionStorage.setItem('nexusOrg', result.content.orgID);
          }
          setLoading(false);
          props.checkAuth();
        } else {
          //var message = "Password must be changed.  You will be redirected in a few moments."
          sessionStorage.setItem('nexusOrg', result.content.orgID);
          var message = 'Invalid password.';
          if (result.content.mustChangePassword) {
            message =
              'Password must be changed.  You will be redirected in a few moments.';
          } else if (
            result.content.mustChangePassword &&
            result.content.authenticated
          ) {
            message =
              'Password must be changed.  You will be redirected in a few moments.';
          } else if (result.content.lockedOut) {
            message =
              'Your account is locked out.  Please contact support to unlock your account.';
          } else if (result.content.multiInvalidLogins) {
            message =
              'Too many failed login attempts.  Please try again in 24 hours.';
          }
          setMessage(message);
          setLoading(false);
        }
      })
      .catch((info) => {
        console.error(info);
      });
  };

  useEffect(() => {
    setTimeout(function () {
      if (
        message ===
        'Password must be changed.  You will be redirected in a few moments.'
      ) {
        history.push({
          pathname: `/${tenant}/ChangePswd`,
          state: { username: username },
        });
      }
    }, 5000);
  }, [message]);

  useEffect(() => {
    setLoading(true);
    CheckTenantURI(tenantURI)
      .then((response) => {
        setLoading(false);
        // console.log(response);
        if (response.success) {
          updateTenant(tenantURI);
          let ti = response.content;
          setTenantName(ti.name);
          setTenantLogo(ti.tenantLogo);
        } else {
          history.push('/');
        }
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      setTenantName('');
      setTenantLogo(null);
      setLoading(false);
    };
  }, [props, tenantURI, history]);

  const formSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const setUN = (e) => {
    setUsername(e.target.value);
    //if (username !== e.target.value) {
    //    handleTimeout(e);
    //}
  };

  //const handleTimeout = (e) => {
  //    if (timer) {
  //        clearTimeout(timer);
  //        setTimer(null);
  //    }
  //    setTimer(setTimeout(() => {
  //        getOrgs(e);
  //    }, 1000));
  //}

  const updateTenant = (payload) => {
    dispatch({ type: 'SET_TENANT', payload: payload });
  };

  return (
    <div className="nexusForm loginForm">
      {loading ? (
        <div className="NexusLoading">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="loginLogo">
            {tenantLogo !== null ? (
              <img
                src={`data:image/jpeg;base64,${tenantLogo}`}
                alt={tenantName}
                title={tenantName}
                width="35%"
                height="35%"
              />
            ) : (
              <img
                src="Images/logo-placeholder.png"
                alt={tenantName}
                title={tenantName}
              />
            )}
          </div>
          <h2>Login</h2>
          <form
            className="nexusFormInner"
            method="post"
            onSubmit={(e) => {
              formSubmit(e);
            }}
          >
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <th>
                    <label className="nexusFormLabel">Username:</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="Username"
                      id="Username"
                      value={username}
                      onChange={(e) => setUN(e)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label className="nexusFormLabel">Password:</label>
                  </th>
                  <td>
                    <input
                      type="password"
                      name="Password"
                      id="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th />
                  <td>
                    <Link to={`/${tenant}/forgot`}>Forgot password?</Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="nexusFormSubmit">
              <div className="nexusFormSpacer">
                <strong>Remember Me:</strong>
                <input
                  type="checkbox"
                  name="chkRemember"
                  id="chkRemember"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              </div>
              <input
                type="submit"
                name="btnLogin"
                id="btnLogin"
                value="Login"
                className="nexusButton"
              />
            </div>
          </form>
          <div className="Error">{message}</div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
