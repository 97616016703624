import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../App/loader';
import { sendTenantData } from '../../../Redux/cart-actions';
import { CRUD_ENUM } from '../../../enums/crud.js';
import * as Validation from '../../../utils/input-validators';
import { GetOrganizations } from '../../../api/OrganizationAPI';
import EditableTableCell from '../../UI/EditableTableCell';
import { EditTenant, DisableTenant } from '../../../api/TenantAPI';
import Notification from '../../UI/Notification';
import { useHistory, Link } from 'react-router-dom';

const TabInfo = (props) => {
    const {
        tenantID,
        name,
        active,
        expiration,
        uri,
        defaulT,
        emailTemplate,
        organizationID,
        tenantLogo,
    } = props.tenant;

    const initialUserState = {
        tenantID: tenantID,
        name: name,
        active: active,
        expiration: expiration,
        uri: uri,
        default: defaulT,
        emailTemplate: emailTemplate,
        organizationID: organizationID,
        tenantLogo: tenantLogo,
    };

    const onChangeHandler = (e) => {
        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });
    };

    const tenant = useSelector((state) => state.TenantReducer.Tenant);
    const history = useHistory();
    const [formInputs, setFormInputs] = useState(initialUserState);
    const dispatch = useDispatch();
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [displayNotification, setDisplayNotification] = useState(false);
    const [status, setStatus] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const canUserEditPerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );
    const [logoUpload, setLogoUpload] = useState(null);
    const [validImgHeightWidth, setValidImgHeightWidth] = useState(false);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            if (file !== null) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = error => reject(error);
            }
        });
    };

    const onFileChange = (e) => {
        setLogoUpload("");
        getBase64(e.target.files[0]).then((data) => {
            setLogoUpload(e.target.files[0]);
            var image = new Image();
            image.src = data;
            image.onload = function () {
                if (this.height < 181 && this.width < 181) {
                    setValidImgHeightWidth(true);
                } else {
                    setValidImgHeightWidth(false);
                }
            };
        });
    };

    /**
     * Handler for form submission. Checks the validity of the form controls,
     * then submits if all changed values are valid; else, notify user of errors and
     * remain on page
     * @param {*} e The triggering form element submission
     */
    const formSubmitClickedHandler = (e) => {
        e.preventDefault();
        if (logoUpload !== "" && logoUpload !== null) {
            getBase64(logoUpload).then((logoResult) => {
                if (validImgHeightWidth === false) {
                    setStatus("Image Height and Width must not exceed 180px.");
                    setSubmitted(true);
                    setDisplayNotification(true);
                    return;
                }
                else if (validImgHeightWidth === true) {
                    const payload = {
                        tenant: formInputs,
                        logo: logoResult,
                    };

                    EditTenant(payload).then((data) => {
                        if (data.success === true) {
                            setIsFormEditable(false);
                            setSubmitted(true);
                            setStatus('success');
                            setDisplayNotification(true);
                        } else {
                            setSubmitted(true);
                            setStatus(data.message);
                            setDisplayNotification(true);
                        }
                    });
                }
            })
        } else {
            const payload = {
                tenant: formInputs,
                logo: "",
            };

            EditTenant(payload).then((data) => {
                if (data.success === true) {
                    setIsFormEditable(false);
                    setSubmitted(true);
                    setStatus('success');
                    setDisplayNotification(true);
                } else {
                    setSubmitted(true);
                    setStatus(data.message);
                    setDisplayNotification(true);
                }
            });
        }
    };

    const editTenantInfoToggle = (
        <>
            <div className="form-group">
                {isFormEditable ? (
                    <button
                        className="nexusButtonCommon"
                        type="button"
                        onClick={() => setTenantInactive()}
                    >
                        Set Tenant Inactive
                    </button>
                ) : (
                    <button
                        className="btn btn-warning nexusButtonCommon"
                        type="button"
                        onClick={() => setIsFormEditable(!isFormEditable)}
                    >
                        Edit Tenant Info
                    </button>
                )}
                <hr />
            </div>
        </>
    );

    const resetClickedHandler = () => {
        setIsFormEditable(false);
        setFormInputs(initialUserState);
    };

    const setTenantInactive = () => {
        const payload = {
            content: tenantID,
        };
        //dispatch(sendTenantData(payload, CRUD_ENUM.DELETE));
        DisableTenant(payload);    // do then/catch statement?
        setIsFormEditable(!isFormEditable);
        history.push(`/${tenant}/tenants/`);
    };

    const infoTable = (
        <div>
            {
                isLoaded ? (
                    <div>
                        {(tenantLogo !== null && isFormEditable === false) && (
                            //  width="35%" height="35%"
                            <Link to={`/${tenant}/Organizations/${organizationID}/info`} >
                                <img src={`data:image/jpeg;base64,${tenantLogo}`} alt={name} title={name} />
                            </Link>
                        )}
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            className="infoTable"
                        >
                            <tbody>
                                <tr>
                                    <th>Organization:</th>
                                    <td>
                                        <Link className="orgTenantLink" to={`/${tenant}/Organizations/${organizationID}/info`} >
                                            {name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Active:</th>
                                    {active === true ? (<td>True</td>) : (<td>False</td>) }
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Expiration"
                                        type="date"
                                        className="form-control"
                                        id="inputExpiration"
                                        name="expiration"
                                        placeholder={formInputs.expiration}
                                        defaultValue={formInputs.expiration}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="URI"
                                        type="text"
                                        className="form-control"
                                        id="inputURI"
                                        name="uri"
                                        placeholder={formInputs.uri}
                                        defaultValue={formInputs.uri}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                {isFormEditable === true && (
                                    <tr>
                                        <th>Logo Image:</th>
                                        <td>
                                            <input
                                                type="file"
                                                name="logo"
                                                id="logo"
                                                className="form-control"
                                                accept="image/png, image/jpeg"
                                                onChange={(e) => onFileChange(e)}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {/* TODO?: Decide whether or not how to show Email Template */}
                                {isFormEditable === true ? (
                                    <tr>
                                        <th>Email Template:</th>
                                        <td>
                                            <input
                                                type="text"
                                                name="emailTemplate"
                                                id="emailTemplate"
                                                className="form-control"
                                                placeholder={formInputs.emailTemplate}
                                                defaultValue={formInputs.emailTemplate}
                                                onChange={(e) => onChangeHandler(e)}
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                        <tr>
                                            <th>Email Template:</th>
                                            <td>{emailTemplate}</td>
                                        </tr>
                                )}

                                {/* TODO?: Default bool doesn't show properly if doing it the way with Active bool */}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    // : this.state.error ? (
                    <p>An error occurred while loading company data.</p>
                )
                // ) : (
                //     <Loader />
                // )
            }
        </div>
    );

    const formSubmitControls = (
        <>
            <hr />
            {isFormEditable ? (
                <div className="form-group">
                    <button
                        className="btn btn-danger nexusButtonCommon"
                        onClick={resetClickedHandler}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary nexusButtonCommon"
                        disabled={!isFormEditable}
                    >
                        Save
                    </button>
                </div>
            ) : null}
        </>
    );

    const content = (
        <form onSubmit={formSubmitClickedHandler}>
            {canUserEditPerson ? editTenantInfoToggle : null}
            {infoTable}
            {canUserEditPerson ? formSubmitControls : null}
            {submitted && displayNotification ? (
                <Notification
                    status={status}
                    title="Submission Result"
                    message={status}
                    dismiss={() => setDisplayNotification(false)}
                ></Notification>
            ) : null}
        </form>
    );
    return content;
};

export default TabInfo;
