import React, {useEffect, useState} from 'react'
import { useHistory } from "react-router-dom";
import { CreateAssetTunnel} from "../../../api/AssetAPI";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useSelector } from 'react-redux';

const NewAssetTunnel = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const history = useHistory();
    const AssetID = props.routeProps.match.params.id;
    const [status, setStatus] = useState("");;
    const [form, setForm] = useState({
        description: "",
        sourceAddress: "Key Master",
        sourcePort: 0,
        destinationAddress: "localhost",
        destinationPort: 3389
    })
    const [portSetting, setPortSetting] = useState(3389);
    const [portTemplate, setPortTemplates] = useState([
        { name: "Custom", value: -1},
        { name: "RDP", value: 3389 },
        { name: "HTTP", value: 80 },
        { name: "HTTPS", value: 443 },
        { name: "MS SQL", value: 1433 },
        { name: "MySQL", value: 3306 }
    ])

    const CreateTunnel = () => {
        if (ValidateForm()) {
            let tunnel = { ...form, assetID: Number(AssetID)}
            CreateAssetTunnel({ "content": tunnel }).then(response => {
                if (response.success) {
                    history.goBack();
                } else {
                    setStatus(response.message);
                }
            });
        }
    }
    const ValidateForm = () => {
        if (form.description.length === 0) {
            setStatus("Description is required!");
            return false;
        }
        if (form.destinationAddress.length === 0) {
            setStatus("Destination address is required!");
            return false;
        }
        if (form.destinationPort <= 0 || form.destinationPort > 65535) {
            setStatus("Please provide a valid destination port");
            return false;
        }

        setStatus("");
        return true;
    }
    const ShowStatus = () => {
        if (status.length > 0)
            return <p>{status}</p>
        else
            return null;
    }
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: (e.target.name.indexOf("Port") > 0 ? Number(e.target.value) : e.target.value)
        })
    }
    const setPortTemplate = (pSetting) => {
        if (pSetting > 0) {
            setForm({
                ...form,
                destinationPort: pSetting
            })
        }
        setPortSetting(pSetting)
    }
    
    return (
        <>
            <BreadcrumbsItem weight={9} to={`/${tenant}/assets/${AssetID}/tunnels/new`} label={`New Tunnel`} />
            <div>
                <h3>Add Asset Tunnel</h3>
                <table cellPadding="0" cellSpacing="0" className="infoTable">
                    <tbody>
                        <tr>
                            <th>Port Setting:</th>
                            <td>
                                <select value={portSetting} onChange={e => setPortTemplate(parseInt(e.target.value, 10))}>
                                    {portTemplate.map(pt =>
                                        <option key={pt.value} value={pt.value}>
                                            {pt.name}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Description:</th>
                            <td><input type="text" value={form.description} onChange={(e) => handleChange(e)} name="description" /></td>
                        </tr>
                        <tr>
                            <th>Source Address:</th>
                            <td><input type="text" value={form.sourceAddress} name="sourceAddress" disabled /></td>
                        </tr>
                        <tr>
                            <th>Source Port:</th>
                            <td><input type="number" value={form.sourcePort} name="sourcePort" disabled /></td>
                        </tr>
                        <tr>
                            <th>Destination Address:</th>
                            <td><input type="text" value={form.destinationAddress} onChange={(e) => handleChange(e)} name="destinationAddress"/></td>
                        </tr>
                        <tr>
                            <th>Destination Port:</th>
                            <td><input type="number" value={form.destinationPort} onChange={(e) => handleChange(e)} name="destinationPort" disabled={portSetting > 0}/></td>
                        </tr>
                    </tbody>
                </table>
                <hr size="3" />
                {ShowStatus()}
                <p>
                    <button className="nexusButtonCommon" onClick={() => history.goBack()}>Back</button>
                    <button className="nexusButtonCommon" onClick={() => CreateTunnel()}>Add</button>
                </p>
            </div>
        </>
  )
}

export default NewAssetTunnel
