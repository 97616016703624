import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CredentialTable from '../../Credentials/CredentialTable.js';
import CredentialForm from '../../Credentials/CredentialForm.js';
import CredentialView from '../../Credentials/CredentialView.js';

export default (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    return (
        <Switch>
            <Route
                path={`/${tenant}/organizations/:orgID/credentials/:credID/form`}
                render={(routeProps) => (
                    <CredentialForm routeProps={routeProps} org={props.org} />
                )}
            />
            <Route
                path={`/${tenant}/organizations/:orgID/credentials/:credID`}
                render={(routeProps) => (
                    <CredentialView routeProps={routeProps} org={props.org} />
                )}
            />
            <Route
                path={`/${tenant}/organizations/:orgID/credentials`}
                render={(routeProps) => (
                    <CredentialTable routeProps={routeProps} org={props.org} />
                )}
            />
        </Switch>
    );
};
