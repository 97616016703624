import React, { useState, useEffect } from 'react';
import { truncateSummary, stripTags } from '../../utils/incidentUtils';
import { UpdateNoteFlags, UpdateNoteCreditHours } from '../../api/IncidentAPI'
import { Link } from 'react-router-dom';
import Loader from '../App/loader';
import { useSelector } from 'react-redux';

const IncidentSearchItem = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(false);

    const [searchItem, setItem] = useState(props.item);

    const [canReview, setCanReview] = useState(props.canReview);
    const [canBill, setCanBill] = useState(props.canBill);
    const [canInvoice, setCanInvoice] = useState(props.canInvoice);

    const round = (num, decimalPlaces = 3) => {
        num = Math.round(num + "e" + decimalPlaces);
        return Number(num + "e" + -decimalPlaces);
    }

    const [showFullText, setShowFullText] = useState(false);
    useEffect(() => {
        setItem(props.item);
        setCanReview(props.canReview);
        setCanBill(props.canBill);
        setCanInvoice(props.canInvoice);
    }, [props])
    const billableChanged = (e, id, idx) => {
        updateFlags({
            noteID: id,
            billable: e.target.checked,
            reviewed: searchItem.notes[idx].reviewed,
            invoiced: searchItem.notes[idx].accountingClosed
        }, idx);
    }
    const invoicedChanged = (e, id, idx) => {
        updateFlags({
            noteID: id,
            billable: searchItem.notes[idx].billable,
            reviewed: searchItem.notes[idx].reviewed,
            invoiced: e.target.checked
        }, idx);
    }
    const reviewedChanged = (e, id, idx) => {
        updateFlags({
            noteID: id,
            billable: searchItem.notes[idx].billable,
            reviewed: e.target.checked,
            invoiced: searchItem.notes[idx].accountingClosed
        }, idx);
    }
    const updateFlags = (params, idx) => {
        setLoading(true);
        UpdateNoteFlags(params).then(response => {
            if (!response.success) {
                console.log(response);
                setLoading(false);
                return;
            }
            let tempLst = { ...searchItem };           
            tempLst.notes[idx].creditHours = round(response.creditHours);
            tempLst.notes[idx].billable = response.billable;
            tempLst.notes[idx].reviewed = response.reviewed;
            tempLst.notes[idx].accountingClosed = response.invoiced;
            setItem(tempLst);
        }).then(() => {
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    }
    const updateTempCreditHours = (e, idx) => {
        let tempLst = { ...searchItem };
        let tempCredit = tempLst.notes[idx].creditHours
        tempLst.notes[idx].creditHours = isNaN(e.target.value) ? 0 : Number(e.target.value);
        if (tempLst.notes[idx].oldCreditHours === undefined) {
            tempLst.notes[idx].oldCreditHours = isNaN(tempCredit) ? 0 : Number(tempCredit);
        }
        setItem(tempLst);
    }
    const updateCreditHours = (e, id, idx) => {
        setLoading(true);
        const params = {
            noteID: id,
            creditHours: Number(searchItem.notes[idx].creditHours)
        };
        UpdateNoteCreditHours(params).then(response => {
            if (!response.success) {
                console.log(response);
                setLoading(false);
                return;
            }
            let tempLst = { ...searchItem };
            let localDelta = tempLst.notes[idx].oldCreditHours - response.creditHours;
            tempLst.noteCreditHours = (tempLst.noteCreditHours - localDelta) > 0 ? tempLst.noteCreditHours - localDelta : 0;
            let someBillableHours = tempLst.noteHours - tempLst.noteCreditHours;
            tempLst.noteBillableHours = round(someBillableHours);
            tempLst.notes[idx].creditHours = response.creditHours;
            tempLst.notes[idx].billableHours = tempLst.notes[idx].hours - tempLst.notes[idx].creditHours;
            delete tempLst.notes[idx].oldCreditHours;
            setItem(tempLst);
        }).then(() => {
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    }

    return (
        <div className="search-result-item">
            {loading ? (
                <div className="NexusLoading">
                    <Loader />
                </div>
            ) : null}
            <div className="search-result-header">
                <div className="search-result-header-left">
                    {searchItem.companyName}
                </div>
                <div className="search-result-header-right">
                    {searchItem.referenceID}
                </div>
            </div>
            <div className="search-result-info">
                <table className="infoTable">
                    <tbody>
                        <tr>
                            <th>Incident Date:</th>
                            <td>{searchItem.incidentDate}</td>
                            <th>Worked Hours:</th>
                            <td>{searchItem.noteHours}</td>
                        </tr>
                        <tr>
                            <th>Requestor:</th>
                            <td>{searchItem.requestorName}</td>
                            <th>Credit Hours:</th>
                            <td>{round(searchItem.noteCreditHours)}</td>
                        </tr>
                        <tr>
                            <th>Assigned To:</th>
                            <td>{searchItem.assignedName}</td>
                            <th>Billable Hours:</th>
                            <td>{round(searchItem.noteBillableHours)}</td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>{searchItem.statusName}</td>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Priority:</th>
                            <td>{searchItem.priorityName}</td>
                            <th></th>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div className="search-result-info-separator">
                    View Incident:
                    &nbsp;
                    <a href={searchItem.incidentURL} target="_blank" rel="noopener noreferrer">(SIM)</a>
                    <Link to={`/${tenant}/Incidents/` + searchItem.incidentID} target="_blank" rel="noopener noreferrer">(NEXUS)</Link>
                    &nbsp;&nbsp;
                    <a onClick={(e) => setShowFullText(!showFullText)}>{showFullText ? "Hide" : "Show"} Full Text</a>
                </div>
                <div className="search-result-summary" dangerouslySetInnerHTML={{ __html: (showFullText ? stripTags(searchItem.summary) : truncateSummary(searchItem.summary)) }}>
                </div>
                <div className="search-result-summary-separator"></div>
                {searchItem.notes.map((note, idx) =>
                    <div key={idx}>
                        <div className="search-result-note">
                            <div className="search-result-note-info-container">
                                <div className="search-result-note-info">
                                    {note.incidentDate} - {note.creatorName}
                                </div>
                                <div className="search-result-note-info-hours">
                                    <table>
                                        <tbody>
                                            <tr><td>Note Hours</td><td>{note.hours}</td></tr>
                                            <tr><td>Note Billable Hours</td><td>{round(note.billableHours)}</td></tr>
                                            <tr><td>Note Credit Hours</td><td>{((note.oldCreditHours !== undefined && note.oldCreditHours !== note.creditHours) ? "*" : "") + round(note.creditHours)}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="search-result-note-summary" dangerouslySetInnerHTML={{ __html: (showFullText ? stripTags(note.summary) : truncateSummary(note.summary)) }}></div>
                        </div>
                        <div className="search-result-note-separator">
                            <div className="search-result-note-separator-options">
                                {canInvoice ?
                                    <div className="search-result-note-separator-option">
                                        <input type="checkbox" checked={note.accountingClosed} onChange={(e) => invoicedChanged(e, note.noteID, idx)} />
                                        <label>Accounting Closed</label>
                                    </div>
                                    : null}
                                {canBill ?
                                    <div className="search-result-note-separator-option">
                                        <input type="checkbox" disabled={note.accountingClosed} checked={note.billable} onChange={(e) => billableChanged(e, note.noteID, idx)} />
                                        <label>Billable</label>
                                    </div>
                                    : null}
                                {canReview ?
                                    <div className="search-result-note-separator-option">
                                        <input type="checkbox" checked={note.reviewed} onChange={(e) => reviewedChanged(e, note.noteID, idx)} />
                                        <label>Reviewer Closed</label>
                                    </div>
                                    : null}
                            </div>
                            <div className="search-result-note-separator-credit">
                                <input type="number" value={round(note.creditHours)} onChange={(e) => updateTempCreditHours(e, idx)} /><button className="nexusButtonCommon" onClick={(e) => updateCreditHours(e, note.noteID, idx)}>Set Credit Hours</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default IncidentSearchItem;