import React, {useEffect, useState} from 'react';
import { TestPBXConnection, TestSoftSwitchConnection } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {

    const [loaded, setLoaded] =  useState(false);
    const [error, setError] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState("");
    
    useEffect(()=>{

    },[]);

    const processResults = (res) => {
        if (res != null) {
            setConnectionStatus(res.message);
            setLoaded(true);
            setError(!res.success);
        } else {
            setLoaded(false);
            setError(true);
        }
    }

    const callPBXtest = (payload) => {
        setLoaded(false);
        setError(false);
        TestPBXConnection(payload)
            .then(res => {
                processResults(res)
            })
            .catch(() => {
                setError(true);
            })
    }

    const callSoftswitchTest = (payload) => {
        setLoaded(false);
        setError(false);
        TestSoftSwitchConnection(payload)
            .then(res => {
                processResults(res)
            })
            .catch(() => {
                setError(true);
            })
    }

    const testSQLConnection = (e) => {
        setButtonClicked(true);
        const payload = {
            pbxid: props.pbxid,
            softswitchid: props.pbxid,
            isnew: false,
            type: "SQL"
        };
        if (props.type === "pbx") {
            callPBXtest(payload)
        } else {
            callSoftswitchTest(payload)
        }
    }

    const testSSHConnection = (e) => {
        setButtonClicked(true);
        const payload = {
            pbxid: props.pbxid,
            softswitchid: props.pbxid,
            isnew: false,
            type: "SSH"
        };
        if (props.type === "pbx") {
            callPBXtest(payload)
        } else {
            callSoftswitchTest(payload)
        }
    }

        const logLines = connectionStatus.length > 0 ? connectionStatus.split("\r\n") : [];
        const errors = error;
        const connectionLog = logLines.map((l, i) =>
            <p key={i} style=
                {logLines.length - 1 === i + 1 ?
                    (errors) ? { color: "red" } : { color: "green" }

                    : {}}
            >{l}</p>
        )
        return (
            <div>
                <div>
                    <button className="nexusButtonCommon" onClick={testSQLConnection}>Test SQL Connection</button>
                    <button className="nexusButtonCommon" onClick={testSSHConnection}>Test SSH Connection</button>
                </div>
                {loaded ? (
                    <div> {connectionLog} </div>
                ) : buttonClicked ? (
                    <Loader />
                ) : ""}
            </div>
        )
}