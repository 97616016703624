import React, { FC } from 'react';
import XbertVersionOverview from './XBert/XBertVersionOverview/XbertVersionOverview';
import { store } from '../../Redux/XBert/store';
import { Provider } from 'react-redux';
interface TabXBertProps {
    assetID: number;
}
export const TabXBert: FC<TabXBertProps> = ({ assetID }) => {    
    return (
        <div>
            <Provider store={store}>
                {' '}
                <XbertVersionOverview assetId={assetID} />
            </Provider>
        </div>
    );
};

export default TabXBert;
