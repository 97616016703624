import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RecordEdit from './dnsRecordEdit.js';
import DomainEdit from './dnsDomainEdit.js';
import DomainList from './dnsDomainList.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    return (
        <Switch>
            <Route path={`/${tenant}/organizations/:orgId/dns/:domainId/records/:recordId`} render={(routeProps) =>
                <RecordEdit routeProps={routeProps} org={props.org} />
            } />
            <Route path={`/${tenant}/organizations/:orgId/dns/:domainId`} render={(routeProps) =>
                <DomainEdit routeProps={routeProps} org={props.org} />
            } />
            <Route path={`/${tenant}/organizations/:orgId/dns`} render={(routeProps) =>
                <DomainList routeProps={routeProps} org={props.org} />
            } />
        </Switch>
    )
}