import APIRequest from './APIRequest';

export const GetAccountSummary = (body) => {
    return APIRequest({
        url: `WHMService/GetAccountSummary`,
        method: "POST",
        body
    });
}

export const GetPackageListOptions = (body) => {
    return APIRequest({
        url: `WHMService/GetPackageListOptions`,
        method: "POST",
        body
    });
}

export const CreateAccount = (body) => {
    return APIRequest({
        url: `WHMService/CreateAccount`,
        method: "POST",
        body
    });
}

export const TerminateAccount = (body) => {
    return APIRequest({
        url: `WHMService/TerminateAccount`,
        method: "POST",
        body
    });
}

export const StartSession = (body) => {
    return APIRequest({
        url: `WHMService/StartSession`,
        method: "POST",
        body
    });
}


