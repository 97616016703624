import React, { createRef, useEffect } from 'react';

const Mouse = (props) => {
    const { mouseMove, mouseClick, mouseDrag, keyPress, nativeStreamDimensions } = props;
    const buttons = { 0: 'left', 1: 'middle', 2: 'right' };
    const mouseContainer = createRef();
    const sessionsContainer = createRef();


    const handleMouseMove = (event) => {
        let pos = translatePosition(event.clientX, event.clientY);
        mouseMove(JSON.stringify({ deltaX: pos.x, deltaY: pos.y }));
    };

    const handleMouseDrag = (event) => {
        if (event.type == 'dragstart') {
            let img = new Image();
            img.src = 'transparent_drag.png';
            event.dataTransfer.setDragImage(img, 10, 10);
        }
        let pos = translatePosition(event.clientX, event.clientY);
        mouseDrag(JSON.stringify({
            deltaX: pos.x,
            deltaY: pos.y,
            type: event.type
        }));
    };

    const handleMouseClick = (event, isDoubleClick = false) => {
        event.preventDefault();
        mouseClick(
            JSON.stringify({ button: buttons[event.button], doubleClick: isDoubleClick })
        );
    };

    const translatePosition = (x, y) => {
        let mouseWidth = mouseContainer.current.getBoundingClientRect().width;
        let mouseHeight = mouseContainer.current.getBoundingClientRect().height;

        let mouseFromTop = mouseContainer.current.getBoundingClientRect().top;
        let mouseFromLeft = mouseContainer.current.getBoundingClientRect().left;

        let trueX = (x - mouseFromLeft) < 0 ? 0 : (x - mouseFromLeft);
        let trueY = (y - mouseFromTop) < 0 ? 0 : (y - mouseFromTop);

        let multipleX = nativeStreamDimensions.width / mouseWidth;
        let multipleY = nativeStreamDimensions.height / mouseHeight;
        let moveX = trueX * multipleX;
        let moveY = trueY * multipleY;

        return { 'x': moveX, 'y': moveY };
    }

    useEffect(() => {
        if (document.activeElement !== mouseContainer.current
            && mouseContainer.current != null &&
            props.specialKeysSet != null) {
            mouseContainer.current.focus();
        }
    }, [props.specialKeysSet]);

    return (
        <div ref={sessionsContainer} style={{ textAlign: 'center', backgroundColor: 'black' }}>
            <div ref={mouseContainer}
                style={{ display: 'inline-block' }}
                tabIndex={-1}
                onMouseMove={(e) => handleMouseMove(e)}
                onDoubleClick={(e) => handleMouseClick(e, true)}
                onContextMenu={(e) => { handleMouseClick(e); }} // right click
                onClick={(e) => handleMouseClick(e, false)}
                onDragStart={(e) => handleMouseDrag(e)}
                onDrag={(e) => handleMouseDrag(e)}
                onDragEnd={(e) => handleMouseDrag(e)}
                draggable="true"
                onKeyDown={(e) => keyPress(e)}
            >
                {props.children}
            </div>
        </div>
    );
};

export default Mouse;