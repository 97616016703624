import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CredentialTable from '.././Credentials/CredentialTable.js';
import CredentialForm from '.././Credentials/CredentialForm.js';
import CredentialView from '.././Credentials/CredentialView.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    return (
        <Switch>
            <Route path={`/${tenant}/assets/:assetID/credentials/:credID/form`} render={(routeProps) =>
                <CredentialForm routeProps={routeProps} asset={props.asset} />
            } />
            <Route path={`/${tenant}/assets/:assetID/credentials/:credID`} render={(routeProps) =>
                <CredentialView routeProps={routeProps} asset={props.asset} />
            } />
            <Route path={`/${tenant}/assets/:assetID/credentials`} render={(routeProps) =>
                <CredentialTable routeProps={routeProps} asset={props.asset} />
            } />
        </Switch>
    )
}