import React, { useState, useEffect} from 'react';
import { GetPersonOrganizations, AddOrganizationToPerson } from '../../../api/PeopleAPI.js';
import Loader from '../../App/loader.js';
import NexusGrid from '../../App/NexusGrid';
import NexusModal from '../../App/NexusModal';
import { GetOrganizationsForPerson } from '../../../api/OrganizationAPI';
import { current } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [currentToAddOrg, setCurrentToAddOrg] = useState(0);
    const [orgsToAdd, setOrgsToAdd] = useState([]);
    const [orgAdded, setOrgAdded] = useState(0);
    const [authMethod, setAuthMethod] = useState(-1);
    const [email, setEmail] = useState("");
    const [chooseExisting, setChooseExisting] = useState(null);
    const [useOrgID, setUseOrgID] = useState(0);
    let regex = new RegExp(`^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$`);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const personID = props.routeProps.match.params.id;
        GetPersonOrganizations({personID: Number(personID)})
        .then(res => {
            setLoaded(true);
            if(res!=null){
                setData(res.content);
            }
        })
        .catch(() => {
            setError(true);
        });
    }, [orgAdded]);

    const openAddModal = () => {
        setDisplayModal(true);
        const personID = props.routeProps.match.params.id;
        setMessage("");
        GetOrganizationsForPerson({content: Number(personID)})
        .then(res => {
            setLoaded(true);
            if(res!=null){
                setOrgsToAdd(res.content);
                setCurrentToAddOrg(res.content[0].orgID);
            }
        })
        .catch(()=>{
            setError(true);
            setMessage("An error occurred while loading the list of organizations.");
        })
    }

    const handleOrgChange = (e) => {
        setCurrentToAddOrg(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleLoginChange = (e) => {
        setUseOrgID(e.target.value);
    }

    const addOrgToPerson = () => {
        setMessage("");
        const personID = props.routeProps.match.params.id;
        const payload = {
            personid: Number(personID),
            orgid: Number(currentToAddOrg),
            authMethod: Number(authMethod),
            username: "",
            email: email,
            useOrg: Number(useOrgID)
        };
        if (chooseExisting === "true") {
            AddOrganizationToPerson(payload).then(response => {
                if (response.success) {
                    setDisplayModal(false);
                    setOrgAdded(currentToAddOrg);
                    setChooseExisting(null);
                } else {
                    setMessage(response.message);
                    setDisplayModal(true);
                }
            }).catch(() => {
                setError(true);
                setMessage("An error occurred while adding person to an organization.");
            })
        } else if (chooseExisting === "false") {
            if (regex.test(email) === true && email !== "") {
                AddOrganizationToPerson(payload).then(response => {
                    if (response.success) {
                        setDisplayModal(false);
                        setOrgAdded(currentToAddOrg);
                        setChooseExisting(null);
                    } else {
                        setMessage(response.message);
                        setDisplayModal(true);
                    }
                }).catch(() => {
                    setError(true);
                    setMessage("An error occurred while adding person to an organization.");
                })
            } else {
                setError(true);
                setMessage("Please enter a proper email address.");
                setDisplayModal(true);
            }
        }
    }

    const share = () => {
        return (
            <NexusModal stateValue={displayModal} stateFunctionClose={() => { setDisplayModal(false); setChooseExisting(null); }} title="Select an Organization" closeText="Add Organization"
                closeAction={() => { addOrgToPerson(); }} width='50%' height='auto'>
                <select onChange={handleOrgChange} style={{ width: '100%', marginBottom: '5px' }}>
                    {orgsToAdd.map((o) => <option key={o.orgID} value={o.orgID}>{o.orgName}</option>)}
                </select>
                <select onChange={handleLoginChange} style={{ width: '100%', marginBottom: '5px' }}>
                    <option value="0">-- Select an Existing Login --</option>
                    {data.map((o) => <option key={o.orgID} value={o.orgID}>Use login for {o.orgName}</option>)}
                </select>
                <p>{message}</p>
            </NexusModal>
        )
    }

    const create = () => {
        return (
            <NexusModal stateValue={displayModal} stateFunctionClose={() => { setDisplayModal(false); setChooseExisting(null); }} title="Select an Organization" closeText="Add Organization"
                closeAction={() => { addOrgToPerson(); }} width='50%' height='auto'>
                <select onChange={handleOrgChange} style={{ width: '100%', marginBottom: '5px' }}>
                    {orgsToAdd.map((o) => <option key={o.orgID} value={o.orgID}>{o.orgName}</option>)}
                </select>
                <select onChange={(e) => setAuthMethod(e.target.value)} style={{ width: '100%', marginBottom: '5px' }}>
                    <option value="-1">-- Select an Authentication Method --</option>
                    <option value="1">Active Directory</option>
                    <option value="4">Local Authentication</option>
                </select>
                <input
                    style={{ width: '100%', marginBottom: '5px' }}
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleEmailChange}
                    placeholder="Enter an email address"
                    required
                />
                <p>{message}</p>
            </NexusModal>
        )
    }

    const choose = () => {
        return (
            <NexusModal stateValue={displayModal} stateFunctionClose={() => loginModal()} title="Choose a Login Option" width='50%' height='auto'>
                <select onChange={(e) => { setChooseExisting(e.target.value) }} style={{ width: '100%', marginBottom: '5px' }}>
                    <option value={null}>-- Select an option --</option>
                    <option value={true}>Share existing login</option>
                    <option value={false}>Create new login</option>
                </select>
                <p>{message}</p>
            </NexusModal>
        )
    }

    const loginModal = () => {
        if (chooseExisting === null) {
            setDisplayModal(false);
        } else {
            setDisplayModal(true);
        }
    }

    const headers1 = [
        { Header: 'Organization', accessor: 'orgName' },
        { Header: 'Org ID', accessor: 'orgID' }
    ];
    return (
        <div className="localSortDGContainer">
            
            <h2>Organizations</h2>
            <button onClick={openAddModal} className="nexusButtonCommon"><i className="fas fa-plus"></i>Add</button>
            {loaded ? (
                <div className="NexusLoading">
                    {chooseExisting === null && (
                        choose()
                    )}
                    {chooseExisting === "true" && (
                        share()
                    )}
                    {chooseExisting === "false" && (
                        create()
                    )}
                    <NexusGrid
                        linkTo={`/${tenant}/organizations/:key/info`}
                        tableKey={'orgID'}
                        hiddenColumns={['orgID']}
                        pageMode={"none"}
                        data={data}
                        defaultSortBy={'OrganizationID'}
                        columns={headers1}
                    />
                </div>
            ) : error ? (
                    <p>An error occurred while loading person's organizations.</p>
            ) : (
                <Loader />
            )}
        </div>
    )

}