import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GetCredentialList } from '../../api/CredentialAPI';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [credentials, setCredentials] = useState([]);
    const [otherCreds, setOtherCreds] = useState([]);

    useEffect(() => {
        loadCredentials(props.org, props.asset);
    }, [props.org, props.asset]);

    const loadCredentials = (org, asset) => {
        setLoading(true);
        let body = {
            organizationID: org?.orgID ? parseInt(org?.orgID, 10) : 0,
            assetID: asset?.assetID ? parseInt(asset?.assetID, 10) : 0
        }
        GetCredentialList(body)
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setCredentials(res.credentials);
                        if (props.org !== undefined) {
                            setOtherCreds(res.otherCredentials);
                        }
                    }
                }
            });
        setLoading(false);
    }

    const getBaseURL = () => {
        if (!props.asset) {
            return `/${tenant}/organizations/${props.org?.orgID}/credentials`;
        }
        return `/${tenant}/assets/${props.asset?.assetID}/credentials`;
    }

    const headers = [
        { Header: "Credential ID", accessor: "credentialID" },
        { Header: "Username", accessor: "username" },
        { Header: "Domain", accessor: "domain" },
        { Header: "Organization", accessor: "organizationName" },
        { Header: "Asset", accessor: "assetName" }
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <Link to={`${getBaseURL()}/new/form`}>
                <div id="addCredentialButton" className="nexusButtonCommon"><i className="fas fa-plus"></i>Add Credential</div>
            </Link>
            <h2>Organization Credentials</h2>
            <NexusGrid
                linkTo={`${getBaseURL()}/:key`}
                tableKey={"credentialID"}
                hiddenColumns={['credentialID']}
                data={credentials}
                defaultSortBy={"username"}
                columns={headers}
            />

            <h2>Asset Credentials</h2>
            <NexusGrid
                linkTo={`${getBaseURL()}/:key`}
                tableKey={"credentialID"}
                hiddenColumns={['credentialID']}
                data={otherCreds}
                defaultSortBy={"username"}
                columns={headers}
            />
        </div>
    )
}