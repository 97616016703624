import APIRequest from './APIRequest';

export const GetOrganizationServices = (body) => {
    return APIRequest({
        url: `Service/GetOrganizationServices`,
        method: "POST",
        body
    });
}

export const GetServiceList = (payload, showOnlyActive = true, showEnabledForOrg = true, orgID = 0) => {
    return APIRequest({
        url: `Service/GetServiceList/${showOnlyActive}/${showEnabledForOrg}/${orgID}`,
        method: "POST",
        body: payload
    });
}

export const CreateService = (body) => {
    return APIRequest({
        url: `Service/CreateService`,
        method: "POST",
        body
    });
}

export const GetService = (body) => {
    console.log('Executing GetService()');
    return APIRequest({
        url: `Service/GetService`,
        method: "POST",
        body
    });
}

export const UpdateService = (body) => {
    return APIRequest({
        url: `Service/UpdateService`,
        method: "POST",
        body
    });
}

export const GetServiceHooks = (body) => {
    return APIRequest({
        url: `Service/GetServiceHooks`,
        method: "POST",
        body
    });
}

export const ActivateServiceHook = (body) => {
    return APIRequest({
        url: `Service/ActivateServiceHook`,
        method: "POST",
        body
    });
}

export const DisableServiceHook = (body) => {
    return APIRequest({
        url: `Service/DisableServiceHook`,
        method: "POST",
        body
    });
}

export const GetServiceOperations = (body) => {
    return APIRequest({
        url: `Service/GetServiceOperations`,
        method: "POST",
        body
    });
}

export const GetUpdatedServiceOperations = (body) => {
    return APIRequest({
        url: `Service/GetUpdatedServiceOperations`,
        method: "POST",
        body
    });
}

export const SetServiceOperations = (body) => {
    return APIRequest({
        url: `Service/SetServiceOperations`,
        method: "POST",
        body
    });
}

export const GetServiceOrgTransitionOperations = (body) => {
    return APIRequest({
        url: `Service/GetServiceOrgTransitionOperations`,
        method: "POST",
        body
    });
}

export const ProgressServiceOrgTransition = (body) => {
    return APIRequest({
        url: `Service/ProgressServiceOrgTransition`,
        method: "POST",
        body
    });
}

export const CancelServiceOrgTransition = (body) => {
    return APIRequest({
        url: `Service/CancelServiceOrgTransition`,
        method: "POST",
        body
    });
}

export const GetServiceOrg = (body) => {
    return APIRequest({
        url: `Service/GetServiceOrg`,
        method: "POST",
        body
    });
}

export const GetServiceOrgTransitions = (body) => {
    return APIRequest({
        url: `Service/GetServiceOrgTransitions`,
        method: "POST",
        body
    });
}

export const CreateServiceOrgTransition = (body) => {
    return APIRequest({
        url: `Service/CreateServiceOrgTransition`,
        method: "POST",
        body
    });
}

export const SaveQuotedPrice = (body) => {
    return APIRequest({
        url:'Service/EditQuotedPrice',
        method: "POST",
        body
    })
}

export const SaveServiceOrgDescription = (body) => {
    return APIRequest({
        url:'Service/EditServiceOrgDescription',
        method: "POST",
        body
    })
}
