import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Loader from '../../../App/loader';
import { GetRecordTypeOptions, GetRecord, UpdateRecord } from '../../../../api/DNSServiceAPI';
import { useSelector } from 'react-redux';

export default props => {
    const tenantURI = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({
        recordId: 0,
        domainId: 0,
        recordName: "",
        type: "",
        content: "",
        timeToLive: "",
        disabled: false,
    });
    const [recordTypes, setRecordTypes] = useState();

    useEffect(() => {
        loadRecordTypeOptions();
    }, []);

    useEffect(() => {
        loadRecord(parseInt(props.routeProps.match.params.recordId, 10));
    }, [props.routeProps.match.params.recordId]);

    const loadRecordTypeOptions = () => {
        setLoading(true);
        GetRecordTypeOptions()
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setRecordTypes(res.content);
                    }
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const loadRecord = (id) => {
        if (id !== 0 && !isNaN(id)) {
            setLoading(true);
            GetRecord({ content: id })
                .then(res => {
                    if (res != null && res.success) {
                        setRecord(res.content);
                    }
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    const saveRecord = () => {
        setLoading(true);
        let newRecord = { ...record };
        newRecord.domainId = parseInt(props.routeProps.match.params.domainId, 10);
        UpdateRecord({ content: newRecord })
            .then(res => {
                if (res !== null) {
                    if (res.success) {
                        setMessage("Record Saved");
                        setRecord(res.content);
                    } else {
                        setMessage(res.message);
                    }
                }
                setLoading(false)
            })
            .catch(() => {
                setMessage("Error Saving Record");
                setLoading(false)
            })
    }

    const submit = (e) => {
        e.preventDefault();
        saveRecord();
    }

    const inputChanged = (e) => {
        let newRecord = { ...record };
        newRecord[e.target.name] = e.target.value;
        setRecord(newRecord);
    }

    const setType = (type) => {
        let newRecord = { ...record };
        newRecord.type = type;
        setRecord(newRecord);
    }

    const toggleDisabled = () => {
        let newRecord = { ...record };
        newRecord.disabled = !newRecord.disabled;
        setRecord(newRecord);
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <BreadcrumbsItem to={`/${tenantURI}/organizations/${props.routeProps.match.params.orgId}/dns`} label='Domains' />
            <BreadcrumbsItem to={`/${tenantURI}/organizations/${props.routeProps.match.params.orgId}/dns/${props.routeProps.match.params.domainId}`} label='Records' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Record Name:</td>
                                <td><input type="text" name="recordName" id="recordName" value={record.recordName} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Type:</td>
                                <td><select name="type" id="type" value={record.type} onChange={(e) => setType(e.target.value)}>
                                    {<option value="" />}
                                    {recordTypes && recordTypes.map((type) =>
                                        <option key={type} value={type}>{type}</option>
                                    )}
                                </select></td>
                            </tr>
                            <tr>
                                <td>Content:</td>
                                <td><input type="text" name="content" id="content" value={record.content} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Time To Live:</td>
                                <td><input type="text" name="timeToLive" id="timeToLive" value={record.timeToLive} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Disabled:</td>
                                <td><input type="checkbox" name="disabled" id="disabled" value={record.disabled} checked={record.disabled} onChange={() => toggleDisabled()} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="SaveRecord" id="SaveRecord" value="Save Record Info" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
        </div>
    );
}