import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GetDomainList } from '../../../../api/DNSServiceAPI';
import Loader from '../../../App/loader';
import NexusGrid from '../../../App/NexusGrid';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        loadDomains();
    });

    const loadDomains = () => {
        setLoading(true);
        GetDomainList({ content: parseInt(props.org.orgID, 10) })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setDomains(res.content);
                    }
                }
            })
        setLoading(false)
    }

    const headers = [
        { Header: "Domain ID", accessor: "domainId" },
        { Header: "Domain Name", accessor: "domainName" },
        { Header: "Master", accessor: "master" },
        { Header: "Type", accessor: "type" }
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h2>Domains</h2>
            <Link to={`/${tenant}/organizations/${props.org.orgID}/dns/new`}>
                <div id="addDomainButton" className="nexusButtonCommon"><i className="fas fa-plus"></i>Add Domain</div>
            </Link>
            <NexusGrid
                linkTo={`/${tenant}/organizations/${props.org.orgID}/dns/:key`}
                tableKey={"domainId"}
                hiddenColumns={['domainId']}
                data={domains}
                defaultSortBy={"domainName"}
                columns={headers}
            />
        </div>
    )
}