import React, { useState, useEffect } from 'react';
import { GetCredentialHistoryList } from '../../api/CredentialAPI';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';

export default props => {
    const [loading, setLoading] = useState(true);
    const [credentialHistories, setCredentialHistories] = useState([]);

    useEffect(() => {
        loadCredentialHistories();
    }, []);

    const loadCredentialHistories = () => {
        setLoading(true);
        GetCredentialHistoryList({content: props.credentialID})
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setCredentialHistories(res.content);
                    }
                }
            });
        setLoading(false);
    }

    const headers = [
        { Header: "Username", accessor: "username" },
        { Header: "Domain", accessor: "domain" },
        { Header: "Active", accessor: "active" },
        { Header: "Change", accessor: "changeType" },
        { Header: "By", accessor: "changeBy" },
        { Header: "Date", accessor: "changeDate", dataFormat: (cell, row) => { return <div>{row.ChangeDate ? new Date(row.ChangeDate).toLocaleString() : ""}</div> } }
    ];

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h2>History</h2>
            <NexusGrid
                data={credentialHistories}
                defaultSortBy={"Change"}
                defaultSortOrder={"desc"}
                columns={headers}
                pageMode="local"
            />

        </div>
    )
}