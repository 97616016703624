import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../App/loader.js';
import { Link } from 'react-router-dom';
import {
    sendCartData,
    sendOrganizationData,
} from '../../../Redux/cart-actions';
import { CRUD_ENUM } from '../../../enums/crud.js';
import Input from '../../UI/Input';
import Notification from '../../UI/Notification.js';
import EditableTableCell from '../../UI/EditableTableCell';
import { UpdateOrganization, DeleteOrganization } from '../../../api/OrganizationAPI';

const TabInfo = (props) => {
    const {
        orgName,
        orgCode,
        address1,
        address2,
        city,
        state,
        zipcode,
        mainNumber,
        faxNumber,
        website,
        orgType,
        ldapServer,
        ldapDomain,
        ldapRoot,
        ldapUser,
        ldapPassword,
    } = props.org;

    const initialOrgState = {
        orgName: orgName,
        orgCode: orgCode,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zipcode: zipcode,
        mainNumber: mainNumber,
        faxNumber: faxNumber,
        website: website,
        orgType: orgType,
        ldapServer: ldapServer,
        ldapDomain: ldapDomain,
        ldapRoot: ldapRoot,
        ldapUser: ldapUser,
        ldapPassword: ldapPassword,
    };

    useEffect(() => {
        setFormInputs(initialOrgState);
    }, [props.org]);

    const onChangeHandler = (e) => {
        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });
    };
    const [formInputs, setFormInputs] = useState(initialOrgState);

    const [submitted, setSubmitted] = useState(false);
    const [displayNotification, setDisplayNotification] = useState(false);
    const [status, setStatus] = useState(null);
    const dispatch = useDispatch();
    const [isFormEditable, setIsFormEditable] = useState(false);
    const canUserEditOrgs = useSelector(
        (state) => state.UserReducer.canModifyOrgs
    );

    const setOrgInactive = () => {
        const payload = {
            orgCode: orgCode,
        };
        DeleteOrganization(payload).then((response) => {
            if (response.success) {
                setIsFormEditable(false);
                setSubmitted(true);
                setStatus('success');
                setDisplayNotification(true);
            }
        })
    };

    const resetClickedHandler = () => {
        setIsFormEditable(false);
        setFormInputs(initialOrgState);
    };

    const formSubmitClickedHandler = async (e) => {
        e.preventDefault();

        const payload = formInputs;
        console.dir(payload);

        try {
            UpdateOrganization(payload).then((response) => {
                if (response.success) {
                    setIsFormEditable(false);
                    setSubmitted(true);
                    setStatus('success');
                    setDisplayNotification(true);
                }
            })
        } catch (error) {
            setSubmitted(true);
            setStatus('error');
        }
    };

    const infoTable = (
        <div>
            <div>
                <table cellPadding="0" cellSpacing="0" className="infoTable">
                    <tbody>
                        <tr>
                            <th>Name:</th>
                            <td>{orgName}</td>
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Org Name"
                                type="text"
                                className="form-control"
                                id="inputOrgName"
                                name="orgName"
                                placeholder={formInputs.orgName}
                                defaultValue={formInputs.orgName}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={false}
                                title="Org Code"
                                type="text"
                                className="form-control"
                                id="inputOrgCode"
                                name="orgCode"
                                placeholder={formInputs.orgCode}
                                defaultValue={formInputs.orgCode}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Address 1"
                                type="text"
                                className="form-control"
                                id="inputAddress1"
                                name="address1"
                                placeholder={formInputs.address1}
                                defaultValue={formInputs.address1}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Address 2"
                                type="text"
                                className="form-control"
                                id="inputAddress2"
                                name="address2"
                                placeholder={formInputs.address2}
                                defaultValue={formInputs.address2}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="City"
                                type="text"
                                className="form-control"
                                id="inputCity"
                                name="city"
                                placeholder={formInputs.city}
                                defaultValue={formInputs.city}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="State"
                                type="text"
                                className="form-control"
                                id="state"
                                name="state"
                                placeholder={formInputs.state}
                                defaultValue={formInputs.state}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Zipcode"
                                type="text"
                                className="form-control"
                                id="inputZipcode"
                                name="zipcode"
                                placeholder={formInputs.zipcode}
                                defaultValue={formInputs.zipcode}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Main Number"
                                type="text"
                                className="form-control"
                                id="inputMainNumber"
                                name="mainNumber"
                                placeholder={formInputs.mainNumber}
                                defaultValue={formInputs.mainNumber}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Fax Number"
                                type="text"
                                className="form-control"
                                id="inputFaxNumber"
                                name="faxNumber"
                                placeholder={formInputs.faxNumber}
                                defaultValue={formInputs.faxNumber}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        <tr>
                            <EditableTableCell
                                isFormEditable={isFormEditable}
                                title="Website"
                                type="text"
                                className="form-control"
                                id="inputWebsite"
                                name="website"
                                placeholder={formInputs.website}
                                defaultValue={formInputs.website}
                                changed={(e) => onChangeHandler(e)}
                            />
                        </tr>
                        {isFormEditable === false ? (
                            <tr>
                                <th>LDAP Info Set:</th>
                                {(ldapServer !== null && ldapDomain !== null && ldapRoot !== null && ldapUser !== null && ldapPassword !== null) ? (
                                    <td>True</td>
                                ) : (
                                    <td>False</td>
                                )}
                            </tr>
                        ) : (
                            <>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="LDAP Server"
                                        type="text"
                                        className="form-control"
                                        id="inputLdapServer"
                                        name="ldapServer"
                                        placeholder={formInputs.ldapServer}
                                        defaultValue={formInputs.ldapServer}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="LDAP Domain"
                                        type="text"
                                        className="form-control"
                                        id="inputLdapDomain"
                                        name="ldapDomain"
                                        placeholder={formInputs.ldapDomain}
                                        defaultValue={formInputs.ldapDomain}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="LDAP Root"
                                        type="text"
                                        className="form-control"
                                        id="inputLdapRoot"
                                        name="ldapRoot"
                                        placeholder={formInputs.ldapRoot}
                                        defaultValue={formInputs.ldapRoot}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="LDAP User"
                                        type="text"
                                        className="form-control"
                                        id="inputLdapUser"
                                        name="ldapUser"
                                        placeholder={formInputs.ldapUser}
                                        defaultValue={formInputs.ldapUser}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="LDAP Password"
                                        type="password"
                                        className="form-control"
                                        id="inputLdapPassword"
                                        name="ldapPassword"
                                        placeholder={formInputs.ldapPassword}
                                        defaultValue={formInputs.ldapPassword}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );

    const editOrgInfoToggle = (
        <>
            <div className="form-group">
                {isFormEditable ? (
                    <button
                        className="nexusButtonCommon"
                        type="button"
                        onClick={() => setOrgInactive()}
                    >
                        Set Organization Inactive
                    </button>
                ) : (
                    <button
                        className="nexusButtonCommon"
                        type="button"
                        onClick={() => setIsFormEditable(!isFormEditable)}
                    >
                        Edit Organizations's Info
                    </button>
                )}
                <hr />
            </div>
        </>
    );

    const formSubmitControls = (
        <>
            <hr />
            {isFormEditable ? (
                <div className="form-group">
                    <button
                        className="nexusButtonCommon"
                        onClick={resetClickedHandler}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="nexusButtonCommon"
                        disabled={!isFormEditable}
                    >
                        Save
                    </button>
                </div>
            ) : null}
        </>
    );

    const content = (
        <div>
            <h3>{orgName}</h3>

            <form onSubmit={formSubmitClickedHandler}>
                {canUserEditOrgs ? editOrgInfoToggle : null}
                {infoTable}
                {canUserEditOrgs ? formSubmitControls : null}
                {submitted && displayNotification ? (
                    <Notification
                        status={status}
                        title="Submission Result"
                        message={status}
                        dismiss={() => setDisplayNotification(false)}
                    ></Notification>
                ) : null}
            </form>
        </div>
    );

    return content;
};
export default TabInfo;
