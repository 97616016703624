import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationCarrier, DisableOrganizationCarrier } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded,setLoaded]= useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [theCarrier, setTheCarrier] = useState({});
    
    useEffect(()=> {
        const carrierID = props.routeProps.match.params.carrierid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationCarrier(Number(orgID), { content: { carrierID: Number(carrierID)}})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setLoaded(true);
                        setTheCarrier(res.content[0]);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const disableCarrier=()=> {
        const carrier = {
            carrierid: Number(props.routeProps.match.params.carrierid),
            active: !theCarrier.active
        }
        DisableOrganizationCarrier({
            carrier: carrier
        })
            .then(res => {
                var obj = theCarrier;
                obj.active = !obj.active;
                setTheCarrier(obj);
            })
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{theCarrier.name}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td>{theCarrier.name}</td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />
                        <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                        <button type="submit" onClick={disableCarrier()} className="nexusButtonCommon">{theCarrier.active ? "Disable" : "Enable"}</button>
                        {canEdit ? (
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/carrier/" + theCarrier.carrierid + "/edit"} className="nexusButtonCommon">Edit</Link>
                        ) : ("")}
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}