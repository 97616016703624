import { userActions } from './user-slice';

export const setUserCanModifyPeople = (canUserModifyPeople = false) => {
    return async (dispatch) => {
        try {
            dispatch(
                userActions.setPeopleModifyProperty({
                    canModifyPeople: canUserModifyPeople,
                    canModifyOrgs: canUserModifyPeople, // just setting them to be the same
                    canModifyTenants: canUserModifyPeople, // just setting them to be the same
                })
            );
        } catch (error) {}
    };
};

export const fetchUserData = () => {
    return async (dispatch) => {
        const fetchData = async () => {

        };

        try {
            const cartData = await fetchData();
            dispatch(
                userActions.setOrgModifyProperty({
                    items: cartData.items || [],
                    totalQuantity: cartData.totalQuantity,
                })
            );
        } catch (error) {
        }
    };
};

