import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import Dashboard from '../Dashboard/dashboard.js';
import PTORequestForm from '../Dashboard/PTORequestForm.js';
import ServiceRouter from '../Services/ServiceRouter.js';
import CredentialTypeRouter from '../Credentials/CredentialTypeRouter.js';
import OrgTable from '../Organization/orgtable.js';
import AssetTable from '../Assets/AssetTable.js';
import AssetTypesTable from '../Assets/AssetTypes/AssetTypesTable.js';
import Organization from '../Organization/organization.js';
import Asset from '../Assets/asset.js';
import PeopleTable from '../People/peopletable.js';
import PeopleForm from '../People/PeopleForm/PeopleForm';
import People from '../People/people.js';
import Project from '../Projects/Project';
import Incident from '../Incidents/Incident';
import IncidentQueue from '../Incidents/IncidentQueue';
import IncidentSearch from '../Incidents/IncidentSearch.js';
import CreateIncident from '../Incidents/CreateIncident';
import BreadcrumbsLink from './BreadcrumbsLink.js';
import PropTypes from 'prop-types';
import OrganizationForm from '../Organization/OrganizationForm/OrganizationForm';
import Sessions from '../Assets/Sessions/Sessions';
import AssetForm from '../Assets/AssetForm/AssetForm';
import { useSelector } from 'react-redux';
import TenantTable from '../Tenant/tenanttable.js';
import Tenant from '../Tenant/tenant.js';
import TenantForm from '../Tenant/TenantForm/TenantForm.js';
import Admin from '../Admin/admin.js'
import DuoCallBack from './DuoCallback.js';
import PTOManagement from '../PTO/PTOManagement.js';

const MainRouter = (props) => {
    const { loadMissingTime } = props;
    const tenant = useSelector(state => state.TenantReducer.Tenant);

    return (
        <>
            <Breadcrumbs separator={<b> &lt; </b>} item={BreadcrumbsLink} compare={(a,b)=>a.weight-b.weight} removeProps={{weight: true}} />
            <Switch>
                <Route exact path={`/${tenant}`} component={Dashboard} />
                {/*<Route*/}
                {/*    exact*/}
                {/*    path="/duo_callback"*/}
                {/*    render={(routeProps) => (*/}
                {/*        <DuoCallBack routeProps={routeProps} />*/}
                {/*    )}*/}
                {/*/>*/}
                <Route
                    exact
                    path={`/${tenant}/Dashboard/:date`}
                    render={(routeProps) => (
                        <Dashboard date={routeProps.match.params.date} />
                    )}
                />
                <Route
                    exact
                    path={`/${tenant}/ptoForm`}
                    render={(routeProps) => (
                        <PTORequestForm routeProps={routeProps} />
                    )}
                />
                <Route
                    path={`/${tenant}/services`}
                    render={(routeProps) => (
                        <ServiceRouter routeProps={routeProps} />
                    )}
                />
                <Route
                    path={`/${tenant}/credentialTypes`}
                    render={(routeProps) => (
                        <CredentialTypeRouter routeProps={routeProps} />
                    )}
                />
                <Route
                    exact
                    path={`/${tenant}/organizations`}
                    render={(routeProps) => (
                        <OrgTable routeProps={routeProps} />
                    )}
                />
                <Route
                    exact
                    path={`/${tenant}/AssetTypes`}
                    render={(routeProps) => (
                        <AssetTypesTable routeProps={routeProps} />
                    )}
                />
                <Route
                    exact
                    path={`/${tenant}/Assets`}
                    render={(routeProps) => (
                        <AssetTable routeProps={routeProps} />
                    )}
                />
                <Route
                    path={`/${tenant}/assets/add`}
                    render={(routeProps) => (
                    <AssetForm routeProps={routeProps}/>)}
                />
                <Route
                    path={`/${tenant}/organizations/add`}
                    render={() => <OrganizationForm />}
                />
                <Route
                    path={`/${tenant}/organizations/:id/:tab`}
                    render={(routeProps) => (
                        <Organization routeProps={routeProps} />
                    )}
                />
                <Redirect
                    from={`/${tenant}/organizations/:id/`}
                    to={`/${tenant}/organizations/:id/info`}
                />

                <Route
                    exact
                    path={`/${tenant}/people`}
                    render={(routeProps) => (
                        <PeopleTable routeProps={routeProps} />
                    )}
                />
                <Route path={`/${tenant}/people/add`} render={() => <PeopleForm />} />
                <Route
                    path={`/${tenant}/people/:id/:tab`}
                    render={(routeProps) => <People routeProps={routeProps} />}
                />
                <Route
                    path={`/${tenant}/assets/:id/:tab`}
                    render={(routeProps) => <Asset routeProps={routeProps} />}
                />
                <Route
                    path={`/${tenant}/projects/:id`}
                    render={(props) => (
                        <Project
                            id={props.match.params.id}
                            loadMissingTime={loadMissingTime}
                        />
                    )}
                />
                <Route
                    path={`/${tenant}/incidents/new`}
                    render={(routeProps) => (
                        <CreateIncident history={routeProps.history} />
                    )}
                />
                <Route
                    path={`/${tenant}/incidents/:id`}
                    render={(props) => (
                        <Incident
                            id={props.match.params.id}
                            loadMissingTime={loadMissingTime}
                        />
                    )}
                />
                <Route path={`/${tenant}/incidentqueue`} render={() => <IncidentQueue />} />
                <Route
                    path={`/${tenant}/incidentsearch`}
                    render={() => <IncidentSearch />}
                />
                {/* <Route
                    path="/sessions"
                    render={() => <Sessions />}
                /> */}
                <Route
                    exact
                    path={`/${tenant}/tenants`}
                    render={(routeProps) => (
                        <TenantTable routeProps={routeProps} />
                    )}
                />
                <Route path={`/${tenant}/tenants/add`} render={() => <TenantForm />} />
                <Route
                    path={`/${tenant}/tenants/:id/:tab`}
                    render={(routeProps) => <Tenant routeProps={routeProps} />}
                />
                {/*<Route*/}
                {/*    path={`/${tenant}/admin`}*/}
                {/*    render={(routeProps) => <Admin routeProps={routeProps} />}*/}
                {/*/>*/}
                {/*<Route exact path={`/${tenant}/pto`} component={PTOManagement} />*/}
                {/*<Route*/}
                {/*    exact*/}
                {/*    path={`/${tenant}/pto/:date`}*/}
                {/*    render={(routeProps) => (*/}
                {/*        <PTOManagement date={routeProps.match.params.date} />*/}
                {/*    )}*/}
                {/*/>*/}
            </Switch>
        </>
    );
};

MainRouter.propTypes = {
    loadMissingTime: PropTypes.func.isRequired,
};

export default MainRouter;
