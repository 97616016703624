import React from 'react';

import { CloseIncidents } from '../api/IncidentAPI';

export const colIcon = (sortCol, name, descending) => {
    if (sortCol === name) {
        return descending ? (
            <img src="/Images/SortUp.png"
                style={{ height: "14px", marginLeft: "4px" }} alt="" />
        ) : (
                <img src="/Images/SortDown.png"
                    style={{ height: "14px", marginLeft: "4px" }} alt="" />
            )
    }
    return null;
}
export const selectIncident = (e, incident, incidents) => {
    const newIncidentList = [...incidents];
    const incInList = newIncidentList.filter(listIncident =>
        listIncident === incident)
    if (incInList.length !== 1)
        return;
    incInList[0].selected = e.target.checked
    return newIncidentList;
}
export const getTotalHours = incidents => {
    const totalHours = incidents.reduce((total, incident) => {
        return total + incident.totalHours
    }, 0);
    return totalHours;
}
export const closeSelectedIncidents = (setLoading, incidents, setError, loadIncidents) => {
    setLoading(true);
    const incidentList = incidents.filter(incident =>
        incident.selected);
    const idList = incidentList.map(incident => incident.incidentID);
    CloseIncidents(idList).then(response => {
        if (!response.success) {
            setError(response.message);
            setLoading(false);
            return;
        }
        setLoading(false);
        loadIncidents();
    }).catch(err => {
        setError("An error occurred while closing the selected incidents.");
        console.log(err);
    })
}
export const truncateSummary = src => {
    const stripped = src.replace(/<\/?[^>]+(>|$)/g, '');
    if (stripped.length < 250) {
        return stripped;
    }
    return stripped.substring(0, 250) + "...";
}
export const stripTags = src => {
    return src.replace(/<\/?[^>]+(>|$)/g, '');
}
export const cleanseSummary = src => {
    const stripped = src.replace(/<\/?[^>]+(>|$)/g, '');
    return stripped;
}
export const changeSort = (sortCol, column, descending, setSortCol, setDescending) => {
    if (column === sortCol) {
        setDescending(!descending);
        return;
    }
    setDescending(true);
    setSortCol(column);
}
export const sortIncidents = (incidents, sortCol, descending) => {
    const sortedIncidents = [...incidents];
    sortedIncidents.sort((incident1, incident2) =>
        incident1[sortCol] > incident2[sortCol] ?
            descending ? -1 : 1
            : descending ? 1 : -1
    );
    return sortedIncidents;
}