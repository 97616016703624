import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ServiceTable from '../Services/ServiceTable.js';
import ServiceForm from './ServiceForm.js';
import Service from './Service.js';

// Root component for service management
// - List Services with filter for active and Add/edit capability. Rows should be linked to their respective edit form.
//  - Service form should allow for assignment of hooks
//  - List service transition operations
//   - 'Manual' transition operations can be added
//   - 'Automatic' transition operations get added or removed when a hook is removed. 
// - List Organizations and what services they consume. Rows should link to their respective service org page
//  - Service Org page should show the status of the service, hooks, and transition operations with controls to:
//      1. Turn the service UP if its not UP
//      2. Turn the service DOWN if its not UP
//      3. Continue if manual interaction is needed on the transition.
// - List Service Org Transitions. This will show service k
export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    return (
        <Switch>
            <Route exact path={`/${tenant}/services`} render={(routeProps) =>
                <ServiceTable routeProps={routeProps} />
            } />
            <Route path={`/${tenant}/services/add`} render={(routeProps) =>
                <ServiceForm routeProps={routeProps} />
            } />
            <Route path={`/${tenant}/services/:id/:tab`} render={(routeProps) =>
                <Service routeProps={routeProps} />
            } />
            <Redirect from={`/${tenant}/services/:id/`} to={`/${tenant}/services/:id/info`} />
        </Switch>
    );
}