import React, {useState, useEffect} from 'react';
import { GetOrganizationPBXs } from '../../api/TelephonyAPI.js';
import LoadingShroud from '../App/LoadingShroud.js';
import NexusGrid from '../App/NexusGrid';
import TelephonyImportExport from './telephonyimportexport.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [filterAct, setFilterAct] = useState(false);
    const [pbxs, setPbxs] = useState([]);

    useEffect(()=>{
        loadItems(filterAct);
    },[]);

    const loadItems = (filterActive) => {
        const orgID = props.routeProps.match.params.id
        GetOrganizationPBXs({ content: { orgID: Number(orgID), active: !filterActive }})
            .then(res => {
                if (res != null) {
                    setPbxs(res.content);
                    setLoaded(true);
                    setFilterAct(filterActive);
                } else {
                    setLoaded(false);
                    setError(true);
                    setFilterAct(filterActive);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    const filterChanged=(e)=> {
        loadItems(e.target.checked);
    }

        const headers = [
            { Header: "PBX ID", accessor: "pbxid" },
            { Header: "Name", accessor: "name" },
            { Header: "Description", accessor: "description" },
            { Header: "Host", accessor: "host" },
            { Header: "SQL Host", accessor: "sqlhost" },
            { Header: "SQL User", accessor: "sqluser" },
            { Header: "SQL Port", accessor: "sqlport" },
            { Header: "SSH User", accessor: "sshuser" },
            { Header: "SSH Port", accessor: "sshport" }
        ];

        return (
            <div>
                <LoadingShroud Active={!loaded} />
                {loaded ? (
                    <div>
                        <div className="telephony-tab-heading">PBXs</div>
                        <TelephonyImportExport
                            routeProps={props.routeProps}
                            objectType={"PBX"}
                            importCallback={() => loadItems(filterAct)} />
                        <div className="telephony-object-filters">
                            <label>Show Inactive</label>
                            <input type="checkbox" onChange={(e) => filterChanged} checked={filterAct} id="" />
                        </div>
                        <NexusGrid
                            linkTo={"/${tenant}/organizations/" + props.routeProps.match.params.id + "/pbx/:key"}
                            tableKey={"pbxid"}
                            hiddenColumns={['pbxid']}
                            data={pbxs}
                            defaultSortBy={"Name"}
                            columns={headers}
                            filterHeaders={false}
                            pageMode={"none"}
                        />
                    </div>
                ) : error ? (
                    <p>An error occurred while loading PBX data.</p>
                ) : null}
            </div>
        )
}