import APIRequest from './APIRequest';

export const GetOrgHookList = (body) => {
    return APIRequest({
        url: `Hook/GetOrgHookList`,
        method: "post",
        body
    });
}

export const GetHookList = () => {
    return APIRequest({
        url: `Hook/GetHookList`,
        method: "GET"
    });
}