import React, {useState, useEffect} from 'react';


const ProjectHeader = props => {
    // Leaving these here for when values need to be editable in state. 
    const [editProjectStartDate, setEditProjectStartDate] = useState(new Date());
    const [editProjectEndDate, setEditProjectEndDate] = useState(new Date());
    const [editProjectContact, setEditProjectContact] = useState(0);
    const [editProjectAssignedTo, setEditProjectAssignedTo] = useState(0);
    const [editProjectTeam, setEditProjectTeam] = useState(0);
    const [editProjectParent, setEditProjectParent] = useState(0);
    const [editProjectHours, setEditProjectHours] = useState(0);
    const [editProjectPercentage, setEditProjectPercentage] = useState(0);
    const [editProjectWorkType, setEditProjectWorkType] = useState(0);
    const [editProjectTitle, setEditProjectTitle] = useState(0);
    const [editProjectDescription, setEditProjectDescription] = useState(0);
    const [editProjectResources, setEditProjectResources] = useState(0);
    const [error, setError] = useState("");
    useEffect(() => {
        // Same as above...
        setEditProjectStartDate(new Date(Date.parse(props.projectInfo.incidentDate)));
        setEditProjectEndDate(new Date(Date.parse(props.projectInfo.incidentDate)));
        setEditProjectContact(props.projectInfo.statusID);
        setEditProjectAssignedTo(props.projectInfo.requestorID);
        setEditProjectTeam(props.projectInfo.priorityID);
        setEditProjectParent(props.projectInfo.assignedToID);
        setEditProjectHours(props.projectInfo.requestTypeID);
        setEditProjectPercentage(props.projectInfo.projectID);
        setEditProjectWorkType(props.projectInfo.workTypeID);
        setEditProjectTitle(props.projectInfo.assetID);
        setEditProjectDescription(props.projectInfo.locationID);
        setEditProjectResources(props.projectInfo.teamID);
    }, [])
    return (
        <table className="nextTable incidentTable">
            <tbody>
                <tr>
                    <th colSpan="4" className="incidentTableHeader">
                        <h3>{props.projectInfo.companyName}</h3>
                    </th>
                </tr>
                <tr>
                    <th>Company Contact:</th>
                    <td style={{maxWidth: "200px"}}>
                        {props.projectInfo.contact}
                    </td>
                    <th>Estimated Hours:</th>
                    <td>
                        {props.projectInfo.estimatedHours}
                    </td>
                </tr>
                <tr>
                    <th>Assigned To:</th>
                    <td>
                        {props.projectInfo.assignedTo}
                    </td>
                    <th>Percentage Complete:</th>
                    <td>
                        {props.projectInfo.percentageComplete}%
                    </td>
                </tr>
                <tr>
                    <th>Team:</th>
                    <td>
                        {props.projectInfo.team}
                    </td>
                    <th>Work Type:</th>
                    <td>
                        {props.projectInfo.workType}
                    </td>
                </tr>
                <tr>
                    <th>Parent Project:</th>
                    <td style={{maxWidth: "200px"}}>
                        {props.projectInfo.parentProject}
                    </td>
                    <th>Title:</th>
                    <td>
                        {props.projectInfo.title}
                    </td>
                </tr>
                <tr>
                    <th>Start Date:</th>
                    <td>
                        {props.projectInfo.startDate}
                    </td>
                    <th>Description:</th>
                    <td>
                        {props.projectInfo.description}
                    </td>
                </tr>
                <tr>
                    <th>End Date:</th>
                    <td>
                        {props.projectInfo.endDate}
                    </td>
                    <th>Resources:</th>
                    <td>
                        {props.projectInfo.resourcesNeeded}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ProjectHeader;