import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetServiceList } from '../../api/ServiceAPI';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);

    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);
    const loadServices = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        setFilterResults(filters);
        setSortByResults(sortBy);
        firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
        firstRender.current = false;
        GetServiceList({ pageSize: pageSize, pageIndex: pageIndex, sortBy: sortBy, filters: filters})
            .then((response) => {
                setLoadingFilterSort(false);
                setLoading(false);
                if (response.success) {
                    setServices(response.content);
                    setTotalResults(response.totalCount);
                    setTotalPages(response.pageCount);
                }
            })
            .catch(() => {
                setLoadingFilterSort(false);
                setLoading(false);
            });
    }, []);

    const headers = [
        { Header: "Service ID", accessor: "serviceId" },
        { Header: "Service Name", accessor: "serviceName" },
        { Header: "Description", accessor: "serviceDescription" },
        {
            Header: "Active", accessor: "active",
            Cell: (row) => {
                return <div>{row.row.original.active ? "Active" : "Disabled"}</div>;
            },
        },
        { Header: "Hooks", accessor: "hookString" }
    ];

    return (
        <div>
            <h2>Services</h2>
            <Link to={`/${tenant}/Services/add`}>
                <div id="addServiceButton" className="nexusButtonCommon"><i className="fas fa-plus"></i>Add Service</div>
            </Link>
            <NexusGrid
                linkTo={`/${tenant}/Services/:key`}
                tableKey={"serviceId"}
                hiddenColumns={['serviceId']}
                data={services}
                defaultSortBy={"serviceName"}
                columns={headers}
                filterHeaders={true}
                fetchData={loadServices}
                filterData={filterResults}
                sortByData={sortByResults}
                loading={loading}
                loadingFilterSort={loadingFilterSort}
                pageMode="remote"
                perPage={25}
                totalResults={totalResults}
                totalPages={totalPages}
            />
        </div>
    )
}
