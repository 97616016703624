import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../App/loader.js';
import CredentialTypeHistoryTable from './CredentialTypeHistoryTable'
import { GetHookList } from '../../api/HookAPI';
import { GetCredentialType, UpdateCredentialType, DeleteCredentialType } from '../../api/CredentialAPI.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirectList, setRedirectList] = useState(false);
    const [hooks, setHooks] = useState([]);
    const [credentialType, setCredentialType] = useState({
        credentialTypeID: 0,
    });

    useEffect(() => {
        loadHooks();
    }, []);

    useEffect(() => {
        loadCredentialType();
    }, [props.routeProps.match.params.typeID]);

    const loadHooks = () => {
        setLoading(true);
        GetHookList()
            .then(res => {
                if (res.success) {
                    let hookOptions = [];
                    hookOptions.push("");
                    res.content.map(h => {
                        hookOptions.push(h.name);
                    });
                    setHooks(hookOptions);
                }
            });
        setLoading(false);
    }

    const loadCredentialType = () => {
        let credentialTypeID = parseInt(props.routeProps.match.params.typeID, 10);
        if (!isNaN(credentialTypeID) && credentialTypeID !== 0) {
            setLoading(true);
            GetCredentialType({ content: credentialTypeID })
                .then(res => {
                    if (res.success) {
                        setCredentialType(res.content.credentialType);
                    }
                });
        }
        setLoading(false);
    }

    const saveCredentialType = () => {
        if (!isNaN(credentialType.credentialTypeID) && credentialType.credentialTypeID !== 0) {
            setLoading(true);
            UpdateCredentialType({ ... credentialType })
                .then(res => {
                    if (res.success) {
                        setMessage("CredentialType Saved");
                        setCredentialType(res.credentialType);
                    } else {
                        setMessage(res.message);
                    }
                });
        }
        setLoading(false);
    }

    const deleteCredentialType = () => {
        if (!isNaN(credentialType.credentialTypeID) && credentialType.credentialTypeID !== 0) {
            setLoading(true);
            DeleteCredentialType({ content: credentialType.credentialTypeID })
                .then(res => {
                    if (res.success) {
                        setMessage("Credential Deleted");
                        setRedirectList(true);
                    } else {
                        setMessage(res.message);
                    }
                });
        }
        setLoading(false);
    }

    const submit = (e) => {
        e.preventDefault();
        saveCredentialType();
    }

    const inputChanged = (e) => {
        let newCredentialType = { ...credentialType };
        newCredentialType[e.target.name] = e.target.value;
        setCredentialType(newCredentialType);
    }

    const setHook = (hook) => {
        let newCredentialType = { ...credentialType };
        newCredentialType["hook"] = hook;
        setCredentialType(newCredentialType);
    }
    
    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            {redirectList && <Redirect to={`/${tenant}/CredentialTypes`} />}
            <BreadcrumbsItem to={`/${tenant}/CredentialTypes`} label='Credential Types' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td><input type="text" name="name" id="name" value={credentialType.name} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td><input type="text" name="description" id="description" value={credentialType.description} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                            <tr>
                                <td>Hook:</td>
                                <td>
                                    <select name="hookSelect" id="hookSelect" value={credentialType.hook} onChange={(e) => setHook(e.target.value)}>
                                        {hooks.map((hook) =>
                                            <option key={hook} value={hook}>{hook}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="nexusFormSubmit">
                                        <input type="submit" name="SaveCredentialType" id="SaveCredentialType" value="Update Credential Type" className="nexusButton" />
                                    </div>
                                </td>
                                <td>
                                    <div className="nexusFormSubmit">
                                        <input type="button" name="DeleteCredentialType" id="DeleteCredentialType" value="Delete CredentialType" className="nexusButton" onClick={() => deleteCredentialType()}/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {message}
                </form>
            </div>
            {credentialType.credentialTypeID !== 0 && <CredentialTypeHistoryTable credentialTypeID={credentialType.credentialTypeID}/>}
        </div>
    );
}