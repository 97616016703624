import React, {useState, useEffect} from 'react';
import Loader from '../App/loader.js';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import NexusAPI from '../../nexusapi';

export default props => {
    const [files, setFiles]  = useState([]);
    const [loaded, setLoaded]  = useState(true);
    const [error, setError] = useState(false);
    const [showFilePond, setShowFilePond]  = useState(false);
    const [isImport,  setIsImport] = useState(false);
    const [importError,  setImportError]  = useState(false);
    const [importMessage, setImportMessage]  = useState('');
    const [exportTypes, setExportTypes] = useState(props.objectType);
    var pond =  React.createRef();
    var api = new NexusAPI('Sierra');

    useEffect(() => {
        console.log(pond);
    },[]);

    const exportObject = (e) => {
        e.preventDefault();
        let download = require('downloadjs');
        const orgID = props.routeProps.match.params.id;
        const exportType = exportTypes;
        api.getFile({
            controller: 'Telephony',
            action: 'ExportObjects',
            method: 'POST',
            body: { orgID: Number(orgID), exportType: exportType },
            success: (data) => {
                if (data != null) {
                    data.blob().then(function (myBlob) {
                        console.log(myBlob);
                        download(myBlob, exportType + '.csv', myBlob.type);
                    });
                } else {
                    setLoaded(false);
                    setError(true);
                }
            },
            error: (error) => {
                console.log(error);
                setError(true);
            },
        });
        setShowFilePond(false);
        setIsImport(false);
    }

    const importObject = (e) => {
        e.preventDefault();
        setShowFilePond(true);
        setIsImport(true);
        setImportError(false);
        setImportMessage('');
    }

    const handleInit = () => {
        console.log('FilePond instance has initialised', pond);
    }

    const setServer = () => {
        const differential = true;
        const orgID = props.routeProps.match.params.id;
        const exportType = exportType;
        const formData = [
            { key: 'orgID', value: Number(orgID) },
            { key: 'objectType', value: exportType },
            { key: 'checkDifferential', value: differential },
        ];
        return api.uploadFilePond({
            controller: 'Telephony',
            action: 'ImportObjects',
            method: 'POST',
            formData: formData,
            onload: (response, pond) => {
                var data = JSON.parse(response);
                if (!data.success) {
                    setShowFilePond(false);
                    setImportError(true);
                    setImportMessage(data.message);
                } else {
                    if (props.importCallback) {
                        props.importCallback();
                    }
                }
            },
        });
    }

        return (
            <div className="telephony-import-export">
                {showFilePond && (
                    <div className="telephony-filepond-container">
                        <FilePond
                            ref={pond}
                            allowMultiple={false}
                            server={setServer()}
                        />
                    </div>
                )}
                {importError && (
                    <div className="telephony-filepond-failure">
                        <div className="telephony-filepond-response">
                            {importMessage}
                        </div>
                    </div>
                )}
                {loaded ? (
                    <div className="telephony-button-container">
                        <div
                            className="nexusButtonCommon"
                            onClick={exportObject}
                        >
                            Export
                        </div>
                        <div
                            className="nexusButtonCommon"
                            onClick={importObject}
                        >
                            Import
                        </div>
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                    <Loader />
                )}
            </div>
        );
}
