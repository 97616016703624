import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../App/loader.js';
import TabView from '../App/tabview.js';

import TabServiceEdit from './TabServiceEdit.js';
import TabServiceHooks from './TabServiceHooks.js';
import TabServiceOperations from './TabServiceOperations.js';
import TabServiceOrganizations from './TabServiceOrganizations.js';
import ServiceOrganization from './ServiceOrganization.js';
import ServiceOrganizationTransition from './ServiceOrganizationTransition.js';
import { getBreadcrumbBack } from '../Organization/organization';

import CreateCPanelAccountForm from './ServiceOrgTransitionForms/CreateCPanelAccountForm.js'

import { GetService } from '../../api/ServiceAPI.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [tabList, setTabList] = useState([]);
    const [service, setService] = useState({
        serviceName: "",
        serviceDescription: "",
        serviceId: 0,
        active: true
    });

    useEffect(() => {
        const serviceID = parseInt(props.routeProps.match.params.id, 10);
        loadService(serviceID);
        setTabList(getTabList());
    }, [props.routeProps.match.params.id]);

    const loadService = () => {
        let serviceId = parseInt(props.routeProps.match.params.id, 10);
        if (!isNaN(serviceId) && serviceId !== 0) {
            setLoading(true);
            GetService({ content: serviceId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setService(res.content);
                        }
                    }
                });
        }
        setLoading(false)
    }

    const updateService = (newService) => {
        setService(newService);
    }

    const getTabList = () => {
        const tabListBaseURL = "/" + tenant + "/services/" + props.routeProps.match.params.id;
        const tabList = [];
        tabList.push({ label: "Service Info", url: tabListBaseURL + "/info", default: true });
        tabList.push({ label: "Hooks", url: tabListBaseURL + "/hooks" });
        tabList.push({ label: "Operations", url: tabListBaseURL + "/operations" });
        tabList.push({ label: "Organizations", url: tabListBaseURL + "/organizations" });
        return tabList;
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div className="orgContainer">
            <BreadcrumbsItem to={`/${tenant}/services/`} label='Services' />
            <BreadcrumbsItem to={`/${tenant}/services/${service.serviceId}/${getBreadcrumbBack(props.routeProps.match.params.id)}`} label={`${service.serviceName}`} />
            <div className="orgContainerInner">
                <h2>{service.serviceName}</h2>
                <TabView tabs={tabList}>
                    <Switch>
                        <Route path={`/${tenant}/services/:serviceId/info`} render={(routeProps) =>
                            <TabServiceEdit routeProps={routeProps} service={service} updateService={updateService} />
                        } />
                        <Route path={`/${tenant}/services/:serviceId/hooks`} render={(routeProps) =>
                            <TabServiceHooks routeProps={routeProps} service={service} />
                        } />
                        <Route path={`/${tenant}/services/:serviceId/operations`} render={(routeProps) =>
                            <TabServiceOperations routeProps={routeProps} service={service} />
                        } />
                        {/* Begin Service Org Transition Forms */}
                        <Route path={`/${tenant}/services/:serviceId/organizations/:orgId/transitions/:transId/CreateCPanelAccount`} render={(routeProps) =>
                            <CreateCPanelAccountForm routeProps={routeProps} service={service} />
                        } />
                        {/* End Service Org Transition Forms */}
                        <Route path={`/${tenant}/services/:serviceId/organizations/:orgId/transitions/:transId`} render={(routeProps) =>
                            <ServiceOrganizationTransition routeProps={routeProps} service={service} />
                        } />
                        <Route path={`/${tenant}/services/:serviceId/organizations/:orgId`} render={(routeProps) =>
                            <ServiceOrganization routeProps={routeProps} service={service} />
                        } />
                        <Route path={`/${tenant}/services/:serviceId/organizations`} render={(routeProps) =>
                            <TabServiceOrganizations routeProps={routeProps} service={service} />
                        } />
                    </Switch>
                </TabView>
            </div>
        </div>
    );
}