import React, { useState, useEffect } from 'react';
import { GetAccountSummary, StartSession } from '../../../../api/WHMServiceAPI';
import Loader from '../../../App/loader';

export default props => {
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({
        domain: "",
        user: "",
        plan: "",
        email: "",
        ip: "",
        suspended: false,
        suspendedReason: "",
    });
    
    useEffect(() => {
        const orgID = parseInt(props.routeProps.match.params.id, 10);
        loadAccountSummary(orgID);
    }, [props.routeProps.match.params.id]);

    const loadAccountSummary = (orgID) => {
        setLoading(true);
        GetAccountSummary({content: orgID})
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setSummary({
                            domain: res.domain,
                            user: res.user,
                            plan: res.plan,
                            email: res.email,
                            ip: res.ip,
                            suspended: res.suspended,
                            suspendedReason: res.suspendedReason,
                        });
                    }
                }
            })
        setLoading(false);
    }
    
    const launchSession = () => {
        setLoading(true);
        StartSession({ content: parseInt(props.org.orgID, 10) })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        window.location.href = res.content;
                    }
                }
            })
        setLoading(false)
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <h3>CPanel</h3>
            <div className="nexusForm">
                <table className="nexusFormInner">
                    <tbody>
                        <tr>
                            <td> User: </td>
                            <td> {summary.user} </td>
                        </tr>
                        <tr>
                            <td> Email: </td>
                            <td> {summary.email} </td>
                        </tr>
                        <tr>
                            <td> Plan: </td>
                            <td> {summary.plan} </td>
                        </tr>
                        <tr>
                            <td> Domain: </td>
                            <td> {summary.domain} </td>
                        </tr>
                        <tr>
                            <td> IP: </td>
                            <td> {summary.ip} </td>
                        </tr>
                        { summary.suspended && <>
                            <tr>
                                <td> Account Suspended </td>
                            </tr>
                            <tr>
                                <td> Reason: </td>
                                <td> {summary.suspendedReason} </td>
                            </tr>
                        </>}
                    </tbody>
                </table>
                {!summary.suspended && <div className="nexusButtonCommon" onClick={() => launchSession()}>Launch CPanel</div>}
            </div>
        </div>
    );
}