import { combineReducers } from 'redux';
import WebSocketReducer from './webSocketReducer';
import TenantReducer from './TenantReducer';
import UserReducer from './user-slice';
import CartReducer from './cart-slice';
import UiReducer from './ui-slice';
import RTCReducer from './rtcReducer';

export default combineReducers({
    WebSocketReducer,
    UserReducer,
    CartReducer,
    UiReducer,
    TenantReducer
});
