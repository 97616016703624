import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../App/loader';
import { sendCartData } from '../../../Redux/cart-actions';
import { CRUD_ENUM } from '../../../enums/crud.js';
import * as Validation from '../../../utils/input-validators';
import { GetOrganizations } from '../../../api/OrganizationAPI';
import EditableTableCell from '../../UI/EditableTableCell';
import { UpdatePerson, DisablePerson } from '../../../api/PeopleAPI';
import Notification from '../../UI/Notification';

const TabInfo = (props) => {
    const {
        firstName,
        lastName,
        personID,
        orgID,
        personOrgID,
        emailAddress1,
        emailAddress2,
        mobile1,
        mobile2,
        extension,
        officePhone,
        homePhone,
        gender,
        title,
        //authenticationMethod,
        creationDate,
    } = props.person;
    const cd = new Date(creationDate).toLocaleString();

    const initialUserState = {
        firstName: firstName,
        lastName: lastName,
        personID: personID,
        orgID: orgID,
        personOrgID: personOrgID,
        emailAddress1: emailAddress1,
        emailAddress2: emailAddress2,
        mobile1: mobile1,
        mobile2: mobile2,
        extension: extension,
        officePhone: officePhone,
        homePhone: homePhone,
        gender: gender,
        title: title,
        //authenticationMethod: authenticationMethod,
    };

    const onChangeHandler = (e) => {
        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });
    };

    const [formInputs, setFormInputs] = useState(initialUserState);
    const dispatch = useDispatch();
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [displayNotification, setDisplayNotification] = useState(false);
    const [status, setStatus] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const canUserEditPerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );

    //useEffect(() => {
    //    console.log(formInputs);
    //}, []);

    /**
     * Handler for form submission. Checks the validity of the form controls,
     * then submits if all changed values are valid; else, notify user of errors and
     * remain on page
     * @param {*} e The triggering form element submission
     */
    const formSubmitClickedHandler = async (e) => {
        e.preventDefault();

        const payload = formInputs;

        try {
            //const response = await UpdatePerson(payload);
            //if (response.success) {
            //    setIsFormEditable(false);
            //    setSubmitted(true);
            //    setStatus('success');
            //    setDisplayNotification(true);
            //}
            UpdatePerson(payload).then((response) => {
                if (response.success) {
                    setIsFormEditable(false);
                    setSubmitted(true);
                    setStatus('success');
                    setDisplayNotification(true);
                }
            })
        } catch (error) {
            setSubmitted(true);
            setStatus('error');
        }
    };

    const editPersonInfoToggle = (
        <>
            <div className="form-group">
                {isFormEditable ? (
                    <button
                        className="btn btn-danger nexusButtonCommon"
                        type="button"
                        onClick={() => setPersonInactive()}
                    >
                        Set Person Inactive
                    </button>
                ) : (
                    <button
                        className="btn btn-warning nexusButtonCommon"
                        type="button"
                        onClick={() => setIsFormEditable(!isFormEditable)}
                    >
                        Edit Person's Info
                    </button>
                )}
                <hr />
            </div>
        </>
    );

    const resetClickedHandler = () => {
        setIsFormEditable(false);
        setFormInputs(initialUserState);
    };

    const setPersonInactive = () => {
        const payload = {
            personID: personID,
            orgID: orgID,
        };

        console.log(payload);
        //dispatch(sendCartData(payload, CRUD_ENUM.DELETE));
        DisablePerson(payload).then((response) => {
            //
        })
    };

    const infoTable = (
        <div>
            {
                isLoaded ? (
                    <div>
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            className="infoTable"
                        >
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td>{firstName + ' ' + lastName}</td>
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="First Name"
                                        type="text"
                                        className="form-control"
                                        id="inputFirstName"
                                        name="firstName"
                                        placeholder={formInputs.firstName}
                                        defaultValue={formInputs.firstName}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Last Name"
                                        type="text"
                                        className="form-control"
                                        id="inputLastName"
                                        name="lastName"
                                        placeholder={formInputs.lastName}
                                        defaultValue={formInputs.lastName}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Title"
                                        type="text"
                                        className="form-control"
                                        id="inputTitle"
                                        name="title"
                                        placeholder={formInputs.title}
                                        defaultValue={formInputs.title}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Email Address 1"
                                        type="text"
                                        className="form-control"
                                        id="inputEmailAddress1"
                                        name="emailAddress1"
                                        placeholder={formInputs.emailAddress1}
                                        defaultValue={formInputs.emailAddress1}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Email Address 2"
                                        type="text"
                                        className="form-control"
                                        id="inputEmailAddress2"
                                        name="emailAddress2"
                                        placeholder={formInputs.emailAddress2}
                                        defaultValue={formInputs.emailAddress2}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Mobile 1"
                                        type="text"
                                        className="form-control"
                                        id="mobile1"
                                        name="mobile1"
                                        placeholder={formInputs.mobile1}
                                        defaultValue={formInputs.mobile1}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>

                                <tr>
                                    <EditableTableCell
                                        isFormEditable={isFormEditable}
                                        title="Mobile 2"
                                        type="text"
                                        className="form-control"
                                        id="mobile2"
                                        name="mobile2"
                                        placeholder={formInputs.mobile2}
                                        defaultValue={formInputs.mobile2}
                                        changed={(e) => onChangeHandler(e)}
                                    />
                                </tr>
                                <tr>
                                    <th>User Since:</th>
                                    <td>{cd}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    // : this.state.error ? (
                    <p>An error occurred while loading company data.</p>
                )
                // ) : (
                //     <Loader />
                // )
            }
        </div>
    );

    const formSubmitControls = (
        <>
            <hr />
            {isFormEditable ? (
                <div className="form-group">
                    <button
                        className="btn btn-danger nexusButtonCommon"
                        onClick={resetClickedHandler}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary nexusButtonCommon"
                        disabled={!isFormEditable}
                    >
                        Save
                    </button>
                </div>
            ) : null}
        </>
    );

    const content = (
        <form onSubmit={formSubmitClickedHandler}>
            {canUserEditPerson ? editPersonInfoToggle : null}
            {infoTable}
            {canUserEditPerson ? formSubmitControls : null}
            {submitted && displayNotification ? (
                <Notification
                    status={status}
                    title="Submission Result"
                    message={status}
                    dismiss={() => setDisplayNotification(false)}
                ></Notification>
            ) : null}
        </form>
    );
    return content;
};

export default TabInfo;
