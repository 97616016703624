/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import {
  RTC_MESSAGES,
  WebSocketContext,
  UserAgentSessionRequestType
} from '../../../Redux/WebSocket';

const SessionFunctions = ({ toggleSpecialKey, keys, agent }) => {
  let assetID = useSelector((state) => state.WebSocketReducer.SelectedAsset);
  const socket = React.useContext(WebSocketContext);

  const sendLocalClipboard = async () => {
    if (navigator.clipboard) {
      navigator.clipboard.readText()
        .then(text => {
          console.log(`read from clipboard: ${text}`);
          socket.send(JSON.stringify({
            type: UserAgentSessionRequestType,
            body: {
              UserName: agent,
              AssetID: parseInt(assetID),
              SubMessageType: RTC_MESSAGES.CLIPBOARD_CHANGE,
              Payload: text,
            },
          }));
        }).catch(err => console.error(err));
    }
  }

  return (
    <div style={{ display: 'inline' }}>
      {
        navigator.clipboard ? <button className="nexusButtonCommon" onClick={sendLocalClipboard}>Send Clipboard</button> : ''
      }
      <button className={`nexusButtonCommonNoHover ${keys.includes('command') ? 'nexusButtonCommonToggle' : ''}`}
        onClick={() => toggleSpecialKey('command')}>Win</button>

      <button className={`nexusButtonCommonNoHover ${keys.includes('control') ? 'nexusButtonCommonToggle' : ''}`}
        onClick={() => toggleSpecialKey('control')}>Ctrl</button>
      <button className={`nexusButtonCommonNoHover ${keys.includes('alt') ? 'nexusButtonCommonToggle' : ''}`}
        onClick={() => toggleSpecialKey('alt')}>Alt</button>
      <button className={`nexusButtonCommonNoHover ${keys.includes('shift') ? 'nexusButtonCommonToggle' : ''}`}
        onClick={() => toggleSpecialKey('shift')}>Shift</button>
    </div>
  );
}

export default SessionFunctions;