import { send } from 'process';
import React, { useEffect, useRef, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GetOrganizations } from '../../../api/OrganizationAPI';
import { AddTenant, UploadTenantLogo } from '../../../api/TenantAPI';
import { CRUD_ENUM } from '../../../enums/crud';
import { sendTenantData } from '../../../Redux/cart-actions';
import { userActions } from '../../../Redux/user-slice';
import * as Validation from '../../../utils/input-validators';
import Loader from '../../App/loader';
import classes from './TenantForm.module.css';
import { FilePond } from 'react-filepond';
import "filepond/dist/filepond.min.css";

const nexusLabel = 'nexusFormLabel';

const TenantForm = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const showLoader = useSelector((state) => state.UiReducer.loading);
    const canUserModifyTenant = useSelector(
        (state) => state.UserReducer.canModifyTenants
    );
    const [tURI, setTURI] = useState("");
    
    const dispatch = useDispatch();
    const [formInputsValidity, setFormInputsValidity] = useState({
        name: true,
        active: true,
        expiration: true,
        uri: true,
        default: true,
        emailTemplate: true,
        organization: true,
        logo: true,
    });
    
    const [showMess, setShowMess]  = useState(false);
    const [showSus, setShowSus] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [validImgHeightWidth, setValidImgHeightWidth] = useState(false);

    const activeInputRef = useRef();
    const expirationInputRef = useRef();
    const uriInputRef = useRef();
    const emailTemplateInputRef = useRef();
    const organizationInputRef = useRef();
    const logoInputRef = useRef();

    const [organizations, setOrganizations] = useState([]);
    const toggleHandler = () => {
        dispatch(userActions.setPeopleModifyProperty(!canUserModifyTenant));
    };

    useEffect(() => {
        GetOrganizations()
            .then((res) => {
                setOrganizations(res.organizations);
            })
            .catch(() => {
                // TODO: properly handle errors
            });
    }, []);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = error => reject(error);
        });
    };

    const onFileChange = (e) => {
        getBase64(e.target.files[0]).then((data) => {
            var image = new Image();
            image.src = data;
            image.onload = function () {
                if (this.height < 181 && this.width < 181) {
                    setValidImgHeightWidth(true);
                } else {
                    setValidImgHeightWidth(false);
                }
            };
        });
    };

    const submitHandler = (event) => {
        event.preventDefault();

        let enteredActive = true;
        let enteredExpiration = null;
        const enteredURI = uriInputRef.current.value;
        const enteredEmailTemplate = emailTemplateInputRef.current.value;
        const enteredOrganization = +organizationInputRef.current.value;
        let enteredLogo = null;

        if (activeInputRef.current.value === "true") {
            enteredActive = true;
        } else {
            enteredActive = false;
        }
        if (expirationInputRef.current.value === "") {
            enteredExpiration = null;
        } else {
            enteredExpiration = expirationInputRef.current.value;
        }
        //console.log(logoInputRef.current.value); // path
        //console.log(logoInputRef.current); // input tag
        //console.log(document.getElementById("logo").files[0]); // actual file
        if (logoInputRef.current.value === "" || logoInputRef.current.value === null || logoInputRef.current.value === undefined) {
            enteredLogo = null;
        } else {
            enteredLogo = document.getElementById("logo").files[0];
        }

        const enteredNameIsValid = true
        const enteredActiveIsValid = true;
        const enteredExpirationIsValid = true;
        const enteredURIIsValid = Validation.isValidString(enteredURI);
        const enteredDefaultIsValid = true;
        const enteredEmailTemplateIsValid = true;
        const enteredOrganizationIsValid = true;
        const enteredLogoIsValid = Validation.isNotNull(enteredLogo);

        setFormInputsValidity({
            name: enteredNameIsValid,
            active: enteredActiveIsValid,
            expiration: enteredExpirationIsValid,
            uri: enteredURIIsValid,
            default: enteredDefaultIsValid,
            emailTemplate: enteredEmailTemplateIsValid,
            organization: enteredOrganizationIsValid,
            logo: enteredLogoIsValid,
        });

        const formIsValid =
            enteredNameIsValid &&
            enteredActiveIsValid &&
            enteredExpirationIsValid &&
            enteredURIIsValid &&
            enteredDefaultIsValid &&
            enteredEmailTemplateIsValid &&
            enteredLogoIsValid;

        if (!formIsValid) {
            setErrorMsg("You are missing input fields. Please enter all the required information.");
            setShowMess(true);
            return; // don't allow submission
        }

        getBase64(enteredLogo).then(logoResult => {
            if (validImgHeightWidth === false) {
                setErrorMsg("Image Height and Width must not exceed 180px.");
                setShowMess(true);
                setShowSus(false);
            }
            else if (validImgHeightWidth === true) {
                const payload = {
                    tenant: {
                        tenantID: 0,
                        name: "",
                        active: enteredActive,
                        expiration: enteredExpiration,
                        uri: enteredURI,
                        default: false,
                        emailTemplate: enteredEmailTemplate,
                        organizationID: +enteredOrganization,
                        tenantLogo: null,
                    },
                    logo: logoResult,
                };

                AddTenant(payload).then((data) => {
                    if (data !== null || data !== undefined) {
                        if (data.success === true) {
                            setErrorMsg("");
                            setShowMess(false);
                            setShowSus(true);
                            resetClickedHandler();
                        } else {
                            setErrorMsg(data.message);
                            setShowMess(true);
                            setShowSus(false);
                        }
                    } else {
                        setErrorMsg("Something went wrong. Please try again later.");
                        setShowMess(true);
                        setShowSus(false);
                        resetClickedHandler();
                    }
                });
            }
        });
    };

    const Organizations = organizations.map((org) => (
        <option key={org.orgID} value={org.orgID}>
            {org.orgName}
        </option>
    ));

    const activeControlClasses = `${classes.control} ${
        formInputsValidity.active ? '' : classes.invalid
    }`;
    const expirationControlClasses = `${classes.control} ${
        formInputsValidity.expiration ? '' : classes.invalid
    }`;
    const uriControlClasses = `${classes.control} ${
        formInputsValidity.uri ? '' : classes.invalid
    }`;
    const emailTempControlClasses = `${classes.control} ${
        formInputsValidity.emailTemplate ? '' : classes.invalid
    }`;
    const organizationControlClasses = `${classes.control} ${
        formInputsValidity.organization ? '' : classes.invalid
    }`;
    const logoControlClasses = `${classes.control} ${
        formInputsValidity.logo ? '' : classes.invalid
    }`;

    const resetClickedHandler = () => {
        activeInputRef.current.value = true;
        expirationInputRef.current.value = null;
        uriInputRef.current.value = "";
        emailTemplateInputRef.current.value = "";
        organizationInputRef.current.value = 1;
        logoInputRef.current.value = null;
    };

    const formSubmitControls = (
        <>
            <hr />
            <div className="form-group">
                <button
                    className="btn btn-danger btn-block"
                    type="button"
                    onClick={resetClickedHandler}
                >
                    Reset Form
                </button>
                <button type="submit" className="btn btn-primary btn-block">
                    Submit
                </button>
            </div>
        </>
    );
    const userInputControls = (
        <>
            <div className="form-group">
                <label>Select an Organization</label>
                <div className={organizationControlClasses}>
                    <select
                        name="organization"
                        id="organization"
                        ref={organizationInputRef}
                    >
                        {Organizations}
                    </select>
                </div>
            </div>

            <div className="form-group">
                <label>Select Status</label>
                <div className={activeControlClasses}>
                    <select
                        name="active"
                        id="active"
                        ref={activeInputRef}
                    >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Expiration</label>
                <div className={expirationControlClasses}>
                    <input
                        type="date"
                        name="expiration"
                        id="expiration"
                        className="form-control"
                        ref={expirationInputRef}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Tenant URI Example: {window.location.protocol + "//" + window.location.host + "/" + tURI + "/"}</label>
                <div className={uriControlClasses}>
                    <input
                        type="text"
                        name="uri"
                        id="uri"
                        className="form-control"
                        ref={uriInputRef}
                        onChange={(e) => setTURI(e.target.value)}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Email Template</label>
                <div className={emailTempControlClasses}>
                    <input
                        type="text"
                        name="emailTemplate"
                        id="emailTemplate"
                        className="form-control"
                        ref={emailTemplateInputRef}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className={nexusLabel}>Logo Image</label>
                <div className={logoControlClasses}>
                    <input
                        type="file"
                        name="logo"
                        id="logo"
                        className="form-control"
                        ref={logoInputRef}
                        accept="image/png, image/jpeg"
                        onChange={(e) => onFileChange(e)}
                    />
                </div>
            </div>

            {formSubmitControls}
        </>
    );

    const userInputForm = (
        <div>
            <Link className="whiteLink" to={`/${tenant}/tenants/`}>
                <i className="fas fa-chevron-left"></i> Tenant List
            </Link>
            <h2>New Tenant</h2>
            <div className="bootstrap-wrapper">
                <Alert show={showMess} variant="danger" dismissible onClose={() => setShowMess(false)}>
                    {errorMsg}
                </Alert>
                <Alert show={showSus} variant="success" dismissible onClose={() => setShowSus(false)}>
                    Tenant was added to the database!
                </Alert>
            </div>
            <div className="nexusForm personForm">
                <form onSubmit={(e) => submitHandler(e)}>
                    {userInputControls}
                </form>

                {showLoader ? <Loader /> : null}
            </div>
        </div>
    );

    let content = canUserModifyTenant ? (
        userInputForm
    ) : (
        <p>You don't have permissions to access this feature</p>
    );

    return content;
};

export default TenantForm;
