import React, { createContext, useState, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import UserInfo from './UserInfo';
import SelectedDateIncidents from './SelectedDateIncidents';
import UnassignedIncidents from './UnassignedIncidents';
import MyIncidents from './MyIncidents';
import TotalOrganizations from './TotalOrganizations';
import TotalAssets from './TotalAssets';
import { GetUserDashboardAccordion } from '../../api/NavigationAPI';

const CollapsedContext = createContext(null);
export { CollapsedContext };

const Dashboard = props => {
    const [collapsedState, setCollapsedState] = useState(null);
    const OnlineAssets = useSelector(state => state.WebSocketReducer.AssetsOnline);

    useEffect(() => {
        GetUserDashboardAccordion().then(response => {
            setCollapsedState(response.content);
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    return (
        <div className="dashboard">
            <h2>Dashboard</h2>
            <div className="tabDisplayArea">
                <div className="bootstrap-wrapper">
                    <div className="row no-gutters">
                        <CollapsedContext.Provider value={collapsedState}>
                            {/*<UserInfo />*/}
                            <SelectedDateIncidents date={props.date} />
                            <UnassignedIncidents />
                            <MyIncidents />
                            <TotalOrganizations />
                            <TotalAssets assetNumOnline={OnlineAssets.length} />
                        </CollapsedContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;