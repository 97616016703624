import React, { Component } from 'react'
import {Accordion, Card, Table} from 'react-bootstrap'
import DNSAddressTable from './DNSAddressTable'
import DHCPAddressTable from './DHCPAddressTable'
import GatewayAddressTable from './GatewayAddressTable'
import WINSAddressTable from './WINSAddressTable'
import UnicastAddressTable from './UnicastAddressTable'

export default props => {

    const addressTable=()=>{
        switch(props.type){
            case "DNS":
                return(
                    <DNSAddressTable addresses = {props.addresses}/>
                )
            case "DHCP":
                return(
                    <DHCPAddressTable addresses = {props.addresses}/>
                )
            case "Gateway":
                return(
                    <GatewayAddressTable addresses = {props.addresses}/>
                )
            case "WINS":
                return(
                    <WINSAddressTable addresses = {props.addresses}/>
                )
            case "Unicast":
                return(
                    <UnicastAddressTable addresses = {props.addresses}/>
                )
            default:
                return(
                    <p>Address Type Not Found</p>
                )
        }
      }

    return (
      <div>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle eventKey={props.eventkey}>
                            {props.type}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={props.eventkey}>
                            <Card.Body>
                                    
                                        {addressTable()}
                                    
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card.Header>
                </Card>
      </div>
    )
}
