import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        persons: [],
        orgs: [],
        totalQuantity: 0,
        changed: false,
    },
    reducers: {
        replaceCart(state, action) {
            state.totalQuantity = action.payload.totalQuantity;
            state.items = action.payload.items;
        },

        addPersonToCart(state, action) {
            const newPerson = action.payload;
            const existingPerson = state.persons.find(
                (person) => person.id === newPerson.id
            );
            state.changed = true;

            if (!existingPerson) {
                state.persons.push({
                    id: newPerson.id,
                });
            } else {
            }
        },
    },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
