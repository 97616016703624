import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationTrunk, EditOrganizationTrunks } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';


export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [trunks, setTrunks] = useState({});
    const trunkTypes = [{label: "Customer", value: 1},{label: "Carrier", value: 2}]
    
    useEffect(() => {
        const trunkId = props.routeProps.match.params.trunkid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationTrunk({ content: { trunkId: Number(trunkId), orgID: Number(orgID) }})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setLoaded(true);
                        setTrunks(res.content[0]);
                    }
                    else
                        setLoaded(false);
                        setError(true);
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const trunkChanged = (e) => {
        var trunk = trunks;
        trunk[e.target.name] = e.target.value;
        setTrunks(trunk);
    }

    const saveTrunk = () => {
        setLoaded(false);
        setError(false);
        EditOrganizationTrunks({
            content: {
                trunkId: Number(trunks.trunkId),
                trunkName: trunks.trunkName,
                trunkDetails: trunks.trunkDetails,
                trunkType: Number(trunks.trunkType),
                orgid: trunks.orgid,
                organization: trunks.organization
            }
        })
            .then(res => {
                if (res != null) {
                    if (res.trunk) {
                        setLoaded(true);
                        setTrunks(res.content);
                    } else {
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

        return (
            <div className="telephony-trunk-info">
                {loaded ? (
                    <div>
                        <h3>{trunks.trunkName}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td><input type="text" name="trunkName" onChange={trunkChanged} value={trunks.trunkName} /></td>
                                </tr>
                                <tr>
                                    <th>Details:</th>
                                    <td><textarea type="text" name="trunkDetails" onChange={trunkChanged} value={trunks.trunkDetails} /></td>
                                </tr>
                                <tr>
                                    <th>Type:</th>
                                    <td>
                                        <select name="trunkType" value={trunks.trunkType} onChange={trunkChanged}>
                                            {trunkTypes.map(type =>
                                                <option key={type.value} value={type.value}>
                                                    {type.label}
                                                </option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/trunk/" + trunks.trunkId} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={saveTrunk}> Save </button>
                        </p>

                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}