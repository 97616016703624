import React, {useState, useEffect}from 'react';
import PBXList from '../../Telephony/pbxlist.js';
import SofSwitchList from '../../Telephony/softswitchlist.js';
import DIDList from '../../Telephony/didlist.js';
import CarrierList from '../../Telephony/carrierlist.js';
import TrunkList from '../../Telephony/trunklist.js';

export default props => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [pbxs, setPbxs] = useState([]);

    useEffect(() =>{

    },[]);

        return (
            <div>
                <CarrierList routeProps={props.routeProps} />

                <TrunkList routeProps={props.routeProps} />

                <SofSwitchList routeProps={props.routeProps} />

                <PBXList routeProps={props.routeProps} />

                <DIDList routeProps={props.routeProps} />
            </div>
        );
}
