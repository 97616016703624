import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    sortIncidents,
    selectIncident,
    truncateSummary,
    closeSelectedIncidents,
    changeSort,
    colIcon
} from '../../utils/incidentUtils';
import { GetMyIncidents } from '../../api/IncidentAPI';
import LoadingShroud from '../App/LoadingShroud';
import AccordionWidget from './AccordionWidget';
import { useSelector } from 'react-redux';

const MyIncidents = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [descending, setDescending] = useState(true);
    const [sortCol, setSortCol] = useState("referenceID");

    const [rawIncidentData, setRawIncidentData] = useState([]);
    const [incidents, setIncidents] = useState([]);

    const [error, setError] = useState("");
    useEffect(() => {
        loadIncidents();
    }, []);

    useEffect(() => {
        setIncidents(sortIncidents(rawIncidentData, sortCol, descending))
    }, [sortCol, descending, rawIncidentData]);

    const loadIncidents = () => {
        setLoading(true);
        GetMyIncidents().then(response => {
            if (!response.success) {
                setError(response.message);
                setLoading(false);
                return;
            }
            const addFlag = response.content.map(incident => {
                return { ...incident, selected: false };
            })
            setRawIncidentData(addFlag)
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setError("An error occurred while loading my incidents.");
            setLoading(false);
        })
    }
    const changeSelectIncident = (e, incident) => {
        setIncidents(selectIncident(e, incident, incidents));
    }
    return (
        <div className="col-12">
            <AccordionWidget title={'My Incidents'} dashboardPreference={'MyIncidents'}>
                <div className="widgetBody">
                    <LoadingShroud Active={loading} />
                    {error !== "" ? (
                        <div className="Error PaddedError">{error}</div>
                    ) : incidents.length === 0 ? (
                        <div className="paddedBold">There are no assigned incidents.</div>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <table className="nextTable incidentTable">
                                <tbody>
                                    <tr>
                                        <th onClick={() => changeSort(sortCol,
                                            "referenceID", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Incident ID
                                    {colIcon(sortCol, "referenceID", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "companyName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Company
                                    {colIcon(sortCol, "companyName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "requestedByName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Requestor
                                    {colIcon(sortCol, "requestedByName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "summary", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Summary
                                    {colIcon(sortCol, "summary", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "project", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Project
                                    {colIcon(sortCol, "project", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "priorityName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Priority
                                    {colIcon(sortCol, "priorityName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "statusName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Status
                                    {colIcon(sortCol, "statusName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "incidentDateFormatted", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            date
                                    {colIcon(sortCol, "incidentDateFormatted", descending)}
                                        </th>
                                        <th>Close</th>
                                    </tr>
                                    {incidents.map(incident =>
                                        <tr key={incident.incidentID}>
                                            <td>
                                                <Link to={`/${tenant}/Incidents/${incident.incidentID}`} className="incidentRefLink">
                                                    {incident.referenceID}
                                                </Link>
                                            </td>
                                            <td>{incident.companyName}</td>
                                            <td>{incident.requestedByName}</td>
                                            <td>
                                                <div className="incidentSummary"
                                                    dangerouslySetInnerHTML={{ __html: truncateSummary(incident.summary) }} />
                                            </td>
                                            <td>
                                                {incident.projectID > 0 ?
                                                    <Link to={`/${tenant}/Projects/${incident.projectID}`} className="incidentRefLink">
                                                        {incident.projectName}
                                                    </Link>
                                                    :
                                                    ""
                                                }
                                            </td>
                                            <td>{incident.priorityName}</td>
                                            <td>{incident.statusName}</td>
                                            <td>{incident.incidentDateFormatted}</td>
                                            <td>
                                                {incident.statusName !== "Completed" ? (
                                                    <input type="checkbox" checked={incident.selected}
                                                        onChange={e => changeSelectIncident(e, incident)} />
                                                ) : null}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="dashboardAlignRightContainer">
                                <input type="button" className="nexusButtonCommon"
                                    value="Close Selected Incidents"
                                    onClick={() => closeSelectedIncidents(setLoading,
                                        incidents,
                                        setError,
                                        () => loadIncidents())} />
                            </div>
                        </div>
                    )}
                </div>
            </AccordionWidget>
        </div>
    )
}
export default MyIncidents;