import APIRequest from './APIRequest';

export const GetBackupSchedules = ({ payload = {}, AssetID = 0, BackupID = 0 }) => {
  return APIRequest({
    url: `Backup/GetBackupSchedules?AssetID=${AssetID}&BackupID=${BackupID}`,
    method: 'post',
    body: payload
  });
};

export const PostBackupSchedule = (payload) => {
  return APIRequest({
    url: `Backup/PostBackupSchedule`,
    method: 'post',
    body: payload,
  });
};

export const DeleteBackupSchedule = (payload) => {
  return APIRequest({
    url: `Backup/DeleteBackupSchedule`,
    method: 'post',
    body: payload,
  });
};
