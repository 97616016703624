import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationSoftSwitch, DisableOrganizationSoftSwitch } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';
import PBXConnection from './pbxconnection.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [canTestConnection, setCanTestConnection] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [softswitch, setSoftswitch] = useState({});

    useEffect(()=> {
        const softswitchID = props.routeProps.match.params.softswitchid;
        GetOrganizationSoftSwitch({ content: { softswitchID: Number(softswitchID) }})
            .then(res => {
                if (res != null) {
                    if (res.softswitches.length === 1){
                        setSoftswitch(res.content[0]);
                        setLoaded(true);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[]);

    const disableSoftSwitch = () => {
        DisableOrganizationSoftSwitch({
            content: {
                softswitchid: Number(props.routeProps.match.params.softswitchid),
                active: !softswitch.active
            }
        })
            .then(res => {
                var obj = softswitch;
                obj.active = !obj.active;
                setSoftswitch(obj);
            })
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{softswitch.name}</h3>

                        <PBXConnection routeProps={props.routeProps} type={"softswitch"} pbxid={softswitch.softswitchid} />
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Description:</th>
                                    <td>{softswitch.description}</td>
                                </tr>
                                <tr>
                                    <th>Host:</th>
                                    <td>{softswitch.host}</td>
                                </tr>
                                <tr>
                                    <th>SQL Host:</th>
                                    <td>{softswitch.sqlhost}</td>
                                </tr>
                                <tr>
                                    <th>SQL User:</th>
                                    <td>{softswitch.sqluser}</td>
                                </tr>
                                <tr>
                                    <th>SQL Password Configured:</th>
                                    <td>{softswitch.sqlpassword}</td>
                                </tr>
                                <tr>
                                    <th>SQL Port:</th>
                                    <td>{softswitch.sqlport}</td>
                                </tr>
                                <tr>
                                    <th>SSH User:</th>
                                    <td>{softswitch.sshuser}</td>
                                </tr>
                                <tr>
                                    <th>SSH Password Configured:</th>
                                    <td>{softswitch.sshpassword}</td>
                                </tr>
                                <tr>
                                    <th>SSH Port:</th>
                                    <td>{softswitch.sshport}</td>
                                </tr>
                                <tr>
                                    <th>Active:</th>
                                    <td>{softswitch.active ? "Yes" : "No"}</td>
                                </tr>
                            </tbody>
                        </table>
                        {softswitch.hasAPI || softswitch.api.softswitchapiid ?
                            <div>
                                <h3>API</h3>
                                <table cellPadding="0" cellSpacing="0" className="infoTable">
                                    <tbody>
                                        <tr>
                                            <th>Host:</th>
                                            <td>{softswitch.api.host}</td>
                                        </tr>
                                        <tr>
                                            <th>Port:</th>
                                            <td>{softswitch.api.port}</td>
                                        </tr>
                                        <tr>
                                            <th>User:</th>
                                            <td>{softswitch.api.user}</td>
                                        </tr>
                                        <tr>
                                            <th>Password Configured:</th>
                                            <td>{softswitch.api.password}</td>
                                        </tr>
                                        <tr>
                                            <th>Active:</th>
                                            <td>{softswitch.api.active ? "Yes" : "No"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : <h4> No API has been configured</h4>
                        }

                        <hr />
                        <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/telephony"} className="nexusButtonCommon">Back</Link>
                        <button type="submit" onClick={() => disableSoftSwitch} className="nexusButtonCommon">{softswitch.active ? "Disable" : "Enable"}</button>
                        {canEdit ? (
                            <Link to={`${tenant}/organizations/` + props.routeProps.match.params.id + "/softswitch/" + softswitch.softswitchid + "/edit"} className="nexusButtonCommon">Edit</Link>
                        ) : ("")}
                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}