import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import FileSystemExplorer from './FileSystemExplorer.js';
import Loader from '../App/loader.js';
import TabView from '../App/tabview.js';
import TabInfo from './Tabinfo.js';
import TabBackup from './Tabbackup.js';
import TabNetwork from './TabNetwork.js';
import TabCredential from './TabCredential.js';
import TabTunnels from './TabTunnels.js';
import TabSessions from './TabSessions.js';
import { GetAsset } from '../../api/AssetAPI';
import { connect, useSelector } from 'react-redux';
import TabXBert from './TabXBert';
import { updateSelectedAsset } from '../../Redux/actions';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getBreadcrumbBack } from '../Organization/organization';

const Asset = props => {
  const tenant = useSelector(state => state.TenantReducer.Tenant);
  const [loaded , setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
      asset: { name: '' },
  });

  useEffect(()=>{
    const assetID = parseInt(props.routeProps.match.params.id, 10);
    GetAsset({ content: assetID })
      .then((res) => {
        setData(res.content);
        setLoaded(true);
      })
      .catch(() => {
        setError(true);
      });
      props.updateSelectedAsset(assetID);
  },[])

    const assetID = props.routeProps.match.params.id;
    const tabListBaseURL = '/' + tenant + '/assets/' + assetID;
    const tabList = [
      {
        label: 'Asset Info',
        url: tabListBaseURL + '/info',
        default: true,
      },
      { label: 'XBert Service', url: tabListBaseURL + '/xbert' },
      { label: 'Backups', url: tabListBaseURL + '/backup' },
      { label: 'Network Interfaces', url: tabListBaseURL + '/networks' },
      { label: 'Tunnel', url: tabListBaseURL + '/tunnels' },
      {
        label: 'File System Explorer',
        url: tabListBaseURL + '/filesystem',
      },
      { label: 'Credentials', url: tabListBaseURL + '/credentials' },
      { label: 'Sessions', url: tabListBaseURL + '/sessions' },
    ];

    return (
      <div className="assetContainer">
        {loaded ? (
          <div>
            <BreadcrumbsItem
              weight={1}
              to={`/${tenant}/organizations/`}
              label={'Organizations'}
            />
            <BreadcrumbsItem
              weight={2}
              to={`/${tenant}/organizations/${data?.orgID}/assets`}
              label={data.orgName}
            />
            <BreadcrumbsItem
              weight={3}
              to={`/${tenant}/assets/${data?.assetID}/${getBreadcrumbBack(assetID)}/`}
              label={data.name}
            />
            <h2>{data.name}</h2>
            <TabView tabs={tabList}>
              <Switch>
                <Route
                  path={`/${tenant}/assets/:id/info`}
                  render={(routeProps) => <TabInfo asset={data} routeProps={routeProps} />}
                />
                <Route
                  path={`/${tenant}/assets/:id/xbert`}
                  render={(routeProps) => (
                    <TabXBert assetID={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={`/${tenant}/assets/:id/backup`}
                  render={(routeProps) => <TabBackup
                  routeProps={routeProps} /> }
                />
                <Route
                  path={`/${tenant}/assets/:id/networks`}
                  render={(routeProps) => (
                    <TabNetwork routeProps={routeProps} />
                  )}
                />
                <Route
                  path={`/${tenant}/assets/:id/tunnels`}
                  render={(routeProps) => (
                    <TabTunnels
                      routeProps={routeProps}
                      assetName={data.name}
                    />
                  )}
                />
                <Route
                  path={`/${tenant}/assets/:id/FileSystem`}
                  render={(routeProps) => <FileSystemExplorer />}
                />
                <Route
                  path={`/${tenant}/assets/:id/Credentials`}
                  render={(routeProps) => (
                    <TabCredential
                      routeProps={routeProps}
                      asset={data}
                    />
                  )}
                />
                <Route
                  path={`/${tenant}/assets/:id/Sessions`}
                  render={(routeProps) => (
                    <TabSessions
                      routeProps={routeProps}
                      asset={data}
                    />
                  )}
                />
              </Switch>
            </TabView>
          </div>
        ) : error ? (
          <p>Error loading asset info.</p>
        ) : (
          <Loader />
        )}
      </div>
    );
  }


let mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedAsset: (AssetID) => dispatch(updateSelectedAsset(AssetID)),
  };
};

export default connect(null, mapDispatchToProps)(Asset);