import React, { useState, useRef } from 'react';
import Loader from './loader.js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Authenticate, ResetPassword } from '../../api/NexusAPI';

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const tenant = useSelector((state) => state.TenantReducer.Tenant);
  const newPswd = useRef('');
  const newPswdConfirmed = useRef('');
  const history = useHistory();

  const submit = () => {
    setLoading(true);
    if (
      newPswd.current.value !== newPswdConfirmed.current.value &&
      newPswd.current.value &&
      newPswdConfirmed.current.value
    ) {
      setMessage('Passwords do NOT match.');
      setLoading(false);
    }
    if (
      newPswd.current.value === newPswdConfirmed.current.value &&
      newPswd.current.value &&
      newPswdConfirmed.current.value
    ) {
      setMessage('Passwords DO match.');
      if (props.routeProps.location.state?.user) {
        ResetPassword(
          props.routeProps.location.state?.user,
          newPswd.current.value
        ).then((response) => {
          if (response.success == true) history.push('/');
          else setMessage('Something went wrong. Please try again.');
        });
      } else {
        Authenticate(
          props.routeProps.location.state?.username,
          newPswd.current.value,
          true,
          null,
          Number(sessionStorage.getItem('nexusOrg')),
          null,
          false,
          false,
          null
        )
          .then((result) => {
            if (result.content.authenticated && result.content.token != null) {
              localStorage.removeItem('nexusToken');
              localStorage.removeItem('nexusID');
              localStorage.removeItem('nexusOrg');
              sessionStorage.setItem('nexusToken', result.content.token);
              sessionStorage.setItem('nexusID', result.content.id);
              setLoading(false);
              props.checkAuth();
            } else {
              setMessage('Something went wrong.  Please try again.');
              setLoading(false);
            }
          })
          .catch((info) => {
            console.error(info);
          });
      }

      setLoading(false);
    }
  };

  return (
    <div className="nexusForm loginForm">
      {loading ? (
        <div className="NexusLoading">
          <Loader />
        </div>
      ) : (
        <div className="bootstrap-wrapper">
          {props.routeProps.location.state?.expired ? (
            <>
              <h2>Link has expired!</h2>
              <Container>
                <Col>
                  <Row className="justify-content-center">
                    <p>Please submit a new password reset request.</p>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => history.push('/')}
                    >
                      Return to Home
                    </button>
                  </Row>
                </Col>
              </Container>
            </>
          ) : (
            <>
              <h2>Change Your Password</h2>
              <form
                className="nexusFormInner"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  submit();
                }}
              >
                <table className="loginTable" cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <th>
                        <label className="nexusFormLabel">New Password:</label>
                      </th>
                      <td>
                        <input
                          type="password"
                          name="newPswd"
                          id="newPswd"
                          ref={newPswd}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="nexusFormLabel">
                          Confirm New Password:
                        </label>
                      </th>
                      <td>
                        <input
                          type="password"
                          name="newPswdConfirmed"
                          id="newPswdConfirmed"
                          ref={newPswdConfirmed}
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="nexusFormSubmit">
                  <input
                    type="submit"
                    name="btnPswd"
                    id="btnPswd"
                    value="Submit"
                    className="nexusButton"
                  />
                </div>
              </form>
              <br />
              <div className="Error">{message}</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
