import React from 'react';
import Loader from '../App/loader';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MissingTimeEntryList = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const niceDate = date => {
        const splitdate = date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/)
        if (splitdate && splitdate.length === 4)
        {
            return `${splitdate[2]}/${splitdate[3]}/${splitdate[1]}`;
        }
        return date;
    }
    return (
        <div className="MissingTime">
            {props.missingDatesLoading ?
                <div className="NexusLoading">
                    <Loader />
                </div>
            : null}
            <strong>Missing Time:</strong>
            <table className="MissingTimeTable">
                <tbody>
                    {props.dates.map(d => 
                        <tr key={d.dt}>
                            <td><Link to={`/${tenant}/Dashboard/` + d.dateFormatted}>
                                {niceDate(d.dateFormatted)}
                            </Link></td>
                            <td>{d.hours} Hours</td>
                        </tr>
                    )}        
                </tbody>
            </table>
            
        </div>
    )
}
export default MissingTimeEntryList;