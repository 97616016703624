import React from "react";
import { Table } from "react-bootstrap";

const BackupTargetList = (props) => {
  const ListTargets = () => {
    return props.targets.map((target, index) => {
      return (
        <tr key={index}>
          <td className="align-middle">
            <p className="text-dark d-inline-block py-2 m-0">{target.target}</p>
            <button
              style={{ float: "right" }}
              type="button"
              className="nexusButtonCommon rounded mx-4"
              target={target.target}
              onClick={Remove}
            >
              Remove Target
            </button>
          </td>
        </tr>
      );
    });
  };

  const Remove = (e) => {
    props.setTargets("remove", e.target.getAttribute("target"));
  };

  return (
    <div className="bootstrap-wrapper">
      <h3>Targets:</h3>
      <p><b>Note: </b>Changes made here will not be saved unless you save the form</p>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Path</th>
          </tr>
        </thead>
        <tbody>{ListTargets()}</tbody>
      </Table>
    </div>
  );
};

export default BackupTargetList;
