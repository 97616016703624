import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NexusGrid, { IndeterminateCheckboxColumnFilter } from '../App/NexusGrid';
import LoadingShroud from '../App/LoadingShroud.js';
import { GetPeople } from '../../api/PeopleAPI';
import * as actions from '../../Redux/user-actions';

const PeopleTable = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [canEditPeople, setCanEditPeople] = useState(false);

  const firstRender = useRef(true);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterResults, setFilterResults] = useState([]);
  const [sortByResults, setSortByResults] = useState([]);
  const [loadingFilterSort, setLoadingFilterSort] = useState(false);

  useEffect(() => {
    dispatch(actions.setUserCanModifyPeople(canEditPeople));
  }, [canEditPeople]);

  const getPeople = React.useCallback(
    ({ pageSize, pageIndex, sortBy, filters }) => {
      setFilterResults(filters);
      setSortByResults(sortBy);
      firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
      firstRender.current = false;

      GetPeople({
        orgid: 0,
        pageSize: pageSize,
        pageIndex: pageIndex,
        filters: filters,
        sortBy: sortBy,
      })
        .then((response) => {
          setLoadingFilterSort(false);
          setLoading(false);
          if (response.success) {
            setPeople(response.content);
            setCanEditPeople(response.canEditPeople);
            setTotalResults(response.totalCount);
            setTotalPages(response.pageCount);
          }
        })
        .catch(() => {
          setLoadingFilterSort(false);
          setLoading(false);
        });
    },
    []
  );

  const headers = [
    { Header: 'First Name', accessor: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Person ID', accessor: 'personID' },
    { Header: 'Primary Email', accessor: 'emailAddress1' },
    { Header: 'Office Phone', accessor: 'officePhone' },
    { Header: 'Primary Mobile', accessor: 'mobile1' },
    { Header: 'Active', accessor: 'active' }
  ];

  return (
    <div className="localSortDGContainer">
      <h2>People</h2>
      <div>
        {canEditPeople ? (
          <Link to={`/${tenant}/people/add`}>
            <div className="nexusButtonCommon">
              <i className="fas fa-plus"></i> Add Person
            </div>
          </Link>
        ) : null}
        <NexusGrid
          linkTo={`/${tenant}/people/:key/info`}
          tableKey={'personID'}
          hiddenColumns={['personID', 'active']}
          data={people}
          defaultSortBy={'personID'}
          columns={headers}
          fetchData={getPeople}
          filterData={filterResults}
          sortByData={sortByResults}
          loading={loading}
          loadingFilterSort={loadingFilterSort}
          pageMode="remote"
          perPage={25}
          totalResults={totalResults}
          totalPages={totalPages}
          defaultSortOrder={'asc'}
        />
      </div>
    </div>
  );
};

export default PeopleTable;
