import React from 'react';
import ReactDOM from 'react-dom';
import NextApp from './components/App/app.js';
import { BrowserRouter } from 'react-router-dom';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { Provider } from 'react-redux';
import store from './Redux/store';
import WebSocketProvider, { WebSocketContext } from './Redux/WebSocket';

var rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <WebSocketProvider>
      <BrowserRouter>
        <BreadcrumbsProvider>
          <NextApp />
        </BreadcrumbsProvider>
      </BrowserRouter>
    </WebSocketProvider>
  </Provider>,
  rootElement
);
