import React, { useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { CreateService } from '../../api/ServiceAPI';
import Loader from '../App/loader';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [localService, setLocalService] = useState({
        name: "",
        description: "",
        msrp: 0,
        sku: ""
    });

    const inputChanged = (e) => {
        let newService = { ...localService };
        newService[e.target.name] = e.target.value;
        setLocalService(newService);
    }

    const createService = () => {
        setLoading(true);
        CreateService({
            name: localService.name,
            description: localService.description,
            msrp: parseInt(localService.msrp),
            sku: localService.sku
        })
            .then(res => {
                setMessage(res.message);
            })
            .catch(res => {
                setMessage(res.message);
            });
        setLoading(false);
    }

    const submit = (e) => {
        e.preventDefault();
        if (localService.name === "") {
            setMessage("Cannot save: name field empty.");
        } else if (localService.description === "") {
            setMessage("Cannot save: description field empty.");
        } else {
            createService();
        }
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            <BreadcrumbsItem to={`/${tenant}/Services/`} label='Service List' />
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Service Name:</td>
                                <td><input type="text" name="name" id="name" value={localService.name} onChange={(e) => inputChanged(e)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td><input type="text" name="description" id="description" value={localService.description} onChange={(e) => inputChanged(e)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>SKU:</td>
                                <td><input type="text" name="sku" id="sku" value={localService.sku} onChange={(e) => inputChanged(e)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>MSRP:</td>
                                <td><input type="number" name="msrp" id="msrp" value={localService.msrp} onChange={(e) => inputChanged(e)} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="SaveService" id="SaveService" value="Create Service" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
        </div>
    )
}