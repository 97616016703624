import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EditAsset, DisableAsset, GetAssetTypes, GetAssetType } from '../../api/AssetAPI';
import Loader from '../App/loader.js';
import AssetRemoteAccess from './AssetRemoteAccess';
import AssetStatusDisplay from './AssetStatusDisplay'
import LineChart from "./AssetStatusDisplay/LineChart"
import EditableTableCell from '../UI/EditableTableCell';
import Notification from '../UI/Notification';
import { WebSocketContext } from "../../Redux/WebSocket";
import FormImpl from 'react-bootstrap/esm/Form';

export default props => {
    const [assetTypes, setAssetTypes] = useState([]);
    const [assetType, setAssetType] = useState(null);

    const {
        assetID,
        azureConnectionString,
        description,
        mfg,
        model,
        name,
        orgID,
        orgName,
        purchaseDate,
        serial,
        warrantyExpiration,
        assetTypeID,
    } = props.asset;

    const purDate = new Date(purchaseDate);
    const pDate = purDate.toLocaleDateString('en-CA');
    
    const warDate = new Date(warrantyExpiration);
    const wDate = warDate.toLocaleDateString('en-CA');

    const initialUserState = {
        assetID: assetID,
        azureConnectionString: azureConnectionString,
        description: description,
        mfg: mfg,
        model: model,
        name: name,
        orgID: orgID,
        orgName: orgName,
        purchaseDate: pDate,
        serial: serial,
        warrantyEx: wDate,
        assetTypeID: assetTypeID,
    }

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [formInputs, setFormInputs] = useState(initialUserState);
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [displayNotification, setDisplayNotification] = useState(false);
    const [status, setStatus] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState();
    const [comSent, setComSent] = useState('hidden');

    const commandLineRef = useRef();
    
    const canUserEditPerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );

    const AssetTypes = assetTypes.map((at) => (
        <option key={at.assetTypeID} value={at.assetTypeID}>
            {at.name}
        </option>
    ));

    const theAssetType = () => {
        let payload = {
            assetTypeID: Number(formInputs.assetTypeID),
            name: ""
        }
        GetAssetType(payload).then((res) => {
            if (res.success === true) {
                setAssetType(res.content.name);
            } else {
                setAssetType(null);
            }
        })
    }

    const theAssetTypes = () => {
        GetAssetTypes().then((res) => {
            if (res.success === true) {
                setAssetTypes(res.content);
            }
        })
    }

    useEffect(() => {
        setLoaded(true);
        theAssetType();
        theAssetTypes();
    }, []);

    const onChangeHandler = (e) => {
        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });
    };

    const resetClickedHandler = () => {
        theAssetType();
        setIsFormEditable(false);
        setFormInputs(initialUserState);
    };

    const setPersonInactive = () => {
        const payload ={
            content: formInputs.assetID
        }
        DisableAsset(payload).then((res)=>{
            if(res.success){
                console.log(res);
                setStatus('success')
                setMessage('Asset marked inactive!')
                setDisplayNotification(true);
                setSubmitted(true);
            }
            else{
                console.log(res);
                setStatus('error')
                setMessage('Something weird happened, talk to admin.')
                setDisplayNotification(true);
                setSubmitted(true);
            }
        })
    }

    const  formSubmitClickedHandler = (e) => {
        e.preventDefault();
        const payload = {
            assetID: formInputs.assetID,
            azureConnectionString: formInputs.azureConnectionString,
            description: formInputs.description,
            mfg: formInputs.mfg,
            model: formInputs.model,
            name: formInputs.name,
            orgID: formInputs.orgID,
            orgName: formInputs.orgName,
            purchaseDate: formInputs.purchaseDate,
            serial: formInputs.serial,
            warrantyExpiration: formInputs.warrantyEx,
            assetTypeID: Number(formInputs.assetTypeID),
        };

        EditAsset(payload).then((res) => {
            if (res.success) {
                theAssetType();
                setIsFormEditable(false);
                setSubmitted(true);
                setStatus('success');
                setDisplayNotification(true);
                setMessage('Asset updated successfully!')
            }
            else{
                setSubmitted(true);
                setStatus('error');
                setDisplayNotification(true);
                setMessage('Something weird happened.')
            }
        })

    }

    const formSubmitControls = (
        <>
            <hr />
            {isFormEditable ? (
                <div className="form-group">
                    <button
                        className="btn btn-danger nexusButtonCommon"
                        onClick={resetClickedHandler}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary nexusButtonCommon"
                        disabled={!isFormEditable}
                    >
                        Save
                    </button>
                </div>
            ) : null}
        </>
    );

    const editPersonInfoToggle = (
        <>
            <div className="form-group">
                {isFormEditable ? (
                    <button
                        className="btn btn-danger nexusButtonCommon"
                        type="button"
                        onClick={() => setPersonInactive()}
                    >
                        Set Asset Inactive
                    </button>
                ) : (
                    <button
                        className="btn btn-warning nexusButtonCommon"
                        type="button"
                        onClick={() => setIsFormEditable(!isFormEditable)}
                    >
                        Edit Asset's Info
                    </button>
                )}
                <hr />
            </div>
        </>
    );

    const infoTable = (
        <div>
            <table cellPadding="0" cellSpacing="0" className="infoTable">
                <tbody>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Description"
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            placeholder={formInputs.description}
                            defaultValue={formInputs.description}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Manufacturer"
                            type="text"
                            className="form-control"
                            id="manufacturer"
                            name="manufacturer"
                            placeholder={formInputs.mfg}
                            defaultValue={formInputs.mfg}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Model"
                            type="text"
                            className="form-control"
                            id="model"
                            name="model"
                            placeholder={formInputs.model}
                            defaultValue={formInputs.model}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Serial"
                            type="text"
                            className="form-control"
                            id="serial"
                            name="serial"
                            placeholder={formInputs.serial}
                            defaultValue={formInputs.serial}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Purchase Date"
                            type="date"
                            className="form-control"
                            id="purchaseDate"
                            name="purchaseDate"
                            placeholder={formInputs.purchaseDate}
                            defaultValue={formInputs.purchaseDate}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        <EditableTableCell
                            isFormEditable={isFormEditable}
                            title="Warranty Expiration"
                            type="date"
                            className="form-control"
                            id="warrantyEx"
                            name="warrantyEx"
                            placeholder={formInputs.warrantyEx}
                            defaultValue={formInputs.warrantyEx}
                            changed={(e) => onChangeHandler(e)}
                        />
                    </tr>
                    <tr>
                        {isFormEditable === false ? (
                            <>
                                <th>Asset Type:</th>
                                <td>{assetType}</td>
                            </>
                        ) : (
                                <>
                                    <th>Asset Type:</th>
                                    <td>
                                        <select
                                            name="assetTypeID"
                                            id="assetTypeID"
                                            placeholder={formInputs.assetTypeID}
                                            defaultValue={formInputs.assetTypeID}
                                            onChange={(e) => onChangeHandler(e)}
                                            className="form-control"
                                        >
                                            <option value={-1}>-- No Asset Type --</option>
                                            {AssetTypes}
                                        </select>
                                    </td>
                                </>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const socket = React.useContext(WebSocketContext);
    const commandstring = useSelector(
        (state) => state.WebSocketReducer.CommandLine
    );
    const submitHandler = () => {
        setComSent(true);
        const com = commandLineRef.current.value;
        const commandRequestMessage = JSON.stringify({
        type: "Nexus.Data.Classes.Models.XBert.WebSocketModels.CommandLineRequest",
        body: { AssetID: parseInt(props.AssetID),
                command: (com),
            }, 
      });
        socket.send(commandRequestMessage);
      }

    const content = (
        <div>
            {loaded ? (
                <>
                <form onSubmit={formSubmitClickedHandler}>
                    {canUserEditPerson ? editPersonInfoToggle : null}
                    {infoTable}
                    {canUserEditPerson ? formSubmitControls : null}
                    {submitted && displayNotification ? (
                        <Notification
                            status={status}
                            title="Submission Result"
                            message={message}
                            dismiss={() => setDisplayNotification(false)}
                        ></Notification>
                    ) : null}
                </form>
                    <div>
                        <div>
                            <label>Send Command to xBert</label>
                        </div>
                        <input type="text" className="form-control" ref={commandLineRef} id="commandInput" />
                        <button type="submit" onClick={submitHandler} className="btn btn-primary btn-block">
                            Send command
                        </button>
                        <div style={{visbility: comSent}}>
                            <p className="Label">
                                Command Output:  
                            </p>
                            <p>
                                {commandstring.commandOutput}
                            </p>
                            <p className="Label">
                                Command Error:  
                            </p>
                            <p>
                                {commandstring.commandError}
                            </p>
                        </div>
                    </div>
                    </>
            ) : error ? (
                <p>Error loading asset details.</p>
            ) : (
                <Loader />
            )}
            <AssetStatusDisplay AssetID={props.routeProps.match.params.id} />
            <LineChart />
        </div>
    )
    return content;
}