import APIRequest from './APIRequest';

export const GetRecordTypeOptions = () => {
    return APIRequest({
        url: `DNSService/GetRecordTypeOptions`,
        method: "GET"
    });
}

export const GetRecord = (body) => {
    return APIRequest({
        url: `DNSService/GetRecord`,
        method: "POST",
        body
    });
}

export const GetRecordList = (body) => {
    return APIRequest({
        url: `DNSService/GetRecordList`,
        method: "POST",
        body
    });
}

export const UpdateRecord = (body) => {
    return APIRequest({
        url: `DNSService/UpdateRecord`,
        method: "POST",
        body
    });
}

export const GetDomainTypeOptions = () => {
    return APIRequest({
        url: `DNSService/GetDomainTypeOptions`,
        method: "GET"
    });
}

export const GetDomain = (body) => {
    return APIRequest({
        url: `DNSService/GetDomain`,
        method: "POST",
        body
    });
}

export const UpdateDomain = (body) => {
    return APIRequest({
        url: `DNSService/UpdateDomain`,
        method: "POST",
        body
    });
}

export const GetDomainList = (body) => {
    return APIRequest({
        url: `DNSService/GetDomainList`,
        method: "POST",
        body
    });
}
