import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';
import {
    XBertVersions,
    GetXBertVersionByAsset,
    GetRequestedVersionByAsset,
    UpdateAssetXBertVersion,
} from '../../api/XBert';
import XBertVersionInfo from '../../api/models/XBertVersionInfo';
import UpdateAssetXBertVersionRequest from '../../api/models/UpdateAssetXBertVersionRequest';
export interface XBertState {
    versions: XBertVersionInfo[];
    currentVersion: XBertVersionInfo;
    requestedVersion: XBertVersionInfo;
    availableVersions: string[];
    assetId: number;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: XBertState = {
    versions: [],
    currentVersion: {
        name: 'none',
        fileName: '',
        xBertVersionID: 0,
        description: '',
    },
    requestedVersion: {
        name: 'none',
        fileName: '',
        xBertVersionID: 0,
        description: '',
    },
    availableVersions: [],
    assetId: 0,
    status: 'idle',
};

export const getXBertVersions = createAsyncThunk(
    'Asset/GetXBertVersions',
    async () => {
        const response = await XBertVersions();
        return response.data.content;
    }
);

export const getXBertVersionByAsset = createAsyncThunk(
    'Asset/GetXBertVersionByAsset',
    async (assetId: number) => {
        const response = await GetXBertVersionByAsset(assetId);
        return response.data.success
            ? response.data.content
            : initialState.currentVersion;
    }
);

export const getRequestedVersionByAsset = createAsyncThunk(
    'Asset/GetRequestedVersionByAsset',
    async (assetId: number) => {
        const response = await GetRequestedVersionByAsset(assetId);
        return response.data.success
            ? response.data.content
            : initialState.requestedVersion;

    }
);

export const updateAssetXBertVersion = createAsyncThunk(
    'Asset/UpdateAssetXBertVersion',
    async (updateRequest: UpdateAssetXBertVersionRequest) => {
        const response = await UpdateAssetXBertVersion(
            updateRequest
        );
        return response.data.content;
    }
);

export const xbertSlice = createSlice({
    name: 'xbert',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setAssetId: (state, action: PayloadAction<number>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.assetId = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getXBertVersions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getXBertVersions.fulfilled, (state, action) => {
                state.status = 'idle';
                state.versions = action.payload;
            })
            .addCase(getXBertVersionByAsset.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRequestedVersionByAsset.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getRequestedVersionByAsset.fulfilled, (state, action) => {
                state.status = 'idle';
                state.requestedVersion = action.payload;
            })
            .addCase(getXBertVersionByAsset.fulfilled, (state, action) => {
                state.status = 'idle';
                state.currentVersion = action.payload;
            })
            .addCase(updateAssetXBertVersion.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAssetXBertVersion.fulfilled, (state, action) => {
                state.status = 'idle';
                state.requestedVersion = action.payload;
            });
    },
});

export const { setAssetId } = xbertSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const xbertVersions = (state: RootState) => state.xbert.versions;
export const currentVersion = (state: RootState) => state.xbert.currentVersion;
export const requestedVersion = (state: RootState) =>
    state.xbert.requestedVersion;
export const assetId = (state: RootState) => state.xbert.assetId;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default xbertSlice.reducer;
