import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NexusGrid from '../App/NexusGrid.js';
import LoadingShroud from '../App/LoadingShroud.js';
import { GetTenants } from '../../api/TenantAPI';
import * as actions from '../../Redux/user-actions';

const TenantTable = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const canUserCreatePerson = useSelector(
        (state) => state.UserReducer.canModifyPeople
    );

    useEffect(() => {
        LoadTenants();
        return () => {
            setTenants([]);
            setLoading(false);
        };;
    }, [props]);

    const LoadTenants = () => {
        GetTenants().then((res) => {
            setTenants(res.content);
            setLoading(false);
        });
    };

    const headers = [
        { Header: 'Tenant ID', accessor: 'tenantID' },
        { Header: 'Tenant Name', accessor: 'name' },
        { Header: 'Tenant URI', accessor: 'uri' },
    ];
    return (
        <div className="localSortDGContainer">
            <h2>Tenants</h2>
            <LoadingShroud Active={loading} />
            <div>
                {canUserCreatePerson ? (
                    <Link to={`/${tenant}/tenants/add`}>
                        <div className="nexusButtonCommon">
                            <i className="fas fa-plus"></i> Add Tenant
                        </div>
                    </Link>
                ) : null}
                <NexusGrid
                    linkTo={`/${tenant}/tenants/:key/info`}
                    tableKey={'tenantID'}
                    hiddenColumns={['tenantID']}
                    data={tenants}
                    defaultSortBy={'tenantID'}
                    columns={headers}
                />
            </div>
        </div>
    );
};

export default TenantTable;
