import { createAction } from '@reduxjs/toolkit';

// rtc reducer (not used in Sessions.js)
export const callStates = {
  CALL_UNAVAILABLE: 'CALL_UNAVAILABLE',
  CALL_AVAILABLE: 'CALL_AVAILABLE',
  CALL_REQUESTED: 'CALL_REQUESTED',
  CALL_IN_PROGRESS: 'CALL_IN_PROGRESS',
};

//webSockets
export const Connect = () => {
    console.log("Connect Action Executed");
    return { type: "WS_CONNECT", payload: {} };
};
export const CALL_SET_LOCAL_STREAM = 'CALL.SET_LOCAL_STREAM';
export const CALL_SET_CALL_STATE = 'CALL.SET_CALL_STATE';
export const CALL_SET_CALLING_DIALOG_VISIBLE = 'CALL.SET_CALLING_DIALOG_VISIBLE';
export const CALL_SET_CALLER_USERNAME = 'CALL.SET_CALLER_USERNAME';
export const CALL_SET_CALL_REJECTED = 'CALL.SET_CALL_REJECTED';
export const CALL_SET_REMOTE_STREAM = 'CALL.SET_REMOTE_STREAM';
export const CALL_SET_LOCAL_MICROPHONE_ENABLED = 'CALL.SET_LOCAL_MICROPHONE_ENABLED';
export const CALL_SET_LOCAL_CAMERA_ENABLED = 'CALL.SET_LOCAL_CAMERA_ENABLED';
export const CALL_SET_SCREEN_SHARING_ACTIVE = 'CALL.SET_SCREEN_SHARING_ACTIVE';
export const CALL_RESET_CALL_STATE = 'CALL.RESET_CALL_STATE';

// websocket reducer
export const updateOnlineAssets = createAction('UPDATE_ONLINE_ASSETS');
export const updateSelectedAsset = createAction('UPDATE_SELECTED_ASSSET');
export const updateSelectedAssetCdriveContents = createAction('UPDATE_CDRIVECONTENTS');
export const updateFileSystem = createAction('UPDATE_FILESYSTEM_DRIVES');
export const updateFilePathContent = createAction('UPDATE_FILEPATH_CONTENT');
export const updateDownloadedFile = createAction('UPDATE_DOWNLOADED_FILE');
export const updateUploadedFile = createAction('UPDATE_UPLOADED_FILE');
export const updateSelectedAssetStatus = createAction('UPDATE_ASSET_STATUS');
export const updateUserAgentSessions = createAction('UPDATE_USERAGENT_SESSIONS');
export const setICECandidate = createAction('UPDATE_ICE_CANDIDATE');
export const setRemoteSdp = createAction('SET_REMOTE_SDP');
export const setAgentHungUp = createAction('SET_AGENT_HUNG_UP');
export const setAgentAccepted = createAction('SET_AGENT_ACCEPTED');
export const setNativeStreamDimensions = createAction('SET_NATIVE_STREAM_DIMMENSIONS');
export const setClipboardContents = createAction('SET_CLIPBOARD_CONTENTS');
export const GetCommandLine = createAction('GET_COMMAND_LINE');
export const GetPingScanner = createAction('GET_PING_SCANNER');

export const Close = () => {
    return { type: "WS_DISCONNECT", payload: {} };
};
