import React, { useRef, useState } from "react";
import { GetBackupSchedules } from "../../api/BackupsAPI";
import NexusGrid from "../App/NexusGrid";
import { Switch, Route, useRouteMatch, Link, Redirect } from "react-router-dom";
import BackupScheduleEdit from "../Assets/tabbackup/BackupScheduleEdit";
import EditAzureConnectionString from "./EditAzureConnectionString";

import moment from "moment";
import { Alert } from "react-bootstrap";

const TabBackup = (props) => {
  const AssetID = props.routeProps.match.params.id;

  const [BackupSchedules, setBackupSchedules] = useState([]);
  const [EditType, setEditType] = useState({
    type: '',
    backupID: 0
  });

  let { path, url } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const firstRender = useRef(true);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterResults, setFilterResults] = useState([]);
  const [sortByResults, setSortByResults] = useState([]);
  const [loadingFilterSort, setLoadingFilterSort] = useState(false);

    const getBackupSchedules = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
    setFilterResults(filters);
    setSortByResults(sortBy);
    firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
    firstRender.current = false;
      GetBackupSchedules({ payload: { pageSize: pageSize, pageIndex: pageIndex, filters, sortBy }, AssetID: AssetID } )
        .then((response) => {
            setLoadingFilterSort(false);
            setLoading(false);
            if (response.success) {
              console.log(response.backupSchedules);
              setBackupSchedules(response.backupSchedules);
              setTotalResults(response.totalCount);
              setTotalPages(response.pageCount);
            }
        })
        .catch(() => {
            setLoadingFilterSort(false);
            setLoading(false);
        });
  }, []);

  const daysOfWeekFormatter = (cell, row) => {
    return (
      <div className="container-fluid p-1">
        <div className="row no-gutters text-center">
          <div className={`col-sm-12 col-md py-2 px-0 my-1 mr-1 border border-light rounded ${ row.sunday ? 'bg-dark text-white' : 'bg-light'}`}>S</div>
          <div className={`col py-2 px-0 my-1 mr-1 border border-light rounded ${ row.monday ? 'bg-dark text-white' : 'bg-light'}`}>M</div>
          <div className={`col py-2 px-0 my-1 mr-1 border border-light rounded ${ row.tuesday ? 'bg-dark text-white' : 'bg-light'}`}>T</div>
          <div className={`col py-2 px-0 my-1 mr-1 border border-light rounded ${ row.wednesday ? 'bg-dark text-white' : 'bg-light'}`}>W</div>
          <div className={`col py-2 px-0 my-1 mr-1 border border-light rounded ${ row.thursday ? 'bg-dark text-white' : 'bg-light'}`}>Th</div>
          <div className={`col py-2 px-0 my-1 mr-1 border border-light rounded ${ row.friday ? 'bg-dark text-white' : 'bg-light'}`}>F</div>
          <div className={`col-sm-12 col-md py-2 px-0 my-1 border border-light rounded ${ row.saturday ? 'bg-dark text-white' : 'bg-light'}`}>S</div>
        </div>
      </div>
    );
  }

  const headers = [
    { Header: 'Backup ID', accessor: 'backupID' },
    { Header: 'Backup Type', accessor: 'backupType' },
    { Header: 'Backup Start', accessor: 'backupStart', 
    Cell: (cell) => {
        return <p>{moment(cell.value).format('MM/DD/YY H:mm')}</p> 
      } 
    },
    { Header: 'Days of the Week', dataFormat: daysOfWeekFormatter, class: 'm-0 p-0 bg-white' },
  ];

  return (
    <>
      <Switch>
        <Route path={`${path}/:backupID`}>
          <BackupScheduleEdit setBackupScheduleList={setBackupSchedules} setEditType={setEditType} EditType={EditType} />
        </Route>
        <Route path={`${path}/new`}>
          <BackupScheduleEdit setBackupScheduleList={setBackupSchedules} setEditType={setEditType} EditType={EditType} />
        </Route>
        <Route exact path={path}>
          <section id={AssetID + `-backup-schedules`}>
            <h1>Backup Schedules</h1>
            <EditAzureConnectionString />
            <br />
            {EditType.type ?
              <div className="bootstrap-wrapper">
                <Alert variant="success" dismissible onClose={() => setEditType({type: '', backupID: 0})}>
                  Backup {EditType.backupID != 'new' ? `#${EditType.backupID}` : null } successfully {EditType.type}
                </Alert>
              </div>
             : null }
            <Link className="newButton" role="button" to={`${url.endsWith('/') ? url : url.concat('/') }new`}
            onClick={() => setEditType({type: '', backupID: 0})}>
              Create New Schedule
            </Link>
            <NexusGrid
              linkTo={`${props.routeProps.location.pathname.endsWith('/') ?
                props.routeProps.location.pathname : props.routeProps.location.pathname.concat('/') }:key`}
              tableKey={'backupID'}
              data={BackupSchedules}
              defaultSortBy={'backupID'}
              columns={headers}
              perPage={50}
              fetchData={getBackupSchedules}
              filterData={filterResults}
              sortByData={sortByResults}
              loading={loading}
              loadingFilterSort={loadingFilterSort}
              pageMode="remote"
              totalResults={totalResults}
              totalPages={totalPages}
            />
          </section>
        </Route>
      </Switch>
    </>
  );
};

export default TabBackup;
