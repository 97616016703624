import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toolbarConfig from '../App/toolbarconfig';
import Loader from '../App/loader';
import { useSelector } from 'react-redux';

import { GetCompanyListBasic } from '../../api/CompanyAPI';
import { GetIncidentParamLists } from '../../api/IncidentAPI'
import { GetIncidentCompanyParamLists } from '../../api/IncidentAPI';
import { CreateIncidentAPI } from '../../api/IncidentAPI';

const CreateIncident = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [errmessage, setErrmessage] = useState("");
    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([
        { itemID: 0, itemName: "-- Select a Company --" }
    ]);
    const [requestors, setRequestors] = useState([
        { itemID: 0, itemName: "-- Select a Requestor --" }
    ]);
    const [requestTypes, setRequestTypes] = useState([
        { itemID: 0, itemName: "Loading..." }
    ]);
    const [statuses, setStatuses] = useState([
        { itemID: 0, itemName: "Loading..." }
    ]);
    const [priorities, setPriorities] = useState([
        { itemID: 0, itemName: "Loading..." }
    ]);
    const [assignToList, setAssignToList] = useState([
        { itemID: 0, itemName: "Loading..." }
    ]);
    const [projectList, setProjectList] = useState([
        { itemID: 0, itemName: "-- Select a Company --" }
    ]);
    const [assetList, setAssetList] = useState([
        { itemID: 0, itemName: "-- Select a Company --" }
    ]);
    const [workTypeList, setWorkTypeList] = useState([
        { itemID: 0, itemName: "-- Select a Company --" }
    ]);
    const [teamList, setTeamList] = useState([
        { itemID: 0, itemName: "Loading..." }
    ]);
    const [myContactID, setMyContactID] = useState(0);
    const [wtSelectionEnabled, setWTSelectionEnabled] = useState(true);
    const [companyID, setCompanyID] = useState(0);
    const [locationID, setLocationID] = useState(0);
    const [requestorID, setRequestorID] = useState(0);
    const [requestTypeID, setRequestTypeID] = useState(0);
    const [statusID, setStatusID] = useState(0);
    const [priorityID, setPriorityID] = useState(0);
    const [assignedToID, setAssignedToID] = useState(0);
    const [projectID, setProjectID] = useState(0);
    const [assetID, setAssetID] = useState(0);
    const [workTypeID, setWorkTypeID] = useState(0);
    const [teamID, setTeamID] = useState(0);
    const [incidentDate, setIncidentDate] = useState(new Date())
    const [summary, setSummary] = useState('');
    const [chkAddNote, setChkAddNote] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        loadInit();
    }, []);
    useEffect(() => {
        if (priorities.filter(p => p.itemID === 9).length === 1)
            setPriorityID(9);
    }, [priorities]);
    useEffect(() => {
        if (assignToList.filter(a => a.itemID === myContactID).length === 1)
            //console.log(myContactID);
            setAssignedToID(myContactID);
    }, [assignToList, myContactID]);
    useEffect(() => {
        if (companyID === 0)
            return;
        setLoading(true);
        GetIncidentCompanyParamLists(companyID)
            .then(response => {
                let APILocationList = [...response.locationList];
                let selectedCompany = companies.filter(company =>
                    company.itemID === companyID
                );
                if (selectedCompany.length === 1) {
                    APILocationList.unshift({ itemID: 0, itemName: selectedCompany[0].itemName });
                }
                setLocations(APILocationList);
                let APIRequestors = [...response.requestorList];
                APIRequestors.unshift({ itemID: 0, itemName: "-- Select a Requestor --" });
                setRequestors(APIRequestors);
                let APIProjects = [...response.projectList];
                APIProjects.unshift({ itemID: 0, itemName: "No Project" });
                setProjectList(APIProjects);
                setProjectID(0);
                let APIAssets = [...response.assetList];
                APIAssets.unshift({ itemID: 0, itemName: "No Asset" });
                setAssetList(APIAssets);
                let APIWorkTypes = [...response.workTypes];
                APIWorkTypes.unshift({ itemID: 0, itemName: "-- Select a Work Type --" });
                setWorkTypeList(APIWorkTypes);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log(err);
            })
    }, [companyID]);
    useEffect(() => {
        if (projectID === 0) {
            setWTSelectionEnabled(true);
            return;
        }
        let proj = projectList.filter(project =>
            project.itemID === projectID);
        if (proj.length === 1)
            setWorkTypeID(proj[0].projectWorkTypeID);
        setWTSelectionEnabled(false);
    }, [projectID]);
    const loadInit = () => {
        setLoading(true);
        GetCompanyListBasic().then(response => {
            setCompanies(response.content);
        }).then(() => {
            return GetIncidentParamLists()
        }).then(response => {
            setRequestTypes(response.requestTypes);
            setRequestTypeID(response.requestTypes[0].itemID);
            setStatuses(response.statusList);
            setStatusID(response.statusList[0].itemID);
            setPriorities(response.priorityList);
            setPriorityID(response.priorityList[0].itemID)
            let APIAssignedToList = [...response.assignedToList];
            APIAssignedToList.unshift({ itemID: 0, itemName: "Unassigned" });
            setAssignToList(APIAssignedToList);
            let APITeamList = [...response.teamList];
            APITeamList.unshift({ itemID: 0, itemName: "No Team" });
            setTeamList(APITeamList);
            setMyContactID(response.requestorContactID);
        }).then(() => {
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    }
    const submitForm = () => {
        setSubmitted(true);
        if (companyID === 0 || requestorID === 0 ||
            workTypeID === 0 || summary.trim() === "") {
            return;
        }
        setLoading(true);
        CreateIncidentAPI({
            companyID: companyID,
            locationID: locationID,
            requestorID: requestorID,
            requestTypeID: requestTypeID,
            statusID: statusID,
            priorityID: priorityID,
            assignedToID: assignedToID,
            projectID: projectID,
            assetID: assetID,
            workTypeID: workTypeID,
            teamID: teamID,
            incidentDate: incidentDate,
            summary: summary
        }).then(response => {
            if (!response.success) {
                setLoading(false);
                setErrmessage(response.message);
                return;
            }
            props.history.push(`/${tenant}/Incidents/${response.content.incidentID}?AddNote=${chkAddNote}`);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    }
    return (
        <div>
            <h2>Create Incident</h2>
            <div className="tabDisplayArea">
                {loading ? (
                    <div className="NexusLoading">
                        <Loader />
                    </div>
                ) : null}
                <table className="nextTable incidentTable">
                    <tbody>
                        <tr>
                            <th className="incidentTableHeader" colSpan="2">
                                Create a New Incident Request
                            </th>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Company Name:</th>
                            <td style={submitted && companyID === 0 ? { backgroundColor: "#d20107" } : null}>
                                <select value={companyID} onChange={e => setCompanyID(parseInt(e.target.value, 10))}>
                                    <option value={0}>-- Select a Company --</option>
                                    {companies.map(company =>
                                        <option key={company.itemID} value={company.itemID}>
                                            {company.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Location:</th>
                            <td>
                                <select value={locationID} onChange={e => setLocationID(parseInt(e.target.value, 10))}>
                                    {locations.map(location =>
                                        <option key={location.itemID} value={location.itemID}>
                                            {location.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Requestor:</th>
                            <td style={submitted && requestorID === 0 ? { backgroundColor: "#d20107" } : null}>
                                <select value={requestorID} onChange={e => setRequestorID(parseInt(e.target.value, 10))}>
                                    {requestors.map(requestor =>
                                        <option key={requestor.itemID} value={requestor.itemID}>
                                            {requestor.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Request Type:</th>
                            <td>
                                <select value={requestTypeID} onChange={e => setRequestTypeID(parseInt(e.target.value, 10))}>
                                    {requestTypes.map(reqtype =>
                                        <option key={reqtype.itemID} value={reqtype.itemID}>
                                            {reqtype.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Incident Date/Time:</th>
                            <td>
                                <DateTimePicker value={incidentDate} onChange={date => setIncidentDate(date)} clearIcon={null} />
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Incident Status:</th>
                            <td>
                                <select value={statusID} onChange={e => setStatusID(parseInt(e.target.value, 10))}>
                                    {statuses.map(status =>
                                        <option key={status.itemID} value={status.itemID}>
                                            {status.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Priority:</th>
                            <td>
                                <select value={priorityID} onChange={e => setPriorityID(parseInt(e.target.value, 10))}>
                                    {priorities.map(priority =>
                                        <option key={priority.itemID} value={priority.itemID}>
                                            {priority.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Assigned To:</th>
                            <td>
                                <select value={assignedToID} onChange={e => setAssignedToID(parseInt(e.target.value, 10))}>
                                    {assignToList.map(contact =>
                                        <option key={contact.itemID} value={contact.itemID}>
                                            {contact.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Project:</th>
                            <td>
                                <select value={projectID} style={{ maxWidth: "300px" }}
                                    onChange={e => setProjectID(parseInt(e.target.value, 10))}>
                                    {projectList.map(project =>
                                        <option key={project.itemID} value={project.itemID}>
                                            {project.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Asset:</th>
                            <td>
                                <select value={assetID} onChange={e => setAssetID(parseInt(e.target.value, 10))}>
                                    {assetList.map(asset =>
                                        <option key={asset.itemID} value={asset.itemID}>
                                            {asset.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Work Type:</th>
                            <td style={submitted && workTypeID === 0 ? { backgroundColor: "#d20107" } : null}>
                                <select value={workTypeID} disabled={!wtSelectionEnabled}
                                    onChange={e => setWorkTypeID(parseInt(e.target.value, 10))}>
                                    {workTypeList.map(workType =>
                                        <option key={workType.itemID} value={workType.itemID}>
                                            {workType.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Team:</th>
                            <td>
                                <select value={teamID} onChange={e => setTeamID(parseInt(e.target.value, 10))}>
                                    {teamList.map(team =>
                                        <option key={team.itemID} value={team.itemID}>
                                            {team.itemName}
                                        </option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th className="createIncidentHeader">Incident Summary:</th>
                            <td style={submitted && summary.trim() === "" ? { backgroundColor: "#d20107" } : null}>
                                <ReactQuill value={summary} onChange={e => setSummary(e)}
                                    modules={{ toolbar: toolbarConfig }} />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2" style={{ backgroundColor: "#333333" }}>
                                <input type="button" className="nexusButtonCommon" value="Create Incident"
                                    onClick={() => submitForm()} />
                                <div style={{ float: "right", marginTop: "6px" }}>
                                    <label>
                                        Add Note:
                                        <input type="checkbox" style={{ margin: "0px 6px" }} checked={chkAddNote}
                                            onChange={e => setChkAddNote(e.target.checked)} />
                                    </label>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                {submitted ? (
                    <div>
                        {companyID === 0 ? (
                            <div className="Error PaddedError">Company is required!</div>
                        ) : null}
                        {requestorID === 0 ? (
                            <div className="Error PaddedError">Requestor is required!</div>
                        ) : null}
                        {workTypeID === 0 ? (
                            <div className="Error PaddedError">Work Type is required!</div>
                        ) : null}
                        {summary.trim() === "" ? (
                            <div className="Error PaddedError">Incident Summary is required!</div>
                        ) : null}
                        <div className="Error PaddedError">{errmessage}</div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default CreateIncident;