/*
 * These extra props are required for pageMode="remote":
 * pageSize
 * pageIndex
 * filters
 * filterIds
 * filterValues
 * sortBy
 * sortByIds
 * sortByDescValues
 * fetchData
 * loading
 * loadingFilterSort
 * 
 * fetchData function when passed must be a React.useCallback
 * a component using pageMode="remote" also must have state:
const firstRender = useRef(true);
const [totalResults, setTotalResults] = useState(0);
const [filterResults, setFilterResults] = useState([]);
const [sortByResults, setSortByResults] = useState([]);
const [loadingFilterSort, setLoadingFilterSort] = useState(false);
-----------------------------------------------------------------------------------
 * example of remote call:
 * pageSize determines if remote pagination is done on backend
const getOrgs = React.useCallback(({pageSize, pageIndex, filters, filterIds, filterValues, sortBy, sortByIds, sortByDescValues}) => {
    setFilterResults(filters);
    setSortByResults(sortBy);
    firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
    firstRender.current = false;
    GetOrganizations(pageSize, pageIndex, filterIds, filterValues, sortByIds, sortByDescValues)
        .then((response) => {
            setLoadingFilterSort(false);
            setLoading(false);
            if (response.success) {
                setOrgs(response.organizations);
                setTotalResults(response.resultCount);
            }
        })
        .catch(() => {
            setLoadingFilterSort(false);
            setLoading(false);
        });
}, []);

* pass getOrgs() as fetchData prop
-----------------------------------------------------------------------------------
 * example of local call:
const getOrgsLocal = () => {
    setLoading(true);
    GetOrganizations()
        .then((response) => {
            setLoading(false);
            if (response.success) {
                setOrgs(response.organizations);
                setTotalResults(response.resultCount);
            }
        })
        .catch(() => {
            setLoading(false);
        });
};

useEffect(() => {
    getOrgsLocal();
}, []);
 */

import React, { useState, useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useRowSelect,
  useAsyncDebounce,
} from 'react-table';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../App/loader';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checkRow, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const IndeterminateRadio = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="radio" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const NexusGrid = (props) => {
  const columns = React.useMemo(() => props.columns, [props.columns]);
  const data = React.useMemo(() => props.data, [props.data]);

  function DefaultColumnFilter({
    column: { Header, filterValue, preFilteredRows, setFilter },
  }) {
    return (
      <input
        className="filter text-filter form-control"
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={`Enter ${Header}...`}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const dataFormat = (index, cell, row) => {
    let itemLink = props.linkTo;
    let replaced = 0;
    if (cell === null) {
      return '';
    }
    if (!itemLink || (index == 0 && props.selectRowMode)) {
      return cell.render('Cell');
    }

    itemLink = itemLink.replaceAll(
      ':key',
      row.values[
        Object.keys(row.values).find(
          (key) => key.toLowerCase() === props.tableKey?.toLowerCase()
        )
      ]
    );
    return <Link to={itemLink}>{cell.render('Cell')}</Link>;
  };

  const getRowId = (row, relativeIndex) => {
    return props.tableKey ? row[props.tableKey] : relativeIndex;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, selectedRowIds, filters, sortBy },
  } = useTable(
    {
      columns,
      data,
      getRowId,
      defaultColumn,
      manualFilters: props.pageMode == 'remote' ? true : false,
      manualPagination: props.pageMode == 'remote' ? true : false,
      manualSort: props.pageMode == 'remote' ? true : false,
      pageCount: props.pageMode == 'remote' ? props.totalPages : -1,
      autoResetPage: false,
      stateReducer: (newState, action) => {
        if (
          action.type === 'toggleRowSelected' &&
          props.selectRowMode == 'radio'
        ) {
          newState.selectedRowIds = {
            [action.id]: true,
          };
        }

        return newState;
      },
      initialState: {
        filters: props.filterData ? props.filterData : [],
        pageIndex: 0,
        pageSize: props.perPage ? props.perPage : 25,
        sortBy:
          props.pageMode == 'remote'
            ? props.sortByData
              ? props.sortByData
              : []
            : [
                {
                  id: props.defaultSortBy ? props.defaultSortBy : '',
                  desc:
                    props.defaultSortOrder == 'desc' ||
                    props.defaultSortOrder == null
                      ? true
                      : false,
                },
              ],
        hiddenColumns: props.hiddenColumns ? props.hiddenColumns : [],
        selectedRowIds:
          props.selectedRows && props.selectedRows[0] != 0
            ? props.selectedRows.reduce((ac, a) => ({ ...ac, [a]: true }), {})
            : { 0: true },
        filters: props.columns.some(c => c['Header'] == 'Active') ? [
          {
            id: 'active', value: 'true'
          }
        ] : []
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (props.selectRowMode) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ page, toggleRowSelected, isAllPageRowsSelected }) => {
              const modifiedOnChange = (event) => {
                page.forEach((row) => {
                  // check each row if it is not disabled
                  !props.unSelectableRows?.includes(row?.id) &&
                    toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              //Count number of selectable and selected rows in the current page
              //to determine the state of select all checkbox
              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;

              page.forEach((row) => {
                row.isSelected && selectedRowsInCurrentPage++;
                !props.unSelectableRows?.includes(row?.id) &&
                  selectableRowsInCurrentPage++;
              });

              //If there are no selectable rows in the current page
              //select all checkbox will be disabled -> see page 2
              const disabled = selectableRowsInCurrentPage === 0;
              const checked =
                (isAllPageRowsSelected ||
                  selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
                !disabled;

              return props.selectRowMode == 'checkbox' ? (
                <IndeterminateCheckbox
                  onChange={modifiedOnChange}
                  checked={checked}
                  disabled={disabled}
                />
              ) : (
                <></>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <>
                {props.selectRowMode == 'checkbox' ? (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    disabled={props.unSelectableRows?.includes(row?.id)}
                  />
                ) : props.selectRowMode == 'radio' ? (
                  <IndeterminateRadio
                    {...row.getToggleRowSelectedProps()}
                    disabled={props.unSelectableRows?.includes(row?.id)}
                  />
                ) : null}
              </>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const DebounceFetch = useAsyncDebounce(props.fetchData, 500);
  const DebounceGoToPage = useAsyncDebounce(gotoPage, 500);
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (props.pageMode == 'remote') {
      DebounceFetch({
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy,
        filters: filters,
      });
    }
  }, [pageSize, pageIndex]);

  useEffect(() => {
    if (props.pageMode == 'remote') {
      DebounceFetch({
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy,
        filters: filters,
      });
      gotoPage(0);
    }
  }, [filters]);

  useEffect(() => {
    if (props.pageMode == 'remote') {
      DebounceFetch({
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy,
        filters: filters,
      });
    }
  }, [sortBy]);

  useEffect(() => {
    if (props.setSelectedRows) {
      // this is assuming the id is always an int, seems to be the case with primary keys
      // in Nexus DB... can change if needed
      props.setSelectedRows(
        Object.keys(selectedRowIds).map((i) => parseInt(i))
      );
    }
  }, [props.setSelectedRows, selectedRowIds]);

  return (
    <div className="bootstrap-wrapper">
      <div className="nexusGrid">
        {props.pageMode != 'none' ? (
          <div className="row pagination no-gutters">
            <div className="col-6">
              <span className="gridTotals">Results: {props.totalResults}</span>
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  {pageSize}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    10,
                    25,
                    50,
                    props.pageMode == 'remote'
                      ? props.totalResults
                      : props.data.length,
                  ].map((pageSize) => (
                    <Dropdown.Item
                      key={pageSize}
                      value={pageSize}
                      onClick={() => setPageSize(pageSize)}
                    >
                      {pageSize}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {props.loadingFilterSort ? (
                <span className="text-dark pl-1">Loading...</span>
              ) : null}
              <span>
                {props.selectedRows?.length > 0 &&
                props.bulkActionFuncs?.length != 0
                  ? props.bulkActionFuncs?.map((b, i) => {
                      return (
                        <div
                          className="nexusButtonCommon my-0"
                          onClick={b.func}
                          key={i}
                        >
                          {b.text}
                        </div>
                      );
                    })
                  : null}
              </span>
            </div>
            <div className="col-6 text-right switch-pages">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                First
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Prev
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Last
              </button>
              <div
                className="d-inline-block"
                style={{ margin: 'auto auto auto 1em' }}
              >
                <span className="gridTotals">
                  Page {pageIndex + 1} of {pageCount} |
                </span>
                <input
                  type="number"
                  placeholder={'Page'}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    DebounceGoToPage(page);
                  }}
                  style={{ width: '55px' }}
                />
              </div>
            </div>
          </div>
        ) : null}
        { props.columns.some(c => c['Header'] == 'Active') ? (
          <div className="row pagination">
          <div className="col-3">
            <input type="checkbox" name="activeOnly" defaultChecked={true} onChange={(e) => {              
              setFilter('active', e.target.checked.toString());              
            }} />
            <label htmlFor="activeOnly" className="text-dark pl-1">
              Show active
            </label>
          </div>
        </div>
        ) : (<></>)}
        {props.loading ? (
          <div className="NexusLoading">
            <Loader />
          </div>
        ) : (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`gridHeader ${
                        i == 0
                          ? props.selectRowMode == 'checkbox'
                            ? 'checkboxCol'
                            : props.selectRowMode == 'radio'
                            ? 'radioCol'
                            : ''
                          : ''
                      }`}
                    >
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.canFilter ? (
                          <i
                            className={`gridSortIcon fa fa-sort${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? '-down'
                                  : '-up'
                                : ''
                            }`}
                          ></i>
                        ) : null}
                      </span>
                      {!column.canFilter || props.filterHeaders == false
                        ? null
                        : column.render('Filter')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="gridRow">
                    {row.cells.map((cell, i) => {
                      return (
                        <td className="gridCell" {...cell.getCellProps()}>
                          {cell.column.dataFormat
                            ? cell.column.dataFormat(cell, row.original)
                            : dataFormat(i, cell, row)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default NexusGrid;

export const IndeterminateCheckboxColumnFilter = ({
  column: { Header, filterValue, preFilteredRows, setFilter },
}) => {
  const checkRef = useRef();

  useEffect(() => {
    checkRef.current.checked = filterValue == 'true';
  }, [filterValue]);

  return (
    <input
      type="checkbox"
      className="filter checkbox-filter"
      ref={checkRef}
      onChange={(e) => GetNewState(e)}
    />
  );

  function GetNewState(e) {
    switch (filterValue) {
      case 'true':
        setFilter('true');
        break;
      case 'false':
        setFilter(undefined);
        break;
    }
  }
};

NexusGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  filterData: PropTypes.array,
  sortByData: PropTypes.array,
  loadingFilterSort: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  tableKey: PropTypes.string,
  linkTo: PropTypes.string,
  totalResults: PropTypes.number,
  pageMode: PropTypes.oneOf(['local', 'remote', 'none']),
  perPage: PropTypes.number,
  filterHeaders: PropTypes.bool,
  defaultSortBy: PropTypes.string,
  defaultSortOrder: PropTypes.oneOf(['desc', 'asc']),
  selectRowMode: PropTypes.oneOf(['checkbox', 'radio']),
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.array,
  unSelectableRows: PropTypes.array,
  bulkActionFuncs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      func: PropTypes.func,
    })
  ),
};
