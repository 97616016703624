import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../App/loader';
import NexusGrid from '../App/NexusGrid';
import { GetService,
    GetServiceOrg, 
    GetServiceOrgTransitions, 
    CreateServiceOrgTransition, 
    SaveQuotedPrice,
    SaveServiceOrgDescription } from '../../api/ServiceAPI';
import { GetOrganization } from '../../api/OrganizationAPI';
import NexusModal from '../App/NexusModal';
import { Alert } from 'react-bootstrap';


const ServiceOrganization = (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [orgName, setOrgName] = useState("");
    const [transitionId, setTransitionId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [serviceOrg, setServiceOrg] = useState({
        serviceName: '',
        serviceOrgId: 0,
        serviceDescription: '',
        sku: "",
        msrp: 0,
        price: 0
    });
    const [serviceOrgTransitions, setServiceOrgTransitions] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [quoteError, setQuoteError] = useState(false);

    useEffect(() => {
        console.log('Executing useEffect');
        loadServiceOrg();
    }, [props.routeProps.match.params.serviceId, props.routeProps.match.params.orgId]);

    useEffect(() => {
        loadServiceOrgTransitions();
    }, [serviceOrg]);

    const openPriceModal = (e)=>{
        e.preventDefault();
        setDisplayModal(true);
    }
    const closePriceModal = ()=>{
        setDisplayModal(false);
    }

    const loadServiceOrg = () => {
        const serviceId = parseInt(props.routeProps.match.params.serviceId, 10);
        const orgId = parseInt(props.routeProps.match.params.orgId, 10);
        let s = null;
        if (!isNaN(serviceId) && serviceId !== 0 && !isNaN(orgId) && orgId !== 0) {
            setLoading(true);

            let serviceOrg = GetServiceOrg({
                serviceId: serviceId,
                orgId: orgId
            }).then(res => {
                //console.log('GetServiceOrg: ' + JSON.stringify(res.content));
                return res.content;
            });

            let service = GetService({
                content: serviceId
            }).then(res => {
                //console.log('GetService: ' + JSON.stringify(res.content));
                return res.content;
            });

            Promise.all([serviceOrg, service]).then((values) => {
                let org = values[0];
                let service = values[1];
                setServiceOrg({
                    ...org,
                    msrp: service.msrp,
                    sku: service.sku,
                    serviceDescription: org.serviceDescription ? org.serviceDescription : service.serviceDescription
                });
            });
        }

        GetOrganization({
            content: orgId
        }).then(res => {
            if(res.success) {
                setOrgName(res.content[0].orgName);
            }
        });
        setLoading(false);
    }

    const loadServiceOrgTransitions = () => {
        if (!isNaN(serviceOrg.serviceOrgId) && serviceOrg.serviceOrgId != 0) {
            setLoading(true);
            GetServiceOrgTransitions({ content: serviceOrg.serviceOrgId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setServiceOrgTransitions(res.content);
                        }
                    }
                })
        }
        setLoading(false);
    }

    const createServiceOrgTransition = () => {
        if (!isNaN(serviceOrg.serviceOrgId) && serviceOrg.serviceOrgId != 0) {
            setLoading(true);
            CreateServiceOrgTransition({ content: serviceOrg.serviceOrgId })
                .then(res => {
                    if (res != null) {
                        if (res.success) {
                            setTransitionId(res.content.serviceOrgTransitionId);
                        }
                    }
                })
        }
        setLoading(false);
    }

    const getStatus = () => {
        let transitioning = false;
        let cancelable = false;
        serviceOrgTransitions.forEach(sot => {
            if (sot.status === "Created" || sot.status === "InProgress") {
                transitioning = true;
                cancelable = sot.cancelable;
            }
        });
        if (transitioning) {
            return serviceOrg.active ^ cancelable ? "Activation in progress" : "Deactivation in progress"; // ^ == xor
        } else {
            return serviceOrg.active ? "Active" : "Disabled";
        }
    }

    const getButtonValue = () => {
        let transitioning = false;
        serviceOrgTransitions.forEach(sot => {
            if (sot.status === "Created" || sot.status === "InProgress") {
                transitioning = true;
            }
        });
       if (transitioning) {
            return "View Transition Progress";
       } else {
           return serviceOrg.active ? "Deactivate" : "Activate";
       }
    }

    const submit = (e) => {

        e.preventDefault();
        console.log("callback worked")
        let transitioning = false;
        
        serviceOrgTransitions.forEach(sot => {
            if (sot.status === "Created" || sot.status === "InProgress") {
                transitioning = true;
                setTransitionId(sot.serviceOrgTransitionId);
            }
        });
        if (!transitioning) {
            createServiceOrgTransition();
        }
    }

    const changeQuote = (e) => {
        setServiceOrg({
            ...serviceOrg,
            price: e.target.value
        });
    }

    const changeDescription = (e) => {
        setServiceOrg({
            ...serviceOrg,
            serviceDescription: e.target.value
        });
    }

    const submitChange = (e) => {
        e.preventDefault();
        SaveQuotedPrice({
            serviceOrgID: serviceOrg.serviceOrgId,
            quotedPrice: parseInt(serviceOrg.price)
        }).then(SaveServiceOrgDescription({
            serviceOrgID: serviceOrg.serviceOrgId,
            serviceDescription: serviceOrg.serviceDescription
        })).then(response => {
            if(response.success) {
                setDisplayModal(false);
            }
        }).catch(e => {
            setQuoteError(true);
        });
    }

    const displayQuotedPrice = () => {
        if(serviceOrg.price) {
            return serviceOrg.price;
        } else {
            return serviceOrg.msrp;
        }
    }

    const headers = [
        { Header: "Type", accessor: "type" },
        { Header: "Status", accessor: "status" },
        { Header: "Service Org Transition ID", accessor: "serviceOrgTransitionId"}, 
        {
            Header: "Start Date", accessor: "startDate",
            dataFormat: (cell, row) => { return <div>{cell.value ? new Date(cell.value).toLocaleString() : ""}</div> }
        },
        {
            Header: "End Date", accessor: "endDate",
            dataFormat: (cell, row) => { return <div>{cell.value ? new Date(cell.value).toLocaleString() : ""}</div> }
        },
    ];

    return (loading ?
        <div className="NexusLoading">
            
            <Loader />
        </div> :
        <div id="modalRoot">
            <NexusModal stateValue={displayModal} stateFunctionClose={() => setDisplayModal(false)} title="Edit Service" closeText="Save"
                closeAction={(e) => submitChange(e)} width='25%' height='auto'>
                <label>Quoted Price</label>
                <br />
                <input type="number" name="Quoted Price"
                onChange={changeQuote} value={serviceOrg.price}
                style={{ width: '100%' }} />
                <br />
                <br />
                <label>Description</label>
                <br />
                <input type="text" name="serviceDescription"
                onChange={changeDescription} value={serviceOrg.serviceDescription}
                style={{ width: '100%' }} />
                <br />
                {quoteError ?
                <div className="bootstrap-wrapper">
                    <Alert className="mt-3 mb-0" variant="danger" dismissible onClose={() => setQuoteError(false)}>
                        Error editing quote: Enter a value
                    </Alert>
                </div>
                : null }
            </NexusModal>
            {transitionId !== 0 &&
                <Redirect to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}/transitions/${transitionId}`} />
            }
            <BreadcrumbsItem to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations`} label='Organizations' />

            <h2>{orgName}</h2>
            <Link id="goToOrgButton" className="nexusButtonCommon" to={`/${tenant}/organizations/${props.routeProps.match.params.orgId}/services`} >All {orgName} Services</Link>

            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Service:</td>
                                <td>{serviceOrg.serviceName}</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>{serviceOrg.serviceDescription}</td>
                            </tr>
                            <tr>
                                <td>Status:</td>
                                <td>{getStatus()}</td>
                            </tr>
                            <tr>
                                <td>SKU:</td>
                                <td>{serviceOrg.sku}</td>
                            </tr>
                            <tr>
                                <td>Default MSRP:</td>
                                <td>${serviceOrg.msrp}</td>
                            </tr>
                            <tr>
                                <td>Quoted Price:</td>
                                <td>${displayQuotedPrice()}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <button className="nexusButton" onClick={openPriceModal}>Edit</button>
                        <br></br>
                        <br></br>
                        <input type="submit" name="ServiceOrgButton" id="ServiceOrgButton"
                        value={getButtonValue()} className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
            <h3>Transitions</h3>
            <div>
                <NexusGrid
                    linkTo={`/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}/transitions/:key`} 
                    tableKey={"serviceOrgTransitionId"}
                    hiddenColumns={['serviceOrgTransitionId']}
                    data={serviceOrgTransitions} 
                    defaultSortBy={"startDate"}
                    defaultSortOrder={"DESC"}
                    columns={headers} 
                />
            </div>
        </div>
    );
}

export default ServiceOrganization