import APIRequest from "./APIRequest";

export const GetXBertSessions = (payload) => {
    return APIRequest({
        url: `Asset/GetXBertSessions`,
        method: 'post',
        body: payload,
    });
};

export const GetAssets = (payload) => {
    return APIRequest({
        url: `Asset/GetAssets`,
        method: 'post',
        body: payload,
    }); 
}

export const GetAssetsNum = () => {
    return APIRequest({
        url: `Asset/GetAssetsNum`,
        method: 'post'
    });
}

export const GetAsset = (payload) => {
    return APIRequest({
        url: `Asset/GetAsset`,
        method: 'post',
        body: payload,
    });
};

export const GetAssetStatuses = (AssetID, DataPoints)=>{
    return APIRequest({
        url:`Asset/GetAssetStatusHistory?AssetID=${AssetID}&DataPoints=${DataPoints}`,
        method: 'get',
    })
}

export const GetAssetNetworkInterfaces = (payload) => {
    return APIRequest({
        url: `Asset/GetAssetNetworkInterfaces`,
        method: 'post',
        body: {content: payload},
    });
};

export const GetOrganizationAssets = (payload) => {
    return APIRequest({
        url: `Asset/GetOrganizationAssets`,
        method: 'post',
        body: payload,
    });
};

export const GetAssetsNotInOrg = (payload) => {
    return APIRequest({
        url: `Asset/GetAssetsNotInOrg`,
        method: 'post',
        body: payload,
    });
};

export const CreateAsset = (payload) => {
    return APIRequest({
        url: `Asset/CreateAsset`,
        method: 'post',
        body: payload,
    });
};

export const EditAsset = (payload) => {
    return APIRequest({
        url: `Asset/EditAsset`,
        method: 'post',
        body: {content: payload},
    });
};

export const UpdateBulkAssetRequest = (payload) => {
    return APIRequest({
        url: `Asset/UpdateBulkAssetRequest`,
        method: 'post',
        body: payload
    });
}

export const DisableAsset = (payload) => {
    return APIRequest({
        url: `Asset/DisableAsset`,
        method: 'post',
        body: {content: payload},
    });
};

export const GetAssetTypes = () => {
    return APIRequest({
        url: `Asset/GetAssetTypes`,
        method: 'post',
    });
};

export const GetAssetType = (payload) => {
    return APIRequest({
        url: `Asset/GetAssetType`,
        method: 'post',
        body: {content: payload},
    });
};

export const CreateAssetType = (payload) => {
    return APIRequest({
        url: `Asset/CreateAssetType`,
        method: 'post',
        body: {content: payload},
    });
};

export const EditAssetType = (payload) => {
    return APIRequest({
        url: `Asset/EditAssetType`,
        method: 'post',
        body: {content: payload},
    });
};

export const DeleteAssetType = (payload) => {
    return APIRequest({
        url: `Asset/DeleteAssetType`,
        method: 'post',
        body: payload,
    });
};

export const UpdateAzureConnectionString = (payload) => {
    return APIRequest({
        url: `Asset/EditAzureConnectionString`,
        method: 'post',
        body: payload,
    });
};
export const GetAssetTunnels = (payload) => {
    return APIRequest({
        url: `Asset/GetAssetTunnels`,
        method: 'post',
        body: payload,
    });
};
export const CreateAssetTunnel = (payload) => {
    return APIRequest({
        url: `Asset/CreateAssetTunnel`,
        method: 'post',
        body: payload,
    });
};
export const DeleteAssetTunnel = (payload) => {
    return APIRequest({
        url: `Asset/DeleteAssetTunnel`,
        method: 'post',
        body: payload,
    });
};

export const GetActiveSessions = (payload) => {
    return APIRequest({
        url: `Asset/GetActiveSessions`,
        method: 'POST',
        body: payload,
    });
};

export const GetAssetXBertVersion = (payload) => {
  return APIRequest({
    url: `Asset/GetAssetXBertVersion`,
    method: "post",
  });
};
export const GetXBertVersions = () => {
    return APIRequest({
        url: `Asset/GetXBertVersions`,
        method: 'get'
    })
}
export const GetXBertVersionByAsset1 = (payload) => {
    return APIRequest({
        url: `Asset/GetXBertVersionByAsset`,
        method: 'post',
        body: { content: payload }
    })
}

export const GetIpForScan = (payload) => {
    return APIRequest({
        url: `Asset/GetIpForScan`,
        method: 'post',
        body: payload
    })
}
