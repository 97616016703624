/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';

import XBertVersion from './XBertVersion/XbertVersion';
import { useAppSelector, useAppDispatch } from '../../../../Redux/XBert/hooks';
import {
  getXBertVersions,
  xbertVersions,
  assetId,
  currentVersion,
} from '../../../../Redux/XBert/XBertSlice';
import NexusGrid from '../../../App/NexusGrid';

interface xBertVersionListProps {
  setSelectedOption: Dispatch<SetStateAction<any>>,
  selectedOptions: Number
}

const xbertVersionList: FC<xBertVersionListProps> = ({setSelectedOption, selectedOptions}) => {
  let { path, url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const versions = useAppSelector(xbertVersions);
  const current_version = useAppSelector(currentVersion);

  useEffect(() => {
    dispatch(getXBertVersions());
  }, []);

  useEffect(() => {
    console.log(selectedOptions);
  }, [selectedOptions]);

  const headers: { Header: string; accessor: string;}[] = [
    { Header: 'Version ID', accessor: 'xBertVersionID' },
    { Header: 'Version Name', accessor: 'name' },
    { Header: 'File Name', accessor: 'fileName' },
    { Header: 'Description', accessor: 'description' },
  ];

  let unSelectableVersions: Array<Number> = [];
  for(let i = 0; i < versions.length; i++) {
    if(versions[i].xBertVersionID < current_version.xBertVersionID) {
      unSelectableVersions.push(versions[i].xBertVersionID);
    }
  }

  return (
    <div>
      <NexusGrid
        columns={headers}
        hiddenColumns={['xBertVersionID']}
        data={versions}
        tableKey={'xBertVersionID'}
        selectRowMode={'radio'}
        setSelectedRows={setSelectedOption}
        selectedRows={[selectedOptions]}
        unSelectableRows={unSelectableVersions}
        filterHeaders={false}
      />
    </div>
  );
};

export default xbertVersionList;
