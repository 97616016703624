import React, { FC, useEffect, useState } from 'react';
import XBertVersionList from '../XBertVersionList/XBertVersionList';
import { useAppSelector, useAppDispatch } from '../../../../Redux/XBert/hooks';
import UpdateAssetXBertVersionRequest, {UpdateMode, VersionToUpdate } from '../../../../api/models/UpdateAssetXBertVersionRequest';
import XbertVersionInfo from '../../../../api/models/XBertVersionInfo';
import {
    currentVersion,
    requestedVersion,
    assetId,
    getXBertVersionByAsset,
    getRequestedVersionByAsset,
    setAssetId,
    updateAssetXBertVersion,
} from '../../../../Redux/XBert/XBertSlice';
import { UpdateAssetXBertVersion, XBertVersions } from '../../../../api/XBert';

interface XBertVersionUpdaterProps {
    assetId: number;
}

const XbertVersionOverview: FC<XBertVersionUpdaterProps> = ({ assetId }) => {
    const dispatch = useAppDispatch();
    const current_version = useAppSelector(currentVersion);
    const requested_version = useAppSelector(requestedVersion);
    const [selectedRadioOption, setSelectedRadioOption] = useState<Number>(0);
    //const currentAsset: number = useAppSelector(assetId);

    useEffect(() => {
        dispatch(getXBertVersionByAsset(+assetId));
        dispatch(getRequestedVersionByAsset(+assetId));
        dispatch(setAssetId(+assetId));
    }, []);

    useEffect(() => {
        setSelectedRadioOption(requested_version.xBertVersionID);
    }, [requested_version]);

    const versionRequestedHandler = (): void => {
        const updateRequest: UpdateAssetXBertVersionRequest = {
          assetId: assetId,
          xBertVersionId: +selectedRadioOption!,
          updateMode: UpdateMode.ID,
          versionToUpdate: VersionToUpdate.APPROVED,
          versionFilename: null,
          versionName: null,
        };
        dispatch(updateAssetXBertVersion(updateRequest));
    };

    const details_overview = (
        <div className="bootstrap-wrapper">
            <div className='row p-1 no-gutters'>
                <div className='col-6'>
                    {selectedRadioOption ?
                    <div className='nexusButtonCommon' onClick={versionRequestedHandler}>Request this Version</div> :
                    <div className='nexusButtonCommonNoHover' style={{opacity: '0.6', cursor: 'default'}}>Request this Version</div>
                    }
                </div>
                <div className='col-6 text-right'>
                    <div className='row'>
                        <div className='col-12'><b>Current Version:</b> {current_version.name}</div>
                        <div className='col-12'><b>Requested Version:</b> {requested_version.name}</div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {details_overview}
            <XBertVersionList setSelectedOption={setSelectedRadioOption} selectedOptions={selectedRadioOption}></XBertVersionList>
        </div>
    );
};

export default XbertVersionOverview;
