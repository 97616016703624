import React, { useEffect, useState } from 'react'
import { GetAssetTunnels, DeleteAssetTunnel } from '../../api/AssetAPI'
import NewAssetTunnel from './Tunnels/NewAssetTunnel'
import NexusGrid from '../App/NexusGrid'
import AssetRemoteAccess from "./AssetRemoteAccess"
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
const TabTunnels = (props) => {

    const AssetID = props.routeProps.match.params.id;
    let { path, url } = useRouteMatch();
    const [Tunnels, setTunnels] = useState([])

    const TIMER_INTERVAL = 5000;

    useEffect(() => {
        const interval = setInterval(() => {
            LoadTunnels();
        }, TIMER_INTERVAL);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        LoadTunnels();
    }, [props])

    const LoadTunnels = () => {
        GetAssetTunnels({ content: { "assetID": Number(AssetID) }}).then(response => {
            setTunnels(response.content)
        })
    }
    const DeleteTunnel = (e, assetTunnelID) => {
        e.preventDefault();
        let answer = window.confirm("Are you sure you want to remove this tunnel?");
        if (answer) {
            DeleteAssetTunnel({ "content": Number(assetTunnelID) }).then(response => {
                if (response.success) {
                    LoadTunnels();
                }
            })
        }
    }
    const GetConnected = (con, row) => {
        var secSinceLastSeen = (new Date() - new Date(row.lastSeen)) / (1000)
        var msg = (con ? "Yes" : "No");
        // Assets check in every 10 seconds, consider it lagged if > 10 AND < 30. 
        // If its over 30, consider it disconnected. 
        if (secSinceLastSeen >= 10)
            msg += " (LAGGED)";
        if (secSinceLastSeen >= 30)
            msg = "No";
        return msg;
    }
    const headers = [
        { Header: "Description", accessor: "description" },
        { Header: "S-Address", accessor: "sourceAddress" },
        { Header: "S-Port", accessor: "sourcePort" },
        { Header: "D-Address", accessor: "destinationAddress" },
        { Header: "D-Port", accessor: "destinationPort" },
        {
            Header: "Connected", accessor: "connected", dataFormat: (cell, row) => {
                return GetConnected(cell.value, row);
            }
        },
        {
            Header: "Connection", dataFormat: (cell, row) => {
                return (
                    <>
                        {row.destinationPort == 3389 ? // Temporary implementation. Rather return a template of predefined tunnels in the future and allow for conditional rendering. 
                            <AssetRemoteAccess AssetTunnelID={row.assetTunnelID} AssetName={props.assetName} />
                            : null}
                        <button className="nexusButtonCommon" onClick={(e) => DeleteTunnel(e, row.assetTunnelID)}>
                            <i className="fas fa-trash"></i> Delete
                        </button>
                    </>)
            }
        }
    ]

    return (
        <>
            <Switch>
                <Route path={`${path}/new`}>
                    <NewAssetTunnel routeProps={props.routeProps} />
                </Route>
                <Route exact path={path}>
                    <h1>Tunnels</h1>
                    <Link className="newButton" role="button" to={`${url.endsWith('/') ? url : url.concat('/') }new`}> Create Tunnel </Link>
                    <NexusGrid
                        data={Tunnels}
                        defaultSortBy={"backupID"}
                        columns={headers}
                        perPage={50}
                    />
                </Route>
            </Switch>
        </>
    )
}

export default TabTunnels
