import APIRequest from './APIRequest';

export const GetTrunks = (trunkid) => {
    return APIRequest({
        url: `Telephony/GetOrganizationTrunks`,
        method: 'POST',
        body: {
            trunkid: trunkid,
        },
    });
};

export const GetOrganizationCarriers = (id, body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationCarriers?orgid=${id}`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationSoftSwitches = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationSoftSwitches`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationPBXs = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationPBXs`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationPBX = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationPBX`,
        method: 'POST',
        body,
    });
};

export const DisableOrganizationPBX = (body) => {
    return APIRequest({
        url: `Telephony/DisableOrganizationPBX`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationDIDs = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationDIDs`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationTrunks = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationTrunks`,
        method: 'POST',
        body,
    });
};

export const EditOrganizationPBX = (body) => {
    return APIRequest({
        url: `Telephony/EditOrganizationPBX`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationSoftSwitch = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationSoftSwitch`,
        method: 'POST',
        body,
    });
};

export const EditOrganizationSoftSwitch = (body) => {
    return APIRequest({
        url: `Telephony/EditOrganizationSoftSwitch`,
        method: 'POST',
        body,
    });
};

export const DisableOrganizationSoftSwitch = (body) => {
    return APIRequest({
        url: `Telephony/DisableOrganizationSoftSwitch`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationCarrier = (id, body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationCarrier?orgid=${id}`,
        method: 'POST',
        body,
    });
};

export const EditOrganizationCarrier = (body) => {
    return APIRequest({
        url: `Telephony/EditOrganizationCarrier`,
        method: 'POST',
        body,
    });
};

export const DisableOrganizationCarrier = (body) => {
    return APIRequest({
        url: `Telephony/DisableOrganizationCarrier`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationDID = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationDID`,
        method: 'POST',
        body,
    });
};

export const EditOrganizationDID = (body) => {
    return APIRequest({
        url: `Telephony/EditOrganizationDID`,
        method: 'POST',
        body,
    });
};

export const DisableOrganizationDID = (body) => {
    return APIRequest({
        url: `Telephony/DisableOrganizationDID`,
        method: 'POST',
        body,
    });
};

export const AddOrganizationDID = (body) => {
    return APIRequest({
        url: `Telephony/AddOrganizationDID`,
        method: 'POST',
        body,
    });
};

export const DisableOrganizationTrunks = (body) => {
    return APIRequest({
        url: `Telephony/DisableOrganizationTrunks`,
        method: 'POST',
        body,
    });
};

export const GetOrganizationTrunk = (body) => {
    return APIRequest({
        url: `Telephony/GetOrganizationTrunk`,
        method: 'POST',
        body,
    });
};

export const EditOrganizationTrunks = (body) => {
    return APIRequest({
        url: `Telephony/EditOrganizationTrunks`,
        method: 'POST',
        body,
    });
};

export const TestSoftSwitchConnection = (body) => {
    return APIRequest({
        url: `Telephony/TestSoftSwitchConnection`,
        method: 'POST',
        body,
    });
};

export const TestPBXConnection = (body) => {
    return APIRequest({
        url: `Telephony/TestPBXConnection`,
        method: 'POST',
        body,
    });
};
