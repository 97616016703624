import React, { useState, useEffect } from 'react';
import Loader from '../App/loader';
import ProjectHeader from './ProjectHeader';
import { GetProject } from '../../api/ProjectAPI';
import ProjectIncidents from './ProjectIncidents';


const Project = props => {
    const [loading, setLoading] = useState(true);
    const [projectInfo, setProjectInfo] = useState({});

    const loadProject = () => {
        return GetProject(parseInt(props.id, 10));
    }
    useEffect(() => {
        setLoading(true);
        loadProject().then(response => {
            setProjectInfo(response.content);

            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return loading || !projectInfo || !projectInfo.incidents ? (
        <div>
            <h2>Incident Management</h2>
            <div className="tabDisplayArea">
                <Loader />
            </div>
        </div>
    ) : (
            <div>
                <h2>Project {projectInfo.title}</h2>
                <div className="tabDisplayArea" style={{ overflow: "auto" }}>
                    <ProjectHeader projectInfo={projectInfo} loadProject={loadProject} />
                    <ProjectIncidents projectID={projectInfo.projectID} incidents={projectInfo.incidents} />
                </div>
            </div>
        )
}
export default Project;