import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { useSelector } from 'react-redux';

import LoginForm from './loginform.js';
import BreadcrumbsLink from './BreadcrumbsLink.js';
import TenantLogin from './TenantLogin.js';
import TenantIdentify from './TenantIdentify.js';
import ChangePassword from './ChangePassword.js';
import ForgotPassword from './forgotPassword.js';
import ValidateLink from './validateLink.js';
import DuoCallBack from './DuoCallback.js';

const LoginRouter = (props) => {
  const tenant = useSelector((state) => state.TenantReducer.Tenant);
  return (
    <>
      <Breadcrumbs
        separator={<b> &lt; </b>}
        item={BreadcrumbsLink}
        compare={(a, b) => a.weight - b.weight}
        removeProps={{ weight: true }}
      />
      <Switch>
        <Route exact path="/" component={TenantLogin} />
        {/*<Route*/}
        {/*    exact*/}
        {/*    path="/duo_callback"*/}
        {/*    render={(routeProps) => (*/}
        {/*        <DuoCallBack routeProps={routeProps} />*/}
        {/*    )}*/}
        {/*/>*/}
        <Route
          exact
          path="/TenantIdentify"
          render={(routeProps) => <TenantIdentify routeProps={routeProps} />}
        />
        <Route
          exact
          path="/:tenantURI"
          render={(routeProps) => (
            <LoginForm routeProps={routeProps} checkAuth={props.checkAuth} />
          )}
        />
        <Route
          exact
          path={`/${tenant}/ChangePswd`}
          render={(routeProps) => (
            <ChangePassword
              routeProps={routeProps}
              checkAuth={props.checkAuth}
            />
          )}
        />
        <Route
          path={`/${tenant}/forgot`}
          render={(routeProps) => <ForgotPassword routeProps={routeProps} />}
        />
        <Route
          path={`/${tenant}/validate`}
          render={(routeProps) => <ValidateLink routeProps={routeProps} />}
        />
      </Switch>
    </>
  );
};

export default LoginRouter;
