/* eslint-disable default-case */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import XBertOnlineIndicator from './xBertOnlineIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../Redux/WebSocket';
import { Table } from 'react-bootstrap';
import FilePathContent from './FileSystemExplorer/FilePathContent';
import base32 from 'hi-base32';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

const FileSystemExplorer = (props) => {
  const socket = React.useContext(WebSocketContext);
  let drives = useSelector(
    (state) => state.WebSocketReducer.SelectedAssetDrives
  );
  let { path, url, params } = useRouteMatch();
  let AssetID = params.id;
  const [mode, setMode] = useState({});

  //component will not work without an asset id in the url as :id.
  //on Load
  useEffect(() => {
    socket.send(
      JSON.stringify({
        type: ' Nexus.Data.Classes.Models.XBert.WebSocketModels.DriveListRequest',
        body: { AssetID: parseInt(AssetID) },
      })
    );
  }, []);

  useEffect(() => {}, [drives]);

  const displayTargetSelectionControls = (
    itemPath,
    showAddExclusionsButton = true
  ) => {
    let addTarget = (e) => {
      props.setBackupTargets('add', itemPath);
    };

    let removeTarget = (e) => {
      props.setBackupTargets('remove', itemPath);
    };

    const editExclusions = (e) => {
      setMode({
        mode: 'Setting Backup Exclusions',
        path: itemPath,
      });
    };

    const addExclusion = (e) => {
      props.AddExclusion(mode.path, itemPath);
      console.log(`exclusion being added to ${mode.path}`);
    };
    const removeExclusion = (e) => {
      props.RemoveExclusion(mode.path, itemPath);
      console.log(`removing exclusion for ${itemPath} in ${mode.path}`);
    };

    if (
      props.backupTargets &&
      !props.backupTargets.filter((x) => x.target == itemPath).length > 0
    ) {
      if (mode.mode != 'Setting Backup Exclusions') {
        return (
          <button
            style={{ float: 'right' }}
            type="button"
            className="nexusButtonCommon rounded mx-4"
            data={itemPath}
            onClick={addTarget}
          >
            Add Target
          </button>
        );
      } else {
        if (
          props.backupTargets.filter((x) => x.exclusions.includes(itemPath))
            .length == 0
        ) {
          return (
            <button
              style={{ float: 'right' }}
              type="button"
              className="nexusButtonCommon rounded mx-4"
              data={itemPath}
              onClick={addExclusion}
            >
              Exclude
            </button>
          );
        } else {
          return (
            <button
              style={{ float: 'right' }}
              type="button"
              className="nexusButtonCommon rounded mmx-4"
              data={itemPath}
              onClick={removeExclusion}
            >
              Remove Exclusion
            </button>
          );
        }
      }
    } else if (
      props.backupTargets &&
      props.backupTargets.filter((x) => x.target == itemPath).length > 0
    ) {
      let encodedPath = base32.encode(itemPath);
      let newPath = url + '/' + encodedPath;

      if (showAddExclusionsButton) {
        return (
          <>
            <Link to={newPath}>
              <button
                style={{ float: 'right' }}
                type="button"
                className="nexusButtonCommon rounded mx-4"
                data={itemPath}
                onClick={editExclusions}
              >
                {' '}
                Edit Target Exclusions
              </button>
            </Link>
            <button
              style={{ float: 'right' }}
              type="button"
              className="nexusButtonCommon rounded mx-4"
              data={itemPath}
              onClick={removeTarget}
            >
              Remove Target
            </button>
          </>
        );
      } else {
        return (
          <button
            style={{ float: 'right' }}
            type="button"
            className="nexusButtonCommon rounded mx-4"
            data={itemPath}
            onClick={removeTarget}
          >
            Remove Target
          </button>
        );
      }
    }
  };

  const ListDrives = () => {
    return drives.map((drive, index) => {
      if (drive.Type == 'Fixed') {
        let FilePath = base32.encode(drive.Path);

        const useVolumeSelectorControls = () => {
          if (props.mode == 'backupTargetEditor') {
            let selectedDrive = props.storageVolumes.filter(
              (x) => x.name == drive.Name
            );
            if (selectedDrive) {
              props.setStorageVolume(selectedDrive[0]);
            }
          }
        };
        return (
          <tr key={index}>
            <td className="px-3 py-4">
              <Link className="py-2 d-inline-block"
                to={`${url}/${FilePath}`}
                onClick={useVolumeSelectorControls}
              >
                {drive.Name}
              </Link>
              {displayTargetSelectionControls(drive.Path)}
            </td>
          </tr>
        );
      }
    });
  };

  const ToggleExclusionMode = () => {
    const ToggleExclusionEditing = (e) => {
      setMode({});
    };

    if (mode.mode == 'Setting Backup Exclusions') {
      return (
        <button
          type="button"
          className="nexusButtonCommon rounded mr-4 d-block ml-0 mt-2"
          onClick={ToggleExclusionEditing}
        >
          Stop Editing Exclusions
        </button>
      );
    }
  };

  return (
    <div className="bootstrap-wrapper">
      <Switch>
        <Route exact path={path}>
          <Table bordered striped>
            <thead className="bg-secondary">
              <tr className="text-white">
                <th className="p-3">
                  <h4 className="d-inline-block font-weight-bold m-auto">Path {ToggleExclusionMode()}</h4>
                </th>
              </tr>
            </thead>
            <tbody>{ListDrives()}</tbody>
          </Table>
        </Route>
        <Route path={`${url}/:FilePath`}>
          <FilePathContent
            ToggleExclusionMode={ToggleExclusionMode}
            assetID={AssetID}
            url={url}
            backupTargets={props.backupTargets}
            setBackupTargets={props.setBackupTargets}
            AddExclusion={props.AddExclusion}
            RemoveExclusion={props.RemoveExclusion}
            mode={mode}
            setMode={setMode}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default FileSystemExplorer;
