import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    sortIncidents,
    colIcon,
    truncateSummary,
    changeSort
} from '../../utils/incidentUtils';
import { GetUnassignedIncidents } from '../../api/IncidentAPI';
import LoadingShroud from '../App/LoadingShroud';
import AccordionWidget from './AccordionWidget';
import { useSelector } from 'react-redux';

const UnassignedIncidents = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(true);
    const [descending, setDescending] = useState(true);
    const [sortCol, setSortCol] = useState("referenceID");
    const [incidents, setIncidents] = useState([]);
    const [rawIncidentData, setRawIncidentdata] = useState([]);
    const [error, setError] = useState("");
    useEffect(() => {
        loadIncidents();
    }, [])
    useEffect(() => {
        setIncidents(sortIncidents(rawIncidentData, sortCol, descending));
    }, [sortCol, descending, rawIncidentData])
    const loadIncidents = () => {
        setLoading(true);
        GetUnassignedIncidents().then(response => {
            if (!response.success) {
                setError(response.message);
                setLoading(false);
                return;
            }
            setRawIncidentdata(response.content)
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setError("An error occurred while loading unassigned incidents.");
            setLoading(false);
        })
    }
    return (
        <div className="col-12">
            <AccordionWidget title={'Unassigned Incidents'} dashboardPreference={'UnassignedIncidents'}>
                <div className="widgetBody">
                    <LoadingShroud Active={loading} />
                    {error !== "" ? (
                        <div className="Error PaddedError">{error}</div>
                    ) : incidents.length === 0 ? (
                        <div className="paddedBold">There are no unassigned incidents.</div>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <table className="nextTable incidentTable">
                                <tbody>
                                    <tr>
                                        <th onClick={() => changeSort(sortCol,
                                            "referenceID", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Incident ID
                                        {colIcon(sortCol, "referenceID", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "companyName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Company
                                    {colIcon(sortCol, "companyName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "requestedByName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Requestor
                                    {colIcon(sortCol, "requestedByName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "summary", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Summary
                                    {colIcon(sortCol, "summary", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "priorityName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Priority
                                    {colIcon(sortCol, "priorityName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "statusName", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Status
                                    {colIcon(sortCol, "statusName", descending)}
                                        </th>
                                        <th onClick={() => changeSort(sortCol,
                                            "incidentDateFormatted", descending, setSortCol,
                                            setDescending)}
                                            style={{ cursor: "pointer" }}>
                                            Date
                                    {colIcon(sortCol, "incidentDateFormatted", descending)}
                                        </th>
                                    </tr>
                                    {incidents.map(incident =>
                                        <tr key={incident.incidentID}>
                                            <td>
                                                <Link to={`/${tenant}/Incidents/${incident.incidentID}`} className="incidentRefLink">
                                                    {incident.referenceID}
                                                </Link>
                                            </td>
                                            <td>{incident.companyName}</td>
                                            <td>{incident.requestedByName}</td>
                                            <td>
                                                <div className="incidentSummary"
                                                    dangerouslySetInnerHTML={{ __html: truncateSummary(incident.summary) }} />
                                            </td>
                                            <td>{incident.priorityName}</td>
                                            <td>{incident.statusName}</td>
                                            <td>{incident.incidentDateFormatted}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </AccordionWidget>
        </div>
    )
}
export default UnassignedIncidents;