import React, { useState, useEffect } from 'react';
import Loader from '../App/loader';
import { Link } from 'react-router-dom';
import { GetUserNavigation } from '../../api/NavigationAPI';
import { useSelector } from 'react-redux';

const Navigation = props => {
    const [loading, setLoading] = useState(true);
    const [navItems, setNav] = useState([]);
    const tenant = useSelector(state => state.TenantReducer.Tenant);

    useEffect(() => {
        setLoading(true);
        GetUserNavigation()
            .then(result => {
                if (result != null) {
                    if (result.success) {
                        setNav(result.content);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);
    return loading ? (
        <div>
            <Loader />
        </div>
    ) : (
            <ul className="leftNav">
                {navItems.map(nav =>
                    <li key={nav.name}><Link to={`/${tenant}${nav.route}`}>{nav.name}</Link></li>
                )}

            </ul>
        )
}
export default Navigation;