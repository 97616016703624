import React, { useState, useEffect } from 'react';
import Loader from '../App/loader';
import IncidentHeader from './IncidentHeader';
import Note from './Note';
import AddNote from './AddNote';
import { GetIncident } from '../../api/IncidentAPI';
import { GetIncidentParamLists } from '../../api/IncidentAPI';
import { GetIncidentCompanyParamLists } from '../../api/IncidentAPI';

const Incident = (props) => {
  const [loading, setLoading] = useState(true);
  const [addNote, setAddNote] = useState(false);
  const [editIncident, setEditIncident] = useState(false);
  const [newNoteReassign, setNewNoteReassign] = useState(0);
  const [newNoteChangeStatus, setNewNoteChangeStatus] = useState(0);
    const [requestorContactID, setRequestorContactID] = useState(0);
  const [locations, setLocations] = useState([
    { itemID: 0, itemName: '-- Select a Company --' },
  ]);
  const [requestors, setRequestors] = useState([
    { itemID: 0, itemName: '-- Select a Company --' },
  ]);
  const [requestTypes, setRequestTypes] = useState([
    { itemID: 0, itemName: 'Loading...' },
  ]);
  const [statuses, setStatuses] = useState([
    { itemID: 0, itemName: 'Loading...' },
  ]);
  const [priorities, setPriorities] = useState([
    { itemID: 0, itemName: 'Loading...' },
  ]);
  const [assignToList, setAssignToList] = useState([
    { itemID: 0, itemName: 'Loading...' },
  ]);
  const [projectList, setProjectList] = useState([
    { itemID: 0, itemName: '-- Select a Company --' },
  ]);
  const [assetList, setAssetList] = useState([
    { itemID: 0, itemName: '-- Select a Company --' },
  ]);
  const [workTypeList, setWorkTypeList] = useState([
    { itemID: 0, itemName: '-- Select a Company --' },
  ]);
  const [teamList, setTeamList] = useState([
    { itemID: 0, itemName: 'Loading...' },
  ]);
  const [incidentInfo, setIncidentInfo] = useState({});

  useEffect(() => {
    const query = window.location.search;
    if (query.match(/AddNote=/)) setAddNote(true);
    setLoading(true);
    loadIncident()
      .then((response) => {
        setIncidentInfo(response.content);
        setNewNoteReassign(response.content.assignedToID);
        setNewNoteChangeStatus(response.content.statusID);
        return GetIncidentParamLists();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    GetIncidentParamLists()
      .then((response) => {
        setRequestTypes(response.requestTypes);
        setStatuses(response.statusList);
        setPriorities(response.priorityList);
        let APIAssignedToList = [...response.assignedToList];
        APIAssignedToList.unshift({ itemID: 0, itemName: 'Unassigned' });
        setAssignToList(APIAssignedToList);
        let APITeamList = [...response.teamList];
        APITeamList.unshift({ itemID: 0, itemName: 'No Team' });
        setTeamList(APITeamList);
        setRequestorContactID(response.requestorContactID);
        return GetIncidentCompanyParamLists(incidentInfo.companyID);
      })
      .then((response) => {
        let APILocationList = [...response.locationList];
        APILocationList.unshift({
          itemID: 0,
          itemName: incidentInfo.companyName,
        });
        setLocations(APILocationList);
        let APIRequestors = [...response.requestorList];
        APIRequestors.unshift({
          itemID: 0,
          itemName: '-- Select a Requestor --',
        });
        setRequestors(APIRequestors);
        let APIProjects = [...response.projectList];
        APIProjects.unshift({ itemID: 0, itemName: 'No Project' });
        setProjectList(APIProjects);
        let APIAssets = [...response.assetList];
        APIAssets.unshift({ itemID: 0, itemName: 'No Asset' });
        setAssetList(APIAssets);
        let APIWorkTypes = [...response.workTypes];
        APIWorkTypes.unshift({
          itemID: 0,
          itemName: '-- Select a Work Type --',
        });
        setWorkTypeList(APIWorkTypes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [incidentInfo]);

  useEffect(() => {
    if (incidentInfo.statusName === 'Completed') setAddNote(false);
  }, [incidentInfo]);

  useEffect(() => {
    if (incidentInfo === null || assignToList.length === 0) return;
    const currentAssignedTo = assignToList.filter(
      (assign) => assign.itemID === incidentInfo.assignedToID
    );
    if (currentAssignedTo.length === 0) {
      let newAssignToList = [...assignToList];
      newAssignToList.unshift({
        itemID: incidentInfo.assignedToID,
        itemName: incidentInfo.assignedToName,
      });
      setAssignToList(newAssignToList);
    }
  }, [incidentInfo, assignToList]);

  const loadIncident = () => {
    return GetIncident(parseInt(props.id, 10));
  };

  return loading || !incidentInfo || !incidentInfo.notes ? (
    <div>
      <h2>Incident Management</h2>
      <div className="tabDisplayArea">
        <Loader />
      </div>
    </div>
  ) : (
    <div>
      <h2>Incident #{incidentInfo.referenceID}</h2>
      <div className="tabDisplayArea" style={{ overflow: 'auto' }}>
        <IncidentHeader
          incidentInfo={incidentInfo}
          addNote={addNote}
          setAddNote={setAddNote}
          setLoading={setLoading}
          locations={locations}
          requestors={requestors}
          requestTypes={requestTypes}
          statuses={statuses}
          priorities={priorities}
          assignToList={assignToList}
          projectList={projectList}
          assetList={assetList}
          workTypeList={workTypeList}
          teamList={teamList}
          editIncident={editIncident}
          setEditIncident={setEditIncident}
          loadIncident={loadIncident}
          setIncidentInfo={setIncidentInfo}
        />
        {incidentInfo.notes.map((note) => (
          <Note
            note={note}
            key={note.noteID}
            setLoading={setLoading}
            loadIncident={loadIncident}
            myContactID={incidentInfo.myContactID}
            statusName={incidentInfo.statusName}
            setIncidentInfo={setIncidentInfo}
            editIncident={editIncident}
          />
        ))}
        {addNote ? (
          <AddNote
            newNoteReassign={newNoteReassign === 0 ? (requestorContactID) : (newNoteReassign)}
            setNewNoteReassign={setNewNoteReassign}
            newNoteChangeStatus={newNoteChangeStatus}
            setNewNoteChangeStatus={setNewNoteChangeStatus}
            setLoading={setLoading}
            loadIncident={loadIncident}
            setAddNote={setAddNote}
            setIncidentInfo={setIncidentInfo}
            assignToList={assignToList}
            incidentStatuses={statuses}
            incidentID={props.id}
            loadMissingTime={props.loadMissingTime}
          />
        ) : null}
        {incidentInfo.statusName !== 'Completed' &&
        !addNote &&
        !editIncident ? (
          <input
            type="button"
            className="nexusButtonCommon"
            value="Add Note"
            onClick={() => setAddNote(true)}
          />
        ) : null}
      </div>
    </div>
  );
};
export default Incident;
