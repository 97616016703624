import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../App/loader';
import {
    sortIncidents,
    cleanseSummary,
    changeSort,
    colIcon
} from '../../utils/incidentUtils';

import { GetProjectIncidents } from '../../api/ProjectAPI';

const ProjectIncidents = props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loading, setLoading] = useState(false);
    const [descending, setDescending] = useState(true);
    const [sortCol, setSortCol] = useState("referenceID");
    const [projectID, setProjectID] = useState(props.incidents);
    const [incidents, setIncidents] = useState(props.incidents);
    const [error, setError] = useState("");
    useEffect(() => {
        setIncidents(sortIncidents(incidents, sortCol, descending))
    }, [sortCol, descending])
    return (
        <div className="dashboardWidgetContainer">
            {loading ? 
                <div className="NexusLoading">
                    <Loader />
                </div>
            : null}
            <div className="widgetHeader">My Incidents</div>
            <div className="widgetBody">
                {error !== "" ? (
                    <div className="Error PaddedError">{error}</div>
                ) : incidents.length === 0 ? (
                    <div className="paddedBold">There are no unassigned incidents.</div>
                ) : (
                    <div style={{width: "100%"}}>
                        <table className="nextTable incidentTable">
                            <tbody>
                                <tr>
                                    <th onClick={() => changeSort(sortCol, 
                                        "referenceID", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Incident ID
                                        {colIcon(sortCol, "referenceID", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "companyName", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Company
                                        {colIcon(sortCol, "companyName", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "requestedByName", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Requestor
                                        {colIcon(sortCol, "requestedByName", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "summary", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Summary
                                        {colIcon(sortCol, "summary", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "priorityName", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Priority
                                        {colIcon(sortCol, "priorityName", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "statusName", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Status
                                        {colIcon(sortCol, "statusName", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "incidentDateFormatted", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Date
                                        {colIcon(sortCol, "incidentDateFormatted", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "budgetHoursFormatted", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Budget Hours
                                        {colIcon(sortCol, "budgetHoursFormatted", descending)}
                                    </th>
                                    <th onClick={() => changeSort(sortCol, 
                                        "hoursFormatted", descending, setSortCol, 
                                        setDescending)} 
                                        style={{cursor: "pointer"}}>
                                        Hours
                                        {colIcon(sortCol, "hoursFormatted", descending)}
                                    </th>
                                </tr>
                                {incidents.map(incident => 
                                    <tr key={incident.incidentID}>
                                        <td>
                                            <Link to={`/${tenant}/Incidents/${incident.incidentID}`} className="incidentRefLink">
                                                {incident.referenceID}
                                            </Link>
                                        </td>
                                        <td>{incident.companyName}</td>
                                        <td>{incident.requestedByName}</td>
                                        <td>
                                            <div className="incidentSummary-Project" dangerouslySetInnerHTML={{ __html: cleanseSummary(incident.summary)}} />
                                        </td>
                                        <td>{incident.priorityName}</td>
                                        <td>{incident.statusName}</td>
                                        <td>{incident.incidentDateFormatted}</td>
                                        <td className="incidentSummary-Hours">{incident.budgetHours}</td>
                                        <td className="incidentSummary-Hours">{incident.totalHours}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
export default ProjectIncidents;