import React, { useState, useRef } from 'react';
import Loader from '../../App/loader';
import NexusGrid from '../../App/NexusGrid';
import NexusModal from '../../App/NexusModal';
import { GetOrganizationServices, GetServiceList } from '../../../api/ServiceAPI';
import { SetOrganizationToService, GetOrganizationCanReceiveService } from '../../../api/OrganizationAPI';
import { useSelector } from 'react-redux';


export default (props) => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [displayModal, setDisplayModal] = useState(false);
    const [orgServices, setOrgServices] = useState([]);
    const [currentServiceAdd, setCurrentServiceAdd] = useState(0);
    const [servicesToAdd, setServicesToAdd] = useState([]);
    const [serviceAdded, setServiceAdded] = useState(0);
    const [canReceiveServices, setCanReceiveServices] = useState(false);

    const firstRender = useRef(true);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filterResults, setFilterResults] = useState([]);
    const [sortByResults, setSortByResults] = useState([]);
    const [loadingFilterSort, setLoadingFilterSort] = useState(false);

    const loadOrgServices = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        let id = parseInt(props.org.orgID, 10);
        if(id !== 0 && !isNaN(id)) {
            setFilterResults(filters);
            setSortByResults(sortBy);
            firstRender.current ? setLoading(true) : setLoadingFilterSort(true);
            firstRender.current = false;
            GetOrganizationServices({content: id, pageSize: pageSize, pageIndex, sortBy, filters})
                .then((response) => {
                    setLoadingFilterSort(false);
                    setLoading(false);
                    if (response.success) {
                        setOrgServices(response.content);
                        setTotalResults(response.totalCount);
                        setTotalPages(response.pageCount);
                    } else {
                        setMessage(response.message);
                    }
                })
                .catch(() => {
                    setMessage('Error');
                    setLoadingFilterSort(false);
                    setLoading(false);
                });
        }
    }, []);

    const openAddModal = () => {
        setDisplayModal(true);
        GetServiceList(true, false, props.org.orgID).then(response => {
            setLoading(false);
            if (response.success) {
                setServicesToAdd(response.services);
                setCurrentServiceAdd(response.services[0].serviceId);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    const handleServiceChange = (e) => {
        setCurrentServiceAdd(e.target.value);
    }

    const addServiceToOrg = () => {
        SetOrganizationToService(currentServiceAdd, props.org.orgID).then(response => {
            setLoading(false);
            if (response.success) {
                setDisplayModal(false);
                setServiceAdded(currentServiceAdd);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    const getCanReceiveService = () => {
        GetOrganizationCanReceiveService(props.org.orgID).then(response => {
            if(response.success) {
                setCanReceiveServices(response.canReceiveServices);
            }
        });
    }

    const headers = [
        { Header: 'Service', accessor: 'serviceName' },
        { Header: 'Service ID', accessor: 'serviceId' },
        { Header: 'Description', accessor: 'serviceDescription' },
        { Header: 'Hooks', accessor: 'hooks' },
    ];

    return (
        <div>
            <h2>Services</h2>
            {canReceiveServices
            ? <button onClick={openAddModal} className="nexusButtonCommon"><i className="fas fa-plus"></i>Add</button>
            : null}
            
            <NexusModal stateValue={displayModal} stateFunctionClose={() => setDisplayModal(false)} title="Select a Service" closeText="Add Service"
            closeAction={() => addServiceToOrg()} width='50%' height='auto'>
                <select onChange={handleServiceChange} style={{ width: '100%' }}>
                    {servicesToAdd.map((service) => <option key={service.serviceId} value={service.serviceId}>{service.serviceName}</option>)}
                </select>
            </NexusModal>
            <NexusGrid
                linkTo={`/${tenant}/Services/:key/organizations/${ props.org.orgID }`}
                tableKey={'serviceId'}
                hiddenColumns={['serviceId']}
                data={orgServices}
                defaultSortBy={'serviceId'}
                columns={headers}
                fetchData={loadOrgServices}
                filterData={filterResults}
                sortByData={sortByResults}
                loading={loading}
                loadingFilterSort={loadingFilterSort}
                pageMode="remote"
                perPage={25}
                totalResults={totalResults}
                totalPages={totalPages}
            />
            {message}
        </div>
    );
};
