import store from '../Redux/store';

const APIRequest = params => {
    const state = store.getState();
    const tenant = state.TenantReducer.Tenant;

    return new Promise((resolve, reject) => {
        let nexusToken = localStorage.getItem("nexusToken");
        if (nexusToken === null)
            nexusToken = sessionStorage.getItem("nexusToken");

        const headers = {
            'Accept': params.respType === 'raw' ? 'blob' : 'application/json',
            'Content-Type': 'application/json'
        };

        if (nexusToken !== null)
            headers['Authorization'] = `Bearer ${nexusToken}`

        fetch(
            `/api/${tenant}/${params.url}`, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body),
            mode: params.cors === false ? 'no-cors' : 'cors'
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok) {
                throw Error(response)
            }
            return response.json();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error });
        });
    });
}

export default APIRequest;
