import store from '../Redux/store';

export const CredentialFileAPIRequest = params => {
    const state = store.getState();
    const tenant = state.TenantReducer.Tenant;

    let nexusToken = localStorage.getItem("nexusToken");
    if (nexusToken === null)
        nexusToken = sessionStorage.getItem("nexusToken");

    return ({
        url: `/api/${tenant}/Credential`,
        process: {
            url: "/UploadCredentialFile",
            method: "post",
            headers: {
                Authorization: `Bearer ${nexusToken}`
            },
            ondata: (formData) => {
                formData.append("CredentialID", params.credentialID);
                return formData;
            },
            onload: () => {params.reload()}
        },
        load: null,
        revert: null,
        restore: null,
        fetch: null,
        patch: null
    });
}
