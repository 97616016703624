import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import toolbarConfig from '../App/toolbarconfig';

import { EditNoteAPI } from '../../api/IncidentAPI';

const Note = props => {
    const [editNoteID, setEditNoteID] = useState(0);
    const [editNoteHoursDecimal, setEditNoteHoursDecimal] = useState(0.08);
    const [editNoteHours, setEditNoteHours] = useState(0);
    const [editNoteMinutes, setEditNoteMinutes] = useState(5);
    const [editNoteType, setEditNoteType] = useState(31);
    const [editNoteDate, setEditNoteDate] = useState(new Date());
    const [editNoteText, setEditNoteText] = useState("");
    const editNote = note => {
        setEditNoteID(note.noteID);
        setEditNoteDate(new Date(Date.parse(note.noteDate)));
        editNoteHoursDecimalChanged(note.hours);
        setEditNoteType(note.noteTypeID);
        setEditNoteText(note.noteText);
    }
    const editNoteHoursDecimalChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 20 ? 20 : value < 0 ? 0 : value
        setEditNoteHoursDecimal(adjustedValue);
        const hours = Math.floor(adjustedValue);
        const minutes = Math.round((adjustedValue - hours) * 60);
        setEditNoteHours(hours);
        setEditNoteMinutes(minutes);
    }
    const editNoteHoursChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 21 ? 20 : value < 0 ? 0 : value;
        setEditNoteHours(adjustedValue);
        const adjustedNoteHoursDecimal = parseFloat(adjustedValue) + parseFloat(editNoteMinutes / 60);
        setEditNoteHoursDecimal(isNaN(parseFloat(adjustedNoteHoursDecimal)) ?
            0 : adjustedNoteHoursDecimal.toFixed(2));
    }
    const editNoteMinutesChanged = value => {
        const adjustedValue = isNaN(value) ? 0 : value >= 60 ? 59 : value < 0 ? 0 : value;
        setEditNoteMinutes(adjustedValue);
        const adjustedNoteHoursDecimal = parseInt(editNoteHours, 10) + (adjustedValue / 60)
        setEditNoteHoursDecimal(isNaN(parseFloat(adjustedNoteHoursDecimal)) ?
            0 : adjustedNoteHoursDecimal.toFixed(2));
    }
    const saveNote = () => {
        props.setLoading(true);
        EditNoteAPI({
            noteID: editNoteID,
            noteDate: editNoteDate,
            noteType: parseInt(editNoteType, 10),
            hours: parseFloat(editNoteHoursDecimal),
            noteText: editNoteText
        }).then(response => {
            if (!response.success) {
                console.log(response);
                return;
            }
            setEditNoteID(0);
            setEditNoteType(31);
            setEditNoteHoursDecimal(0.00);
            setEditNoteHours(0);
            setEditNoteMinutes(0);
            setEditNoteText("");
            return props.loadIncident();
        }).then(response => {
            props.setIncidentInfo(response.content);
            props.setLoading(false);
        }).catch(err => {
            console.log(err);
            props.setLoading(false);
        })
    }
    return (
        <div className="incidentNote" key={props.note.noteID}>
            {props.note.noteID === editNoteID ? (
                <div>
                    <table className="nextTable incidentTable">
                        <tbody>
                            <tr>
                                <th colSpan="2">Edit Note</th>
                            </tr>
                            <tr>
                                <th style={{ width: "180px" }}>Note Date / Time:</th>
                                <td>
                                    <DateTimePicker value={editNoteDate}
                                        onChange={date => setEditNoteDate(date)}
                                        clearIcon={null} />
                                </td>
                            </tr>
                            <tr>
                                <th>Note Type:</th>
                                <td>
                                    <select value={editNoteType} onChange={e => setEditNoteType(e.target.value)}>
                                        <option value={31}>Tech Note</option>
                                        <option value={32}>Internal Note</option>
                                    </select>
                                </td>
                            </tr>
                            {parseInt(editNoteType, 10) === 31 ? (
                                <tr>
                                    <th>Hours:</th>
                                    <td>
                                        <input type="number" step="0.01"
                                            value={editNoteHoursDecimal}
                                            style={{ width: "45px" }}
                                            onChange={e => editNoteHoursDecimalChanged(e.target.value)}
                                            onBlur={e => {
                                                if (isNaN(parseFloat(e.target.value))) {
                                                    editNoteHoursDecimalChanged(0)
                                                }
                                            }} />
                                        <span style={{ marginLeft: "14px" }}>
                                            (<input type="number" value={editNoteHours} style={{ width: "35px" }}
                                                onChange={e => editNoteHoursChanged(e.target.value)}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        editNoteHoursChanged(0);
                                                    }
                                                }} /> Hours
                                            <input type="number" value={editNoteMinutes}
                                                style={{ width: "35px", marginLeft: "8px" }}
                                                onChange={e => editNoteMinutesChanged(e.target.value)}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        editNoteMinutesChanged(0);
                                                    }
                                                }} /> Minutes)
                                        </span>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                    <ReactQuill value={editNoteText} onChange={e => setEditNoteText(e)}
                        modules={{ toolbar: toolbarConfig }} />
                    <div style={{ backgroundColor: "#a20107", textAlign: "center", padding: "8px" }}>
                        <input type="button" className="nexusButtonCommon" value="Save Note"
                            onClick={() => saveNote()} />
                        <input type="button" className="nexusButtonCommon" value="Cancel"
                            onClick={() => setEditNoteID(0)} />
                    </div>
                </div>
            ) : (
                    <div>
                        <table className="nextTable noteTable">
                            <tbody>
                                <tr>
                                    <th style={{ width: "150px" }}>{props.note.noteDateFormatted}</th>
                                    <th style={{ textAlign: "left" }}>{props.note.creatorName}</th>
                                    <th style={{ width: "100px", textAlign: "left" }}>{props.note.noteTypeName}</th>
                                    <th style={{ width: "90px" }}>{props.note.hours.toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </table>
                        <div className="noteDisplayArea">
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.note.noteText) }} />
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            {props.statusName !== "Completed" &&
                                                props.note.creatorID === props.myContactID &&
                                                !props.editIncident ? (
                                                    <input type="button" className="nexusButtonCommon"
                                                        value="Edit Note"
                                                        onClick={() => editNote(props.note)} />
                                                ) : null}
                                        </td>
                                        <td style={{ textAlign: "right", fontStyle: "italic", fontSize: "8pt" }}>
                                            Creation Date: {props.note.createdDateFormatted}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default Note;