import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CredentialTypeTable from './CredentialTypeTable.js';
import CredentialTypeForm from './CredentialTypeForm.js';
import CredentialTypeEdit from './CredentialTypeEdit.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    return (
        <Switch>
            <Route exact path={`/${tenant}/credentialTypes`} render={(routeProps) =>
                <CredentialTypeTable routeProps={routeProps} />
            } />
            <Route path={`/${tenant}/credentialTypes/add`} render={(routeProps) =>
                <CredentialTypeForm routeProps={routeProps} />
            } />
            <Route path={`/${tenant}/credentialTypes/:typeID`} render={(routeProps) =>
                <CredentialTypeEdit routeProps={routeProps} />
            } />
        </Switch>
    );
}