import React, { useState, useEffect } from 'react'
import NexusAPI from '../../nexusapi'

const AssetRemoteAccess = (props) => {
    const [loading, setLoading] = useState(true);
    const [fileAPI] = useState(new NexusAPI("Sierra"));

    useEffect(() => {
       
    }, [props])

    const DownloadRDP = () => {
        setLoading(true);
        let download = require('downloadjs');
        fileAPI.getFile({
            controller: "Asset",
            action: "GetAssetRDPConnection",
            method: "POST",
            body: { content: parseInt(props.AssetTunnelID) },
            success: (data) => {
                if (data != null) {
                    data.blob().then(function (myBlob) {
                        console.log(myBlob);
                        download(myBlob, `${props.AssetName}.rdp`, myBlob.type)
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            },
            error: (error) => {
                console.log(error);
                setLoading(false);
            }
        })
        setLoading(false);
    }

    return (
            <button className="nexusButtonCommon" onClick={(e) => DownloadRDP()}> <i className="fas fa-network-wired"></i> Remote Desktop</button>
    )
}

export default AssetRemoteAccess
