import React, { useRef, useContext, useEffect, useState } from 'react';
import { Accordion, NavLink } from 'react-bootstrap';
import { SetUserDashboardAccordion } from '../../api/NavigationAPI';
import { CollapsedContext } from './dashboard';

const AccordionWidget = ({title, children, dashboardPreference}) => {
    const collapsedContext = useContext(CollapsedContext);
    const [currentCollapseState, setCurrentCollapseState] = useState("0");

    useEffect(() => {
        if (collapsedContext && dashboardPreference) {
            let prefs = Object.keys(collapsedContext);
            for(let i = 0; i < prefs.length; i++) {
                if(prefs[i].toLowerCase() == dashboardPreference.toLowerCase()) {
                    setCurrentCollapseState(Object.values(collapsedContext)[i] ? "1" : "0" );
                }
            }
        }
    }, [collapsedContext, dashboardPreference]);

    const updatePreference = () => {
        const newCollapsedVal = parseInt(currentCollapseState) ? 0 : 1;
        SetUserDashboardAccordion({
            widget: dashboardPreference,
            collapsed: newCollapsedVal
        }).then(response => {
            setCurrentCollapseState(String(newCollapsedVal));
            console.log(response);
        }).catch((err) => {
            console.log(err);
        });
        
    }

    return (
        <Accordion defaultActiveKey={currentCollapseState} activeKey={currentCollapseState} className="dashboardWidgetContainer">
            <div className="widgetHeader row no-gutters">
                <div className="col-2"></div>
                <div className="col-8">{title}</div>
                <div className="col-2">
                    <Accordion.Toggle variant="link" as={NavLink} eventKey="0" className="text-white p-0 text-right"
                    onClick={() => dashboardPreference ? updatePreference() : null}>
                        <i className="fa fa-solid fa-angle-down" style={{ verticalAlign: 'middle' }}></i>
                    </Accordion.Toggle>
                </div>
            </div>
            <Accordion.Collapse eventKey="0">
                {children}
            </Accordion.Collapse>
        </Accordion>
    );
}

export default AccordionWidget;