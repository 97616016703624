import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loader from '../../App/loader';

import { ProgressServiceOrgTransition } from '../../../api/ServiceAPI';
import { GetOrganization } from '../../../api/OrganizationAPI';
import { GetPackageListOptions, CreateAccount } from '../../../api/WHMServiceAPI';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [packageOptions, setPackageOptions] = useState([]);
    //const [organization, setOrganization] = useState({});
    const [domain, setDomain] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [pack, setPackage] = useState(""); //package is a reserved word... :-(

    useEffect(() => {
        loadPackageListOptions();
    });

    /*
    useEffect(() => {
        const orgID = parseInt(props.routeProps.match.params.id, 10);
        //loadOrganization(orgID);
    }, [props.routeProps.match.params.id]);
    */

    const loadPackageListOptions = () => {
        setLoading(true);
        GetPackageListOptions()
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setPackageOptions(res.content);
                    }
                }
            })
        setLoading(false);
    }

    /*
    const loadOrganization = (orgID) => {
        setLoading(true);
        GetOrganization({ orgID: orgID })
            .then(res => {
                if (res.success) {
                    if (res.organizations[0].orgID != null) {
                        setOrganization(res.organizations[0]);
                    }
                }
            })
        setLoading(false);
    }
    */

    const createAccount = () => {
        setLoading(true);
        CreateAccount({
            organizationID: parseInt(props.routeProps.match.params.orgId, 10),
            domain: domain,
            username: username,
            email: email,
            package: pack
        })
            .then(res => {
                if (res.success) {
                    progressServiceOrgTransition();
                } else {
                    setMessage(res.message);
                }
            })
        setLoading(false);
    }

    const progressServiceOrgTransition = () => {
        setLoading(true);
        ProgressServiceOrgTransition({ id: parseInt(props.routeProps.match.params.transId, 10) })
            .then(res => {
                if (res != null) {
                    if (res.success) {
                        setRedirect(true);
                    }
                }
            })
        setLoading(false)
    }

    const submit = (e) => {
        e.preventDefault();
        if (username === "") {
            setMessage("Cannot save: username field empty.");
            return;
        } 
        if (email === "") {
            setMessage("Cannot save: email field empty.");
            return;
        } 
        if (domain === "") {
            setMessage("Cannot save: domain field empty.");
            return;
        } 
        if (pack === "") {
            setMessage("Cannot save: pack field empty.");
            return;
        } 
        createAccount();
    }

    return (loading ?
        <div className="NexusLoading">
            <Loader />
        </div> :
        <div>
            {redirect &&
                <Redirect to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}/transitions/${props.routeProps.match.params.transId}`} />
            }

            <BreadcrumbsItem to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}`} label='ServiceOrg' />
            <BreadcrumbsItem to={`/${tenant}/services/${props.routeProps.match.params.serviceId}/organizations/${props.routeProps.match.params.orgId}/transitions/${props.routeProps.match.params.transId}`} label='Transition' />
            
            <h3>Create CPanel Account</h3>
            { /*organization.name*/ }
            <div className="nexusForm">
                <form className="nexusFormInner" onSubmit={(e) => submit(e)}>
                    <table className="nexusFormInner">
                        <tbody>
                            <tr>
                                <td>Username:</td>
                                <td><input type="text" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Domain:</td>
                                <td><input type="text" name="domain" id="domain" value={domain} onChange={(e) => setDomain(e.target.value)} /></td>
                                <td>*</td>
                            </tr>
                            <tr>
                                <td>Package:</td>
                                <td>
                                    <select name="packageSelect" id="packageSelect" value={pack} onChange={(e) => setPackage(e.target.value)}>
                                        {pack === "" && <option value="" />}
                                        {packageOptions?.map((po) =>
                                            <option key={po} value={po}>{po}</option>
                                        )}
                                    </select>
                                </td>
                                <td>*</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nexusFormSubmit">
                        <input type="submit" name="CreateAccount" id="CreateAccount" value="Create Account" className="nexusButton" />
                    </div>
                    {message}
                </form>
            </div>
        </div>
    )
}