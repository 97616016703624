import React, {useState ,useEffect} from 'react';
import { GetOrganizationCarriers } from '../../api/TelephonyAPI.js';
import LoadingShroud from '../App/LoadingShroud.js';
import NexusGrid from '../App/NexusGrid';
import TelephonyImportExport from './telephonyimportexport.js';
import { useSelector } from 'react-redux';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [carriers, setCarriers] = useState([]);

    useEffect(()=>{
        loadItems(filterActive);
    },[]);

    const loadItems = (filterAct) => {
        const orgID = props.routeProps.match.params.id
        GetOrganizationCarriers(Number(orgID), { content: { active: !filterAct}})
            .then(res => {
                if (res != null) {
                    //console.log("Should be carriers", res)
                    for (var i = 0; i < res.content.length; i++) {
                        if (res.content[i].name === "") {
                            res.content[i].name = "(blank)";
                        }
                    }
                    setCarriers(res.content);
                    setLoaded(true);
                    setFilterActive(filterAct);
                } else {
                    setLoaded(false);
                    setError(true);
                    setFilterActive(filterAct);
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    const filterChanged = (e) => {
        loadItems(e.target.checked);
    }

        const headers = [
            { Header: "Carrier ID", accessor: "carrierid" },
            { Header: "Name", accessor: "name" }
        ];
        return (
            <div>
                <LoadingShroud Active={!loaded} />
                {loaded ? (
                    <div>
                        <div className="telephony-tab-heading">Carriers</div>
                        <TelephonyImportExport
                            routeProps={props.routeProps}
                            objectType={"Carrier"}
                            importCallback={() => loadItems(filterActive)} />
                        <div className="telephony-object-filters">
                            <label>Show Inactive</label>
                            <input type="checkbox" onChange={(e) => filterChanged(e)} checked={filterActive} id="" />
                        </div>
                        <NexusGrid
                            linkTo={"/${tenant}/organizations/" + props.routeProps.match.params.id + "/carrier/:key"}
                            tableKey={"carrierid"}
                            data={carriers}
                            defaultSortBy={"name"}
                            columns={headers}
                            filterHeaders={false}
                            pageMode={"none"}
                        />
                    </div>
                ) : error ? (
                    <p>An error occurred while loading carrier data.</p>
                ) : null
                }
            </div>
        )
}