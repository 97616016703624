import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetOrganizationCarrier, EditOrganizationCarrier } from '../../api/TelephonyAPI.js';
import Loader from '../App/loader.js';

export default props => {
    const tenant = useSelector(state => state.TenantReducer.Tenant);
    const [loaded,setLoaded] = useState(false);
    const [error,setError] = useState(false);
    const [canEdit,setCanEdit] = useState(true);
    const [theCarrier, setTheCarrier]  = useState({});

    useEffect(()=>{
        const carrierID = props.routeProps.match.params.carrierid;
        const orgID = props.routeProps.match.params.id;
        GetOrganizationCarrier(Number(orgID), { content: { carrierID: Number(carrierID)}})
            .then(res => {
                if (res != null) {
                    if (res.content.length === 1){
                        setLoaded(true);
                        setTheCarrier(res.content[0]);
                    }
                    else{
                        setLoaded(false);
                        setError(true);
                    }
                } else {
                    setLoaded(false);
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
    },[])

    const carrierChanged = (e) => {
        var carrier = theCarrier;
        carrier[e.target.name] = e.target.value;
        setTheCarrier(carrier);
    }

    const savecarrier = () => {
        setLoaded(false);
        setError(false);
        EditOrganizationCarrier({
            content: {
                carrierid: theCarrier.carrierid,
                orgid: theCarrier.orgid,
                name: theCarrier.name
            }
        }).then(res => {
            if (res != null) {
                if (res.content){
                    setTheCarrier(res.content);
                    setLoaded(true)
                }
                else{
                    setLoaded(false);
                    setError(true);
                } 
            } else {
                setLoaded(false);
                setError(true);
            }
        })
            .catch(() => {
                setError(true);
            })
    }

        return (
            <div>
                {loaded ? (
                    <div>
                        <h3>{theCarrier.name}</h3>
                        <table cellPadding="0" cellSpacing="0" className="infoTable">
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td><input type="text" name="name" onChange={carrierChanged} value={theCarrier.name} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <hr size="3" />
                        <p>
                            <Link to={`/${tenant}/organizations/` + props.routeProps.match.params.id + "/carrier/" + theCarrier.carrierid} className="nexusButtonCommon">Back</Link>
                            <button className="nexusButtonCommon" onClick={savecarrier}> Save </button>
                        </p>

                    </div>
                ) : error ? (
                    <p>An error occurred while loading company data.</p>
                ) : (
                            <Loader />
                        )}
            </div>
        )
}