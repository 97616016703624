import APIRequest from './APIRequest';

export const CheckUserNavigation = (path) => {
    return APIRequest({
        url: `Navigation/CheckuserNavigation`,
        method: 'post',
        body: { route: path }
    });
}

export const GetUserNavigation = () => {
    return APIRequest({
        url: `Navigation/GetUserNavigation`,
        method: 'get',
    });
}

export const GetUserDashboardAccordion = () => {
    return APIRequest({
        url: `Navigation/GetUserDashboardAccordion`,
        method: 'get',
    });
}

export const SetUserDashboardAccordion = (body) => {
    return APIRequest({
        url: `Navigation/SetUserDashboardAccordion`,
        method: 'post',
        body
    });
}